import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import './Leadership.scss';

export default function Leadership() {
  return (
    <Row className="leadership mx-0">
      <Col md={{ span: 4 }} xs={12} className="col">
        <div className="leaderName">
          <Image src="leadership/SanjayMandavia.png" alt="Sanjay Mandavia" />
          <div>
            Capt. Sanjay Mandavia (MD)
          </div>
        </div>
        <hr />
        <div className="content">
          <p>
            <strong>
              Founding member of India's top simulator center Flight Simulator Technique Centre (FSTC) and has grown business from 2 simulators to 9 simulators. From a business turnover of ₹ 6 crore in FY 2012 to ₹ 150 crore in FY20 show his entrepreneurial skills and has successfully raised $10M from PE investors
            </strong>
          </p>
          <ul>
            <li>Aviation expert with 20+ years of experience and a highly experienced pilot with achievements in both start-up and established business ventures</li>
            <li>Senior Commander on Airbus A330 with over 11000 hours of commercial flying</li>
            <li>DGCA approved check pilot on Airbus 330 for Kingfisher Airlines</li>
          </ul>
        </div>
        <hr />
      </Col>
      {/* <Col md={{ span: 4 }} xs={12} className="col">
        <div className="leaderName">
          <Image src="leadership/SrinivasRao.png" alt="Srinivas Rao" />
          <div>
            Capt. Srinivas Rao (CEO)
          </div>
        </div>
        <hr />
        <div className="content">
          <p>
            <strong>
              Aviation Flying and Administration with over 32 years of experience including 24 years of training experience. Developed MPL program and EBT for Airline training
            </strong>
          </p>
          <ul>
            <li>Senior TRE on A320/ A330 with 21000 hours of flying and simulators experience</li>
            <li>Holds Masters degree in Aviation Management with Research theses on Decision Making by Airline Pilots</li>
            <li>Worked with top notch Domestic & International Airlines like Indian Airlines, Jet Airways, Malaysian Airlines, Sri-Lankan holding senior positions</li>
            <li>Worked on projects including setting up of Flying club, Acquisition and Due-diligence on airline takeover and integration of airlines</li>
          </ul>
        </div>
        <hr />
      </Col> */}
    </Row>
  );
}
