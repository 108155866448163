/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, } from 'react-router-dom';
import App from './App';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTIC_TRACKING } from "./config/config";

import './fonts/Avenir.ttc';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

if (GOOGLE_ANALYTIC_TRACKING) {
  //Initialize google analytics page view tracking
  ReactGA.initialize(GOOGLE_ANALYTIC_TRACKING, {
    debug: true
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
