import React from 'react';
import '../FAQItems/FAQItem.scss';
// import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
// import { HOME_URL, SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'wearMask',
    header: 'Do I need to wear a mask all through my journey?',
    body: (
      <p>
        Yes. As per the government guidelines for air passengers, you are requested to wear your mask at all time as a part of your personal hygiene. To know more about air travel guidelines,
        <a href="https://www.flybig.in/airTravelGuidelines">www.flybig.in/air travel guidelines</a>
      </p>
    ),
  },
  {
    id: 'foodBeverage',
    header: 'Will food and beverages be served on the flight?',
    body: (
      <p>
        Food and beverage services on the flight have been suspended temporarily. You are advised not to consume any food on board the flight. You can have your meals at the airport eateries before boarding.
      </p>
    ),
  },
  {
    id: 'sickInFlight',
    header: 'What to do if a traveller starts feeling sick in-flight?',
    body: (
      <p>
        If a co-passenger is unwell on the aircraft, then it should be brought to the notice of the crew. Every airline, including flybig, has established procedures to minimise the risk to others in such cases.
      </p>
    ),
  },
  {
    id: 'flightPrecaution',
    header: 'What precaution do I need to take in-flight while travelling?',
    body: (
      <p>
        For in-flight you can take precautions as follows:
        <ul>
          <li>Washing hands frequently</li>
          <li>Avoiding touching the mouth, nose, or face</li>
          <li>Practising social distancing</li>
          <li>Carrying a hand sanitizer for frequent use</li>
          <li>Avoiding touching surfaces such as trays or handles in an airplane</li>
        </ul>
        The above are necessary precautionary measures you can follow. To know more, you can visit the official
        {' '}
        <a href="???">WHO website</a>
        .
      </p>
    ),
  },
];

export default function Inflight({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              id={item.id}
              selectedEventKey={eventKey}
              header={item.header}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
