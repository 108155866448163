/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FaArrowRight as ArrowRight, FaCheck } from 'react-icons/fa';
import { FaCaretRight as Right, FaCaretDown as Down } from 'react-icons/fa';
import { HOME_URL } from '../../config/config';
import './COC.scss';
import { PHONE_NUMBER } from '../../constants';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';

export default function COC() {

	const [activeKey, setActiveKey] = useState(0);
	const [activeKeySub, setActiveKeySub] = useState(0);
	// useEffect(() => {
	// 	const currentPath = location.pathname;
	// 	if (currentPath == '/coc/independence-week_offer-offer') setActiveKey(6);
	// }, [])

	return (
		<div className="COC">

			<h3>Conditions of carriage</h3>
			<Accordion activeKey={activeKey} onSelect={e => setActiveKey(e)}>
				<MyCard header="1) Definitions" eventKey={1}>
					<p>
						The capitalized terms used in these Conditions of Carriage will have the meanings ascribed to them
						below.
					</p>

					<p>
						<strong>“Baggage”</strong>
						{' '}
						– means the guest's personal property carried along by him/her in connection with the
						trip to be undertaken. Unless otherwise specified, it consists of both checked-in baggage and hand
						baggage.
					</p>

					<p>
						<strong>“Baggage Check”</strong>
						{' '}
						– means the tag/receipt issued by flybig to a guest that relates to the carriage
						of the guest's checked-in baggage and is affixed to the boarding pass.
					</p>

					<p>
						<strong>“Baggage Identification Tag”</strong>
						{' '}
						– means a document issued solely for identification of checked-in
						baggage and is attached to each such checked-in baggage.
					</p>

					<p>
						<strong>“Booking”</strong>
						{' '}
						– means a booking made in flybig’s database subject to the receipt by flybig, of the full
						payment, which is the primary record of the reservation.
					</p>
					<p>
						<strong>“Checked-in baggage”</strong>
						{' '}
						– means such baggage which is carried by flybig for the guest, and for
						which flybig has issued a baggage Check to the guest upon check-in. Checked-in baggage cannot
						be carried as a hand baggage by the guest.
					</p>
					<p>
						<strong>“CGST”</strong>
						{' '}
						– means tax leviable on provision of goods and services under the Central Goods and
						Services Tax Act, 2017
					</p>
					<p>
						<strong>“Conditions of Carriage”</strong>
						{' '}
						– means these conditions, i.e. the contract which governs the carriage of
						guests on flybig carriers for domestic air travel and terms of bookings made by guests, as are
						available on flybig’s website and are incorporated by reference in the Itinerary/Receipt/Invoice
						delivered with the guest's booking and notices or communications provided to guests from
						time to time. The Conditions of Carriage are subject to changes as may be introduced by flybig from
						time to time.
					</p>
					<p>
						<strong>“Confirmed booking”</strong>
						{' '}
						– means the confirmation of a booking generated to the guest upon
						receipt of full payment from the guest in respect of his/her booking, and the issuance of a valid
						confirmation number or PNR by flybig or a Travel Partner, as the case may be.
					</p>
					<p>
						<strong>“Connected Segments”</strong>
						{' '}
						– means the connectivity offered by flybig between two Flight Segments
						operated by flybig.
					</p>
					<p>
						<strong>“Credit Note”</strong>
						{' '}
						– means a document issued by flybig pursuant to any adjustment in the taxable value
						of services provided or to be provided by it to the guest including any adjustment on account of
						cancellation of booking, special service requests, etc. as per the applicable policy.
					</p>
					<p>
						<strong>“Guest”</strong>
						{' '}
						– means any person, except members of the crew, carried or to be carried in an aircraft
						by flybig pursuant to a Confirmed booking.
					</p>
					<p>
						<strong>“Damage”</strong>
						{' '}
						– includes death or bodily injury to a guest, loss of or other damage to baggage,
						arising out of or in connection with carriage performed by flybig pursuant to a Confirmed booking.
					</p>
					<p>
						<strong>“Fees”</strong>
						– means charges which are applicable for the provision of service by flybig.
					</p>

					<p>
						<strong>“Flight Segment”</strong>
						{' '}
						– means a single take-off and landing.
					</p>

					<p>
						<strong>“Force Majeure”</strong>
						{' '}
						– means unusual and unforeseeable circumstances beyond flybig’s control, the
						consequences of which could not have been avoided even if all due care had been exercised.
						Circumstances beyond flybig’s control include without limitation, weather, air traffic control,
						meteoroidal conditions, security risks, mechanical failures, acts of terrorism, acts of nature, , political
						instability, strikes, riots, wars, hostilities, disturbances, governmental regulations, orders, demands
						or requirements, shortages of critical manpower, parts or materials, labour unrest.
					</p>
					<p>
						<strong>“GST”</strong>
						{' '}
						- means tax leviable on provision of goods and services and includes SGST or CGST or UTGST
						or IGST or a combination thereof.
					</p>
					<p>
						<strong>“GSTIN”</strong>
						{' '}
						means a unique goods and services tax identification number issued to the person duly
						registered under the GST regulations.
					</p>
					<p>
						<strong>“Hand baggage”</strong>
						{' '}
						– means any of the guest's baggage other than checked-in baggage, which the
						guest is permitted to carry with him/her in the flight in accordance with the Conditions of Carriage
						and other regulations of the applicable regulatory and statuary authorities and applicable laws.
					</p>
					<p>
						<strong>“IGST”</strong>
						{' '}
						– means tax leviable on provision of goods and services under the Integrated Goods and
						Service Tax Act, 2017.
					</p>
					<p>
						<strong>“INR”</strong>
						{' '}
						- means Indian Rupees, the lawful currency of Republic of India.
					</p>
					<p>
						<strong>“Invoice”</strong>
						{' '}
						- means invoice issued by flybig in relation to a Confirmed booking which shall be sent to
						the guest and/or the GSTIN holder, in accordance with the GST regulations.
					</p>
					<p>
						<strong>“Itinerary/Receipt”</strong>
						{' '}
						– means a document or documents that flybig issues to guests travelling with
						a valid booking, that contains the guest's name, flight information, booking reference number,
						Conditions of Carriage extract and/or link and notices.
					</p>
					<p>
						<strong>“PNR”</strong>
						{' '}
						– means a Passenger’s Name Record, which is a unique booking reference number which
						identifies the guest's booking in flybig’s database for a Flight Segment or combination of Flight
						Segments.
					</p>
					<p>
						<strong>“Passenger Service Fee”</strong>
						{' '}
						or
						{' '}
						<strong>“PSF”</strong>
						{' '}
						– means the Passenger Service Fee which is imposed per guest
						per Sector. flybig collects the PSF from the guests as applicable on behalf of the airport operators.
						The PSF as applicable at the time of making the booking shall apply.
					</p>
					<p>
						<strong>“Sector”</strong>
						{' '}
						– means one or more consecutive Flight Segments having the same flight number.
					</p>

					<p>
						<strong>“SGST”</strong>
						{' '}
						– means tax leviable on provision of goods and services under the State Goods and Service
						Tax Acts of the respective state in India.
					</p>

					<p>
						<strong>“Stopover”</strong>
						{' '}
						– means a scheduled stop on the guest's journey, at a point between the place of
						departure and the place of destination.
					</p>

					<p>
						<strong>“Tariff”</strong>
						{' '}
						– means the published fares, GST, fees & charges, airport levies and/or related Conditions of
						Carriage of flybig.
					</p>

					<p>
						<strong>“Travel Partner”</strong>
						{' '}
						– means a travel agency that makes bookings for air travel and other ancillary services
						for flybig flights.
					</p>

					<p>
						<strong>“User Development Fee”</strong>
						{' '}
						or
						{' '}
						<strong>“UDF”</strong>
						{' '}
						– means the User Development Fee as imposed by the relevant
						airport authority at certain airports in India. The UDF as applicable at the time of making the booking
						shall apply.
					</p>

					<p>
						<strong>“UTGST”</strong>
						- means tax leviable on provision of goods and services under Union Territory Goods and
						Services Tax Act, 2017 with respect to the relevant union territory of India.
					</p>

					<p>
						<strong>“Agreed Stopping Places”</strong>
						- Means those Places, except the place of departure and the place of destination, set forth in the ticket or shown in Carrier’s timetables as scheduled stopping places on the guest’s route.
					</p>
					<p>
						<strong>“Carriage”</strong>
						- Means carriage of guests and/or baggage by air.
					</p>

					{ /*
          <p>
            <strong>“Convention”</strong>
            {' '}
            - Which describes about the  instruments is applicable to the contract of carriage.
          </p>
          */ }

					<p>
						<strong>“Days”</strong>
						- Calendar days including all seven days of the week; provided that, for the purpose of notification, the day upon which notice is dispatched shall not be counted; and that for purposes of determining duration of validity the day upon which the ticket is issued or flight commenced, shall not be counted.
					</p>

					<p>
						<strong>“Change without notice”</strong>
						- Except  as may be required by applicable laws , government regulations, orders or requirements, flybig’s Regulations and conditions  of carriage are subject to change without notice ; provided that no such change shall apply to a  contract of carriage after the carriage has commenced from the point of origin.
					</p>

					<p>
						<strong>“Ticket not transferrable”</strong>
						-A ticket is not transferable. If a ticket presented by someone other than the person entitled to be carried there under or to a refund in connection therewith, flybig shall not be liable to the person so entitled if in good faith it provides carriage or makes a refund to the person presenting the ticket.
					</p>

					<p>
						<strong>“Period of validity”</strong>
						- Except as otherwise provided in the ticket, in these condition or flybig’s Regulations a ticket is valid carriage for one year from the date of commencement of travel or if no portion of the ticket is used from the date of issue.
					</p>

					<p>
						<strong>“Extension of validity”</strong>
						- Guest holding a confirmed reservation is prevented from travelling within the period of validity on account of some valid operational reasons.
					</p>

					<p>
						<strong>“Stopovers”</strong>
						- Stopovers may be permitted at agreed stopping places subject to Government requirements and flybig’s regulations. Stopovers must be arranged with flybig in advance and specified on the ticket.
					</p>

					<p>
						<strong>“Applicable Fares”</strong>
						- Applicable fares are those published by or on behalf of flybig, if not so published constructed in accordance with flybig’s regulations.
					</p>

					<p>
						<strong>“Routing”</strong>
						- Unless otherwise provided in flybig’s regulations, fares apply only to routings published in connection therewith.
					</p>

					<p>
						<strong>“Currency”</strong>
						- Fares and charges are payable in any currency acceptable to flybig.
					</p>

					<p>
						<strong>“Reservation Requirements”</strong>
						-Reservations are not confirmed until recorded as accepted by flybig or its authorized agents.
					</p>
					<p>
						<strong>“Ticketing Time Limits”</strong>
						- If a guest has not paid for his or her ticket prior to the specified ticketing time limit, flybig may cancel the reservation.
					</p>

					<p>
						<strong>“Excess value Declaration and charge”</strong>
						- In accordance with the flybig’s regulation, it offers an excess valuation facility, a guest may declare a value for checked in baggage in excess of the applicable liability limits.
					</p>

					<p>
						<strong>“Collection & delivery of baggage”</strong>
						- The guest shall collect his or her baggage as soon as it is available for collection at place of destination or agreed stopping places.
					</p>

					<p>
						<strong>“Guest/Person to whom refund will be made”</strong>
						-There are certain voluntarily & involuntarily nature of refunds & based on the fares availed by the guest.
					</p>

					<p>
						<strong>“Liability for damage”</strong>
						-flybig is subject to the rules and limitations relating to liability established by the convention.
					</p>

					<p>
						<strong>“We”</strong>
						/
						<strong>“our”</strong>
						{' '}
						/
						<strong>“ourselves”</strong>
						{' '}
						/
						<strong>“us”</strong>
						{' '}
						/
						<strong>“Carrier”</strong>
						{' '}
						/
						<strong>“flybig”</strong>
						{' '}
						– means Big Charter Pvt. Ltd. having
						registered address at [304, 3 rd Floor, Cosmos Court-Premises SOC Ltd., SV Road, Vile Parle (W),
						Mumbai, Maharashtra 400056, India.]
					</p>

					<p>
						<strong>“Website”</strong>
						{' '}
						– means the internet site
						<a href={`${HOME_URL}`}>www.flybig.in</a>
						provided by flybig on which the guests can
						make bookings and also to access information about flybig.
					</p>
				</MyCard>

				<MyCard header="2) Interpretation" eventKey={2}>
					<p>
						The title or caption of each Article of these Conditions of Carriage is for convenience only and is not
						to be used for interpretation of the text.
					</p>
				</MyCard>

				<MyCard header="3) Applicability" eventKey={3}>
					<p>
						These Conditions of Carriage will solely apply for the origin and destination of a Flight Segment
						within India.
					</p>
				</MyCard>

				<MyCard header="4) Overriding Law and Conditions Prevail over the Conditions of Carriage" eventKey={4}>
					<p>
						These Conditions of Carriage are applicable unless they are inconsistent with flybig’s Tariffs or
						applicable law in which event such Tariffs or relevant applicable laws shall prevail. If any provision of
						these Conditions of Carriage is invalid under any applicable law, the other provisions shall
						nevertheless remain valid irrespective of such invalid provision. Except as provided in these
						Conditions of Carriage, in the event of inconsistency between these Conditions of Carriage and any
						other regulations that fly big may have, dealing with subject matter covered here, these Conditions
						of Carriage shall prevail.
					</p>
				</MyCard>

				<MyCard header="5) Bookings - General Provisions, Changes and Cancellations" eventKey={5}>
					<p><strong>General Provisions</strong></p>
					<p>
						The booking (and the resultant PNR generated thereafter) upon receipt of full payment for such
						booking constitutes prima facie evidence of the contract of carriage between flybig and the
						guest named on the booking. flybig will permit carriage only to the guest(s) named in the
						booking subject to the appropriate, valid and applicable identification proof being produced at the
						time of travel and any other requirements imposed by law, regulations or flybig’s policies.
					</p>
					<p>
						List of accepted documents:
					</p>
					<ol>
						<li>Valid Passport</li>
						<li>Valid Driving License</li>
						<li>Election Photo Identification Card</li>
						<li>Aadhaar Card</li>
						<li>Photo identity card issued by the employer, being government and reputed private sector organisations</li>
						<li>Children/UNMR travelling on flybig should carry their school identification cards or any other photo identification proof</li>
						<li>Valid birth certificates of infants need to be produced at the time of check-in</li>
						<li>For Foreign Nationals who travel on domestic flights, the only valid photo ID for travel is Passport.</li>
					</ol>

					<p>
						flybig aspires to be a “paper-less” airline. flybig offers a printable itinerary with a PNR and the
						details of the reservation. An itinerary does not constitute a document of carriage. The booking in
						our database is the primary record of carriage and in the event of any differences between the
						Itinerary and the booking in our database, the booking and its associated terms and conditions will
						prevail.
					</p>
					<p>Some bookings can be made at discounted fares which may be partially or completely non-refundable.</p>
					<p>flybig does not issue open bookings, i.e. a booking is required to have a specific date for travel and cannot be booked in an open-ended manner.</p>
					<p>flybig does not accept wait-listed bookings.</p>
					<strong>
						Bookings and payments can be made at the following points of sale:
					</strong>
					<ol>
						<li>On our Website</li>
						<li>flybig mobile app (Android only)</li>
						<li>
							By contacting the 24/7 flybig call centre:
							{PHONE_NUMBER}
						</li>
						<li>flybig Airport Ticketing Office and City Ticketing Office</li>
						<li>flybig Travel Partners</li>
					</ol>
					<p>
						Guests using our Website and/or traveling with flybig agree to these Conditions of Carriage that
						are available on our Website or referred to in the Itinerary/Receipt.
					</p>
					<p>
						We accept all domestic and international cards (except Amex card) at the flybig call centre and on our
						Website and Mobile native applications for Android, and Windows devices. Guests should carry
						the credit card, or a copy of the credit card duly signed by the card holder, at the time of check-in. Debit cards of select banks (both Indian and International) are also accepted. The usage of these
						cards for payment is governed by the terms and conditions of the respective bank or company
						issuing such card, as the case may be. flybig does not accept personal cheques towards booking
						payments.
					</p>
					<p>
						Bookings may be made by cash payment at flybig’s airport counters however cash payment for more
						than INR 2,00,000 (Indian Rupees Two Lacs) against 1 PNR will not be accepted under any
						circumstances.
					</p>
					<p>
						The Invoice shall be sent to the email address and in the name of the first guest, mentioned in the
						Itinerary. In case of a booking made by a GSTIN holder, the invoice shall be sent in the name of the
						GSTIN holder and to the email address mentioned while providing the GSTIN information at the time of
						booking. Additionally, guests may download the Invoice using their PNR on the flybig website {" "}
						<a href={`${HOME_URL}`}>www.flybig.in</a> Invoices shall be only in INR, even in cases where the payment is made in a currency other
						than INR.
					</p>
					<p>
						GST may also be applicable on any special services requested by the guest at applicable rates in
						accordance with applicable law.
					</p>

					<p>
						<strong>For Cards issued outside India:</strong>
						Guests who have used such cards for making a booking may
						receive a verification call. Guests travelling on such bookings are requested to carry the card or the
						copy of card used for making the reservation at the time of check-in. If the transaction remains unverified,
						the amount will be refunded and an email notification will be sent to make the payment towards
						such booking through the prescribed alternate modes within the stipulated time, failing which the
						reservation may be canceled. We strongl recommend that guests check their Itinerary for accuracy,
						and verify their itinerary 12 to 24 hours before the scheduled departure of the flight on our Website or
						the call center. flybig can provide travel certificate / proof of travel at a nominal charge of INR 200
						(inclusive of GST) per person per sector.
					</p>

					<br />

					<p><strong>Permitted Baggage allowance:</strong></p>
					<ul className='ml-4'>
						<li>For ATRs 15kgs check-in (Only 1 Piece) & 7kgs hand baggage (Only 1 Piece)</li>
						<li>For DHC-6 7kgs check-in (Only 1 Piece) & 3kgs hand baggage (Only 1 Piece)</li>
					</ul>
					<p>*Pooling of baggage is not allowed, even if the passengers are booked in one PNR.</p>
					<br />
					<p><strong><u>What is Defence category / Armed Force?</u></strong></p>
					<p><strong>Applicable if you are a war widow / serving / retired personnel or the spouse / dependent child of the Armed Forces or Paramilitary Forces of India. A valid identity proof & necessary armed forces ID is mandatory at the time of check-in. Failing to provide relevant document will result in denial of boarding.</strong></p>
					<p>Up to 50% discount on base fare is provided for bookings made under Defence category and is open to serving and retired Defence and paramilitary personnel. This offer cannot be clubbed with any other promotional scheme or discount offer extended by flybig. The discount of upto 50% shall be reduced to the extent of any other promotional scheme available such that the overall discount does not exceed 50%.</p>
					<p>
						Defence reservations can be booked only through flybig official website <a href={`${HOME_URL}`}>www.flybig.in</a>
					</p>

					<p>
						<strong>Number of seats under Defence Category</strong>
						<br />
						There is restricted inventory available, which is served on first come first serve basis. In case the inventory is exhausted under this category, you may choose to fly a different date or select a normal class fare to check the availability on the same date.
					</p>

					<p>
						<strong>Family</strong>
						<br />
						The special offer is open to serving and retired Defence and paramilitary personnel and their families.
					</p>
					<p>
						<strong>Required Documents</strong>
						<br />
						Travellers would be required to produce their Military ID card/ Dependent card at the time of check-in/airport entry for verification. Passengers will be denied boarding in case they fail the verification stage during the check-in process, also the ticket will stand cancel if the valid Military ID / Dependent card is not provided. A fresh ticket needs to be purchased with the available fare.
					</p>
					<p>
						<strong>Rescheduling / Date Change / No-Show</strong>
						<br />
						The reservation booked under Defence Category / Armed Forces Discount category can be modified with NIL charges, the ticket cancellation charge is INR 2500 or base fare + fuel surcharge whichever is lower & No-Show would charge INR 3500. Also this fare cannot be clubbed with any other fare, hence if the seat is not available on the specified date, guest need to book different date with seat availability.
					</p>

					<p><strong>Permitted Baggage allowance:</strong></p>
					<p>25kgs check-in (Only 1 Piece) & 7kgs hand baggage (Only 1 Piece) (Only Applicable in ATRs)</p>
					<br />
					<p><strong><u>What is Senior Citizen Fare?</u></strong></p>
					<p><strong>Applicable for Senior Citizens above age of 60yrs. Entitled for discount of 6% on base fare. Proof of Date of Birth is mandatory & needs to be presented at the time of check-in. Failing to provide relevant document will result in denial of boarding.</strong></p>
					<p>Upto 6% discount on base fare is provided for bookings made under Senior Citizen with the age limit of 60 and above. This offer cannot be clubbed with any other promotional scheme or discount offer extended by flybig. The discount of upto 6% shall be reduced to the extent of any other promotional scheme available such that the overall discount does not exceed 6%.</p>
					<p>
						Senior Citizen reservations can be booked only through flybig official website <a href={`${HOME_URL}`}>www.flybig.in</a>
					</p>

					<p>
						<strong>Number of seats under Senior Citizen</strong>
						<br />
						There is limited inventory available, which is served on first come first serve basis. In case the inventory is exhausted under this category, you may choose to fly a different date or select a normal class fare to check the availability on the same date.
					</p>
					<p>
						<strong>Family</strong>
						<br />
						The special offer is open to Senior citizen only and not their families.
					</p>
					<p>
						<strong>Required Documents</strong>
						<br />
						Travelers would be required to produce their identity proof with Age or Birth Date at the time of check-in/airport entry for verification. Guests will be denied boarding in case they fail the verification stage during the check-in process, also the ticket will stand cancel if the valid ID Proof is not provided and a fresh ticket needs to be purchased.
					</p>
					<p>
						<strong>Rescheduling & Cancellation</strong>
						<br />
						The reservation booked under Senior Citizen Discount category can be modified with NIL charges, the ticket cancellation charge is INR 2500 or base fare + fuel surcharge whichever is lower & No-Show would charge is INR 3500. Also this fare cannot be clubbed with any other fare, hence if seat is not available on the specified date, guest need to book different date with seat availability.
					</p>

					<br />
					<p><strong>Permitted Baggage allowance:</strong></p>
					<p>15kgs check-in (Only 1 Piece) and 7kgs hand bag (Only 1 Piece) (Only Applicable for ATRs)</p>
					<br />

					<p><strong><u>What is student fare?</u></strong></p>
					<p><strong>
						Applicable for students above the age of 12 years. A valid student ID from a bonafide university/school is mandatory at the time of check-in for verification. The discount entitles you to a 10% off on base fare and additional baggage allowance of 10kgs. Failing to provide relevant documents will result in either charging prevailing fares or denial of boarding.
						<br />
					</strong></p>
					<p>Up to 10% discount on base fare is provided for bookings made under Student fare. This offer cannot be clubbed with any other promotional scheme or discount offer extended by flybig. The discount of upto 10% shall be reduced to the extent of any other promotional scheme available such that the overall discount does not exceed 10%.</p>
					<p>
						Student fare reservations can be booked only through flybig official website <a href={`${HOME_URL}`}>www.flybig.in</a>
					</p>
					<p>
						<strong>Number of seats under Student Fare</strong>
						<br />
						There is limited inventory available, which is served on first come first serve basis. In case the inventory is exhausted under this category, you may choose to fly a different date or select a normal class fare to check the availability on the same date.
					</p>
					<p>
						<strong>Family</strong>
						<br />
						The special offer is open to Students only and not their families.
					</p>
					<p>
						<strong>Required Documents</strong>
						<br />
						A valid student ID from a bonfide university/school is mandatory at the time of check-in for verification. The discount entitles you to a 10% off on base fare and additional baggage allowance of 10kgs. Failing to provide relevant documents will result in either charging prevailing fares or denial of boarding.
					</p>
					<p>
						<strong>Rescheduling & Cancellation</strong>
						<br />
						The reservation booked under Student Discount category can be modified with NIL charges, the ticket cancellation charges - INR 2500 or base fare + fuel surcharge whichever is lower & No-Show would charge INR 3500. Also this fare cannot be clubbed with any other fare, hence if the seat is not available on the specified date, guest need to book different date with seat availability.
					</p>
					<br />
					<p><strong>Permitted Baggage allowance:</strong></p>
					<p>25kgs check-in (Only 1 Piece) & 7kgs hand baggage (Only 1 Piece) (Only Applicable for ATRs)</p>
					<br />
					<p>
						<strong>Cancellation & Date Change</strong>
						- by when a guest can make changes/cancellations – Table
						explained below
					</p>
					<table className="flybigTable">
						<tbody>
							<tr>
								<th rowSpan={2}>
									Proposed Charges
								</th>
								<th colSpan={2} className='text-center'>ATRs</th>
							</tr>
							<tr>
								<th>biglite</th>
								<th>bigeasy</th>
							</tr>
							<tr>
								<td>Applicability</td>
								<td>Applicable on all S9 domestic flights</td>
								<td>Applicable on all S9 domestic flights</td>
							</tr>
							<tr>
								<td>Date Change Charges</td>
								<td>Anytime change – upto INR 2000 or base fare + fuel surcharge whichever is lower + fare difference(if any)</td>
								<td>
									Free Date Change / but differential in fare would apply if applicable
								</td>
							</tr>

							<tr>
								<td>Cancellations</td>
								<td>Non-Refundable (Only statutory taxes will be refunded, if any)</td>
								<td>
									0-3days - INR 2500 or base fare + fuel surcharge whichever is lower 4 days above - NIL
								</td>
							</tr>

							<tr>
								<td>No-Show Penalty</td>
								<td>Non-Refundable (Only statutory taxes will be refunded, if any)</td>
								<td>Non-Refundable (Only statutory taxes will be refunded, if any)</td>
							</tr>

							<tr>
								<td>Seating -</td>
								<td>Chargeable</td>
								<td>Free Seating</td>
							</tr>

							<tr>
								<td>Priority Check-in</td>
								<td>Chargeable</td>
								<td>Free Check-in</td>
							</tr>

							<tr>
								<td colSpan={3}>
									Any changes or cancellations free of charge within 24 hours of booking, if booked at least 7 days before the travel dates
								</td>
							</tr>

							<tr>
								<td>Availability</td>
								<td>Domestic only, subject to availability. Available on all non-stop, connecting and via flights.</td>
								<td>Domestic only, subject to availability. Available on all non-stop, connecting and via flights.</td>
							</tr>

							<tr>
								<td>Meals and Snacks</td>
								<td>Buy On Board (BOB)- Chargeable</td>
								<td>Buy On Board (BOB)- Chargeable</td>
							</tr>
						</tbody>
					</table>

					<table className="flybigTable">
						<tbody>
							<tr>
								<th rowSpan={2}> Proposed Charges </th>
								<th className='text-center'>DHC-6</th>
							</tr>
							<tr>
								<th className='text-center'>biglite</th>
							</tr>
							<tr>
								<td>Applicability</td>
								<td>Applicable on all S9 domestic flights</td>
							</tr>
							<tr>
								<td>Date Change Charges</td>
								<td>Anytime change – upto INR 2000 + fare difference(if any)</td>
							</tr>
							<tr>
								<td>Cancellations</td>
								<td>Anytime cancellation upto INR 3000 or base fare + fuel surcharge whichever is lower</td>
							</tr>
							<tr>
								<td>No-Show Penalty</td>
								<td>Entire base fare will be forfeited</td>
							</tr>
							<tr>
								<td>Seating -</td>
								<td>Not Chargeable</td>
							</tr>
							<tr>
								<td>Availability</td>
								<td>Domestic only, subject to availability. Available on all non-stop, connecting and via flights.</td>
							</tr>
						</tbody>
					</table>

					<br />
					<table className="flybigTable">
						<tbody>
							<tr>
								<th>Other Fare Rules</th>
								<th>Return Fare</th>
								<th>Group Fare</th>
							</tr>
							<tr>
								<th>
									Applicability
								</th>
								<td>
									Only available on Domestic sectors,
									subject to seat availability
								</td>

								<td>
									More than 9 guests may be booked as a
									group, for the same you may write to us
									at {' '}
									<a href="mailto:groupdesk@flybig.in">groupdesk@flybig.in</a>
								</td>
							</tr>
							<tr>
								<th>
									Change/Cancellations
								</th>

								<td>
									Yes, you can cancel or change your
									Return Fare booking. Please refer
									Conditions of Carriage for
									change/cancellation fee and detailed
									T&Cs.
								</td>
								<td>
									Yes, you can cancel or change your Group
									Fare booking. T&C apply
								</td>
							</tr>

							<tr>

								<th>
									Switch to other fare type
								</th>

								<td>
									Any such change will be considered as change of booking and the applicable change fee and difference of fare will accordingly apply.
								</td>
								<td>
									NA
								</td>
							</tr>
							<tr>
								<th>
									Partial Cancellation
								</th>
								<td>You can partially cancel a booking on Return Fare</td>
								<td>T&C apply</td>
							</tr>
						</tbody>
					</table>
					<p>*Applicable for both type of Aircraft (ATR & DHC-6)</p>

					<p><strong>Changes and Cancellations</strong></p>
					<p>
						Once a confirmed booking is made, the flybig fares paid are non-refundable & refundable, along with the Airport Fees (Fuel Surcharge + Cute Fee) / levies, subject to flybig change or cancellation rules and fees and all the all the additional taxes related to Government (taxable referring to guest) will be non-refundable. If the guest does not check-in for a flight at least forty-five (45) minutes prior to its scheduled departure time, the total amount for the confirmed booking will be forfeited. Guest are, however, always entitled to a refund of Airport Fees such as PSF, UDF etc. if charged from the guest. Please click here to claim your airport fees & levies.
					</p>
					<p>
						<strong>flybig allows cancellations or changes to be made subject to the following:</strong>
					</p>
					<p>
						Changes / cancellations to booking must be made at least two (2) hours prior to scheduled departure
						time of the said booking.
					</p>
					<p>
						flybig shall retain a Change/Cancellation Fees as per terms and conditions agreed at the time of
						making the reservation, <strong>in accordance to DGCA CAR Section 3 Series M, Part II</strong>
						{' '}
						. The amount of
						change/cancellation will be as per the booked fare type as mentioned in the above table and the
						fare rules. This fee will be deducted from the total amount of flybig fares, and the remainder is
						refundable to the guests (in the case of cancellation) or may be applied to the guest's
						changed booking (in the case of a change).
					</p>
					<p>
						No Change/ Cancellation Fees shall be retained by flybig in case the guest cancels or changes a
						booking within 24 hours of the booking having been made, provided that the booking is for a flight
						whose departure is not less than seven days from the date of the booking. Any such change to a
						booking will nevertheless require the guest to pay any increase between the original booking
						and the amended booking.
					</p>

					<table className="flybigTable">
						<tbody>
							<tr>
								<th>Time duration</th>
								<th>Applicable Fee (Inclusive of GST)</th>
							</tr>
							<tr>
								<td>
									Cancellation upto 2 hours before
									scheduled departure
								</td>
								<td>
									Up to INR 3000 or Airfare charges (Base fare +
									fuel surcharge), whichever is lower.
								</td>
							</tr>

							<tr>
								<td>
									Change upto 2 hours before scheduled
									departure
								</td>
								<td>
									Up to INR 2000 or base fare + fuel surcharge whichever is lower + fare difference(if any).
								</td>
							</tr>

							<tr>
								<td>
									0-2 hours before scheduled departure
								</td>
								<td>
									No show charges
								</td>
							</tr>

							<tr>
								<td colSpan={2}>
									<i>
										For domestic bookings, guests can make any changes or cancellations free of charge
										within 24 hours of booking, if booked at least 7 days before the travel dates.
									</i>
								</td>
							</tr>
						</tbody>
					</table>
					<p>*Applicable for both type of Aircraft (ATR & DHC-6)</p>
					<p>
						<strong>
							flybig does not allow name changes to a booking already made. A spelling correction in the
							name of the guest (Eg Mishra spelt as Misra) upto 2 character can be done within 24 hours
							of the booking having been made without incurring any additional charges by calling us at our call
							centre
						</strong>
					</p>
					<p>
						In case of changes to a booking already made, the residual amount (i.e. after the deduction of the applicable fee per guest per sector fee mentioned above) will be applied to the changed itinerary. Changes to the itinerary will be made at prevailing prices at the time of the change. If the residual amount is less than the price of the changed itinerary, the guest will be required to pay the difference.
					</p>
					<p>
						In the case of a changed itinerary where the residual amount is greater than the price of the new itinerary, or in the case of a cancellation of a booking already made, the guest will receive the balance amount (i.e. after the deduction of the applicable fee per guest per sector mentioned above and the cost of the new itinerary, if applicable) as a refund or, can keep the balance amount in the same PNR, which can be used for future travel on flybig by the guest himself/herself for up to one year from the date of the cancellation.
					</p>
					<p>
						<strong>
							In case the guest desires refund against the residual value after deduction of the applicable
							fee, will be made available as per the following:
						</strong>
					</p>
					<p>
						For bookings made through credit cards/debit cards online on our website, the refund will be processed back to the credit card or debit card (as the case may be) in seven working days from the date of refund request.
					</p>
					<p>
						For bookings made through Net Banking online on our website, refunds will be processed to the same
						bank account and will reflect within 7 working days.
					</p>
					<p>
						For bookings made through Travel Partners or online travel portals, the refund may be claimed from the
						respective Travel Partners/ Portals.
					</p>
					<p>
						For bookings made by cash/card swipe at the airport, refunds will be processed through an electronic transfer of funds (NEFT), pursuant to filling the requisite refund form at the airport in seven working days from the date of refund request.
					</p>
					<p>
						Bookings are not transferable and any such refund upon cancellation will be made only to the guest
						through the process set out above.
					</p>
					<p>
						If any change in Itinerary results in a change or adjustment in price/value, a revised Invoice/Credit Note,
						whichever is applicable, will be issued to the guest.
					</p>
					<p>
						flybig may refuse refund when application thereof is made after the expiry of the validity of the
						booking.
					</p>

					<p>
						All Ancillaries charged are non-refundable, such as – prepaid baggage / seats charges / Sports equipment’s / Travel Certificate. A nominal fee is charged for each ancillary.
					</p>
					<br />
					<p>Seat charges differ from row to row for ATRs:</p>
					<table className="flybigTable">
						<tbody>
							<tr>
								<th>ATR</th>
								<th>WINDOW</th>
								<th>AISLE</th>
							</tr>
							<tr>
								<td>Row 1</td>
								<td>₹ 500.00</td>
								<td>₹ 500.00</td>
							</tr>
							<tr>
								<td>Row 2-5</td>
								<td>₹ 300.00</td>
								<td>₹ 200.00</td>
							</tr>
							<tr>
								<td>Row 6-9</td>
								<td>₹ 100.00</td>
								<td>₹ 100.00</td>
							</tr>
							<tr>
								<td>Row 10-16</td>
								<td>₹ 200.00</td>
								<td>₹ 100.00</td>
							</tr>
							<tr>
								<td>Row 17-18</td>
								<td>₹ 500.00</td>
								<td>₹ 500.00</td>
							</tr>
						</tbody>
					</table>

					<p className='mb-1'>*DHC-6 Seats are not chargeable</p>
					<p className='mb-1'>*Sports Equipment’s handling charges – INR 1500</p>
					<p className='mb-1'>*Travel Certificate – INR 200 per passenger per route</p>

				</MyCard >

				<MyCard header="6) Group Guidelines – Group booking terms and conditions" eventKey={6}>
					<strong>1 Executive Summary</strong>
					<p>
						This document outlines the Basic Group booking Terms and Conditions.
					</p>
					<strong>Definition</strong>
					<p>
						A “group” is defined as 10 or more guests occupying seats, booked together, all travelling on the same route, date and flight on the same booking.
					</p>
					<p>
						<strong>Eligibility</strong>
					</p>
					<ul>
						<li>Any type of groups travelling together e.g. religious groups, school groups etc.</li>
						<li>Individual bookings are not permitted to be classified as a Group booking if booked and paid individually</li>
					</ul>
					<p>
						<strong>2 Group booking Terms and Conditions</strong>
					</p>
					<p>
						<strong>2.1 Group booking Payment Requirements</strong>
					</p>

					<p>
						A 25% non-refundable deposit will be required to secure the seats and the fare quoted, subject to seat availably upon receipt of deposit.
					</p>
					<ul>
						<li>A minimum of 80% of the group must travel together for the entire journey</li>
						<li>Space on aircraft and fare given cannot be guaranteed and is subject to change until deposit is received</li>
						<li>If a group fails to pay the outstanding balance within the set time limit in full, the booking will be cancelled and deposit will be forfeited</li>
					</ul>
					<p><strong>2.2 Price Quote Validity</strong></p>
					<ul>
						<li>Quotations provided 2 days or less before flight departure: normal booking policy applies as per the flybig Terms and Conditions of Carriage, with payment as per ticketing time limit.</li>
						<li>Quotations provided 3 to 14 days before flight departure of the first leg of travel: valid for 24 hours from the time of the quote.</li>
						<li>Quotations provided 15 days or more prior to flight departure of the first leg of travel: valid for 7 days from date of quote, or 14 days before departure, whichever comes first.</li>
					</ul>
					<p><strong>2.3 Payment Deadlines</strong></p>
					<ul>
						<li>Group bookings made within 2 days of flight departure on first date of travel: full payment is required immediately to secure the seats and quoted fare in the form of a booking along with the Full names as per the passport.</li>
						<li>If quote is requested 3 to 7 days before flight departure on first date of travel: full payment is required within 24 hours to secure the seats and quoted fare in the form of a booking, along with the Full names as per the passport.</li>
						<li>If quote is requested 8 to 14 days before flight departure on first date of travel: a 50% non-refundable deposit is required within 24 hours. Full settlement is required no later than 7 days before flight departure on first date of travel. Failure to settle within the required period will result in automatic cancellation of the booking and the deposit will be forfeited.</li>
						<li>If quote is requested more than 14 days before flight departure on the first date of travel: a 25% non-refundable deposit is required within 7 days. An additional 25% deposit is required 30 days from receipt of the first deposit, or 14 days before flight departure on the first date of travel, whichever first. The outstanding balance must be settled in full at least 7 days prior to flight departure on the first date of travel.</li>
						<li>Bank charges are for the payee’s account.</li>
					</ul>

					<p><strong>2.4 Guest Names</strong></p>
					<ul>
						<li>
							At least 07 days prior to departure, all guests’ names traveling in the group must be provided to flybig. Failure to comply, may result in denied boarding and flybig reserves the right to cancel the booking, which shall remain non-refundable.
						</li>
					</ul>

					<p><strong>2.5 Once Deposit is Paid</strong></p>
					<ul>
						<li>- Date and time changes will require a new fare quote and will incur an additional administration or other fee – all deposits are non-refundable and non-transferable</li>
						<li>- Additional guests can be booked within the existing booking at the applicable fare and subject to seat availability.</li>
					</ul>
					<p><strong>2.6 After booking</strong></p>
					<ul>
						<li>- Change fees 2500/- will apply for any and all date changes together with difference in fares and taxes, all of which will be for the guests’ account</li>
						<li>- Additional guests can be booked within the existing booking at the applicable fare and subject to seat availability. Payment Deadlines apply as set out in this document in section 2.3 above.</li>
						<li>- If a group size reduces, payment for the guests no longer travelling will be forfeited.</li>
						<li>- 100% Penalty applies for cancellations and no-shows, resulting in forfeiture of the booking and no refund payable to the guest, as set out in the Terms and Conditions of Carriage.</li>
					</ul>
					<p><strong>2.7 Cancellations and No-Shows</strong></p>
					<ul>
						<li>- In the case of no shows, no refunds are permitted and the booking is forfeited</li>
						<li>- Credit vouchers are not permitted.</li>
						<li>- If a guest does not arrive for his/her flight, the ticket will be forfeited and a new ticket will have to be purchased for the next flybig flight at the applicable fares.</li>
						<li>- All refunds will be made only to the account holder from which the payment is received or one of the guests in the PNR. In case any refund needs to made to a third party a NOC will be required from the account holder from whose account the payment has been originally made.</li>
					</ul>
					<p><strong>2.8 Baggage Allowance (Checked In)</strong></p>
					<ul>
						<li>- Baggage pooling/clubbing is not allowed.</li>
						<li>- Adult and Child – 15kgs.</li>
						<li>- Baggage and ancillaries are not included in Group fare quotes, but subject to payment of the applicable Fees and Charges</li>
					</ul>
					<p><strong>2.9 Hand baggage</strong></p>
					<ul>
						<li>
							- only 7kgs hand bag allowed for Adult and Children, 7kgs for infant
						</li>
					</ul>
					<p><strong>2.10 Excess baggage</strong></p>
					<ul>
						<li>
							- Refer to flybig Terms and Conditions of Carriage
						</li>
					</ul>
					<p><strong>2.11 Group booking Check-in Procedure</strong></p>
					<ul>
						<li>- The entire group, being all guests travelling as part of the group, must check-in 2 hours prior to the stipulated dep</li>
						<li>- Each guest travelling will have to check-in individually (guests cannot check-in on behalf of each other)</li>
						<li>- Pre-seating can be arranged prior to departure at a charge per person, per sector, as set out in our fees and charges.</li>
						<li>- Refer to flybig Terms and Conditions of Carriage for general terms regulating check-in and boarding requirements, terms and conditions</li>
					</ul>
					<p><strong>2.12 General</strong></p>
					<ul>
						<li>- The flybig Terms and Conditions of Carriage apply to all guests traveling as part of a group and in respect of all group bookings, except as expressly varied by these flybig Group booking Terms and Conditions</li>
						<li>- Group booking confirmation is applicable to 10 or more guests travelling and booking together; should the group size be reduced to fewer than 10 guests (excluding infants) the fare may be changed to published fares, and the booking no longer classified as a group booking.</li>
						<li>- The flybig check in counter opens 2 hours before the scheduled departure time and closes exactly 45 minutes before the scheduled departure time – no late arrivals will be permitted to check in.</li>
					</ul>

					<p><strong>2.13 Groups of Escorted Children</strong></p>
					<ul>
						<li>
							- flybig will only accept children aged less than 12 years but older than 2 years in large Groups with the following adult guardian ratios. An adult guardian shall, for the purposes of these Group Terms and Conditions, be deemed to be a person over the age of 18 years, or the legal age or majority in such person’s country of residence, whichever is the greater.
						</li>
					</ul>
					<p>
						Groups of Children
					</p>
					<ul>
						<li>- From age 4 to under 15 years of age, there shall be one Adult Guardian to 9 children</li>
						<li>- From age 2 to under 4 years of age there shall be one Adult Guardian to 2 children</li>
					</ul>
					<p>In the event of an aircraft change, it is possible that additional Adult Guardians are required to comply with Operational and Safety regulations. If additional Adult Guardians are not able to travel, the Group shall be entitled to a full refund for that sector or the Group will be placed on the next flybig flight subject to availability.</p>
					<p>The Adult Guardian remains responsible for the child's health, well-being and behaviour both when on the ground and on-board the aircraft. The Adult Guardian will also retain responsibility for them in case of aircraft delays/changes.</p>
				</MyCard>

				<MyCard header="7) Fares, Taxes, Fees and Charges" eventKey={7}>

					<p>
						<strong>Airfare Charges</strong>
					</p>
					<p>
						Airfare Charges are inclusive of the following components:
					</p>
					<ul>
						<li>Base Fare</li>
						<li>Fuel Charges</li>
						<li>Common User Terminal Equipment (CUTE) Charges</li>
					</ul>
					<p>
						Airfare Charges apply only for carriage from the airport at the point of origin to the airport at the
						point of destination, unless otherwise expressly stated. Airfare Charges do not include ground
						transport service between airports and / or between airports and town terminals. The guest's
						airfare charges will be calculated in accordance with flybig’s tariff in effect on the date of payment
						for travel on the specific dates and Itinerary shown on it. Any change in the itinerary or dates of
						travel, may result in a change in the airfare charges to be paid.
					</p>

					<p>
						All our bookings are subject to the Conditions of Carriage. However, our special airfare charges have
						certain additional terms and conditions attached to them. In the event booking is made on the
						special airfare charges, except to the extent specified otherwise in the additional terms and
						conditions governing the special air fares charges, these Conditions of Carriage shall apply in its
						entirety.
					</p>
					<p>
						Airfare Charges booked through our Website or on flybig mobile app (available on App store/Android users only) are
						bound by the same terms and conditions as those of all other bookings.
					</p>
					<p>
						flybig offers airfare charges on a one-way basis for ease of purchase. Certain promotional airfare
						charges require a round-trip itinerary and once purchased do not allow partial cancellation of the
						itinerary. Guests shall always refer to detailed airfare charges rules at time of making the booking.
					</p>
					<p>
						All airfare charges are quoted in Indian Rupees (INR).
					</p>
					<p>
						Please note that certain airfare charges may have conditions which limit or exclude the guest's
						right to change or cancel bookings.
					</p>
					<p>
						Airfare charges are subject to change without notice.
					</p>
					<p>
						Once purchased, the airfare charges will not change, provided, however, that if the guest requests
						for a subsequent change in the booking or routing, revised airfare charges may be applicable.
					</p>
					<p>
						A booking is valid from the date the booking was made until the last commencement date of travel
						thereon. Unused bookings or portions thereof, are non-refundable and will expire in accordance with
						these Conditions of Carriage. Guests who fail to check-in and travel on a Confirmed booking on the
						date of travel shall be treated as a no-show guest.
					</p>
					<p>
						A person shall not be entitled to be carried on a flybig flight unless that person presents a valid
						Itinerary, duly issued in accordance with these Conditions of Carriage.
					</p>
					<p>
						flybig from time to time introduces/offers certain add-on services inter-alia including seat selection, pre-paid snacks or additional excess baggage allowance, at the time of booking. Such services shall be governed by the respective terms and conditions for such add-on services mentioned at the relevant sections of the website.
					</p>



					<p><strong>Taxes, Duties, Fees, Charges and Surcharges</strong></p>
					<p>
						The amount of any applicable taxes, duties, fees, charges or surcharges payable by the guest is
						calculated as on the date of booking. If there is a change in an existing tax, duty, fee, charges or
						surcharge, or a new tax, duty, fee, charges or surcharge is imposed, after the date of booking, the
						guest will not be obliged to pay such tax, duty, fee, charges or surcharge, or a new tax, duty, fee,
						charges or surcharge or get a refund as the case may be, unless:
					</p>
					<p>
						<strong>a)</strong>
						{' '}
						The guest decides to change the date of travel which requires rebooking, or
					</p>
					<p>
						<strong>b)</strong>
						{' '}
						The tax, duty, fee, charges, surcharge (including UDF) is to be paid to or is on behalf of a regulatory
						authority.
					</p>

					<p>
						The total fare payable to flybig per booking, per guest, per sector includes four components:
						basic fare, taxes imposed by the Government, surcharges that are imposed by flybig, and fees &
						charges imposed by the Government and/or Airports.
					</p>

					<p>
						Please note that these fare components may undergo a change at any time without notice.
					</p>
					<p><strong>Currently flybig’s fares include:</strong></p>

					<ul>
						<li>Airline fuel Charges</li>
						<li>CUTE Charges</li>
						<li>RCS Provision ( Applicable on RCS routes only )</li>
					</ul>
					<p>
						The airline fuel charges will vary depending on the Sector, date of booking, and the actual date of
						travel.
					</p>
					<p>
						The CUTE charges are levied at select airports for usage of Common User Terminal Equipment
						(CUTE) services. For flights departing from Ahmedabad, Lucknow, Mumbai, Kochi, Delhi 1D, Delhi
						T3, Guwahati, Hyderabad, Jaipur, Goa, Srinagar, Coimbatore, Thiruvananthapuram and Varanasi a
						fee of
						{' '}
						<strong>INR 50 per guest (inclusive of GST)</strong>
						, per sector shall be applicable for all bookings.
					</p>
					<p>
						A convenience fee, as mentioned in table below - per person per flight for all Net banking and -
						Credit Cards/Debit Cards is collected by the airlines for establishing, maintaining and operating
						the online flight booking system, to enable the guests to book the air tickets online while
						using a Credit/Debit card/Net Banking. This fee includes the charges paid by the airlines to the
						concerned Bank (varies from one Bank to another) for availing of such facility.
					</p>

					<table className="flybigTable">
						<tbody>
							<tr>
								<th>Currency</th>
								<th>Convenience Fee (Per guest per segment)</th>
							</tr>
							<tr>
								<td>INR</td>
								<td>
									300 all inclusive for WEB
									<br />
									250 all inclusive for CRS bookings
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									Please note that if the payment is to be made in any other currency than Indian Rupee, the convenience Fee will be calculated as per the conversion rate prevalent at the time of payment.
								</td>
							</tr>
						</tbody>
					</table>

					<p>
						A booking fee of INR 250 all-inclusive per guest per sector (or equivalent) will be applicable for all
						bookings made from the call-center and airport ticketing counter.
					</p>
					<p>
						For guests who request for print-outs of the itinerary at the airport reservations counter, an
						additional fee of INR 50 per print-out all-inclusive will apply.
						Please also note that some services may require additional fees not mentioned above.
					</p>
					<p><strong>Currency</strong></p>
					<p>
						Fares, taxes, fees and charges are payable in the currency chosen by the guest or the Travel
						Partner while making the booking. The guest or Travel Partner can choose only from the
						options provided by flybig. Please note that certain fees and charges for currencies other than INR
						are fixed in the respective currencies, and may not match with the amount obtained by directly
						converting the INR fee or Charges with the exchange rate.
						{ /* Currently we are not accepting USD currency through American Express Cards. */}
					</p>


				</MyCard>

				<MyCard header="8) Reservations" eventKey={8}>
					<p>
						<strong>Requirements, Personal Data and Seating arrangements</strong>
					</p>
					<p>
						<strong>Reservation Requirements</strong>
					</p>
					<p>
						Certain fares have conditions which limit or exclude the guest’s right to change or cancel reservations. flybig requests that at the point of reservation, the guest provides the airline or its Travel Partner with a contact number (valid mobile number) for each city in the guest’s itinerary including the applicable country and area codes. This will assist flybig in informing guests of any /changes with a flight on which they are booked.
					</p>
					<p>
						With effect from 1 July, 2017, there is a requirement of providing the GSTIN or the UIN (Unique Identification Number) of the guest who would be claiming input tax credit. flybig requests the guest at the point of reservation to provide the GSTIN applicable. Only single GSTIN can be mentioned against one PNR. GST shall be computed on the basis of GSTIN provided by the guest. Once PNR is generated, no changes to GSTIN will be allowed. Further, no addition/deletion of GSTIN/UIN can be made to the existing PNR.
					</p>
					<p><strong>Personal Data</strong></p>
					<p>
						The guest recognizes that personal data has been given to flybig for the purposes of making a
						booking, obtaining and for providing ancillary services or products that may be offered by flybig and/or
						its service partners from time to time, developing and providing services, facilitating immigration and
						entry procedures, and making available such data to government agencies, in connection with the
						guest's travel.
					</p>
					<p>
						The guest consents that such personal data may be used by flybig or its partners to check online
						credit/debit/identity fraud and also to improve the experience of the guests with flybig. For these
						purposes, the guest authorizes flybig to retain and use such data and to transmit it to the own
						offices, Travel Partners, government agencies, other carriers, the providers of the above-mentioned
						services, authorized third parties. The guest also recognizes that flybig and / or its service partners
						may contact them via email or other means to inform them of account status or changes or alterations to
						the service, or to inform them of other services provided or contemplated or promotional schemes etc.
					</p>
					<p><strong>Seating</strong></p>
					<div>
						<ul className='ml-4'>
							<li>flybig offers assigned seating at airport check-in counters. Guests below the age of 15 are not permitted to sit on the emergency exit rows - Row 2 & 7 on DHC6 Twin Otter.</li>
							<li>All seats on the DHC6 Twin Otter are non-reclining.</li>
							<li>flybig offers assigned seating at airport check-in counters. Guests below the age of 15 are not permitted to sit on the emergency exit rows - Row 1 on ATR.</li>
							<li>All seats on the ATR are partial-reclining.</li>
						</ul>
					</div>

					<p><strong>Name Format</strong></p>
					<p>In case, you don't have a surname mentioned in your government ID, you can use your first name as your surname during the booking process.</p>
					<p>For example: A person with a name "ABC" can fill their surname as "ABC" during the booking. This way the booking would be made in the name of (Title) ABC ABC.</p>
				</MyCard>

				<MyCard header="9) Conditions Precedent" eventKey={9}>
					<p>
						<strong>Checking-In and Boarding Details</strong>
					</p>
					<p>
						<strong><u>Checking-In</u></strong>
					</p>
					<p>
						Guests shall not be entitled to be allowed to travel on a flybig flight unless they present a valid
						Itinerary, duly issued in accordance with the Conditions of Carriage. All guests, including children
						and infants (only valid birth certificate), must present valid photo identification in original at the time
						of check-in. The guests must ensure that they carry valid identification and travel documents at the time of travel.
					</p>
					<p>
						Besides valid photo identification, guests may be required to present the credit / debit card or a signed
						photocopy of the credit / debit card used to make the payment for the booking, at the time of check-in.
					</p>
					<p>
						<strong>
							For Indian Nationals, the following documents are considered as valid photo identification:
						</strong>
					</p>
					<ul>
						<li>Valid passport,</li>
						<li>Valid driving license,</li>
						<li>Election photo ID,</li>
						<li>Aadhaar card</li>
						<li>Photo ID issued by the employer (where the employer is a government entity),.</li>
						<li>In case of children, their school photo ID cards or any other photo ID,</li>
						<li>For infants, their valid birth certificate or passport.</li>
						<li>For any other nationality only passport, in original will be considered as the only valid photo identification.</li>
					</ul>
					<p>
						Guests who cannot provide sufficient identification will be denied boarding at the guest's
						sole risk and liability and flybig shall not be held liable for such denial or any consequences resulting
						therefrom.
					</p>
					<p>
						flybig recommends that guests report for Check-in at least 2 hours prior to the departure of the
						scheduled flight. The guest's journey will be smoother if they allow themselves ample time to
						comply with the check-in time-lines. flybig reserves the right to cancel a guest's reservation if
						he/she does not comply with the check-in time-lines.
					</p>
					<p>
						Check-in closes 45 minutes prior to the scheduled departure of the flight.
					</p>
					<p>
						Web Check-in opens 72hrs prior to the flight departure and closes 01hr before the scheduled departure of the flight.
					</p>
					<p>
						Guests are requested to report 2 hours prior to departure time at Srinagar and Jammu airports
						for check-in due to extra security measures.
						{' '}
						<strong>(future)</strong>
					</p>
					<p>
						Failure to complete the check in formalities within the stipulated time limit would result in forfeiture
						of the booking amount. The booking would be declared a “No show” and the guest would not
						be entitled to a refund or a credit. However, only the PSF and UDF may be refunded to the guest
						upon request made by the guest to flybig.
					</p>
					<p>
						Guests must ensure that there is enough time gap between two flights while making a booking
						involving a change of airplanes or carriers at a point of Stopover. flybig will not be liable in
						any manner, if the guest misses his/her connecting flight as a result of any delay in flybig flight
						due to any reason whatsoever.
					</p>

					<p><strong>Boarding</strong></p>
					<p>
						In order to maintain schedules, the boarding gate will be closed 25 minutes prior to the departure
						time. The guests must be present at the boarding gate not later than the time specified by flybig
						when they check in or any subsequent announcements made at the airport. Any guest failing to
						report at the boarding within the aforesaid time-lines shall be treated as a “Gate No Show” and the
						ticket amount for such booking shall be forfeited by the Company. The guests are, however,
						entitled to a refund of the Government and Airport Fees and/or taxes (if applicable).
					</p>
					<p><strong>Failure to Comply</strong></p>
					<p>
						flybig will not be liable to the guest for any loss or expense incurred due to their failure to
						comply with the provisions of this Article.
					</p>
				</MyCard>

				<MyCard header="10) Refusal and Limitation of Carriage and Special/Disability Assistance" eventKey={10}>
					<p>
						<strong>Right to Refuse Carriage</strong>
					</p>
					<p>
						<strong>flybig may refuse carriage of the guest or the guest's Baggage if in the exercise of its discretion, it is determined that:</strong>
					</p>
					<p>
						Such action is necessary in order to comply with any applicable laws, regulations or orders of any
						state or country to be flown from, into or over,
					</p>
					<p>
						<strong>
							The guest's conduct, status, age, mental or physical condition and/or the condition or state of
							the guest baggage is such:
						</strong>
					</p>
					<p>
						Has to cause reasonable fear of harm to other guest, or to our crew or the aircraft or safety in
						general, or
					</p>
					<p>That the <strong>guest</strong> may pose a hazard or risk to himself, other persons or to property.</p>
					<p>The <strong>guest</strong> has committed misconduct on a previous flight and there is a reasonable likelihood that such conduct may be repeated,</p>
					<p>The <strong>guest</strong> has not observed, or is likely to fail to observe, our instructions,</p>
					<p>The <strong>guest</strong> has refused to submit to a security check,</p>
					<p>The <strong>guest</strong> fails to observe flybig instructions with respect to safety or security,</p>
					<p>The applicable fare or any charges or taxes payable by the <strong>guest</strong>, have not been paid,</p>
					<p>The payment of the <strong>guest's</strong> fare is fraudulent,</p>
					<p>The <strong>guest</strong> does not have proper and/or adequate travel documentation,</p>
					<p>The <strong>guest</strong> has failed to complete the Check-In timeline or failed to arrive at the boarding gate on time,</p>
					<p>The booking of our seat has been done fraudulently or unlawfully, or has been purchased for a person not authorized by us,</p>
					<p>The credit card by which the payment was made by the <strong>guest</strong>, has been lost or stolen,</p>
					<p>The itinerary or the booking is counterfeit or has been obtained fraudulently,</p>
					<p>The itinerary has been altered by anyone other tha flybig or its Travel Partners, or has been mutilated (in which case, we reserve the right to retain such document), and/or</p>
					<p>The person checking in or boarding, cannot prove that he/she is the person named as the <strong>guest</strong> on the itinerary (in which case, we reserve the right to retain such itinerary)</p>

					<p><strong>Denied Boarding</strong></p>
					<p>
						I. There may be instances when certain flybig flights may be overbooked due to commercial, operational or technical reasons such as seat capacity optimization, or change in the aircraft from a higher capacity aircraft to a lower capacity aircraft resulting in flybig having to deny boarding to certain guests with confirmed booking, in accordance with Para 3.2 of DGCA CAR Section 3 Series M, Part IV, Issue I, dated August 6, 2010.
					</p>
					<p>
						II. Upon occurrence of the event stated under paragraph I above, flybig will endeavour to seek
						volunteers from among the guests with confirmed booking willing to surrender such confirmed
						booking to make such reservations available for other guests with confirmed booking.flybig will
						accommodate such volunteering guests on an alternate available flight and in addition, may
						offer certain benefits or facilities at flybig discretion, to the volunteering guests. In case flybig
						does not find sufficient number of volunteers to surrender their confirmed bookings on an
						overbooked flight, flybig has the right to deny boarding to some of the guests based on their
						check-in sequence at the airport counter (or such other criteria as flybig may decide from time to
						time),
						{' '}
						<strong>except the following categories of guests:</strong>
					</p>

					<ol>
						<li>A minor;</li>
						<li>A safety assistant;</li>
						<li>A pregnant woman;</li>
						<li>
							Guests with sensory impairment such as:
							<ul>
								<li> visual impairment,</li>
								<li> hearing impairment, or</li>
								<li> visual & hearing impairment;</li>
							</ul>
						</li>
						<li>Guests using or requiring wheelchairs, crutches, braces or other prosthetic devices and requiring special assistance/able bodied guest;</li>
						<li>Guests with limited mobility due to injured or fractured limbs;</li>
						<li>Guests having autism;</li>
						<li>Guests with certain adverse medical conditions; and</li>
						<li>Such other guest(s) accompanying any of the aforementioned persons, who in flybig’s discretion, should not be denied boarding considering specific circumstances.</li>

					</ol>
					<p><strong>III. Compensation to guests for Denied Boarding.</strong></p>
					<p>
						<strong>A.</strong>
						{' '}
						Subject to paragraphs III (B) and III (C) below, flybig will pay compensation, in the form of vouchers,
						to the guests who are denied boarding by flybig in accordance with
						{' '}
						<strong>
							Para 3.2 of DGCA CAR Section
							3 Series M, Part IV, Issue I, dated August 6, 2010,
						</strong>
						{' '}
						in the following manner:
					</p>

					<table className="flybigTable">
						<tbody>
							<tr>
								<th>Alternative Flight Arrangement</th>
								<th>Compensation</th>
							</tr>
							<tr>
								<td>
									If flybig arranges alternate flight that is
									scheduled to depart within 24 hours of the
									booked scheduled departure
								</td>
								<td>
									An amount equal to 200% of booked one-way
									basic fare plus fuel charge, subject to maximum
									of INR 10,000
								</td>
							</tr>
							<tr>
								<td>
									flybig arranges alternate flight that is scheduled
									to depart more than 24 hours of the booked
									scheduled departure
								</td>
								<td>
									An amount equal to 400% of booked one-way
									basic fare plus fuel charge, subject to maximum
									of INR 20,000
								</td>
							</tr>
							<tr>
								<td>
									In case, guest does not opt for alternate
									flight
								</td>
								<td>
									A refund of full value of ticket and
									compensation equal to 400% of booked one-way
									basic fare plus airline fuel charge, subject
									to maximum of INR 20,000
								</td>
							</tr>
						</tbody>
					</table>

					<p>
						<strong>B.</strong>
						{' '}
						flybig shall not be liable to pay any compensation, as specified under paragraph III (A) above, to
						guests, who are denied boarding by flybig, if an alternate flight is arranged by flybig that is
						scheduled to depart within one (1) hour of the original schedule departure time of the initial
						reservation.
					</p>
					<p>
						<strong>C. </strong>
						A guest booked on connecting flights of flybig or any other airline with flybig under a
						common PNR, shall be compensated in accordance with Para III (A) above and in accordance with
						Para 3.2 of DGCA CAR Section 3 Series M, Part IV, Issue I, dated August 6, 2010, by flybig only for the
						first leg of such connection, when he/ she has been delayed at the departure station on account of
						denied boarding by flybig for such first leg, and as a result of such denied boarding has arrived at the
						final destination at least three hours later than the scheduled arrival time.
					</p>
					<p>
						For clarity, flybig will not have any obligation to compensate a guest travelling on such
						connecting flights who has been delayed due to denied boarding in the first leg of the connecting
						flight serviced by flybig, if such guest arrives at the final destination within three hours of the
						scheduled arrival time.
					</p>
					<p>
						<strong>D.</strong>
						{' '}
						In case of denied boarding in any subsequent leg of a connecting flight the airline servicing such
						subsequent leg of the connecting flight shall be responsible for compensating the guest in
						accordance with
						{' '}
						<strong>Para 3.2 of DGCA CAR Section 3 Series M, Part IV, Issue I, dated August 6, 2010.</strong>
					</p>
					<p>
						<strong>
							E. The following cases will not be considered as denied boarding even in case of overbooked
							flights, and compensation under this paragraph III shall not be payable in such cases:
						</strong>
					</p>
					<p>
						(i) No show at the boarding gate by a guest (which are governed by provisions under heading
						“Boarding/Bookings - General Provisions, Changes and Cancellations” under of these Conditions of
						Carriage);
					</p>
					<p>
						(ii) Cancellation of the flight (compensation for which are governed by provisions under heading
						“Changes and Cancellations/Bookings - General Provisions, Changes and Cancellations” of these
						Conditions of Carriage); and
					</p>
					<p>
						(iii) denial of boarding to any guest (at flybig’s absolute discretion) who is under the influence of
						alcohol or drugs, for health, safety or unruly guests, on account of security breach or reasons or
						in the absence of adequate travel documents.
					</p>

					<p><strong>Guidelines on Unruly behaviour by a Guest on flybig’s aircraft</strong></p>

					<p>
						No guest shall on board an aircraft -
					</p>
					<ul>
						<li>Assault, intimidate or threaten, whether physically or verbally, a crew member which may interfere with the performance of the duties of the crew member or lessens the ability of the crew member to perform those duties.</li>
						<li>Refuse to follow a lawful instruction given by the Pilot-in-Command, or on behalf of the Pilot-in-Command by a crew member, for the purpose of ensuring the safety of the aircraft or of any person or property on board or for the purpose of maintaining good order and discipline on board.</li>
						<li>assault, intimidate or threaten, whether physically or verbally, any person,</li>
						<li>intentionally cause damage to or destroy any of property,</li>
						<li>consume alcoholic beverages or drugs,</li>
						<li>Which is likely to endanger the safety of the aircraft or of any person or jeopardizes the good order and discipline on board the aircraft.</li>
						<li>No person shall smoke:</li>
						<li>In any part of an aircraft or in its vicinity. Anywhere in an aircraft during take-off, landing or refueling or during a period in which a notice is temporarily displayed indicating that smoking is prohibited.</li>
						<li>No guest shall interfere with the pilot or with a member of the operating crew of an aircraft, or tamper with the aircraft or its equipment or conduct himself/herself in a disorderly manner in an aircraft or commit any act likely to imperil the safety of an aircraft or its guests or crew.</li>
						<li>Use of mobile phones in flight mode is allowed on all phases of the flight. Other PEDs like laptop, electronic entertainment devices are allowed only during cruise</li>
					</ul>
					<p><strong>Special/Disability Assistance</strong></p>
					<ol>
						<li>Infant</li>
						<li>Unaccompanied Minors</li>
						<li>Pregnant Women</li>
						<li>Safety Assistants</li>
						<li>Guests with Sensory Impairment</li>
						<ol type="A">
							<li>Visual Impairment</li>
							<li>Hearing Impairment</li>
							<li>Visual & Hearing Impairment</li>
						</ol>
						<li>Service Dog</li>
						<li>Mobility Assistance</li>
						<li>Wheelchair users or Customers using wheelchairs, Crutches / braces / other prosthetic devices</li>
						<li>Spinal Support Equipment (SSE)</li>
						<li>Traveling with broken limbs</li>
						<li>Guests with Psychiatric or Emotional disorders</li>
						<li>Guests having Autism or Intellectual Disability</li>
						<li>Internal Devices</li>
						<li>Portable oxygen concentrators (POC)</li>
						<li>Guests with certain adverse medical conditions</li>
						<li>Guests Grievance Redressal</li>
						<li>Frequently asked Questions</li>
					</ol>

					<p><strong>Special Assistance/Able Bodied Guest Assistance</strong></p>
					<p><strong>Seating accommodation & aircraft accessibility for guests with a disability:</strong></p>

					<p>We will allocate you a seat that is convenient and designated as accessible for persons with disabilities at no additional charge. These seats will remain blocked until two hours before the departure time. Our seats have movable armrests for easy accessibility to the seats. However, in order to meet the safety requirements of the <strong>DGCA CAR Section 3, Series M, Part I</strong>, guests with mobility issues which make it unsafe to facilitate aircraft evacuations will not be given a seat in a location where they would obstruct emergency exits, impede the crew in their duties, obstruct access to emergency equipment or hinder aircraft evacuation in case of an emergency. To allow us to provide the best assistance, please provide us with as much information as possible at the time of your booking and or at the time of check-in</p>

					<Accordion activeKey={activeKeySub} onSelect={e => setActiveKeySub(e)}>
						<MyCard prefix="sub_" header="1. Infants" eventKey={1}>
							<p>
								<strong>Age:</strong>
							</p>

							<p>
								For safety reasons, children above the age of seven (7) days and under the age of two (2) years as on
								the date of travel are regarded as <strong>Infants.</strong> In the event of a failure to produce a valid proof of age for
								the infant, full fare as applicable on the date of travel will be payable for the infant and the infant
								will be allowed to travel only subject to availability of seats.
							</p>
							<p><strong>Seating:</strong></p>
							<ul>
								<li>No extra seats may be booked for Infants.</li>
								<li>Infants cannot travel in their own seat and must be seated in an adult's lap.</li>
							</ul>
							<p><strong>Number:</strong></p>
							<p>
								A maximum of one infant per adult is allowed.
							</p>
							<p><strong>Fee:</strong></p>
							<p>
								For infants not occupying a seat, a fee, as mentioned in fees and charges, per sector is applicable,
								applicable from time to time.
							</p>
							<p><strong>Baggage allowance:</strong></p>
							<p>
								Infants are permitted a baggage allowance for hand baggage only for a maximum weight of 7Kgs.
							</p>
						</MyCard>

						<MyCard prefix="sub_" header="2. Minors:" eventKey={2}>
							<p>Children above the age of two (2) and below the age of twelve (12) years are regarded as Minors.</p>
							<p>Children aged between the ages of 2 and 5 must be accompanied by a companion aged 18 years or more.</p>
							<p><strong>A child’s journey under unaccompanied minor service</strong></p>
							<p>
								As a child, travelling alone can be an exciting experience but a parent would need to know and be
								assured that the child will be taken care of.
							</p>
							<p>
								flybig may, at its sole discretion, accept unaccompanied minors, that is, children aged above 5 and
								below 12 upon payment of an additional fee as mentioned in fees and charges per sector:
							</p>
							<p><strong>At departure</strong></p>
							<ul>
								<li>Please make the child available at the airport at least 2 hours prior to departure.</li>
								<li>A parent or guardian is required to complete a signed form ( click here to download the form) at the point of check-in at each departure airport prior to travel, which must include the name and contact numbers for a parent or guardian (above the age of 18) who will meet the unaccompanied minor at the point of arrival;</li>
								<li>Valid photo-ID for both the unaccompanied minor and the parent or guardian is required at the point of check-in;</li>
								<li>A staff member will take the child to the aircraft where our cabin crew will then look after them</li>
								<li>The parent or guardian must remain at the airport until the flight has departed.</li>
							</ul>
							<p><strong>On Board</strong></p>
							<ul>
								<li>During the flight our cabin crew will keep an eye on the child and will make sure that they have everything they need.</li>
								<li>Do not worry about the snack, as it is included in our unaccompanied minor service</li>
								<li>To make sure that the child has a comfortable flight, the parent is requested to pack medication, warm clothing and a favourite toy or game in their hand baggage.</li>
							</ul>
							<p><strong>Transit/Transfer</strong></p>
							<ul>
								<li>If the stopover at transfer station is less than 6 hours and if the child is booked through out on flybig, we shall be able to accept the child under unaccompanied minor service</li>
								<li>If the stopover at transfer stations is more than 6 hours and/or involves night halt and/or transfer is on other airline except flybig, we shall not be able to accept the child under unaccompanied minor service. In such cases child must be accompanied by someone who is above the age of 18 years in age.</li>
							</ul>
							<p><strong>Upon arrival</strong></p>
							<ul>
								<li>When the flight arrives, our ground staff will accompany and assist the child through the airport arrivals process.</li>
								<li>We will only release the child to the care of the nominated adult, after checking their photo ID as per the details mentioned in the form</li>
								<li>Please be available at the arrival hall at least 30 mins prior arrival to receive the child</li>
							</ul>

							<table className="flybigTable">
								<tbody>
									<tr>
										<th>Age of child</th>
										<th>Availing unaccompanied minor service</th>
									</tr>
									<tr>
										<td>Under 5 years</td>
										<td>unaccompanied minor service is unavailable, the child must travel with someone over the age of 18 years.</td>
									</tr>
									<tr>
										<td>5-12 years</td>
										<td>Book unaccompanied minor service and can travel on all flights.</td>
									</tr>
									<tr>
										<td>13-18 years</td>
										<td>The child can travel alone or we would be happy to offer unaccompanied minor service</td>
									</tr>
								</tbody>
							</table>

							<p><strong><u>Rescheduling / Date Change / No-Show  -</u></strong></p>
							<p>The reservation booked under Unaccompanied Minor Discount category can be modified with NIL charges, the ticket cancellation charge is INR 2500 or base fare + fuel surcharge whichever is lower & no-show would charge is INR 3500. Also this fare cannot be clubbed with any other fare, hence if the seat is not available on the specified date, guest need to book different date with seat availability.</p>


							<p><strong><u>Baggage allowance:</u></strong></p>
							<p>15kgs check-in & 7kgs hand baggage</p>
						</MyCard>

						<MyCard prefix="sub_" header="3. Pregnant Women" eventKey={3}>
							<p>
								We understand that pregnancy is not a medical condition unless there are known medical complications which can endanger the life of mother or the child. However, we request you to kindly take note of the following:
							</p>
							<ul>
								<li>In case of normal pregnancies, expectant mothers till the end of 36 weeks of pregnancy may be permitted to fly on flybig flights provided there are no prior complications. Fit to Fly certificate from the treating obstetrician dated not more than 7 days before the date of travel is required only if the pregnancy is between 28th & 36th week.</li>
								<li>Multiple (twins, triplets etc.) and uncomplicated pregnancy is between 28th till the 36th week. Expectant mothers are allowed to travel only up 36 weeks of pregnancy with Fit to Fly certificate from the treating obstetrician dated not more than 7 days before the date of travel, it is required only if the pregnancy is between 28th & 36th week.</li>
								<li>Expectant mothers beyond 36 weeks of pregnancy shall not be allowed to travel in case of complicated pregnancy. Fit to Fly certificate from the treating obstetrician dated not more than 3 days before the date of travel is required only if the pregnancy is between 28th & 36th week. In addition, medical clearance is given by the company doctor who will discuss the case on an individual basis with the guest’s doctor/or will check all the medical documents of the guest (pregnant lady) in case treating obstetrician is not contactable.</li>
								<li>In addition, medical clearance is given by the company doctor who will discuss the case on an individual basis with the guest’s doctor/or will check all the medical documents of the guest (pregnant lady) in case treating obstetrician is not contactable.</li>
							</ul>
						</MyCard>

						<MyCard prefix="sub_" header="4. Safety Assistants" eventKey={4}>
							<p>
								A Safety Assistant is any able-bodied person who assists the guest with disability to exit the
								aircraft in case of an emergency evacuation, or to establish communication with cabin crew for the
								purpose of the required safety briefing.
							</p>
							<p><strong>Who can be a Safety Assistant?</strong></p>
							<p>
								The safety assistant shall be at least 18 years old, physically and mentally able and willing to
								evacuate the disabled guest in case of an emergency
							</p>
							<p><strong>Seating of the Safety Assistant</strong></p>
							<p>
								The Safety Assistant will be seated next to the guest, in order to assist in case of an emergency.
								If a safety assistant is accompanying several guests with disability, the safety assistant will be
								seated in the same row as the guest with disability. In case seats are not available in the same
								row, then the safety assistant is seated in the row immediately in front or behind of the guest
								with disability.
							</p>

							<p><strong>Examples of requirement of a safety assistant:</strong></p>
							<ul>
								<li>
									<strong>Guests travelling in a stretcher -</strong>
									{' '}
									The safety assistant must be a medical / paramedical escort. In selected cases flybig’s internal physician may permit a non-medical Safety assistant. (flybig is not accepting stretchers on the ATR aircraft)
								</li>
								<li>
									<strong>Guests with mental disability</strong>
									{' '}
									who are unable to comprehend or respond appropriately to safety instructions, including the regulatory safety briefing and any other safety related instructions provided in the aircraft.
								</li>
								<li>
									<strong>Guests with a mobility impairment</strong>
									{' '}
									so severe that the person is unable to physically assist in his or her own evacuation of the aircraft in case of an emergency.
								</li>
								<li>
									<strong>Guests with severe hearing , vision impairments and speech impairment ,</strong>
									{' '}
									(all together) - if the <strong>guests</strong> cannot establish some means of communication with the cabin crew that is adequate both to allow transmission of the regulatory safety briefing, and to enable the <strong>guests</strong> to assist in his or her own evacuation of the aircraft in the event of an emergency
								</li>
								<li>Any other disability which flybig, in its sole discretion decides may affect the safety of the <strong>guest</strong> while on board an aircraft</li>
							</ul>
							<p><strong>Guest Responsibility</strong></p>
							<p>
								We at flybig are highly committed to <strong>guest's</strong> safety and convenience and making the best of
								efforts to ensure that all our <strong>guests</strong> experience the finest of services on board our planes.
								Having said that, it is pertinent to mention here that our airline is still in an evolving stage. We have
								learnt to interact with persons with disability flying with us, however, we are still learning, constantly
								exploring, and finding ways to serve our guests better.
							</p>
							<p>
								At this stage, we are trying our best to gain expertise and competence, thereby enhancing our
								teams’ awareness & <strong>guest</strong> handling skills.
							</p>
							<p>
								In order to make your journey comfortable, we solicit guest's support and cooperation. For a
								guest travelling alone, should be able to perform the following activities independently:
							</p>
							<p><strong>Requirements:</strong></p>
							<ul>
								<li>Fasten and unfasten seat belt</li>
								<li>Take out and put on the lifejacket</li>
								<li>Leave the seat and get to an emergency exit (this does not need to be by walking)</li>
								<li>Put on an oxygen mask</li>
							</ul>
							<p>
								<strong>
									For certain guests we express our special concern and insist that they travel with a
									companion. Generally, the guests who are likely to need a companion are those who:
								</strong>
							</p>
							<ul>
								<li>Have a severe learning or cognitive challenge which prevents them from understanding or reacting to safety instructions</li>
								<li>Are hearing and visually impaired</li>
								<li>Have a challenge that prevents them from moving without assistance to reach an emergency exit.</li>
							</ul>
						</MyCard>

						<MyCard prefix="sub_" header="5. Guests with Sensory Impairment" eventKey={5}>
							<p><strong>(A) Visual Impairment</strong></p>

							<p>
								<strong>Pre-requisite</strong>
							</p>
							<p>
								If assistance is required within the airport, <strong>guest</strong> or his representative shall intimate
								flybig about the type of assistance required prior to the departure.
							</p>

							<p>
								<strong>At the airport</strong>
							</p>
							<ul>
								<li>flybig would request <strong>guests</strong> with Visual Impairment to check-in early for their flight for a hassle free
									experience If <strong>guest</strong> declares independence in mobility within the airport and doesn’t require any staff for assistance, the <strong>guest</strong> must ensure to be present at the designated boarding gate at the boarding time advised to him verbally by the staff</li>
								<li>Do note that our boarding gates close 25mins (domestic) prior to the scheduled departure time, post which boarding is not allowed.</li>
								<li>Also note since some of the airports are silent airports there would not be any sort of announcements made.</li>
								<li>The <strong>guest</strong> is welcome to board the aircraft at leisure; however if requested to the staff at the departure
									gate, the <strong>guest</strong> will be able to board first, the staff at the boarding gate shall assist you in boarding first.</li>
							</ul>
							<p>
								<strong>On board</strong>
							</p>
							<p>
								Every flybig aircraft comes equipped with safety briefing cards printed in Braille for our <strong>guests</strong>
								who have vision disabilities. Our cabin crew will read the snack menu options, identify food items and
								placement on the tray and assist with opening the food package upon request. Our cabin crew will not be able to provide any assistance in feeding such <strong>guest</strong>
							</p>
							<p>Due to safety regulations, the <strong>guest</strong> with visual impairment will not be allocated an exit row seat.</p>

							<p>
								<strong>(B) Hearing Impairment</strong>
							</p>
							<p>
								<strong>Pre-requisite</strong>
							</p>
							<ul>
								<li>If assistance is required within the airport, <strong>guest</strong> or his representative shall intimate flybig about the type of assistance required prior to the departure.
								</li>
							</ul>
							<p>
								<strong>At the airport</strong>
							</p>
							<ul>
								<li>
									flybig would request <strong>guests</strong> with Hearing Impairment to check-in early for their flight for a hassle
									free experience.
								</li>
								<li>
									If <strong>guest</strong> declares independence in mobility within the airport and doesn’t require any staff for assistance, the <strong>guest</strong> must ensure to be present at the designated boarding gate at the boarding time mentioned on their boarding pass. Also note most of the airports are equipped with electronic flight display, however, the nearest flybig staff can always be contacted for any assistance required.

								</li>
								<li>
									Do note that our boarding gates close 25 mins (domestic) prior to the scheduled departure time, post which boarding is not allowed.
								</li>
								<li>
									The <strong>guest</strong> is welcome to board the aircraft at leisure; however if requested to the staff at the
									departure gate, the <strong>guest</strong> will be able to board first. The staff at the boarding gate shall assist
								</li>
							</ul>
							<p>
								<strong>On board</strong>
							</p>
							<p>
								Due to safety regulations, the <strong>guest</strong> will not be allocated an exit row seat.
							</p>

							<p><strong>(C) Visual and Hearing Impairment</strong></p>
							<p><strong>Pre-requisite</strong></p>
							<ul>
								<li>
									If assistance is required within the airport, <strong>guest</strong> or his representative shall intimate flybig about the type of assistance required at least 48 hours prior to the departure.
								</li>
								<li>

									For <strong>guest's</strong> safety, they will need to travel with a safety assistant if they need help to do any one
									of the following:
									<br />

									<FaCheck />
									Fasten and unfasten your seat belt
									<br />
									<FaCheck />
									Take out and put on your life-jacket
									<br />
									<FaCheck />
									Leave your seat and get yourself to an emergency exit (this does not need to be by walking)
									<br />
									<FaCheck />
									Put on an oxygen mask
									<br />
									<FaCheck />
									Establish communication with crew with regards to safety briefings and react to safety instructions.
									<br />

								</li>
							</ul>
							<p>
								If you are required to travel with a companion, flybig will sell them a ticket at the same fare as that
								paid by the first <strong>guest</strong>, even if rates have increased in the meantime. It may also be possible to
								find another <strong>guest</strong> on the flight willing to take the role of an accompanying person for
								emergency purposes but this cannot be guaranteed.
							</p>
						</MyCard>

						<MyCard prefix="sub_" header="6. Service Dogs" eventKey={6}>
							<u>flybig does not accept Service Dogs on ATRs.</u>
						</MyCard>

						<MyCard prefix="sub_" header="7. Mobility Assistance" eventKey={7}>
							<ul>
								<li>flybig would be more than happy to provide a wheelchair as per your requirement. In order to ensure the service meets your needs, our reservation team or the airport staff may ask you some questions to determine the level of assistance you require.</li>

								<li>flybig wheelchair service is provided with an attendant at no additional charge to you. The attendant will assist in transporting you within the airport area and to the gate or the aircraft door (depending on the airport facilities).</li>

								<li>Our team is trained in transferring techniques and can assist if you are unable to transfer by yourself from the wheelchair to/from your seat;</li>
								<li>Whilst we will do everything we can to help our <strong>guests</strong> to their onward flight, they are requested to be aware that there might be a delay in assisting them during busy periods. flybig shall not be liable for any such <strong>guest</strong> missing onward flights, we would always advise them to factor in delay times when making their travel plans.</li>
								<li>Our employees are trained to assist our <strong>guests</strong> with disabilities and for any sorts of limitations, we offer a wide variety of special assistance/able bodied assistance.</li>
								<li>Please be aware that there might be unpredictable circumstances such as weather or mechanical problems that may call for a last minute unavoidable change, but we will do our best to accommo date your needs.</li>
								<li>
									If you are travelling in a group of 10 or more people and require wheelchairs, please contact our call centre at
									{PHONE_NUMBER}
									{' '}
									least 48 hours before scheduled departure
								</li>
								<li>Please contact us for mobility assistance for a group reservation on groupdesk@flybig.in or by contacting our agents at the call centre.</li>
							</ul>
							<p><strong>flybig does provide the following facilities to its guests with reduced mobility:</strong></p>
							<ul>
								<li>Assistance in moving to and from seats; and</li>
								<li>Help in preparation for eating.</li>
							</ul>
							<p>
								However, we express our inability to provide <strong>guests</strong> with any assistance for personal care needs
								on-board, such as, eating/drinking, administration of medications, assistance inside the lavatory, or
								other personal care needs. If assistance is required with any of these, we recommend a Safety Assistant.
							</p>
						</MyCard>

						<MyCard prefix="sub_" header="8. Wheelchair users or guests using wheelchairs, Crutches / braces / other prosthetic devices" eventKey={8}>
							<p><strong>Before travel</strong></p>
							<p>
								We want our <strong>guests</strong> to be as comfortable as possible on their flybig flight. Our staff will be
								pleased to discuss their needs, so call us and we'll help you plan your travel
							</p>
							<p><strong>Traveling with own wheelchair</strong></p>
							<ul>
								<li>If guests wish to check-in their own wheel chair, flybig shall provide assistance to dismantle and/or reassemble the wheelchair under the supervision of the <strong>guest</strong>. However flybig shall not be liable for any damage arising while handling the wheelchair. Such checked in wheelchair will be returned to the <strong>guest</strong> at the destination airport</li>
								<li>If the guest wishes to use their own wheelchair at the airport, they are permitted to do so up to the aircraft, whereupon it will be stored in the hold area.</li>
								<li>In the event of loss or damage, mobility aids or any equipment relating to the disability which are placed into the checked are luggage is not covered for anything more than the maximum liability under flybig’s Conditions of Carriage.</li>
								<li>We recommend suitable travel insurance for any equipment carried along</li>
								<li><strong>Guests</strong> are allowed to use their own crutches, braces and / or other prosthetic devices up to the aircraft, and may board with such items as hand baggage, provided the <strong>guests</strong> are dependent on them.</li>
								<li><strong>Guest</strong> with a disability may use their own manual powered wheelchair till the aircraft door</li>
								<li>Unfortunately electronic powered wheelchair(s) with a wet-cell battery shall not be accepted since it’s corrosive in nature and is considered to be a ‘’Dangerous Good’’</li>
								<li>Unfortunately electronic powered wheelchair(s) with lithium or non spillable battery shall not be allowed beyond the check-in counter, due to security reasons</li>
								<li>Electronic wheelchairs will be dismantled at our check-in counters and the <strong>guest</strong> needs to carry something to seal the battery terminals to avoid damage to the battery</li>
							</ul>
							<p><strong>Airport Wheelchairs</strong></p>
							<p>
								We have wheelchairs available for use at all airport locations to assist with transportation from check-in
								to the boarding gate, making connections (if applicable) and from the aircraft to the arrivals hall at the
								destination. <strong>Guests</strong> can request this service when making their booking, and upon arrival at the
								airport they can notify one of our airport service agents about their wheelchair requirement. For an
								hassle free experience, <strong>guest</strong> shall notify flybig at least 48 hrs prior departure
							</p>
							<p><strong>Safety Assistant</strong></p>
							<p><strong>On board</strong></p>
							<p>
								If a <strong>guest</strong> is travelling without safety assistant, flybig shall be happy providing below mentioned
								assistance:
							</p>
							<ul>
								<li>will be able to extend a meet and assist service to the <strong>guest</strong> to assist in boarding, deplaning, flight connections and check-in / transfer / reclaiming of checked-in bags.</li>
								<li>stowing their hand baggage</li>
								<li>opening any parts of <strong>guests</strong> on board snack.</li>
								<li>on board individual safety briefings if requested by <strong>guest</strong></li>
							</ul>
							<p>
								flybig regrets its inability to assist with following,
							</p>
							<ul>
								<li>
									assist the <strong>guest</strong> with personal care and toileting needs before, after and during the flight.
								</li>
							</ul>
							<p><strong>At destination</strong></p>

							<ul>
								<li>If the wheelchair has been stowed in the hold, we'll make every effort to return it to the <strong>guest</strong> as close to the aircraft door as possible. If this is not possible, we will make sure there is a wheelchair available to assist <strong>guest</strong> to the baggage reclaim area.</li>
								<li>We can arrange assistance between the gate and the arrivals hall if the <strong>guest</strong> would like this service. Depending on the airport, this will either be by airport wheelchair or accessible buses.</li>
							</ul>
						</MyCard>

						<MyCard prefix="sub_" header="9. Spinal Support Equipment (SSE)" eventKey={9}>
							<ul>
								<li><strong>Guests</strong> must bring their own spinal support equipment and should be fully responsible for installation, usage and removal.</li>
								<li><strong>Guest</strong>-supplied spinal support equipment will be carried free of charge.</li>
								<li>
									If you are planning to travel with Spinal Support Equipment, we recommend that you please contact us at
									{PHONE_NUMBER}
									{' '}
									<strong>at least 7 (seven)</strong> working days prior to departure and inform us of your SSE specifications.
								</li>
								<li>Our Engineering team will then check to ensure that your equipment can be accommodated safely in the aircraft.</li>
							</ul>
						</MyCard>

						<MyCard prefix="sub_" header="10. Travelling with broken limbs" eventKey={10}>
							<ul>
								<li>
									All <strong>guests</strong> with a mobility problems such as fractured legs in plaster or paraplegics will
									preferably be allocated seats which will allow maximum space for their comfort, subject to load
									factor
								</li>
								<li>
									All <strong>guests</strong> with limbs in plaster casts shall preferably be accommodated in seats near toilets and
									floor level exits provided with slide/raft (not emergency exit row seats).
								</li>
							</ul>
						</MyCard>

						<MyCard prefix="sub_" header="11. Guests with Psychiatric or Emotional disorders" eventKey={11}>
							<ul>
								<li><strong>Guests</strong> with psychiatric or emotional disorders shall always travel with a safety assistant.</li>
								<li>The safety assistant shall carry the treating physician’s certificate along with a prescription for a sedative medicine, which may be administered by the attendant, prior to commencement of travel or as may be required en-route.</li>
							</ul>
						</MyCard>

						<MyCard prefix="sub_" header="12. Guests having Autism/ Intellectual disability/Dementia / Alzheimer&#39;s and Down syndrome"
							eventKey={12} >
							<ul>
								<li>
									<strong>Guests</strong> with Autism/Intellectual disability/Dementia/Alzheimer's and Down syndrome need
									to book their flights at least 48hrs prior departure by calling our call centre at
									{PHONE_NUMBER}
								</li>
								<li>
									While making the reservation, please advise us about your specific requirements and the best way to
									approach and assist in travel
								</li>
								<li>
									<strong>Guests</strong> with Autism/ Intellectual disability/Dementia / Alzheimer's and Down syndromewill
									be given time to settle in their seat before other guests board the aircraft.
								</li>
								<li>
									Our <strong>guest</strong> service and cabin crew team will provide all the assistance required other than the
									ones provided by a safety assistant.
								</li>
							</ul>
							<p><strong>flybig offers</strong></p>
							<p>
								Assistance, upon request, from the ticket counter till the aircraft seat shall be provided. In addition,
								meeting their family member at the gate upon arrival is also available. These requests need to be
								made at the ticket counter at check-in. The name of the person picking up the <strong>guest</strong> must be
								communicated at the time of check-in as well.
							</p>
						</MyCard>

						<MyCard prefix="sub_" header="13. Internal Medical Devices" eventKey={13}>
							<p>
								If you are travelling with an internal medical device, such as a pacemaker or a defibrillator, kindly inform
								us while making the reservation and also at the airport, to the security personnel conducting your
								screening, before the process begins.
							</p>
						</MyCard>

						<MyCard prefix="sub_" header="14. Portable oxygen concentrators (POC)" eventKey={14}>
							<p>
								Only Portable Oxygen Concentrators (“POC”), approved by Federal Aviation Administration (“FAA”)
								is permitted on board flybig flights either as checked-in or hand baggage, free of cost, subject to
								following conditions being met:
							</p>

							<ul>
								<li>A request for carriage or use of the POC on board being made by the guest to flybig a minimum seventy-two (72) hours in advance from the scheduled departure time;</li>
								<li>POC should not cause any interference with the electrical, navigation or communication equipment on board;</li>
								<li><strong>Guest</strong> carrying the POC on board must comply with all security requirements and instructions communicated by the crew, from time to time;</li>
								<li><strong>Guest</strong> alone will be solely responsible for packing, assembling, dismantling or facilitating administration of POC both on ground and on board. flybig will under no circumstances be liable or responsible for the same.</li>
								<li>Notwithstanding anything contained herein, flybig, in its sole discretion, reserves the right to refuse carriage of the POC, if it is likely to endanger the safety of the aircraft or of any person;</li>
							</ul>
							<p><strong>If POC is to be carried as checked-in baggage:</strong></p>
							<ul>
								<li>
									For safety purposes, <strong>guest</strong> must ensure that the POC prior to check in is empty and free of
									pressure. Thus, if the POC was used before coming to the airport, then the guest must to reset
									the POC to avoid any residual oxygen in the equipment;
								</li>
								<li>
									If POC operates on lithium battery, battery should be removed and carried by <strong>guest</strong> as hand
									baggage. If POC operates on any other type of battery, battery should be removed and packed
									separately in such a way that battery terminals do not come in contact with any metal objects. Car
									riage of such batteries will further be subject to applicable regulatory requirements including but not
									limited to those mentioned under the Aircraft <strong>(Carriage of Dangerous Goods) Rules, 2003, Interna
										tional Civil Aviation Organization (ICAO) Technical Instructions for the Safe Transport of Dangerous
										Goods by Air, the International Air Transport Association (IATA) Dangerous Goods Regulations.</strong>
								</li>
							</ul>
							<p><strong>If Portable Oxygen Cylinder is to be carried as hand baggage:</strong></p>
							<p>
								<strong>Guests</strong> carrying/using the POC on-board shall be assigned only a window seat. Such <strong>guests</strong>
								will not be assigned any seats in rows 1 & 2 in ATRs. Such <strong>guests</strong> shall not be seated in the aisle
								seats. flybig reserves the rights to change the seats of such <strong>guests,</strong> if required.
							</p>
							<p>
								Such <strong>guests</strong> must be capable of seeing, hearing and understanding the POC device warnings and
								should be in a position to take appropriate action, without assistance, in response to those warnings;
								During taxi, take-off and landing the POC, if being used must be stowed under the seat in front.
							</p>

							<p>
								<strong>
									flybig has a list of FAA approved POCs, such list is indicative and not exhaustive. Please call our
									call centre or airport operation to clarify if your POC is approved for carriage in our aircraft
								</strong>
							</p>
						</MyCard>

						<MyCard prefix="sub_" header="15. Guests with certain adverse medical conditions" eventKey={15}>
							<ul>
								<li>
									<strong>Guests</strong> who are ailing from any particular medical condition can travel on flybig after giving prior
									information regarding his/her medical condition, at least 48 hours prior to the scheduled departure
									of the flight, as per the medical form provided on flybig website, in order for flybig to provide
									complete assistance to such <strong>guest</strong>.
								</li>
								<li>The <strong>guest</strong> must also inform flybig regarding any hospitalization requirement upon arrival.</li>
								<li>In case a <strong>guest</strong> will be accompanied by a safety assistant during travel then his/her name and contact details must also be shared with flybig.</li>
								<li>
									flybig would want to mention that its cabin crew are not authorized to give special assistance/able
									bodied assistance (e.g. lifting etc.). Further they are trained only in first aid and are not permitted to administer any injection or to give medication. flybig neither
									facilitates nor allows carriage or usage of oxygen cylinders on board.
								</li>
							</ul>
							<p>
								A <strong>guest</strong> who has agreed to this Conditions of Carriage agrees and understands that he/she has
								accepted to undertake the travel on flybig on his/her own will and volition and further agrees that
								flybig will not be responsible for any liability other than the liability as specified in the Conditions of
								Carriage.
							</p>
							<p><strong>Guests requiring stretchers</strong></p>
							<p>
								Unfortunately flybig will not be able to accept request for stretcher on ATR Aircrafts.
							</p>
						</MyCard>

						<MyCard prefix="sub_" header="16. Guest Grievance Redressal" eventKey={16}>
							<p>
								At flybig we strive to achieve the highest level of <strong>guest</strong> satisfaction and our teams have been trained to deliver the same. However, in the event you feel dissatisfied about something please do not hesitate to reach out to us so we can make things right if they were not. We are reachable to you in more ways than one.
							</p>
							<p>
								Call Center:
								{PHONE_NUMBER}
							</p>
							<p>
								Nodal Officer, Kanchan Gidwani at {' '}
								<a href="mailto:nodalofficer@flybig.in">nodalofficer@flybig.in</a>
							</p>
							<p>
								Appellate Authority, Manish Koul at {' '}
								<a href="mailto:appellateauthority@flybig.in">appellateauthority@flybig.in</a>
							</p>

							<p>You may contact them from Monday - Friday between 0930hrs - 1830hrs.</p>
							<p>
								Contact: {' '}
								<a href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a>
							</p>
							<p>OR</p>
							<p>Our Guest Services Manager, Airport Manager at various airports shall be more than happy to help you.</p>
						</MyCard>

						<MyCard prefix="sub_" header="17. Frequently Asked Questions" eventKey={17}>
							<p><strong>1. I am visually impaired and will be carrying a white cane with me, how shall I inform the airlines?</strong></p>
							<p>
								Kindly contact us at
								{' '}
								{PHONE_NUMBER}
								{' '}
								if you have any assisting devices. We will find out the best
								way to store them so they arrive without damage. At the airport, please let the check-in personnel
								know that you may need extra time to board the plane. After the plane has landed, kindly wait in the
								plane until you see or have your assisting device, wheelchair, etc.
							</p>
							<p><strong>2. I am visually impaired, can I travel with my guide dog? If yes, what are the formalities involved?</strong></p>
							<p>
								flybig does not accepts guide dogs on ATRs.
							</p>
							<p><strong>3. I will be carrying my own motorized wheelchair, can you advise how shall I go about it?</strong></p>
							<p>
								We will require the dimensions, weight, type of tires, type of batteries, etc. of your wheelchair. We
								suggest you carry spare batteries. Wheelchair battery may be disconnected due to safety reasons
								and <strong>wet cell batteries are not allowed to be carried on a flybig flight</strong>. Kindly inform your travel
								agent/us about any assisting devices you may need or already have like canes and crutches.
							</p>
							<p><strong>4. If I will be traveling through different time zones, how will jet lag affect my situation?</strong></p>
							<p>
								The following should be taken care during your trip: upper body strength, communication ability, speech issues, voice issues, vision problems, hearing problems, heat issues, medication needs, oxygen requirements, and dietary requirements. Make sure you carry your medications and back-up prescriptions with you on the plane, along with the doctors' name, address, fax number for faxing prescriptions, phone numbers, medical diagnosis, names and dosages of medication you're taking and any allergies you may have.
							</p>
							<p>
								<strong>
									5. Is stretcher service available on flybig flights? How long does it take to apply for a stretcher
									service in advance?
								</strong>
							</p>
							<p>
								Unfortunately flybig does not accepts request for stretcher on ATR aircrafts.
							</p>
							<p><strong>Self- Offloading</strong></p>
							<p>
								Self-offloading after boarding the aircraft is not permitted, since it can cause safety and security
								risks, besides inconveniencing fellow <strong>guests</strong> on board. Accordingly, in the event that any
								<strong>guest</strong> expresses his/her desire to off-load himself/herself and is subsequently off-loaded, then
								such <strong>guest</strong> shall be penalized an amount as mentioned in <strong>Fees &charges</strong>. flybig may at its sole
								discretion, not penalize such <strong>guest,</strong> in case of a medical emergency and in such cases, flybig may
								require a doctor’s certificate from airport doctor as well.
							</p>
						</MyCard>
					</Accordion>
				</MyCard>

				<MyCard header="11) Baggage" eventKey={11}>
					<p>
						<strong>Check your baggage details (ATR Aircraft)</strong>
					</p>
					<p>*If the Guests are carrying more than the allowed baggage limit (1Piece of bag with 15kgs) will be charged the following baggage fee at the time of check-in</p>

					<table className="flybigTable">
						<tbody>
							<tr>
								<th className='text-center'>Domestic Travel - Check-in bag (ATR)</th>
							</tr>
							<tr>
								<td className='text-center'>INR 550 for every additional Kg* & INR 1000 for every additional piece of luggage</td>
							</tr>
							<tr>
								<td>
									<p className='text-justify'>
										*For domestic flights, maximum weight for check-in baggage in a single piece cannot exceed 23kg (within the dimensions as prescribed on our website), however charges for excess weight will be levied beyond 15kgs. Additional pieces of baggage will be chargeable at a rate of INR 1000 per piece. The maximum allowed weight and piece depends on the flight load on the day and time of departure. flybig reserves the right to accept or deny baggage.
									</p>
									<p>*Baggage pooling/clubbing is not allowed.</p>
								</td>
							</tr>
						</tbody>
					</table>

					<table className="flybigTable">
						<tbody>
							<tr>
								<th className='text-center'>Domestic Travel - Hand bag (ATR)</th>
							</tr>
							<tr>
								<td className='text-center'>
									<p>Hand Baggage: Only one Piece of hand bag including laptop bag up to 7 kgs and 35cm x 30cm x 20cm, shall be allowed per guest. For contactless travel we recommend to place it under the seat in front, on board.</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className='text-justify'>
										*For domestic flights, maximum weight for hand baggage in a single piece cannot exceed 07kg (within the dimensions as prescribed on our website), however charges for extra piece or weight will be levied beyond 07kgs. Additional pieces of baggage will be chargeable at a rate of INR 1000 per piece. The maximum allowed weight and piece depends on the flight load on the day and time of departure. flybig reserves the right to accept or deny baggage.
									</p>
									<p>*Baggage pooling/clubbing is not allowed.</p>
								</td>
							</tr>
						</tbody>
					</table>


					<p><strong>Check your baggage details (DHC-6 Aircraft)</strong></p>
					<p>*If the Guests are carrying more than the allowed baggage limit (1Piece of bag with 07kgs) will be charged the following baggage fee at the time of check-in</p>

					<table className="flybigTable">
						<tbody>
							<tr>
								<th className='text-center'>Domestic Travel - Check-in bag (DHC-6)</th>
							</tr>
							<tr>
								<td className='text-center'>
									<p>INR 700 for every additional Kg* & INR 1000 for every additional piece of luggage</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className='text-justify'>
										*For domestic flights, maximum weight for check-in baggage in a single piece cannot exceed 7kg (within the dimensions as prescribed on our website), however charges for excess weight will be levied beyond 7kgs. Additional pieces of baggage will be chargeable at a rate of INR 1000 per piece. The maximum allowed weight and piece depends on the flight load on the day and time of departure. flybig reserves the right to accept or deny baggage.
									</p>
									<p>*Baggage pooling/clubbing is not allowed.</p>
								</td>
							</tr>
						</tbody>
					</table>


					<table className="flybigTable">
						<tbody>
							<tr>
								<th className='text-center'>Domestic Travel - Hand bag (DHC-6)</th>
							</tr>
							<tr>
								<td className='text-center'>
									<p>Hand Baggage: Only one hand bag including laptop bag up to 3 kgs and 23cm (H) x 33cm (L) x 33cm (W) OR 9" x 13" x 13", shall be allowed per guest. For contactless travel we recommend to place it under the seat in front, on board.</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className='text-justify'>
										*For domestic flights, maximum weight for hand baggage in a single piece cannot exceed 03kg (within the dimensions as prescribed on our website), however charges for extra piece or weight will be levied beyond 03kgs. Additional pieces of baggage will be chargeable at a rate of INR 1000 per piece. The maximum allowed weight and piece depends on the flight load on the day and time of departure. flybig reserves the right to accept or deny baggage.
									</p>
									<p>*Baggage pooling/clubbing is not allowed.</p>
								</td>
							</tr>
						</tbody>
					</table>


					<p><strong>Items Unacceptable As baggage or to be carried inside baggage</strong></p>
					<p>
						flybig reserves the right to refuse carriage of such baggage or such items found in the baggage as
						stated below. For more details on permissible / prohibited goods, please refer to our website or
						write to us at
						{' '}
						<a href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a>
					</p>
					<p>
						Items which are not properly packed in suitcases or other suitable containers in order to ensure safe
						carriage with ordinary care and handling;
					</p>
					<p>
						Items which are likely to endanger the aircraft or persons or property on board the aircraft such as
						those specified in the International Civil Aviation Organization (ICAO)Technical Instructions for the
						Safe Transport of Dangerous Goods by Air, the International Air Transport Association (IATA)
						Dangerous Goods Regulations, Dangerous Goods as per local laws and applicable regulations and as
						per our own Terms and Conditions; Items, the carriage of which is prohibited by applicable laws,
						regulations or orders;
					</p>
					<p>
						<strong>Compressed gases:</strong>
						{' '}
						deeply refrigerated, flammable, non-flammable and poisonous such as butane oxygen, liquid nitrogen, aqualung cylinders and compressed gas cylinders;
					</p>
					<p>
						<strong>Corrosives items</strong>
						{' '}
						such as acids, alkalis, mercury and wet cell batteries and apparatus containing mercury;
					</p>
					<p>
						<strong>Explosives</strong>
						, munitions, fireworks and flares, ammunition including blank cartridges handguns, fireworks, pistol caps, swords, knives and similar items;
					</p>
					<p>Small lithium battery-powered vehicles such as air wheels, solo wheels, hover boards, minisegways and balance wheels;</p>
					<p>
						<strong>Flammable liquids and solids</strong>
						{' '}
						such as lighter refills, lighter fuel, matches, paints, thinners, firelighters, lighters that need inverting before ignition, matches, radioactive material, briefcases with installed alarm devices;
					</p>
					<p>
						<strong>Oxidizing materials</strong>
						{' '}
						such as bleaching powder and peroxides;
					</p>
					<p>
						<strong>Poisons and infectious substances</strong>
						{' '}
						such as insecticides, weed-killers and live virus materials;
					</p>
					<p>Fish (including, sea food), animals, birds, insects in any form, whether live and/or dead and/or frozen and/or dried;</p>
					<p>Anything that possesses and/or is capable of possessing and/or emitting a conspicuous and/or offensive odour;</p>
					<p>
						<strong>Other dangerous articles</strong>
						{' '}
						such as magnetized materials, offensive or irritating materials;
					</p>
					<p>
						<strong>Human or animal remains;</strong>
						{' '}
						Live or dead animals;
					</p>
					<p>
						Items, which, in our reasonable opinion, are unsuitable for carriage by reason of their weight, shape,
						size or character.
					</p>
					<p>
						Any other item that flybig feels may cause inconvenience or a safety hazard to its guests.
					</p>
					<p>
						Lithium Batteries can cause safety hazards. Accordingly, batteries will not be allowed in checked-in
						baggage. Guests, may be permitted, subject to airport security clearance, to carry a maximum of
						two (2) spare lithium batteries with a maximum of 8gms lithium content and 160Wh, in hand
						baggage only, provided that they are packaged in their original retail packaging or each is packaged
						in separate plastic bags with the exposed terminals taped.
					</p>
					<p>
						Power Banks are allowed only in hand baggage.
					</p>
					<p>
						<strong>Weapon bags are not allowed in ATRs.</strong>
					</p>
					<p>
						<strong>We are not accepting weapon on ATR aircraft in hand or registered baggage.</strong>
					</p>
					<p>
						<strong>Checked-in baggage</strong>
					</p>
					<p>
						Free checked-in baggage Allowance (if applicable) will be as per the conditions under Article 5
						(General Provisions) and as mentioned in the fare Rules too.
					</p>
					<p>
						In ATRs, dimension of a checked-in baggage must not exceed L 152 cm x W 58 cm x H 101 cm.
					</p>
					<p>In DHC-6s, dimension of a checked-in baggage must not exceed 44 inch (112cm)</p>
					<p>
						<strong>Guests</strong> can book pre-paid excess baggage up to 2 hours prior to the scheduled departure of their
						flight via our website or call centre..
					</p>

					<p className='mb-1'><strong>ATR: -</strong></p>
					<table className="flybigTable">
						<tbody>
							<tr>
								<th>Baggage</th>
								<th>1kg–5kg</th>
								<th>5kg–9kg</th>
								<th>10Kg-15kg</th>
								<th>At Airport Per Kg</th>
							</tr>
							<tr>
								<td>INR</td>
								<td>1199/-</td>
								<td>2099/-</td>
								<td>3299/-</td>
								<td>550/-</td>
							</tr>
							<tr>
								<td colSpan={5}>
									Please note that if the payment is to be made in any other currency than Indian Rupee, the
									fee(s) will be calculated as per the conversion rate prevalent at the time of payment.
								</td>
							</tr>
						</tbody>
					</table>

					<p className='mb-1'><strong>DHC-6: -</strong></p>
					<table className="flybigTable">
						<tbody>
							<tr>
								<th>Baggage</th>
								<th>1kg</th>
								<th>1.1kg- 3.0kg</th>
								<th>3.1Kg-5.0kg</th>
							</tr>
							<tr>
								<td>INR</td>
								<td>700/-</td>
								<td>2000/-</td>
								<td>3250/-</td>
							</tr>
							<tr>
								<td colSpan={5}>
									Please note that if the payment is to be made in any other currency than Indian Rupee, the
									fee(s) will be calculated as per the conversion rate prevalent at the time of payment.
								</td>
							</tr>
						</tbody>
					</table>

					<p>
						Guest carrying more than the allowed baggage limits will be charged a rate of INR 550 per kg (inclusive of GST, if applicable) for domestic travel. Upon delivery of the guest’s baggage which the guest wishes to check-in on flybig, flybig upon being satisfied with its condition (in accordance with the provisions of the Conditions of Carriage) will take custody of, and issue a baggage identification tag for, each piece of checked baggage. Checked baggage must have the guest’s name or other personal identification affixed to it.
					</p>
					<p>
						flybig will levy a fee of INR 1500/- (or equivalent in foreign currency) at the airport, for the following
						special baggage checked-in:
					</p>
					<ol className='px-5'>
						<li>Sports equipment,</li>
						<li>Skiing equipment,</li>
						<li>Golf bags,</li>
						<li>Bicycle</li>
						<li>Surf boards,</li>
						<li>Snow board,</li>
						<li>Water Ski,</li>
						<li>Scuba diving equipment,</li>
						<li>LED/LCD, any fragile item or other large and/or odd-sized items, including cartons, whose dimensions (L+W+H) exceed 158 cms</li>

					</ol>
					<p>
						This fee will be applied over and above any excess baggage charges that might be applicable. The fee will apply per piece of checked-in baggage, per sector, (inclusive of GST, if applicable). The weight of such baggage will be considered as part of the permissible baggage allowance and standard excess baggage charges, if applicable, will be levied.
					</p>
					<br />
					<p>Carriage of fire arm, air gun and/or ammunition by a guest (whether as hand baggage or as checked-in baggage) is strictly prohibited on flybig ATR carriers.</p>
					<br />

					<p><strong>Valuable and Fragile Goods</strong></p>
					<p>
						Guests are strongly advised not to check in such items as baggage which by their very nature is
						valuable and/or fragile. If such items are checked-in as baggage, guests agree that they send for
						carriage of such items, at their own risk. Such items include, without limitation, money, jewellery,
						silverware, electronic devices, medicines, perishable goods, computers, cameras, video equipment,
						negotiable papers, securities and/or other valuables, passports and other identification documents,
						title deeds, artefacts, manuscripts and the like.
					</p>
					<p>
						flybig shall not accept any responsibility for such items carried by the guests in their baggage. In case if the guest's checked-in baggage is spoilt due to spillage or leakage of glass bottles/jars unsuitably packed, flybig’ s liability for damage is not extended to the same.
					</p>
					<p>
						Items like money, jewellery, photographic / video & or other optical equipment, computers and
						other electronic equipment, computer software, silverware, glassware, securities, bonds, business
						and personal documents, samples, paintings, artifacts, other work of art, antiques, research, unique
						or irreplaceable items, scholarship items / documents, irreplaceable books or publications or similar
						valuable items are not covered under any loss or damage policy of flybig. These items should not be
						transported in or as a luggage on a flybig flight.
					</p>
					<p><strong>Hand baggage</strong></p>
					<p>
						Hand baggage is accepted in the cabin subject to the availability of space in the overhead bin or under the guest’s seat. In the event a piece of hand baggage is over-sized or over-weight, flybig may require transfer of such hand baggage to the checked-in baggage compartment of the aircraft prior to take-off. In such cases, a guest’s hand baggage will be specially tagged and returned upon arrival, and the same can be collected from the conveyor belts where checked-in baggage is normally received. In the event the hand baggage is transferred to the checked-in baggage compartment due to increased weight over and above what is permissible for hand baggage, guest shall be liable to make payment for such excessive weight of the hand baggage to flybig, at then applicable rate per kilo gram for the excess baggage.
					</p>
					<ul className='ml-4'>
						<li>For ATRs Subject to any exclusions and other restrictions provided below, each travelling guest is permitted to carry one Piece of hand baggage weighing a maximum of 7 Kgs, and not exceeding the following dimensions: 35(height) x 30(length) x 20(breadth) in cms.</li>
						<li>For DHC-6 Subject to any exclusions and other restrictions provided below, each travelling guest is permitted to carry one piece of hand baggage weighing a maximum of 3 Kgs, and not exceeding the following dimensions: 23cm (H) x 33cm (L) x 33cm (W) OR 9" x 13" x 13".</li>
					</ul>

					<p>
						Guests accompanying infants are allowed an additional piece of hand baggage as per the
						limitations on weight and size provided above. In addition to the one piece of hand baggage
						permitted to be carried in accordance with the above, flybig will permit a guest to carry one
						additional personal article such as ladies’ purse or a small bag containing laptop not weighing more
						than 3kgs.
					</p>
					<p>
						Items determined by us to be of an offensive nature, will not be permitted on board.
					</p>
					<p>
						Subject to the prevalent applicable local laws and regulations, guests may carry liquids in their
						Hand baggage, subject to screening and security checks, and provided they meet the following
						restrictions:
					</p>
					<ol>
						<li>Any liquid is in a container with a maximum volume of 100ml; and</li>
						<li>All liquid containers meeting the maximum volume of 100ml each can be fitted comfortably into a transparent, re-sealable 1 litre plastic bag.</li>
					</ol>

					<p>
						If a guest wishes to carry an oversized item on board which is not compliant with the permissible
						limits set forth above, but will fit safely in a seat, flybig may allow such guest at its discretion to
						purchase an additional seat on that flight, subject to availability and applicable fares. This facility is
						not available as part of online reservation and guest may call our guest care number to
						arrange for booking such a seat for your baggage. flybig reserves the right to refuse to carry any
						items or baggage, due to excessive size or otherwise, if flybig deems it to be a safety risk.
					</p>
					<p><strong>Right to Refuse Carriage</strong></p>
					<p>
						flybig may refuse to carry as baggage any item reasonably considered by the airline to be unsuitable
						for carriage because of its size, shape, weight, content, being radioactive, or character, or for safety
						or operational reasons, or in the interests of the comfort of other guests. Information about
						unacceptable items is available upon request.
					</p>
					<p>
						flybig may refuse to accept baggage for carriage unless it is in flybig’s reasonable opinion, properly
						and securely packed in suitable container/packaging.
					</p>
					<p><strong>Right to Search</strong></p>
					<p>
						For reasons of safety and security, we or the competent regulatory authority may require the
						guests to undergo a search, x-ray or other type of scan on his/her person or baggage. flybig
						reserves the right to search the baggage in the absence of the guest, if he/she is not available,
						for the purpose of determining whether they are in possession of, or whether their baggage contains
						any unacceptable or prohibited items.
					</p>
					<p>
						If a guest refuses to comply with such searches or scans, we reserve the right to refuse carriage
						to such guest and his/her baggage without refund of fare and without any other liability to such
						guest. Any other procedures as may be required under law may follow in relation to such cases.
						In the event that a search / scan causes injury to the guest or damage to his/her baggage, we
						shall not be liable for such injury or damage, unless the same is due to our wilful fault or gross
						neglect.
					</p>
					<p><strong>Collection and Delivery of baggage</strong></p>
					<p>
						flybig will make every effort to ensure that the checked-in baggage of guests arrives in a safe
						condition.
					</p>
					<p>
						The guests are advised to collect their baggage as soon as it is available for collection at the
						place of destination from the relevant conveyor belts.
					</p>
					<p>
						Should the guest not collect it within a reasonable time, flybig may charge a storage fee. Should
						the guest's checked-in baggage not be claimed within three (3) months of the time it is made
						available, flybig may dispose it off without any liability or notice to the guest.
					</p>
					<p>
						If the guests are travelling in a group (i.e. in one PNR there are more than one guest) and
						the baggage is not delivered at arrival, all the guests under that booking need to be present at
						the arrival hall at the time of registering a complaint for the lost baggage. The baggage will be
						considered as delivered in good condition if the above condition is not met.
					</p>
					<p>
						flybig shall deliver checked-in baggage to the bearer of the baggage Check, upon payment of all
						unpaid sums due to flybig, under these Conditions of Carriage.
					</p>
					<p>
						flybig is under no obligation to ascertain that the bearer of the baggage Check is entitled to delivery
						of the baggage, and flybig is not liable for any loss, damage, or expense arising out of, or in
						connection with its failure to ascertain so.
					</p>
					<p>
						Delivery of the baggage will be made at the destination shown in the baggage Check.
					</p>
					<p>
						If the person claiming the baggage is unable to produce the baggage Check and identify the baggage
						by means of a baggage tag, flybig will deliver the baggage to such person only on the condition that
						he/she establishes to flybig reasonable satisfaction, his right thereto, and if required by flybig,
						such person shall furnish adequate security to indemnify flybig for any loss, damage or expense
						which may be incurred by flybig, as a result of such delivery.
					</p>
					<p>
						Acceptance of baggage by the guests without complaint at the time of delivery, is prima facie
						evidence that the baggage has been delivered in good condition and in accordance with the contract
						of carriage. Every guest shall be required to raise the irregularity report in case there is a loss of
						baggage while he/she is at the respective destination airport at the time of arrival and not after
						departing from the destination airport.
					</p>
					<p>
						In order to ensure that the guests pick-up their own baggage only and that there are no
						confusions, flybig reserves all rights to do baggage identification tag match upon arrivals either for
						all guests or on random basis without any prejudice or discrimination.
						In the event where there is a possibility or slightest assumption of guest picking up somebody else’s baggage, flybig
						reserves all rights to divulge personal information of such guest to the original owner of the
						baggage, and will not be liable for any losses incurred thereof.
					</p>
					<p><strong>flybig assumes no liability for wear and tear to luggage which includes:</strong></p>
					<ul>
						<li>Broken wheels or feet</li>
						<li>Minor cuts</li>
						<li>Loss of external locks, security straps</li>
						<li>Damage to any protruding part of the baggage.</li>
						<li>Damage resulting from over packing and damage to retractable luggage handles</li>
						<li>Scratches, torn zippers, straps, handles, scuffs, dents soiling or manufacturer defects</li>
						<li>Unsuitably packed, perishable, damaged or fragile baggage, or for water damage to non- waterproof baggage.</li>
						<li>Any other wear and tear</li>
					</ul>
					<p>
						flybig does not accept responsibility for the transfer of baggage to other flights.
					</p>
					<p><strong>Items Removed By Airport Security Personnel</strong></p>
					<p>flybig will not be responsible for, or have any liability in respect of, articles removed from the guest's baggage by any airport security.</p>
					<p><strong>Carriage of Human Remains</strong></p>
					<ol>
						<li>Urns with ashes are allowed in hand baggage.</li>
						<li>Carriage of human remains are not permitted on ATRs.</li>
						<li>Carriage of human remains are not permitted on DHC-6.</li>
					</ol>
					<p><strong>Carriage of Alcoholic beverages</strong></p>
					<p>
						Guests may carry up to 5Litres of alcoholic beverages as part of their checked-in baggage,
						provided the following conditions are met:
					</p>
					<ol>
						<li>The alcoholic beverage is in retail packaging and is packed appropriately (to prevent damage / leakage).</li>
						<li>Alcohol content in the beverage is not more than 70%.</li>
						<li>Alcohol content in the beverage is more than 70% should not be accepted. (Because that's pure spirit comes under DG hence cannot be transported)</li>
					</ol>
					<p>
						If the alcoholic beverage contains 24% or less alcohol by volume the above limitation of 5 Litres does
						not apply.
					</p>
					<p>
						The alcoholic beverages are also permitted in carry-on baggage when purchased from the Airport
						Security Hold Area and should be placed in a transparent re-sealable plastic bag of a maximum capacity
						not exceeding 1 Litre. The indicative size of the 1 litre bag is: 20.5 cm x 20.5 cm or 25 cm x 15 cm or
						equivalent. The containers must fit comfortably within the bag, which should be fully closed.
					</p>
					<p>
						The guests must comply with other applicable state / national regulations, if any.
					</p>
				</MyCard>

				<MyCard header="12) Connected Segments" eventKey={12}>
					<p>
						Information related to your Connecting Flights
					</p>
					<p><strong>Any booking with respect to the connected segments...</strong></p>
					<p>
						Any booking with respect to the connected segments shall be required to be booked in advance and
						both segments in the connected segments shall be confirmed on the same PNR and will be identified
						by a single fare/ surcharge component.
					</p>
					<p><strong>In cases where the guest undertakes travel on the connected segments...</strong></p>
					<p>
						In cases where the guest undertakes travel on the connected segments, flybig will check-in
						baggage through to the final destination of the guest. However, in case of domestic connections
						and vice-versa, at the time of check-in, guests will have to collect their baggage at the end of
						each leg and re-check again after completing applicable regulatory formalities. The guests will.
						be provided with two boarding cards - one for the flight taken from the point of origin till the point of
						transit and other from the point of transit until the next destination. In case at point of transit/ Stopover,
						the guest is required to change terminals, the guest will be responsible for:
					</p>
					<ul>
						<li>Transportation from arrival terminal (arrival from the point of origin) to departure terminal (departure to the next destination).</li>
						<li>Reporting on-time at the check-in counter for the next flight.</li>
					</ul>
					<p>
						flybig will not provide any food or lodging at the point of transit/ Stopover.
					</p>
					<p>
						<strong>“Point of transit/ Stopover”</strong>
						{' '}
						in reference to
						{' '}
						<strong>“Connected Segments”</strong>
						{' '}
						which requires change of
						terminals by the guest at point of transit/ Stopover shall include the arrival terminal (arrival
						from the point of origin) as well as the departure terminal (departure to the next destination).
					</p>
					<p><strong>In case of connecting guests failing to show up for boarding...</strong></p>
					<p>
						In case of connecting guests failing to show up for boarding for flights for the Connecting
						Segments at the point of Stopover, 25 minutes in case of domestic flights, prior to departure of the
						concerned flight, despite having arrived on-time from the point of origin, the guests will be
						treated as a
						<strong>‘Gate No Show’</strong>
					</p>
					<p>
						The baggage of such guests will be offloaded and handed over to the guests or the relevant
						airport authority at the Stopover station (in case there is change in terminal required for departure
						to next destination, the baggage will be offloaded and handed over to the guests or the relevant
						airport authority at the departure terminal of the point of transit/ Stopover), without any liability on
						the part of flybig.
					</p>
					<p>
						The ticket amount for such booking shall be forfeited. The guests are, however, entitled to a
						refund of the Government and Airport Fees and/or taxes (if applicable).
					</p>
					<p>
						Also, connecting guest shall board the flight from the origin city only. flybig will not entertain
						carriage of such guests if they intend to board from any of the transfer airports. Any such
						request would be treated as cancellation and re-booking at the applicable fares at the time of such
						request, fare difference and cancel fee may apply.
					</p>
					<p><strong>In case of delay or cancellation of a flight operated as part of the connected segments...</strong></p>
					<p>
						<strong>
							In case of delay or cancellation of a flight operated as part of the connected segments, the
							following shall apply:
						</strong>
					</p>
					<p>
						In case of delay or cancellation of the flight at the point of origin which leads to the possibility of a
						missed connection at the point of Stopover, the guest shall have to right to choose a refund, or a
						credit for future travel on flybig, or re-booking onto an alternative flybig flight at no additional cost,
						subject to availability.
					</p>
					<p>
						In case of a delay or cancellation of the connecting flight at the point of Stopover, flybig will, at its
						discretion and subject to availability of seats, first offer the guest the option to travel on the
						next flybig flight, then at its discretion, provide ground transportation to and from the airport and
						lodging for overnight stay to the guest at the point of Stopover (provided that the next available
						flight is only on the following day) or offer a choice between refund or credit of the leg that is unflown,
						to the guest.
					</p>
					<p>
						The guest may also opt for a partial refund on a pro- rata basis in case of involuntary
						termination of journey at a transit point for reasons other than being unruly or a disciplinary issue in
						the opinion of flybig.
					</p>
				</MyCard>

				<MyCard header="13) Security Screening" eventKey={13}>
					<p>
						<strong>
							Security screening prior to boarding the aircraft
							Screening
						</strong>
					</p>
					<p>
						As required by applicable law, all guests will be subjected to a security screening prior to
						boarding the aircraft.
					</p>
					<p>
						Itineraries (in hard copies) will be checked at the time of entering the terminal building. Checked-in
						baggage will be screened through an X-ray machine before check-in. Random physical checks are
						also carried out for checked-in baggage in addition to X-ray screening.
					</p>
					<p>
						As per security regulations, guests will be required to undergo personal frisking and their hand
						baggage will be x-rayed before entering the pre-boarding sterile area.
					</p>
					<p>
						Checked-in baggage reconciliation is carried out through an internal matching system. In addition to
						the baggage reconciliation, the physical identification of the checked-in baggage by the guests
						may be carried out. Unidentified baggage will not be loaded on to the aircraft.
					</p>
					<p>
						A secondary security check may also be carried out near the step ladder/boarding gate before
						embarkation as stipulated by BCAS.
					</p>
					<p><strong>General</strong></p>
					<p><strong>According to security regulations, guests are advised:</strong></p>
					<p>Not to accept any packets from unknown persons,</p>
					<p>Not to leave baggage unobserved at any time, especially within airport area,</p>
					<p>Unattended baggage may be removed by airport security staff as object of suspicion,</p>
					<p>To declare before baggage screening or check-in if carrying any arms or explosive substance.</p>
					<p>Concealment is an offence under Aircraft Act and Rules,</p>
					<p>To carry only one piece of hand baggage, the sum of the three dimensions (length, breadth and height) of which should not exceed 115 cms,</p>
					<p>Battery cells / Dry cells carried in the hand baggage or in any electrical/ electronic items are liable to be removed and flybig may not be in a position to return such removed items same at the</p>
					<p>Guest's destination. flybig recommends that such items be carried in checked-in baggage,</p>
					<p>All baggage should be clearly labelled with the guest's name, address and contact details.</p>
				</MyCard>

				<MyCard header="14) Schedule, Delay and/or Cancellation of Flights" eventKey={14}>

					<p>
						<strong>Details on Schedule, Delays or Cancellation of Flights</strong>
					</p>
					<p>
						<strong>Times and Schedules Not Guaranteed</strong>
					</p>
					<p>
						flybig undertakes to use its best efforts to avoid delay in carrying its guests and their baggage.
						We will endeavour to adhere to published schedules in effect on the date of travel. However, times
						shown in schedules or elsewhere, are subject to change at any time, and we shall not be liable in any
						way whatsoever, for any loss incurred by guests as a result of such change.
					</p>
					<p>
						flybig will not be liable for any error or omission in publications of schedules, or in statements or
						representations made by employees, agents, or representatives of flybig, as to the dates or times of
						departure or arrival, or as to the operation of any flight..
					</p>
					<p><strong>Cancellation, Changes of Schedule etc.</strong></p>
					<p>
						At any time after a booking has been made, we may change our schedules and/or cancel, terminate,
						divert, postpone, reschedule or delay any flight where we reasonably consider this to be justified by
						circumstances beyond our control, or for reasons of safety, or for commercial reasons.
						Circumstances beyond flybig control can include, without limitation, weather, air traffic control,
						mechanical failures, acts of terrorism, acts of nature, force majeure, strikes, riots, wars, hostilities,
						disturbances, governmental regulations, orders, demands or requirements, shortages of critical
						manpower, parts or materials, labour unrest etc.
					</p>
					<p>
						If a flybig flight is cancelled, rescheduled to depart more than an hour prior to the original time of
						departure or delayed by more than two hours (depending on the length of the journey), a guest
						shall have to right to choose a refund; or a credit for future travel on flybig; or re-booking onto an
						alternative flybig flight at no additional cost (subject to availability).
					</p>
					<p>
						In the special case in which a subsequent portion of a flybig flight is cancelled while a guest is
						already in transit, such guest shall have the right to choose to remain at the transit station and
						to be re-booked onto an alternative flybig flight to the final destination at no additional cost subject
						to availability; or to remain at the transit station and accept a partial refund for the portion of the
						flight not completed; or to return to the point of origin and receive a refund; or re-booking onto an
						alternative flybig flight at no additional cost subject to availability.
					</p>
					<p>
						We strongly recommend all guests to provide correct phone number and email address, to
						enable us to inform of flight delays or cancellations in unforeseen cases. Guests who have not
						provided valid contact information at the time of booking may not be entitled to any compensation.
						Any compensation in this matter will be meted out as per DGCA CAR Section 3 Series M, Part IV
						(
						<a href="https://dgca.gov.in/digigov-portal/?page=civilAviationRequirementPDF" rel="noreferrer" target="_blank">https://dgca.gov.in/digigov-portal/?page=civilAviationRequirementPDF</a>
						{' '}
						)
					</p>
					<p><strong>Sole Remedy</strong></p>
					<p>
						The guest may file a grievance with the AirSewa App or Portal( https://airsewa.gov.in ). If the
						guest is not satisfied with the resolution of the grievance by flybig, the guest is at liberty to
						complain to any statuary body or court established under relevant applicable laws.
					</p>
				</MyCard>

				<MyCard header="15) Refunds" eventKey={15}>
					<p><strong>Information on Refund</strong></p>
					<p><strong>General</strong></p>
					<p>
						Upon failure by flybig to provide carriage in accordance with these Conditions of Carriage, or where
						a guest requests a voluntary change of his arrangements, refund for an unused booking, or
						portion thereof shall be made by flybig in accordance with this article and in accordance with
						flybig’s regulations, after deducting the applicable fees, charges and taxes thereon.
					</p>
					<p>
						<strong>
							Refunds against the residual value after deduction of the applicable fee will be made available as
							per the following:
						</strong>
					</p>
					<ul>
						<li>For bookings made through credit cards/debit cards online on our website, the refund will be processed back to the credit card or debit card (as the case may be) in seven working days from the date of refund request.</li>
						<li>For bookings made through net banking, the refund will be processed into the same bank account.</li>
						<li>For bookings made through Travel Partners or online travel portals, the refund may be claimed from the respective travel agents/ portals.</li>
						<li>For bookings made by cash/card swipe at the airport, refunds will be processed through an electronic transfer of funds (NEFT), pursuant to filling the requisite refund form at the airport in seven working days from the date of refund request.</li>
					</ul>

					<p><strong>Persons to Whom Refund Will Be Made</strong></p>
					<p>
						flybig shall be entitled to make refund either to the person named in the booking, or to the person
						who has paid for the booking, upon presentation of satisfactory proof and holding himself out as a
						person to whom refund may be made and shall be deemed a proper refund, and shall discharge
						flybig from liability and any further claim for refund from any person.
					</p>
					<p><strong>Currency</strong></p>
					<p>
						All refunds will be made in INR, after deducting any applicable fee and/or charges.
					</p>
				</MyCard>

				<MyCard header="16) On-board the Aircraft" eventKey={16}>
					<p>
						<strong>Rules & Regulations On-board</strong>
					</p>
					<p>
						<strong>Conduct</strong>
					</p>

					<p>
						If, in flybig’s opinion, a guest conducts himself aboard the aircraft so as to endanger the aircraft
						or any person or property on board, or obstruct the crew in the performance of their duties, or fail
						to comply with any instructions of the crew, including but not limited to those with respect to
						smoking, alcohol or drug consumption, or behave in a manner which causes discomfort,
						inconvenience, damage or injury to other guests or the crew, flybig may take such measures as
						it deems reasonably necessary to prevent continuation of such conduct, including restraint. Such
						guest may be disembarked and refused onward carriage at any point, and may be prosecuted
						for offences committed on board the aircraft.
					</p>
					<p><strong>Payment of Diversion Costs</strong></p>
					<p>
						If, as a result of conduct by a guest flybig decides, in the exercise of flybig’s reasonable
						discretion, to divert the aircraft for the purpose of offloading that guest, the guest must pay
						all costs resulting from that diversion.
					</p>
					<p><strong>Use of Electronic Devices Prohibited</strong></p>
					<p>
						For safety reasons, flybig may forbid or limit operation aboard the aircraft of electronic equipment,
						including, but not limited to, cellular telephones, laptop computers, portable recorders, portable
						radios, CD players, electronic games or transmitting devices, including radio controlled toys and
						walkie-talkies. Operation of hearing aids and heart pacemakers is permitted.
					</p>
					<p>
						Guests shall note that any act (attempted or otherwise) of removal of life jacket from the aircraft
						without consent of flybig is prohibited.
					</p>
					<p><strong>No Smoking</strong></p>
					<p>Smoking is strictly prohibited on all flybig aircrafts. All lavatories are fitted with smoke detectors.</p>
					<p>Tampering with or disabling a smoke detector is prohibited.</p>
					<p><strong>General</strong></p>
					<p>
						flybig offers an enhanced selection of food and beverages for sale on-board. Hot snacks and hot
						beverages are not available on ATRs and on the flights that have a total flight time of less than 50
						minutes.
					</p>
					<p>
						Complimentary drinking water is offered on all flybig flights.
					</p>
					<p>
						flybig allows guests to bring food items on board. Allowed food items include cold snacks, soft
						beverages, snack bars and biscuits. For the convenience of our guests, messy, oily or strong
						smelling food items are not allowed on board.
					</p>
					<p>
						Guests seated near an emergency exit will be briefed by the crew regarding emergency
						procedures and are requested to pay special attention to these requirements. Please note that able
						bodied guests aged above fifteen (15) years will be allocated emergency exit rows.
					</p>

					<strong>Lavatories:</strong>
					<p>A lavatory with a single panel door and additional grab bar is available on all our aircraft. Our cabin crew can provide assistance moving to and from the lavatory door (same as above), but will not be able to assist the guest inside the lavatory.</p>
					<p>flybig DHC-6 Twin Otter aircraft DOES NOT have a lavatory on board. We apologize for any inconvenience this may cause and recommend to the Guest to use the restroom facilities before boarding the aircraft.</p>

					<strong>Air conditioning:</strong>
					<p>flybig DHC-6 Twin Otter aircraft may not be equipped with air conditioning facility. flybig shall not be held responsible for any discomfort caused by the absence of air conditioning facilities on board.</p>
				</MyCard>

				<MyCard header="17) Administrative Formalities" eventKey={17}>
					<p>
						<strong>Travel Documents, Fines, Customs and Security Inspections</strong>
					</p>
					<p>
						<strong>General</strong>
					</p>

					<p>
						The guests shall be solely responsible for obtaining all required travel documents and for
						complying with all laws, regulations, orders, demands and travel requirements.
					</p>
					<p>
						flybig shall not be liable for any aid or information given by any agent or employee of flybig to any
						guest in connection with obtaining necessary documents or complying with such laws,
						regulations, orders, demands, and requirements, whether given in writing or otherwise, or for the
						consequences to any guest resulting from his failure to obtain such documents or to comply
						with such laws, regulations, orders, demands, requirements, rules or instructions.
						flybig shall not be liable if it determines that what it understands to be applicable law, government
						regulation, demand, order or requirement, requires that it refuse, and it does refuse, to carry a
						guest.
					</p>
					<p><strong>Travel Documents</strong></p>
					<p>
						Prior to travel, the guest must present all exit, entry, health and any other documents required
						by law, regulation, order, demand or other governmental requirement, and permit flybig to take
						and retain copies thereof. flybig reserves the right to refuse carriage if the guest does not
						comply with these requirements, or their travel documents do not appear to be in order, or who
						does not permit flybig to take and retain copies thereof.
					</p>
					<p><strong>Guest Responsible For Fines, Detention Costs, etc.</strong></p>
					<p>
						If flybig is required to pay any fine or penalty, or it incurs any expenditure by reason of the guest's
						failure to comply with laws, regulations, orders, demands or other travel requirements, or to produce
						the required documents, the guest shall reimburse to flybig, on demand, any amount so paid or
						expenditure so incurred by flybig. flybig may apply towards such payment or expenditure, the value of
						any unused carriage on the guest's booking, or any of the guest's funds in flybig’s possession.
					</p>
					<p><strong>Customs Inspection</strong></p>
					<p>
						If required, the guest shall attend inspection of their baggage, by customs or other Government
						officials. flybig will not be liable to the guest for any loss or damage suffered by them in the
						course of such inspection or through their failure to comply with this requirement.
					</p>
					<p><strong>Security Inspection</strong></p>
					<p>
						The guest shall agree to any security checks by Governments, airport officials or by flybig.
					</p>

				</MyCard>

				<MyCard header="18) Death or Injuries, Damage of Baggage, Applicable Rules." eventKey={18}>
					<p><strong>Applicability</strong></p>
					<p>
						The liability of each carrier involved in the guest's journey will be determined by its own
						conditions of carriage. The provisions for flybig’s liability for damage are set out below.
					</p>
					<p><strong>Applicable Rules</strong></p>
					<p>
						The rules applicable to flybig’ s liability shall be as provided under the Carriage by Air Act, 1972 (as
						amended from time to time).
					</p>
					<p><strong>Death or Injury to guests</strong></p>
					<p>
						In the event of death or any other bodily injury suffered by a guest during the course of carriage
						by air, flybig’ s liability will be governed by the relevant provisions of the Carriage by Air Act, 1972
						and any modifications etc. as notified by the Ministry of Civil Aviation, Government of India, and as
						amended from time to time and the rules contained thereunder and in no event shall the liability
						amounts under this scenario exceed the limit.
					</p>
					<p>
						If flybig proves that the damage was caused by, or contributed to by, the negligence of the injured
						or deceased / injured guest, flybig may be exonerated wholly or partly from its liability.
						flybig is not responsible for any illness, injury or disability, including death, attributable to the
						guest's physical condition or for the aggravation of such condition.
					</p>
					<p><strong>Damage to baggage</strong></p>
					<p>
						Liability for loss, delay or damage to baggage is limited under the provisions of Carriage by Air Act,
						1972 and the rules framed thereunder with certain exceptions, adaptations, modifications etc. as
						notified by the Ministry of Civil Aviation, Government of India, and as amended from time to time.
						flybig’s liability for damage to baggage will be reduced by any negligence on part of the guests,
						which causes or contributes to the damage in accordance with applicable law.
					</p>
					<p>
						Guests are solely responsible for carriage of their hand bag / personal belongings and flybig will
						not be liable for any loss / damage of their hand baggage / personal belongings.
						flybig's liability for loss or damage to baggage is INR 350/ kg*.
					</p>
					<p>
						However, flybig assumes no liability for fragile or perishable articles.
					</p>
					<p>
						flybig shall have no liability whatsoever for damage to articles not permitted to be contained in
						checked-in baggage as per the provisions of the Conditions of Carriage (including, without limitation,
						fragile or perishable items, items having a special value, such as money, jewellery, precious metals,
						computers, personal electronic devices, negotiable papers, camera, TV, securities, or other
						valuables, business documents, passports and other identification documents).
					</p>
					<p>
						flybig will not be liable for any damage arising from flybig’ s compliance with applicable laws or
						Government rules and regulations or from the guest's failure to comply with the same.
						* limited to a maximum of INR 25,000/- only. Other conditions as per the Act apply.
						The guest shall be responsible for any damage caused by the guest's baggage to other
						persons or property, including flybig’s property and flybig will not be liable to any third person for
						any damage caused by the guest's baggage.
					</p>
					<p>
						flybig will not be liable for any damage arising from flybig’s compliance with applicable laws or
						Government rules and regulations or from the guest's failure to comply with the same.
					</p>
					<p><strong>Delay caused to guest</strong></p>
					<p>
						Liability for damage caused by delay in the carriage or baggage is limited under the provisions of
						Carriage by Air Act, 1972 and the rules framed thereunder with certain exceptions, adaptations,
						modifications etc. as notified by the Ministry of Civil Aviation, Government of India, and as amended
						from time to time.
					</p>
					<p>
						The Conditions of Carriage, including these Conditions of Carriage and exclusions or limits of liability,
						applies to our Travel Partners, servants, employees and representatives to the same extent as it and
						they apply to flybig.
					</p>
					<p>
						flybig will not be liable for any damage arising from flybig’s compliance with applicable laws or
						Government rules and regulations or from the guest’ s failure to comply with the same.
						In any event our liability under any circumstances shall not exceed the amount of proven damages
						under any circumstances.
					</p>
				</MyCard>

				<MyCard header="19) Time Limitations on Claims and Actions" eventKey={19}>
					<p>
						<strong>Notice of Claims, Limitations of Actions</strong>
					</p>
					<p>
						<strong>Notice of Claims</strong>
					</p>
					<p>
						Acceptance of baggage by the bearer of the baggage identification tag without complaint at the time
						of delivery is sufficient evidence that the baggage has been delivered in good condition and in
						accordance with the contract of carriage, unless the guest proves otherwise.
					</p>
					<p><strong>Limitation of Actions</strong></p>
					<p>
						Any right to damages shall be extinguished if an action is not brought against flybig within three (3)
						years of the date of arrival at the destination, or the date on which the aircraft was scheduled to
						arrive, or the date on which the carriage stopped. The method of calculating the period of limitation
						shall be determined by law of the court where the case is heard.
					</p>
				</MyCard>

				<MyCard header="20) Governing Law and Dispute Settlement Mechanism" eventKey={20}>

					<p><strong>Governing Laws & Disputes Settlement</strong></p>
					<p>
						These Conditions of Carriage shall be construed by, and governed in accordance with the laws of
						India.
					</p>
					<p><strong>Dispute Settlement Mechanism</strong></p>
					<p>
						All disputes arising out of, or in connection with these Conditions of Carriage shall be settled by the
						courts of Mumbai, India, which shall have exclusive jurisdiction to hear the matters in relation to
						these Conditions of Carriage.
					</p>
				</MyCard>

				<MyCard header="21) Modifications and Amendments" eventKey={21}>
					<p>
						flybig reserves the right to vary, amend or alter these Conditions of Carriage at any time without any
						prior notice or liability.
					</p>
				</MyCard>

			</Accordion>
		</div>
	);
}

const MyCard = ({ header = "", eventKey = 0, children = '', prefix = '' }) => {
	const isCurrentEventKey = false;
	const toggle = () => {
		console.log('toggled', eventKey);
	}

	return (
		<Card>
			<Accordion.Toggle as={Card.Header} eventKey={eventKey}>
				<button className="accordionButton" id={`${prefix}sertion_${eventKey}`} type="button" onClick={null} >
					<h4 style={{ textAlign: 'left' }}>
						{header}
					</h4>{isCurrentEventKey ? (<Down />) : (<Right />)}
				</button>
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={eventKey}>
				<Card.Body> {children}</Card.Body>
			</Accordion.Collapse>
		</Card>
	)
}