/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function DisabilityAssistance() {
  return (
    <div className="saitem">
      {/* <div className="header">
        <p>
          Disability Assistance
        </p>
      </div> */}
      <p>
        flybig welcomes the opportunity to provide caring guest service to our guests with disabilities. To be able to assist you the best, we request you to notify us of your
        {' '}
        <strong>special needs/wheelchair request at least forty eight (48) hours prior</strong>
        {' '}
        to the schedule departure of your flight. While making your booking please let us know how we can best assist you and what seat will best accommodate your needs. If you arrive at the airport without a seat assignment, we will make every effort to provide the requested accommodation.
      </p>

      <strong>Note: In compliance with current regulations, if any of our guest has limited mobility, is hearing or visually impaired, is unable to understand/react to safety instructions, we will be unable to allocate a seat in the emergency exit rows.</strong>
      <p>To ensure a seamless check-in and boarding experience, we request you to report at least two (2) hours prior to the scheduled time of flight departure at the reservation counter.</p>
      <strong>Our cabin crew members are trained in providing first aid assistance and the following assistance to you</strong>
      <ul>
        <li>
          An individual safety briefing to any guest who may need the assistance of another person to move expeditiously to an exit in the event of an emergency
        </li>
        <li>
          An Individual safety briefing to any guest whose disability prevents them from comprehending the safety information presented during our safety briefing.

        </li>
        <li>
          Assistance with stowing and retrieving carry-on and assistive devices.
        </li>
        <li>
          Assistance with opening food and drink items.
        </li>
      </ul>
      <strong>Our crew would not be able to assist you in the list of services given below</strong>
      <ul>
        <li>
          Administration of medication
        </li>
        <li>
          Assistance in actual eating or drinking
        </li>
        <li>
          Assistance within the restroom or other personal care needs. If assistance is required with any of these, we recommend that you travel with a Safety Assistant.
        </li>
      </ul>

      <strong>
        Lavatories
      </strong>

      <p>A lavatory with a single panel door (except a few aircraft where we have bi-folding door) and additional grab bar is available on all our aircraft. Our cabin crew can provide assistance in moving to and from the lavatory door, but will not be able to assist you inside the lavatory.</p>
      <strong>Boarding</strong>
      <p>flybig offers pre-board courtesy for all our guests needing assistance and are requested to let the gate staff know of the need to pre-board and are requested to be in the gate area when pre-boarding begins.  Please note that our boarding gates close twenty-five (25) minutes (domestic) and twenty-five (25) minutes (international) prior to the scheduled departure time, post which boarding is not allowed. Also note that some airports are silent airports and there are no pre-departure announcements made.</p>
      <p>The following are relevant for our guests requiring disability assistance:</p>

      <ol>
        <li>Requirement of traveling with a Safety Assistant</li>
        <li>For our guests with Sensory Impairment</li>
        <ul>
          <li>Visual Impairment</li>
          <li>Hearing Impairment</li>
          <li>Visual & Hearing Impairment</li>
        </ul>
        <li>Service Dog</li>
        <li>Mobility Assistance</li>
        <li>Assistive device</li>
        <li>Spinal Support Equipment (SSE)</li>
        <li>Traveling with broken limbs</li>
        <li>Guests with Psychiatric or Emotional disorders</li>
        <li>Guest with Intellectual disability/Dementia/Alzheimer/Autism and Down syndrome:</li>
        <li>Internal Devices</li>
        <li>Portable oxygen concentrators (POC)</li>
        <li>Guests with certain adverse medical conditions</li>
      </ol>
    </div>
  );
}
