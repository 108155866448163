/* eslint-disable */
import React from 'react';
import './FAQItem.scss';
import CardHolder from '../../../components/CardHolder';

const data = [
	{
		id: 'BaggageAllowance',
		header: 'How much baggage am I allowed to carry?',
		body: (
			<>
				<p>Free Baggage Allowance</p>
				<table className="flybigTable" style={{ width: 'auto' }}>
					<tbody>
						<tr>
							<th className='text-center' rowSpan={2}>Aircraft</th>
							<th className='text-center' colSpan={2}>ATR</th>
							<th className='text-center' colSpan={2}>DHC6</th>
						</tr>
						<tr>
							<th>Hand Baggage</th>
							<th>Check-in Baggage</th>
							<th>Hand Baggage</th>
							<th>Check-in Baggage</th>
						</tr>
						<tr>
							<th>Maximum weight</th>
							<td>Only one piece bag weighing not more than 7 kgs</td>
							<td>Only one piece bag weighing not more than 15kgs</td>
							<td>Only one piece bag weighing not more than 3kgs</td>
							<td>Only one piece bag weighing not more than 7 kgs</td>
						</tr>
						<tr>
							<th>Dimensions</th>
							<td>35cm x 30cm x 20cm</td>
							<td>158cm (62 inches) (Length + Width + Height)</td>
							<td>23cm x 33cm  x 33cm</td>
							<td>113 cm (44 inches) (Length + Width + Height)</td>
						</tr>
					</tbody>
				</table>


				<p>Items determined by us to be of an offensive nature, will not be permitted on board.</p>
				<p>Subject to the prevalent applicable local laws and regulations, Guests may carry liquids in their Hand Baggage, subject to screening and security checks, and provided they meet the following restrictions:</p>
				<p>Any liquid is in a container with a maximum volume of 100ml; and</p>
				<p>All liquid containers meeting the maximum volume of 100ml each can be fitted comfortably into a transparent, re-sealable 1 litre plastic bag.</p>
				<p>If a Guest wishes to carry an oversized item on board which is not compliant with the permissible limits set forth above, but will fit safely in a seat, flybig may allow such Guest at its discretion to purchase an additional seat on that flight, subject to availability and applicable fares. This facility is not available as part of online reservation and Guest may call our guest helpline number to arrange for booking such a seat for your Baggage. flybig reserves the right to refuse to carry any items or Baggage, due to excessive size or otherwise, if flybig deems it to be a safety risk.</p>

				<strong>Infant Baggage Allowance</strong>
				<p className='mb-1'>Domestic Travel:</p>
				<p className='mb-1'>Hand Baggage- Up to 07 Kgs</p>
				<p className='mb-1'>Check-in Baggage -NIL</p>
				<p className='mb-1'>One stroller or baby pram per infant is allowed without any charge</p>
				<p className='mb-1'>No Hand Baggage permitted for Infants in DHC 6 Aircraft.</p>

			</>
		),
	},
	{
		id: 'BaggagePrepaid',
		header: 'What is the pre-paid excess baggage slab and how can I book it?',
		body: (
			<>
				<p>
					Passengers can book pre-paid excess baggage anytime up to 2 hours prior to the scheduled departure of their flight via our website or call centre.
				</p>
				<strong className='ml-4'>Domestic</strong>
				<p>
					To facilitate affordable purchase of excess baggage, the following pre-paid excess baggage slabs are offered to domestic travellers
				</p>
				<table className="flybigTable" style={{ width: '90%' }}>
					<thead>
						<tr>
							<th>Aircraft</th>
							<th colSpan={2}>ATR</th>
							<th colSpan={2}>DHC6</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td rowSpan={3}>Prepaid Baggage charges</td>
							<td>1kg-5kg</td>
							<td>1199/-</td>
							<td>Upto 1 Kg</td>
							<td>700/-</td>
						</tr>
						<tr>
							<td>5.1kg-9kg</td>
							<td>2099/-</td>
							<td>1.1kg-3kg</td>
							<td>2000/-</td>
						</tr>
						<tr>
							<td>9.1kg-15kg</td>
							<td>3299/-</td>
							<td>3.1Kg-5Kg</td>
							<td>3250/-</td>
						</tr>
						<tr>
							<td>Excess Baggage charges</td>
							<td>At Airport/Kg</td>
							<td>550/-</td>
							<td>At Airport/Kg</td>
							<td>700/-</td>
						</tr>
					</tbody>
				</table>
				<i>Please note if the payment is to be made in any other currency than Indian Rupee, the fee(s) will be calculated as per the conversion rate prevalent at the time of payment.</i>
			</>
		),
	},
	{
		id: 'BaggageExcess',
		header: 'What if I exceed my free baggage weight allowance?',
		body: (
			<>
				<p>
					Guests carrying more than the free permissible baggage allowance, will be charged the following baggage fee at the time of check-in:
				</p>
				<table className="flybigTable mt-5" style={{ width: '90%' }}>
					<thead>
						<tr>
							<th>Aircraft</th>
							<th colSpan={2}>ATR</th>
							<th colSpan={2}>DHC6</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td rowSpan={3}>Prepaid Baggage charges</td>
							<td>1kg-4kg</td>
							<td>1199/-</td>
							<td>Upto 1 Kg</td>
							<td>700/-</td>
						</tr>
						<tr>
							<td>4.1kg-9kg</td>
							<td>2099/-</td>
							<td>1.1kg-3kg</td>
							<td>2000/-</td>
						</tr>
						<tr>
							<td>9.1kg-15kg</td>
							<td>3299/-</td>
							<td>3.1Kg-5Kg</td>
							<td>3250/-</td>
						</tr>
						<tr>
							<td>Excess Baggage charges</td>
							<td>At Airport/Kg</td>
							<td>550/-</td>
							<td>At Airport/Kg</td>
							<td>700/-</td>
						</tr>
						<tr>
							<td>Extra Piece</td>
							<td>At Airport/per piece</td>
							<td>1000/-</td>
							<td>At Airport/per piece</td>
							<td>1000/-</td>
						</tr>
					</tbody>
				</table>
				<p>Domestic Travel - Check-in bag</p>
				<p className='fw-bold'>INR 550 for every additional Kg* & INR 1000 for every additional piece of luggage for ATR</p>
				<p className='fw-bold'>INR 700 for every additional Kg* & INR 1000 for every additional piece of luggage for DHC6</p>
				<p>*For domestic flights, maximum weight for check-in baggage in a single piece cannot exceed 23kg (within the dimensions as prescribed on our website), however charges for excess weight will be levied beyond 15kgs. Additional pieces of baggage will be chargeable at a rate of INR 1000 per piece. The maximum allowed weight and piece depends on the flight load on the day and time of departure. flybig reserves the right to accept or deny baggage.</p>
				<p>*Baggage pooling/clubbing/combining is not allowed even if the guests are booked in a single PNR.</p>
			</>
		),
	},
	{
		id: 'BaggageProhibited',
		header: 'What items are not permitted on flybig flight?',
		body: (
			<>
				<strong>Prohibited in Checked-in and Cabin baggage:</strong>
				<ul>
					<li>Compressed gases - deeply refrigerated, flammable, non-flammable and poisonous such as butane oxygen, liquid nitrogen, aqualung cylinders and compressed gas cylinders</li>
					<li>Corrosives such as acids, alkalis, mercury and wet cell batteries and apparatus containing mercury</li>
					<li>Explosives, munitions, fireworks and flares, ammunition including blank cartridges, handguns, fire- works, pistol caps</li>
					<li>Flammable liquids and solids such as lighter refills, lighter fuel, matches, paints, thinners, fire-lighters, lighters that need inverting before ignition, matches, radioactive material, briefcases and attached case with installed alarm devices</li>
					<li>Oxidizing materials such as bleaching powder and peroxides</li>
					<li>Poisons and infectious substances such as insecticides, weed-killers and live virus materials</li>
					<li>Fish (including other sea food), animals, birds, insects in any form, whether alive and/or dead, and/or frozen and/or dried and/or cooked.</li>
					<li>Anything that possesses and/or is capable of possessing and/or emitting a conspicuous and/or offensive odour</li>
					<li>Other dangerous articles such as magnetized materials, offensive or irritating materials</li>
				</ul>
				<strong>Prohibited items in Cabin Baggage:</strong>
				<ul>
					<li>Dry cell batteries</li>
					<li>Knives, scissors, Swiss army knives and other sharp instruments</li>
					<li>Toy replicas of fire arms and ammunition</li>
					<li>Weapons such as whips, nan-chakus, baton, or stun gun</li>
					<li>Electronic devices which cannot be switched off</li>
					<li>Aerosols and liquids*</li>
					<li>Any other items which are deemed security hazards by local law</li>
				</ul>
				<p>* Passengers can carry one clear transparent re-sealable litre sized plastic bag containing small quantities of liquid items / gels / pastes/aerosols or items of a similar consistency. Liquids / gels / aerosols include but are not limited to beverages, shampoos, suntan lotions, creams, toothpastes, hair gels and hair sprays. The contents will be subject to screening and security checks and must comply with flybig's security guidelines about permissible objects.</p>
				<br />
				<strong>Please note:</strong>
				<p>flybig recommends that all medication, valuables, fragile items, perishable items and precious items (cameras, jewellery, money, electronics items, etc.) should be carried in Cabin Baggage and not in Check-in Baggage. flybig shall not accept any responsibility for these items.</p>

			</>
		),
	},
	{
		id: 'BaggageAdvisories',
		header: 'Are there any general advisories that I should keep in mind regarding my baggage?',
		body: (
			<>
				<p>Yes, there are many:</p>
				<ul>
					<li>Don't accept any packets from unknown persons</li>
					<li>Don't leave baggage unobserved at any time, especially at the airport. Unattended baggage may be removed by Airport Security as an object of suspicion</li>
					<li>Please declare if you are carrying any arms or explosive substances, prior to Baggage screening/check-in.</li>

				</ul>
				<strong>Concealment is considered an offence under the Aircraft Act and Rules</strong>
				<ul>
					<li>Clearly label all baggage items with passenger's name, address and contact details</li>
					<li>All prohibited/restricted items are liable to be removed by security and flybig may not be in a position to return such removed items</li>
				</ul>

			</>
		),
	},
	{
		id: 'BaggageAlcohol',
		header: 'Carriage of Alcoholic Beverage?',
		body: (
			<>
				<p>Passengers may carry up to 5 Litres of alcoholic beverages as part of their checked-in baggage, provided the following conditions are met:</p>
				<ol>
					<li>The alcoholic beverage is in retail packaging and is packed appropriately (to prevent damage/leakage).</li>
					<li>Alcohol content in the beverage is not more than 70%.</li>
					<li>Alcohol content in the beverage is more than 70% should not be accepted. (Because that's pure spirit comes under DG hence cannot be transported)</li>
				</ol>

				<p>If the alcoholic beverage contains 24% or less alcohol by volume the above limitation of 5 Litres does not apply.</p>
				<p>The alcoholic beverages are also permitted in carry-on baggage when purchased from the Airport Security Hold Area and should be placed in a transparent re-sealable plastic bag of a maximum capacity not exceeding 1 Litre. The indicative size of the 1 Litre bag is: 20.5 cm x 20.5 cm or 25 cm x 15 cm or equivalent. The containers must fit comfortably within the bag, which should be fully closed.</p>
				<p>The passengers must comply with other applicable state/national regulations, if any.</p>
				<p>Carrying liquor to/from airports in Gujarat & Bihar states is strictly prohibited as Gujarat and Bihar are dry states.</p>

			</>
		),
	},
	{
		id: 'BaggageOverSize',
		header: 'How can I carry non-standard baggage such as an odd-sized or an item that provides special assistance?',
		body: (
			<>
				<strong>Over Sized / Odd-Sized Items</strong>
				<p>
					If you wish to carry an oversized item on board, which will fit safely in a seat, you must purchase an additional seat on that flight, subject to availability and applicable fares. However, flybig reserves the right to refuse carrying of any large item(s) which we feel may present a safety risk.
				</p>
				<strong>Special Assistance Items</strong>
				<p>
					A Passenger requiring a fully collapsible wheelchair and / or a pair of crutches and / or braces or other prosthetic device may board such items as Cabin Baggage, provided that the passenger is dependent upon them. In case of fragile items flybig has limited liability.
				</p>
				<strong>What items are considered as special Baggage and how can I carry them?</strong>
				<p>The following items are considered as Special Baggage:</p>
				<ul>
					<li>Sports equipment</li>
					<li>Skiing equipment</li>
					<li>Golf bags</li>
					<li>Bicycle</li>
					<li>Surf boards</li>
					<li>Snow board</li>
					<li>Water Ski</li>
					<li>Scuba diving equipment</li>
					<li>LED/LCD, any fragile item or other large and/or odd-sized items, including cartons, whose dimensions (L+W+H) exceed 158 cms & 113 cm (44 inches) for DHC6</li>
				</ul>
				<p>flybig will levy a fee of INR 1500 (or equivalent in foreign currency) for Domestic flights for any Special Baggage that is submitted as Checked Baggage. This fee will be applied over and above any Excess Baggage charges that might be applicable.</p>
				<h4 className='ml-3'>Please note:</h4>
				<p>Jewellery: flybig does not hold any liability on carrying cash or jewellery but it is subject to clearance by the airport regulatory authorities.</p>
				<p>Musical Instruments: Carriage of musical instruments is allowed in our Domestic at no additional cost. Guitars, if packed in soft cases, can be carried in hand baggage, however, other instruments have to be properly packed and can be carried in Check-in baggage only.</p>
			</>
		),
	},
	{
		id: 'BaggageSpecial',
		header: 'What items are considered as special baggage and how can I carry them?',
		body: (
			<>
				<p>
					The following items are considered as Special Baggage:
				</p>
				<ol className='px-5'>
					<li>Sports equipment,</li>
					<li>Skiing equipment,</li>
					<li>Golf bags,</li>
					<li>Bicycle</li>
					<li>Surf boards,</li>
					<li>Snow board,</li>
					<li>Water Ski,</li>
					<li>Scuba diving equipment,</li>
					<li>LED/LCD, any fragile item or other large and/or odd-sized items, including cartons, whose dimensions (L+W+H) exceed 158 cms</li>

				</ol>
				<p>flybig will levy a fee of INR 1500 (or equivalent in foreign currency) for Domestic flights for any Special Baggage that is submitted as Checked Baggage. This fee will be applied over and above any Excess Baggage charges that might be applicable.</p>
				<strong>Please note:</strong>
				<p>Jewellery: flybig does not hold any liability on carrying cash or jewellery but it is subject to clearance by the airport regulatory authorities.</p>
				<p>Musical Instruments: Carriage of musical instruments is allowed in our Domestic at no additional cost. Guitars, if packed in soft cases, can be carried in hand baggage, however, other instruments have to be properly packed and can be carried in Check-in baggage only.</p>

			</>
		),
	},
	{
		id: 'BaggageLost',
		header: 'I have lost my handbag, what do I do?',
		body: (
			<p> Guests are solely responsible for carriage of their hand bag / personal belongings. flybig is not liable for any loss / damage caused to guest's hand baggage / personal belongings.</p>
		),
	},
	{
		id: 'BaggageLossNoReport',
		header: 'What happens if I am unable to report loss / damage to my bag?',
		body: (
			<p> Any receipt of baggage without complaint on the termination of the journey shall be prima facie evidence that the baggage has been delivered correctly and in good condition. A guest who leaves the baggage delivery area without reporting a bag as missing or damaged to an flybig representative will be deemed to have received his/her bag correctly and in good condition.</p>
		),
	},
	{
		id: 'unaccompaniedMinors',
		header: 'What is the charges for unaccompanied Minors?',
		body: (
			<p>Unaccompanied Minor fee of Rs. 3500/- will be charged for passengers up to 12 years of age and not traveling with an adult in addition to the fare.</p>
		),
	},
];

export default function Baggage({ eventKey }) {
	return (
		<div className="faqitem">
			{
				data.map((item) => (
					<CardHolder
						eventKey={item.id}
						selectedEventKey={eventKey}
						id={item.id}
						header={item.header}
					>
						{item.body}
					</CardHolder>
				))
			}
			{/* </Accordion> */}
		</div>
	);
}
