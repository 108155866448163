/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from 'react';
import { Form, InputGroup, Row } from 'react-bootstrap';
import { FaPhone } from 'react-icons/fa';
import { __RouterContext } from 'react-router';
import { HOME_URL, PARTNER_LOGIN } from '../config/config';
import { PHONE_NUMBER } from '../constants';
import './Header.scss';
import FlybigNavbar from './Navbar';
import Marquee from "react-fast-marquee";
// import newWeb from '../Images/new_web_button.png'

export default function Header() {
  const [searchText, setSearchText] = useState('');
  const { location } = useContext(__RouterContext);
  const isHeaderIframe = location.pathname.toLowerCase().includes('headerframe');

  return (
    <div style={{ position: 'relative' }}>
      <Marquee speed="50" style={{ marginTop: "15px" }}>
        <img src={process.env.PUBLIC_URL + '/favicon.png'} alt="" style={{ height: "15px", margin: "0 5px 0 20px" }} />
        As per the latest DGCA guidelines, wearing a mask at all times covering your nose, mouth and chin properly is to be followed diligently throughout the flybig journey. Non-adherence to any COVID-19 safety protocols may result in denied boarding!

        <img src={process.env.PUBLIC_URL + '/favicon.png'} alt="" style={{ height: "15px", margin: "0 5px 0 20px" }} />
        Beware of individuals or groups who are misleading job seekers by soliciting financial considerations or requesting money for job placements within flybig or its sister concerns...

      </Marquee>
      <div className="info-header hidden-sm">
        <nav className="navbar mb-0">
          <div className="ibe-navbar contact-register">
            <div className="px-3 px-lg-5 py-5 row" style={{ justifyContent: 'space-between' }}>
              {/* <div className="col-sm-12 col-md-3 col-lg-3 pb-3" style={{ display: 'flex', alignItems: 'center' }}>
                <span>flybig Helpline no.: 1800-xxxx-xxxx</span>
              </div> */}
              <div className="col-lg-3 col-md-3 col-sm-12 mb-0 align-self-center">
                <Form
                  target={isHeaderIframe ? '_top' : ''}
                  method="GET"
                  action="/search"
                  onSubmit={(e) => {
                    if (!isHeaderIframe) {
                      e.preventDefault();
                      const win = window.open(`/search?searchText=${searchText}`, '_self');
                      win.focus();
                    }
                  }}
                >
                  <InputGroup style={{ height: '30px' }}>
                    <Form.Control
                      style={{ height: '30px' }}
                      type="text"
                      placeholder="Search here.."
                      name="searchText"
                      value={searchText}
                      onChange={(e) => {
                        e.preventDefault();
                        setSearchText(e.target.value);
                      }}
                    />
                    <InputGroup.Prepend>
                      <input src={process.env.PUBLIC_URL + '/favicon.png'} type="image" title={`search ${searchText}`} style={{ height: '30px', padding: '5px' }} />
                    </InputGroup.Prepend>
                  </InputGroup>
                </Form>
              </div>
              {/* col-lg-6 col-md-4 col-sm-12 mb-0 pb-lg-3 */}
              {/* <div className="col-lg-4 col-md-4 col-sm-12 my-0 py-0">
                <a href="https://ibook.flybig.in" target="__blank"><img src={newWeb} alt="" className='w-100' /></a>
              </div> */}
              <div className="col-lg-4 col-md-4 col-sm-12 mb-0 align-self-center">

                <div className="d-flex ibe-contact justify-content-between justify-content-lg-end align-items-center mb-0">
                  <div className="d-flex flex-row mb-0" id="phone">
                    <div className="ibe-circle phone mb-0">
                      <FaPhone />
                    </div>
                    <span className="p-2 pl-3">{PHONE_NUMBER}</span>
                  </div>
                  {/* <div className="ibe-circle ml-3">
                      <FaEnvelope />
                    </div>
                    <span className="p-2 pl-3">info@flybig.in</span> */}
                  <span className="p-2  ml-3 bg-purple text-white mb-0">
                    <a target="_parent" href={`${PARTNER_LOGIN}`} className="text-white">Partner Login</a>
                    {' '}
                    |
                    {' '}
                    <a target="_parent" href={`${HOME_URL}/register`} className="text-white">Register</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="main-header">
        <nav className="navbar navbar-static-top">
          <div className="ibe-navbar">
            <div className="navbar-header">
              <a target="_parent" className="navbar-brand" href="/book" style={{ padding: '0 15px' }}>
                <img src={process.env.PUBLIC_URL + '/flybig.png'} style={{ height: '65px', marginTop: '-10px' }} className="logo-container" />
              </a>
            </div>
            <Row className="m-0" style={{ borderRadius: '8px 0px 0px 8px', justifyContent: 'flex-end' }}>
              <FlybigNavbar />
            </Row>
          </div>
        </nav>
      </div>

      {/* <Row>
        <Col xs={1} classNameName="flybig-navbar" style={{ borderRadius: '0px 8px 8px 0px' }} />
        <Col xs="auto">
          <Image style={{ height: '40px', margin: '0px' }} src="/flybig.png" />
        </Col>

      </Row>
      <SocialTray /> */}
    </div>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
