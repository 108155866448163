/* eslint-disable max-len */
import React from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';

import './Item.scss';
import { Link } from 'react-router-dom';

export default function Corporate() {
  return (
    <div className="item">
      <div className="cellheader cardItemHeader">
        CORPORATE & COMMERCIAL
      </div>
      <hr />
      <Row className="header" style={{ justifyContent: 'start' }}>
        <Col xl={12}>

          <div class="fw-bold mb-2">
            <Link to="/careers/latest">AGM - FINANCE & ACCOUNTS</Link>
          </div>
          <p>To be responsible for smooth and efficient handling of all Financial Accounting responsibilities.
            Location: Gurgaon, Reporting to VP - Finance.</p>

          <p><strong>MINIMUM QUALIFICATION & REQUIREMENT</strong></p>
          <ul>
            <li>CA/ CMA/ CPA/ MBA/Master's - Finance</li>
            <li>3 years plus having experience in similar functions</li>
            <li>Core Financial Account</li>
            <li>Project implementation, systems development</li>
            <li>Proficient in Tally</li>
          </ul>


          <p><strong>JOB PROFILE</strong></p>
          <ul>
            <li> Incumbent will be responsible for managing and responsible for accounting and financial duties of Finance</li>
            <li> Able to achieve budget objectives by scheduling expenditures; analysing variances; initiating corrective actions</li>
            <li> Oversee all direct & indirect tax and regulatory compliance issues</li>
            <li> Fully conversant with the Companies Act 2013 requirement of a private limited company</li>
            <li> Finding gaps in the accounting processes of the company and ensure timely reporting.</li>
            <li> Implementing effective policies for smooth functioning of finance department.</li>
            <li> Preparing monthly financial statements as well as daily/weekly reports as per applicable accounting standards.</li>
            <li> Providing financial reports to senior management/Directors of the company.</li>
            <li> Preparing cash flow statements and additionally assisting in cash flow management</li>
            <li> Manage the overall MIS reporting for flybig</li>
            <li> Provide in-depth analysis & evaluation of all financial statements</li>
            <li> Deputize for the reporting manager whenever required</li>
          </ul>

          <p><strong>SET OF SKILLS & ATTRIBUTES</strong></p>
          <ul>
            <li>The candidate should possess excellent client - agency relationship skills, analytical skills, coordination skills, and strong organizational and communication skills</li>
            <li>The candidate should be proficient in MS Office tools, especially in advance MS Excel including development of macros</li>
            <li>Should have superior analytical and problem-solving skills in a commercial environment</li>
            <li>The candidate should be able to multitask in a team work environment and under pressure / deadline Proven analytical and problem solving skills</li>
            <li>Strong verbal & written communication in English.</li>
            <li>Must have good organizational skills, be a self-starter and a Team Player</li>
          </ul>

          <p><strong>Preference</strong></p>
          <ul>
            <li>Professional from aviation / hospitality sector</li>
          </ul>

          <p>Share your credentials with <a href="mailto:hr@flybig.in">hr@flybig.in</a></p>
        </Col>
      </Row>
    </div>
  );
}
