/* eslint-disable max-len */
import React from 'react';
import { PHONE_NUMBER } from '../../../constants';
import './SAItem.scss';

export default function Stretchers() {
  return (
    <div className="saitem">
      <ul>
        <li>flybig accepts request for stretcher for Airbus A320 only. We will not be able to carry stretcher on connecting flights.</li>
        <li>
          To book a stretcher on an flybig flight,
          <ul>
            <li>
              Guests are requested to call us at our call centre @
              {` ${PHONE_NUMBER} `}
              at least forty eight (48) hours before the scheduled time of departure.
            </li>
            <li>Guests are required to take a print-out of the flybig medical form, available on the website. (Click here to download). Please have the medical form filled out by guest’s treating physician.</li>
            <li>Guests are required to share the travel details, along with the filled medical form on the email id shared by our call centre team member.</li>
            <li>Our Medical Department gives the clearance for all stretcher cases and the same would be informed to you within twenty four (24) hours of your making such a request.</li>
            <li>Based on the report shared, we may require an flybig medical representative to meet the guest at the airport on the date of travel to determine the guest’s medical fitness. flybig may deny boarding to a passenger in its sole discretion if  such passenger is found to be unfit  to travel by flybig’s medical representative.</li>
            <li>flybig may require a fresh medical form on the day of travel, for select cases. We will inform you of the same on the confirmation email.</li>
          </ul>
        </li>
        <li>The stretcher installation on board our airplane requires us to fold (6) seats across three rows for the stretcher to fit securely against the fuselage and rear bulkhead. The other three adjoining aisle seats across the same rows provide access to the stretcher patient, hence the applicable charges will be for (9) seats at the prevailing fare, plus a handling fee as mentioned in Annexure A.</li>
        <li>Our guest service and cabin crew team will provide all the assistance required other than the ones provided by a safety assistant.</li>
      </ul>

      <p style={{ textDecoration: 'underline' }}><strong>Guest Grievance Redressal</strong></p>
      <p>At flybig we strive to achieve the highest level of guest satisfaction and our teams have been trained to deliver the same. However, in the event you feel dissatisfied about something please do no hesitate to reach out to us so we can make things right if they were not. We are reachable to you in more ways than one.</p>
      <p>
        Call Center:
        {' '}
        {PHONE_NUMBER}
      </p>
      <p>
        Nodal Officer :
        {' '}
        Ms. Kanchan Gidwani Email:
        {' '}
        <a href="mailto:nodalofficer@flybig.in">nodalofficer@flybig.in</a>
      </p>
      <p>
        Appellate Authority: Mr. Manish Koul Email:
        {' '}
        <a href="mailto:appellateauthority@flybig.in">appellateauthority@flybig.in</a>
      </p>
      <p>
        Contact:
        {' '}
        <a href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a>
      </p>
      <p>OR</p>
      <p>Our Guest Services Manager at various airports shall be more than happy to help you.</p>

    </div>
  );
}
