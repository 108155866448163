/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function BloodDisorder() {
  return (
    <div className="saitem">
      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>
        <tr>
          <td>ANEMIA</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY IF HEMOGLOBIN COUNT IS LESS THAN 7.5GM/DL</td>
          <td>IF HEMOGLOBIN IS MORE THAN OR EQUAL TO 7.5GM/DL</td>
          <td>NO</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>SICKLE CELL DISEASE</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 10 DAYS AFER A SICKLING EPISODE</td>
          <td>AFTER 10 DAYS IF DOES NOT REQUIRE SUPPLEMENT OF OXYGEN</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

      </table>
    </div>
  );
}
