/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function UnaccompaniedMinor() {
  return (
    <div className="faqitem">
      <div className="header">
        <p>
          Who is considered an Unaccompanied Minor?
        </p>
      </div>
      <ul>
        <li>Children above the age of two and below the age of twelve years are regarded as Minors. flybig offers the following assistance services to minors:</li>
        <li>Under 5 years - 'Fly Junior Solo' service is unavailable, however, the child MUST travel with someone over the age of 18 years</li>
        <li>5-12 years - 'Fly Junior Solo' service is available and can the child can travel on all flights</li>
        <li>13-18 years - The child can travel alone or we would be happy to offer the 'Fly Junior Solo' service</li>
      </ul>
      <div className="header">
        <p>
          By what time must an Unaccompanied Minor report at the airport?
        </p>
      </div>
      <p>
        All unaccompanied minors must report at the airport at least 2 hours prior to departure for domestic flight and 4 hours prior to departure for international flights
      </p>
      <div className="header">
        <p>
          Do I need to fill any form for my child’s travel with flybig?
        </p>
      </div>
      <p>
        A parent or guardian is required to complete a signed Unaccompanied Minor form (Form to be downloaded from website) at the point of check-in at each departure airport prior to travel. The form must include the name and contact numbers of the parent or guardian (above the age of 18) who will meet the unaccompanied minor at the point of arrival
      </p>
      <div className="header">
        <p>
          Do I need to provide a valid photo ID proof for my child?
        </p>
      </div>
      <p>
        Valid photo ID for both the unaccompanied minor and the parent/guardian is required at the point of check-in
      </p>
      <div className="header">
        <p>
          What kind of assistance will the child be provided with?
        </p>
      </div>
      <strong>During Travel</strong>
      <p>
        A staff member will take the child to the aircraft where our cabin crew will then look after them.
      </p>
      <strong>On Board</strong>
      <p>
        During the flight, our cabin crew will keep an eye on the child and will make sure that they have everything they need. Do not worry about the snack, as it is included on our Flying Solo services. To make sure that the child has a comfortable flight, the parent/guardian is requested to pack medication, warm clothing and a favourite toy or game in their hand baggage.
      </p>
      <strong>Post Arrival</strong>
      <p>
        When the flight arrives, our ground staff will accompany and assist the child through the airport arrivals process. Please be available at the arrival hall at least 30 mins prior arrival to receive the child.
      </p>
      <div className="header">
        <p>
          Does the parent or guardian remain at the airport until the flight has departed?
        </p>
      </div>
      <p>
        Yes
      </p>
      <div className="header">
        <p>
          Will flybig accept an unaccompanied minor if there is any transit/transfer?
        </p>
      </div>
      <p>
        If the stopover at transfer station is less than 6 hours and if the child is booked through out on flybig, we shall be able to accept the child under the 'Flying Solo' Services. If the stopover at transfer stations is more than 6 hours and/or involves night halt and/or transfer is on other airline except flybig, we shall not be able to accept the child under the 'Fly Junior Solo' services. In such cases, the child must be accompanied by someone who is above the age of 18 years.
      </p>
    </div>
  );
}
