/* eslint-disable jsx-a11y/anchor-is-valid */
import './Links.scss';
import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Copyright from './Copyright';
import {
  HOME_URL, SQIVA_URL, FACEBOOK_URL, TWITTER_URL, INSTAGRAM_URL, LINKEDIN_URL
} from '../config/config';
// import newWeb from '../Images/new_web_button.png'

export default function Links() {
  return (
    <>
      <Row style={{ margin: '0px' }} className="links">
        <Col lg={2} md={2} sm={12} className="linkSection">
          <br />

          <p>Home</p>
          <a target="_parent" href={`${HOME_URL}`}>Book Flight</a>
          <a target="_parent" href={`${SQIVA_URL}/manage-booking`}>Manage Flight</a>
          <a target="_parent" href={`${SQIVA_URL}/web-checkin`}>Web Check-in</a>
          {/* <a target="_parent" href={`${HOME_URL}/Schedule`}>Schedule</a> */}
          <br />
          <p>Services</p>
          <a rel="noreferrer" target="_blank" href={`${HOME_URL}/documents/Flight_Schedule_June_2024.pdf`}>Flight Schedule</a>
          <a rel="noreferrer" target="_blank" href={`${HOME_URL}/documents/flybig_Tariff_sheet_for_the_month_of_July_2024.pdf`}>Tariff sheet</a>
          <br />
        </Col>

        <Col lg={3} md={3} sm={12} className="linkSection">
          <br />
          <p>Travel Information</p>
          <a target="_parent" href={`${HOME_URL}/COC`}>Conditions of carriage</a>
          <a rel="noreferrer" target="_blank" href={`${HOME_URL}/documents/passenger-charter.pdf`}>Passenger Charter</a>
          <a target="_parent" href={`${HOME_URL}/Assistance`}>Special/Disability Assistance</a>
          <a target="_parent" href={`${HOME_URL}/GroupBookingReservation`}>Group Booking Reservation</a>
          <a target="_parent" href={`${HOME_URL}/weather-advisory`}>Weather advisory</a>
          <a target="_parent" href={`${HOME_URL}/faqs`}>FAQs</a>
          <br />
          <p>Downloads</p>
          <a target="_parent" href={`${SQIVA_URL}/manage-booking`}>Email Itinerary</a>
          <a target="_parent" href={`${SQIVA_URL}/manage-booking`}>Travel Certificate</a>
          <a target="_parent" href={`${HOME_URL}/travel-mandate`}>Travel Mandate</a>
          <br />
        </Col>
        <Col lg={3} md={3} sm={12} className="linkSection">
          <br />
          <p>Corporate</p>
          <a target="_parent" href={`${HOME_URL}/about`}>About us</a>
          <a target="_parent" href={`${HOME_URL}/contact`}>Contact Us</a>
          <a target="_parent" href={`${HOME_URL}/careers`}>Careers</a>
          <a target="_parent" href={`${HOME_URL}/leadershipteam`}>Leadership Team</a>
          <a target="_blank" rel="noreferrer" href={`${HOME_URL}/documents/Annual Return_FY 2021-22.pdf`}>Annual Return FY 2021-22</a>
          <a target="_blank" rel="noreferrer" href={`${HOME_URL}/documents/EGM Notice & Explanatory Statement.pdf`}>Notice of EGM - 01/2023-24</a>
          <br />
          {/* <div className="col-sm-12 px-0 mt-5">
            <a href="https://ibook.flybig.in" target="__blank"><img src={newWeb} alt="" style={{ width: 175 }} /></a>
          </div> */}
        </Col>
        <Col lg={2} md={2} sm={12} className="linkSection">
          {/* <br />
          <p>Customer Care</p>
          <a target="_parent" href={`${HOME_URL}/faqs`}>FAQs</a> */}

          <br />
          <p>Follow us on</p>
          <a rel="noreferrer" target="_blank" style={{ display: 'inline' }} href={FACEBOOK_URL}>
            <Image src={`${HOME_URL}/social/facebook.ico`} style={{ height: '30px' }} />
          </a>
          <a rel="noreferrer" target="_blank" style={{ display: 'inline', margin: '5px' }} href={TWITTER_URL}>
            <Image src={`${HOME_URL}/social/twitter.ico`} style={{ height: '30px' }} />
          </a>
          <a rel="noreferrer" target="_blank" style={{ display: 'inline' }} href={INSTAGRAM_URL}>
            <Image src={`${HOME_URL}/social/instagram.ico`} style={{ height: '30px' }} />
          </a>
          <a rel="noreferrer" target="_blank" style={{ display: 'inline', margin: '5px' }} href={LINKEDIN_URL}>
            <Image src={`${HOME_URL}/social/linkedin.ico`} style={{ height: '30px' }} />
          </a>
          <br />
          <br />
          <p>Important Links</p>
          <a target="_parent" href={`${HOME_URL}/privacy-policy`}>Privacy Policy</a>
          <a target="_parent" href={`${HOME_URL}/terms-and-conditions`}>Terms & Conditions</a>
          <br />
          <p>Forms</p>
          <a rel="noreferrer" target="_blank" href={`${HOME_URL}/documents/Form of Indemnity.pdf`}>Medical Form</a>
          <a rel="noreferrer" target="_blank" href={`${HOME_URL}/documents/UMNR.pdf`}>Minor Form</a>
          <a rel="noreferrer" target="_blank" href={`${HOME_URL}/documents/Indemnity bond.pdf`}>Wheel Chair Form</a>
          <br />
        </Col>
      </Row>
      <Copyright />
    </>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
