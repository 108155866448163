import React, { useState } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from "formik";
import * as Yup from "yup";
import { StateToCityDict } from "../../data/cities";
import { SQIVA_URL } from "../../config/config";
import "./PartnerLogin.scss";
import PictureInput from "./PictureInput";
import axios from "axios";

const FILE_SIZE = 2000000;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const phoneRegExp =
  /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().min(2).max(100).email("Invalid email").required(),
  name: Yup.string().min(2).max(100).required(),
  shortname: Yup.string().min(2).max(100).required(),
  gstin: Yup.string().min(2).max(50),
  address: Yup.string().min(2).max(100).required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  zip: Yup.string().min(2).max(50).required(),
  phone: Yup.string().min(8).max(50).required(),
  pan: Yup.string().min(8).max(50).required(),
  fax: Yup.string().min(8).max(50),
  website: Yup.string().min(8).max(100),
  firstname: Yup.string().min(2).max(100).required(),
  lastname: Yup.string().min(2).max(100).required(),
  mobile: Yup.string()
    .required()
    .matches(phoneRegExp, "Phone number is not valid"),
  gstindocument: Yup.mixed()
    .test("fileSize", "File too large", (value, ...props) => {
      if (value) return value.size <= FILE_SIZE;
      return true;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (value) return SUPPORTED_FORMATS.includes(value.type);
      return true;
    }),
  pandocument: Yup.mixed()
    .required("A file is required")
    .test("fileSize", "File too large", (value, ...props) => {
      if (value) return value.size <= FILE_SIZE;
      return true;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (value) return SUPPORTED_FORMATS.includes(value.type);
      return true;
    }),
  addressdocument: Yup.mixed()
    .required("A file is required")
    .test("fileSize", "File too large", (value, ...props) => {
      if (value) return value.size <= FILE_SIZE;
      return true;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (value) return SUPPORTED_FORMATS.includes(value.type);
      return true;
    }),
});

export default function PartnerLogin() {
  const [cities, setCities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const companyFields = [
    {
      label: "NAME",
      id: "name",
      name: "name",
      type: "text",
    },
    {
      label: "SHORT NAME",
      id: "shortname",
      name: "shortname",
      type: "text",
    },
    {
      label: "GSTIN",
      id: "gstin",
      name: "gstin",
      type: "text",
    },
    {
      label: "ADDRESS",
      id: "address",
      name: "address",
      type: "text",
    },
    {
      label: "STATE",
      id: "state",
      name: "state",
      type: "select",
      onBlur: ({ target }) => {
        setCities(StateToCityDict[target.value]);
      },
      options: Object.keys(StateToCityDict),
    },
    {
      label: "CITY",
      id: "city",
      name: "city",
      type: "select",
      options: cities,
    },
    {
      label: "ZIP",
      id: "zip",
      name: "zip",
      type: "text",
    },
    {
      label: "COUNTRY",
      id: "country",
      name: "country",
      type: "text",
    },
    {
      label: "PHONE",
      id: "phone",
      name: "phone",
      type: "text",
    },
    {
      label: "FAX",
      id: "fax",
      name: "fax",
      type: "text",
    },
    {
      label: "WEBSITE",
      id: "website",
      name: "website",
      type: "text",
    },
    {
      label: "PAN",
      id: "pan",
      name: "pan",
      type: "text",
    },
    {
      label: "UPLOAD GSTIN Certificate",
      id: "gstindocument",
      name: "gstindocument",
      type: "file",
      // onChange:{e => form.setFieldValue(field.name, e.target.files[0])}
      // onChange: function ({ target }) {
      //   setGstinFile(target.files[0]);
      // },
    },
    {
      label: "UPLOAD PAN",
      id: "pandocument",
      name: "pandocument",
      type: "file",
      component: { PictureInput },
      // onChange: function ({ target }) {
      //   setPanFile(target.files[0]);
      // },
    },
    {
      label: "UPLOAD ADDRESS PROOF",
      id: "addressdocument",
      name: "addressdocument",
      type: "file",
      component: { PictureInput },
      // onChange: function ({ target }) {
      //   setAddressFile(target.files[0]);
      // },
    },
  ];

  const userInfoFields = [
    {
      label: "FIRST NAME",
      field: "firstname",
      name: "firstname",
      type: "text",
    },
    {
      label: "LAST NAME",
      field: "lastname",
      name: "lastname",
      type: "text",
    },
    {
      label: "GENDER",
      field: "gender",
      name: "gender",
      type: "select",
      options: ["Male", "Female"],
    },
    {
      label: "BIRTHDATE",
      field: "birthdate",
      placeholder: "mm/dd/yyyy",
      name: "birthdate",
      type: "date",
    },
    {
      label: "EMAIL",
      field: "email",
      name: "email",
      type: "email",
    },
    {
      label: "MOBILE",
      field: "mobile",
      name: "mobile",
      type: "text",
    },
  ];

  return (
    <>
      <div className="partnerLoginContainer">
        <h4>Partner Register</h4>
        <div className="container">
          <h3 className="font-weight-bold">Company</h3>

          <Formik
            initialValues={{
              name: "",
              shortname: "",
              gstin: "",
              address: "",
              state: "",
              city: "",
              zip: "",
              country: "India",
              phone: "",
              fax: "",
              website: "",
              pan: "",
              firstname: "",
              lastname: "",
              email: "",
              mobile: "",
              birthdate: "",
              gstindocument: "",
              pandocument: "",
              addressdocument: "",
            }}
            validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={(values, actions, ...props) => {
              const formData = new FormData();
              Object.keys(values).forEach((key) => {
                if (values[key].size) {
                  formData.append(
                    key,
                    new Blob([values[key]], { type: values[key].type }),
                    values[key].name
                  );
                } else {
                  formData.set(
                    key,
                    key === "gender" || key === "city" || key === "state"
                      ? values[key].value
                      : values[key]
                  );
                }
              });

              // for (var pair of formData.entries()) {
              //   console.log(pair[0] + ", " + pair[1]);
              // }

              axios({
                method: "post",
                url: `${SQIVA_URL}/send-partner-register`,
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
                .then((response) => {
                  // handle success
                  if (response.success === false) {
                    setErrorMsg(response.response);
                  } else {
                    setErrorMsg(
                      "Thank you for registering with flybig ,information submitted has been sent to flybig team and you will have  their revert within 24 - 48 Hrs."
                    );
                  }
                  setShowModal(true);
                })
                .catch((response) => {
                  // handle error
                  setErrorMsg("Some exception occurred.");
                  setShowModal(true);
                });
            }}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <Row>
                  {companyFields.map((field, i) => {
                    return (
                      <Col
                        xl={3}
                        md={4}
                        sm={6}
                        xs={12}
                        className="mb-2"
                        key={i}
                      >
                        <div className="formikComponentContainer">
                          <label htmlFor={field.name}>{field.label}</label>
                          {(() => {
                            if (field.type === "select") {
                              return (
                                <>
                                  <Field
                                    className="form-control form-control-lg"
                                    {...field}
                                    as={field.type}
                                  >
                                    <option value="">
                                      Select {field.name}
                                    </option>
                                    {field.options.map((option, i) => (
                                      <option key={i}>{option}</option>
                                    ))}
                                  </Field>
                                  {touched[field.name] &&
                                    errors[field.name] && (
                                      <span className="text-danger">
                                        {errors[field.name]}
                                      </span>
                                    )}
                                </>
                              );
                            } else if (field.type === "file") {
                              return (
                                <>
                                  <Field
                                    name={field.name}
                                    component={PictureInput}
                                  />
                                  {touched[field.name] &&
                                    errors[field.name] && (
                                      <span className="text-danger">
                                        {errors[field.name]}
                                      </span>
                                    )}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <Field
                                    className="form-control form-control-lg"
                                    {...field}
                                  />
                                  {touched[field.name] &&
                                    errors[field.name] && (
                                      <span className="text-danger">
                                        {errors[field.name]}
                                      </span>
                                    )}
                                </>
                              );
                            }
                          })()}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <hr />

                <h3 className="font-weight-bold">User Information</h3>
                <Row>
                  {userInfoFields.map((field, i) => {
                    return (
                      <Col
                        xl={3}
                        md={4}
                        sm={6}
                        xs={12}
                        className="mb-2"
                        key={i}
                      >
                        <div className="formikComponentContainer">
                          <label htmlFor={field.name}>{field.label}</label>
                          {field.type === "select" ? (
                            <>
                              <Field
                                className="form-control form-control-lg"
                                {...field}
                                as={field.type}
                              >
                                {field.options.map((option, i) => (
                                  <option key={i}>{option}</option>
                                ))}
                              </Field>
                              {touched[field.name] && errors[field.name] && (
                                <span className="text-danger">
                                  {errors[field.name]}
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              <Field
                                className="form-control form-control-lg"
                                {...field}
                              />
                              {touched[field.name] && errors[field.name] && (
                                <span className="text-danger">
                                  {errors[field.name]}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <hr />
                <div className="text-center">
                  <Button type="submit" size="lg">
                    Registor
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="pinkColor">flybig Registration</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ fontSize: "large", textAlign: "center" }}>
          <p>{errorMsg}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            size="lg"
            onClick={() => setShowModal(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
