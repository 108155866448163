import React from 'react';
import '../FAQItems/FAQItem.scss';
// import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
// import { HOME_URL, SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'preBookSeat',
    header: 'Can I pre-book my seat?',
    body: (
      <p>
        Yes. You can pre-book your seat while booking the flight or during web check-in.
      </p>
    ),
  },
  {
    id: 'liquidLimit',
    header: 'What is the permissible limit to carry liquids on the flight?',
    body: (
      <p>
        The permissible limit to carry liquid on-board the flight is 100 ml only. Due to the current COVID-19 crisis, you are advised to use hand sanitizer frequently. Hence, you are allowed to carry a bottle of sanitizer of up to 350 ml in your cabin baggage.
      </p>
    ),
  },
  {
    id: 'baggageAllowance',
    header: 'What is the cabin and check-in baggage allowance for the flight?',
    body: (
      <p>
        As per the passenger guidelines for air travel, you are allowed to carry only one check-in bag and one cabin bag. flybig specifies that check-in baggage should not weigh more than 20 kg. Also, the cabin bag should not exceed 115 cm and not weigh more than 7 kg.
      </p>
    ),
  },
  {
    id: 'reportingTime',
    header: 'What is the reporting time for the flights?',
    body: (
      <p>
        You are expected to report 3 hours before the departure of the flight. This is to ensure you have a contact-less and hassle-free boarding.
      </p>
    ),
  },
];

export default function Booking({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              id={item.id}
              selectedEventKey={eventKey}
              header={item.header}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
