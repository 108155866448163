/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function Trauma() {
  return (
    <div className="saitem">
      <ul>
        <li>If you are travelling with broken or fractured limbs in a plaster, please carry a ‘fit to fly’ certificate. The cast must be at least forty eight (48) hours old as a fresh fracture could swell inside the cast (except in cases of a Bivalve Cast or a Post cast Slab).</li>
        <li>In case of additional injuries other than broken limbs you will need to get the Medif form filled and submitted at least 48h prior to departure.</li>
      </ul>

      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>

        <tr>
          <td>BROKEN/PLASTERED LEGS</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>PLASTER KNEE JOINT AND ABOVE BUT NOT EXTENDING TO PELVIS PASSENGER CAN NOT FOLD THE KNEE /UNABLE TO TRAVEL IN SITTING POSTURE</td>
          <td>AS STRETCHER CASE</td>
          <td>YES</td>
          <td>YES</td>
        </tr>

        <tr>
          <td>MAJOR BACK/NECK INJURY/SPINAL INJURY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>ADVISED TO REQUEST FOR STRETCHER</td>
          <td>AS A STRETCHER CASE</td>
          <td>YES</td>
          <td>YES</td>
        </tr>

        <tr>
          <td>FULL PLASTER INVOLVING LOWER LIMB AND PELVIS</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>ADVISED TO REQUEST FOR STRETCHER</td>
          <td>AS STRETCHER CASE</td>
          <td>YES</td>
          <td>YES</td>
        </tr>

        <tr>
          <td>BURNS</td>
          <td>MEDICAL CONDITION</td>
          <td>(NOT PERMITTED (SEVERE BURNS) IF STILL SHOCKED OR WITH WIDESPREAD INFECTION)</td>
          <td>Intentionally Left Blank</td>
          <td>NO</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>SPINE SURGERY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>Within 7 days of surgery</td>
          <td>Permitted after 7 days of surgery. Passengers must be able to sit upreight for the duration of flight</td>
          <td>YES</td>
          <td>No</td>
        </tr>

      </table>
    </div>
  );
}
