/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function EmotionalDisorders() {
  return (
    <div className="saitem">
      <ul>
        <li>We would request our guests with psychiatric or emotional disorders to always travel with a safety assistant.</li>
        <li>The safety assistant shall carry the treating physician’s certificate along with a prescription for a sedative medicine, which may be administered by the attendant, prior to commencement of travel or as may be required en-route.</li>
      </ul>
    </div>
  );
}
