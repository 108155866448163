import { HOME_URL } from "../../config/config";

const baggage = "baggage";
const manageBooking = "manageBooking";
const checkin = "checkin";
const paymentfees = "paymentfees";
const generalqueries = "generalqueries";
const specialhandling = "specialhandling";
// const specialMedicalCondition = 'specialMedicalCondition';
const covidBooking = "booking";
const covidCheckin = "checkin";
const covidInflight = "inflight";
const refund = "refund";
const covidOthers = "others";
const travelCertificate = "travelCertificate";
const medicalAssistance = "medicalAssistance";
const flightDelayAndCancellations = "flightDelayAndCancellations";

const headerDict = {
  manageBooking: "MANAGE BOOKING",
  baggage: "BAGGAGE",
  refund: "Refund",
  checkin: "CHECK-IN",
  paymentfees: "PAYMENT & FEES",
  generalqueries: "GENERAL QUERIES",
  specialhandling: "SPECIAL HANDLING",
  promotionalfares: "PROMOTIONAL FARES",
  inflightqueries: "IN-FLIGHT QUERIES",
  specialMedicalCondition: "MEDICAL ASSISTANCE",
  travelCertificate: "Travel Certificate",
  medicalAssistance: "Medical Assistance",
  flightDelayAndCancellations: "Flight Delay And Cancellations",
  covid: {
    booking: "BOOKING",
    inflight: "INFIGHT",
    checkin: "CHECK-IN",
    others: "OTHERS",
  },
};

// manage-booking----------------------------------------------------------------
const ContentDictionary = [
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "How can I change/ cancel my reservation?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#changeReservation`,
    tags: ["change", "cancel", "booking", "reservation"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "How is View/ Change bookings beneficial to me?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#editBookingBenefit`,
    tags: ["manage", "booking", "benefical", "change", "view"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "What will I be able to do through View/Change booking? What are the features of the View/ Change Bookings option on the website?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#features`,
    tags: ["manage", "booking", "options", "features"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "How is View/ Change bookings accessible?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#accessManageBooking`,
    tags: ["manage", "booking", "access", "accessible"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "Would I be able to change my origin and destination through the View/Change Booking facility?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#changePlace`,
    tags: ["manage", "booking", "origin", "destination", "change"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "Would I be able to View/Change booking if I have booked on phone at the Call Centre but paid online?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#phoneManageBooking`,
    tags: ["manage", "booking", "booked", "call", "centre", "center", "online"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "Would I be able to cancel my ticket and use the same ticket in the future?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#reuseTicket`,
    tags: ["manage", "booking", "cancel", "same", "ticket", "future"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "Till when can I cancel/ refund my booking online?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#periodManageBooking`,
    tags: ["manage", "booking", "cancel", "refund", "online"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "What charges are incurred in case of flight cancellation?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#flightCancellationCharges`,
    tags: ["manage", "booking", "cancellation", "flight", "charges"],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "Can I alter my name in case I am not able to travel and transfer my ticket to another person?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#alterName`,
    tags: [
      "manage",
      "booking",
      "change",
      "alter",
      "name",
      "transfer",
      "ticket",
    ],
  },
  {
    page: "faqs",
    section: headerDict.manageBooking,
    text: "What is the procedure to cancel my flight?",
    link: `${HOME_URL}/faqs?section=${manageBooking}#cancellationProcedure`,
    tags: ["manage", "booking", "process", "procedure", "cancel", "flight"],
  },
  // manage-booking----------------------------------------------------------------

  // baggage------------------------------------------------------------------------
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "How much baggage am I allowed to carry?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggageAllowance`,
    tags: ["baggage", "luggage", "bag", "carry", "allowed"],
  },
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "What is the pre-paid excess baggage slab and how can I book it?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggagePrepaid`,
    tags: ["baggage", "luggage", "bag", "pre-paid", "slab"],
  },
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "What if I exceed my free baggage weight allowance?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggageExcess`,
    tags: ["baggage", "luggage", "bag", "free", "allowance"],
  },
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "What items are not permitted on flybig flight?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggageProhibited`,
    tags: ["baggage", "luggage", "bag", "items", "permitted"],
  },
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "Are there any general advisories that I should keep in mind regarding my baggage?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggageAdvisories`,
    tags: ["baggage", "luggage", "bag", "advisory", "advisories", "general"],
  },
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "Carriage of Alcoholic beverages?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggageAlcohol`,
    tags: ["baggage", "luggage", "bag", "alcohol", "beverage"],
  },
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "How can I carry non-standard baggage such as an odd-sized item or an item that provides special assistance?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggageOverSize`,
    tags: ["baggage", "bag", "size", "bagsizeassistance", "oversize"],
  },
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "What items are considered as special baggage and how can I carry them?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggageSpecial`,
    tags: [
      "baggage",
      "special",
      "baggage",
      "specialbaggage",
      "special-baggage",
    ],
  },
  {
    page: "faqs",
    section: headerDict.baggage,
    text: "What happens if I am unable to report loss / damage to my bag?",
    link: `${HOME_URL}/faqs?section=${baggage}#BaggageLost`,
    tags: ["baggage", "luggage", "bag"],
  },
  // baggage------------------------------------------------------------------------

  // payment and fees------------------------------------------------------------------------
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What are Fare Rules?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#fareRules`,
    tags: ["fare", "farerule", "rule", "fee"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is Biglite Fare?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#BigliteFare`,
    tags: ["fare", "farerule", "rule", "lite", "fee"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is BigEasy Fare?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#BigEasyFare`,
    tags: ["fare", "farerule", "rule", "easy", "fee"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is Group Fare?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#groupFare`,
    tags: ["fare", "farerule", "rule", "group", "fee"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is Defence category?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#defence`,
    tags: ["defence", "category", "concession", "discount"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is Senior Citizen Fare?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#senior`,
    tags: [
      "senior",
      "citizen",
      "seniorcitizen",
      "category",
      "concession",
      "discount",
    ],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is group Reservation?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#groupReservation`,
    tags: ["group", "reservation", "discount", "fees"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is student Fare?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#student`,
    tags: ["student", "concession", "discount"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "How do I make a payment on flybig website?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#paymentQuery`,
    tags: ["payment", "pay", "fees"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What if I want to make a payment on the website through my VISA Debit Card?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#paymentVisa`,
    tags: ["payment", "debit", "card", "visa", "debitcard"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is Verified by Visa (VBV) or MasterCard Secure Code (MSC)?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#visamaster`,
    tags: ["payment", "card", "visa", "mastercard"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What if my bank doesn’t offer Verified by Visa or MasterCard SecureCode?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#masterSecure`,
    tags: ["payment", "card", "visa", "mastercard"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "Which all are the other form of payments accepted at flybig?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#otherPayments`,
    tags: ["payment"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "Which all currencies are accepted at flybig?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#flybigCurrency`,
    tags: ["currency", "INR"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "Do I need to carry a copy of my credit/ debit card or the original copy on the date of travel?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#carryCard`,
    tags: ["travel", "document", "credit", "debit", "card", "traveldocument"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "Are there any additional charges if I make my payment through the call centre?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#additionalCharges`,
    tags: [
      "payment",
      "additionalcharge",
      "call",
      "centre",
      "callcentre",
      "center",
      "callcenter",
    ],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "Can I book through the call center using my credit card for another guest?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#callCenter`,
    tags: [
      "book",
      "callcentre",
      "creditcard",
      "call",
      "centre",
      "callcentre",
      "center",
      "callcenter",
    ],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "Why am I charged a convenience fee?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#convenienceFeeReason`,
    tags: ["fee", "convenience"],
  },
  {
    page: "faqs",
    section: headerDict.paymentfees,
    text: "What is Convenience Fee?",
    link: `${HOME_URL}/faqs?section=${paymentfees}#convenienceFee`,
    tags: ["fee", "convenience"],
  },
  // payment and fees ----------------------------------------------------------------------------

  // generalQueries--------------------------------------------------------------------------------

  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Can I be denied boarding if my name or title on the booking does not match the details on my photo identity card?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQNameMismatch`,
    tags: ["denied", "boarding", "identity"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Which all documents are accepted for Domestic Flights?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQDocuments`,
    tags: ["document", "accepted", "domestic"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Which all documents are accepted for Foreign Nationals ?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQDocumentsForeignNational`,
    tags: ["documents", "foreign", "nationals"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Is there any additional document required for entering Imphal?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQDocumentImphal`,
    tags: ["Imphal", "document"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "The amount has been deducted from my account however, I have not received the ticket?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQNoTicket`,
    tags: ["deducted", "amount", "account"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "How can I get a print out of my ticket/itinerary?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQPrintOut`,
    tags: ["itinerary", "ticket", "print"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "How can I print/re-print my Web Check-in boarding pass?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQPrintBoardingPass`,
    tags: ["web", "check-in", "boarding", "print", "re-print"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "How can I book a specific seat for myself on the flight?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQBookSeat`,
    tags: ["seat", "specific", "book"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "What is Priority Check-in Service?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQPriorityCheckin`,
    tags: ["priority", "check-in,service"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "On-board purchases:",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQOnBoardPurchase`,
    tags: ["purchase", "on-board"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Is alcohol offered on-board flybig?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQAlcohol`,
    tags: ["alcohol", "on-board,offered"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Is smoking allowed on- board flybig flights?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQSmoking`,
    tags: ["smoking", "on-board", "allowed"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Can I carry water and food on-board?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQFoodWater`,
    tags: ["water", "food", "on-board"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Does flybig provide ear plugs and cotton?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQEarPlugs`,
    tags: ["cotton", "plug", "ear"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "Will the cabin crew be able to provide me with any medical assistance?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQMedicalAssistance`,
    tags: [
      "medical",
      "assistance",
      "special",
      "assistance",
      "special-assistance",
    ],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "What type of aircraft does flybig fly?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQAircraftType`,
    tags: ["aircraft", "fly"],
  },
  {
    page: "faqs",
    section: headerDict.generalqueries,
    text: "What all cities does flybig fly to?",
    link: `${HOME_URL}/faqs?section=${generalqueries}#GQDestinations`,
    tags: ["cities", "fly"],
  },
  // generalQueries--------------------------------------------------------------------------------

  // specialHandling-------------------------------------------------------------------------------
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "Until when are Expectant Mothers permitted to fly?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHExpectantMothers`,
    tags: ["expected", "pregnant", "mother", "expectant"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "Who is considered Flying junior Solo (Unaccompanied Minor)?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHFlyingJrSolo`,
    tags: ["solo", "unaccompanied", "minor"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "By what time must flying Junior Solo (Unaccompanied Minor) report at the airport?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHJrReportingTime`,
    tags: ["solo", "unaccompanied", "minor"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "Do I need to fill any form for my child’s travel with flybig?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHJrForm`,
    tags: ["child", "travel,form"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "Do I need to provide a valid photo ID proof for my child?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHJrPhotoID`,
    tags: ["valid", "photo", "ID", "proof", "child"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "What kind of assistance will the child be provided with?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHJrAssistance`,
    tags: ["assistance", "child"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "Does the parent or guardian remain at the airport until the flight has departed?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHJrParentStay`,
    tags: ["parent", "guardian"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "Will flybig accept an flying Junior Solo (Unaccompanied Minor) if there is any transit/transfer?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHJrTransit`,
    tags: ["transit", "transfer", "solo", "unaccompanied", "minor"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "What is the age of an infant?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHInfantAge`,
    tags: ["age", "infant"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "What if I don't have a valid proof for the infant's age?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHInfantNoProof`,
    tags: ["valid", "proof", "age", "infant"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "Do I need to book a separate seat for an Infant?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHInfantSeat`,
    tags: ["infant", "seat", "separate"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "How do I book for an infant?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHInfantBook`,
    tags: ["book", "infant"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "How many infants can travel per flight?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHInfantCount`,
    tags: ["infant", "flight", "travel"],
  },
  {
    page: "faqs",
    section: headerDict.specialhandling,
    text: "What is the baggage allowance for Infant?",
    link: `${HOME_URL}/faqs?section=${specialhandling}#SHInfantBaggageAllowance`,
    tags: ["baggage", "Infant", "allowance"],
  },
  // specialHandling-------------------------------------------------------------------------------
  // refund----------------------------------------------------------------------------------------
  {
    page: "faqs",
    section: headerDict.refund,
    text: "Can a refund be obtained on an Itinerary cancellation? (Passenger)",
    link: `${HOME_URL}/faqs?section=${refund}#refundIternaryCancellation`,
    tags: ["cancellation", "refund", "itinerary"],
  },
  {
    page: "faqs",
    section: headerDict.refund,
    text: "How can a refund for a cancelled ticket be obtained?",
    link: `${HOME_URL}/faqs?section=${refund}#refundProcess`,
    tags: ["refund", "cancelled", "ticket"],
  },
  {
    page: "faqs",
    section: headerDict.refund,
    text: "How can a refund for a reservation paid in cash be obtained?",
    link: `${HOME_URL}/faqs?section=${refund}#refundCashReservation`,
    tags: ["refund", "cash", "reservation"],
  },
  {
    page: "faqs",
    section: headerDict.refund,
    text: "How can I obtain a refund for a ticket booked through a travel agency?",
    link: `${HOME_URL}/faqs?section=${refund}#refundTravelAgency`,
    tags: ["refund", "travel", "agent", "agency"],
  },
  {
    page: "faqs",
    section: headerDict.refund,
    text: "Can a refund be obtained in case I am unable to travel?",
    link: `${HOME_URL}/faqs?section=${refund}#refundNoTravel`,
    tags: ["refund", "travel"],
  },
  {
    page: "faqs",
    section: headerDict.refund,
    text: "What is the amount refunded for a flight I missed?",
    link: `${HOME_URL}/faqs?section=${refund}#refundMissedFlight`,
    tags: ["missed", "refund"],
  },
  {
    page: "faqs",
    section: headerDict.refund,
    text: "What is the procedure for claiming No Show taxes?",
    link: `${HOME_URL}/faqs?section=${refund}#refundNoShowTaxes`,
    tags: ["taxes", "tax", "show"],
  },
  // refund----------------------------------------------------------------------------------------

  // travel Certificate ----------------------------------------------------------------------------
  {
    page: "faqs",
    section: headerDict.travelCertificate,
    text: "What is a Travel Certificate?",
    link: `${HOME_URL}/faqs?section=${travelCertificate}#travelCertificate`,
    tags: ["travel", "certificate"],
  },
  {
    page: "faqs",
    section: headerDict.travelCertificate,
    text: "Can a Travel Certificate help us with reimbursement, GST and LTC claims?",
    link: `${HOME_URL}/faqs?section=${travelCertificate}#reimbursement`,
    tags: ["certificate", "reimbursement", "LTC", "GST"],
  },
  {
    page: "faqs",
    section: headerDict.travelCertificate,
    text: "How can I retrieve and make the payment for a travel certificate?",
    link: `${HOME_URL}/faqs?section=${travelCertificate}#travelCertificatePayment`,
    tags: ["certificate", "retrieve", "payment"],
  },
  // travel Certificate ----------------------------------------------------------------------------
  // medical assistance ----------------------------------------------------------------------------

  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "Until when are Expectant Mothers permitted to fly?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#expectantMothers`,
    tags: ["expected", "pregnant", "mother", "expectant"],
  },
  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "Do I need to inform flybig if I have any medical condition?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#informMedCondition`,
    tags: [
      "medical",
      "assistance",
      "special",
      "assistance",
      "special-assistance",
    ],
  },
  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "Will the cabin crew be able to provide me with any medical assistance?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#crewMedAssistance`,
    tags: [
      "medical",
      "assistance",
      "special",
      "assistance",
      "special-assistance",
    ],
  },
  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "What rules apply for passengers carrying their own wheelchair?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#carryWheelChair`,
    tags: ["medical", "wheelchair", "assistance"],
  },
  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "How can I opt for wheelchair assistance at the airport?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#wheelChairAssistance`,
    tags: ["medical", "wheelchair", "assistance"],
  },
  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "What rules apply if I want to carry a Portable Oxygen Concentrator (“POC”)?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#carryOxygen`,
    tags: ["Oxygen", "Portable", "Oxygen", "Medical", "portableoxygen"],
  },
  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "Can I carry crutches, braces and or other prosthetic devices on-board?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#carryDevices`,
    tags: ["Crutche", "Brace", "Prosthetic"],
  },
  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "Can seats be assigned to passengers who are differently abled?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#differentlyAbled`,
    tags: [
      "Specially",
      "Abled",
      "Disabled",
      "Differently",
      "abled",
      "speciallyabled",
      "differentiallyabled",
    ],
  },
  {
    page: "faqs",
    section: headerDict.medicalAssistance,
    text: "Are differently abled passengers offered assistance by crew to use lavatory?",
    link: `${HOME_URL}/faqs?section=${medicalAssistance}#differentlyAbledAssistance`,
    tags: ["Lavatory", "Assistance", "Medical"],
  },
  // medical assistance ----------------------------------------------------------------------------
  // flight delay and cancellations ----------------------------------------------------------------
  {
    page: "faqs",
    section: headerDict.flightDelayAndCancellations,
    text: "Will I be contacted in advance if my flight is delayed, preponed or cancelled?",
    link: `${HOME_URL}/faqs?section=${flightDelayAndCancellations}#FDCNotice`,
    tags: ["delay", "delayed", "cancelled", "preponed", "contact", "advance"],
  },
  {
    page: "faqs",
    section: headerDict.flightDelayAndCancellations,
    text: "What is flybig's policy if a flight is cancelled, delayed or there is planned/unplanned schedule change?",
    link: `${HOME_URL}/faqs?section=${flightDelayAndCancellations}#FDCPolicy`,
    tags: [
      "cancel",
      "delay",
      "delayed",
      "policy",
      "planned",
      "unplanned",
      "change",
    ],
  },
  {
    page: "faqs",
    section: headerDict.flightDelayAndCancellations,
    text: "Will flybig provide any additional benefits to passengers in case their flight is cancelled or rescheduled?",
    link: `${HOME_URL}/faqs?section=${flightDelayAndCancellations}#FDCBenefits`,
    tags: ["cancelled", "rescheduled", "benefits", "additional"],
  },
  // flight delay and cancellations ----------------------------------------------------------------
  // checkin ---------------------------------------------------------------------------------------
  {
    page: "faqs",
    section: headerDict.checkin,
    text: "How do I check-in at the airport?",
    link: `${HOME_URL}/faqs?section=${checkin}#checkin`,
    tags: ["check-in", "checkin", "airport"],
  },
  {
    page: "faqs",
    section: headerDict.checkin,
    text: "What is Web Check-in?",
    link: `${HOME_URL}/faqs?section=${checkin}#webcheckin`,
    tags: ["check-in", "checkin", "web", "check-in", "webcheckin"],
  },
  {
    page: "faqs",
    section: headerDict.checkin,
    text: "What is Kiosk Check-in??",
    link: `${HOME_URL}/faqs?section=${checkin}#kioskcheckin`,
    tags: ["check-in", "kiosk", "check-in", "checkin"],
  },
  // checkin ---------------------------------------------------------------------------------------

  //------------------------------------------------------------------------------
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.booking,
    text: "Can I pre-book my seat?",
    link: `${HOME_URL}/travelmandate?section=${covidBooking}#preBookSeat`,
    tags: ["seat", "prebook", "checkin", "webcheckin", "web-checkin"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.booking,
    text: "What is the permissible limit to carry liquids on the flight?",
    link: `${HOME_URL}/travelmandate?section=${covidBooking}#liquidLimit`,
    tags: ["liquid", "hand", "sanitizer", "bottle"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.booking,
    text: "What is the cabin and check-in baggage allowance for the flight?",
    link: `${HOME_URL}/travelmandate?section=${covidBooking}#baggageAllowance`,
    tags: ["baggage", "cabin", "check-in", "bag"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.booking,
    text: "What is the reporting time for the flights?",
    link: `${HOME_URL}/travelmandate?section=${covidBooking}#reportingTime`,
    tags: ["reporting", "departure", "boarding", "time"],
  },
  //------------------------------------------------------------------------------
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.checkin,
    text: "Is web check-in mandatory?",
    link: `${HOME_URL}/travelmandate?section=${covidCheckin}#mandatoryCheckin`,
    tags: ["webcheckin", "checkin", "check-in", "departure"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.checkin,
    text: "Do I have to check-in my baggage online? How will I receive the check-in baggage receipt?",
    link: `${HOME_URL}/travelmandate?section=${covidCheckin}#baggageReceipt`,
    tags: ["webcheckin", "checkin", "check-in", "online", "receipt", "baggage"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.checkin,
    text: "How do I put my baggage tag on my check-in baggage?",
    link: `${HOME_URL}/travelmandate?section=${covidCheckin}#baggageTag`,
    tags: ["baggage", "tag", "webcheckin", "checkin", "check-in"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.checkin,
    text: "Will I get an e-boarding pass?",
    link: `${HOME_URL}/travelmandate?section=${covidCheckin}#eBoardingPass`,
    tags: ["e-boarding", "pass", "webcheckin", "checkin", "declaration"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.checkin,
    text: "What is the check-in process at the airport?",
    link: `${HOME_URL}/travelmandate?section=${covidCheckin}#checkinProcess`,
    tags: ["check-in", "check", "webcheckin", "checkin", "covid"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.checkin,
    text: "Is it mandatory to register on the Aarogya Setu app?",
    link: `${HOME_URL}/travelmandate?section=${covidCheckin}#aarogyaRegister`,
    tags: ["Aarogya", "guidelines", "covid", "health", "status"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.checkin,
    text: "What is the closure time for check-in and boarding the flight?",
    link: `${HOME_URL}/travelmandate?section=${covidCheckin}#closureTime`,
    tags: ["check-in", "boarding", "checkin", "web-checkin", "contactless"],
  },
  //------------------------------------------------------------------------------
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.inflight,
    text: "Do I need to wear a mask all through my journey?",
    link: `${HOME_URL}/travelmandate?section=${covidInflight}#wearMask`,
    tags: ["mask", "covid", "guidelines"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.inflight,
    text: "Will food and beverages be served on the flight?",
    link: `${HOME_URL}/travelmandate?section=${covidInflight}#foodBeverage`,
    tags: ["food", "beverage", "meal"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.inflight,
    text: "What to do if a traveller starts feeling sick in-flight?",
    link: `${HOME_URL}/travelmandate?section=${covidInflight}#sickInFlight`,
    tags: ["covid", "safety", "precaution"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.inflight,
    text: "What precaution do I need to take in-flight while travelling?",
    link: `${HOME_URL}/travelmandate?section=${covidInflight}#flightPrecaution`,
    tags: ["covid", "safety", "precaution"],
  },
  //------------------------------------------------------------------------------
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.others,
    text: "Is flybig taking any precautions to sanitise their aircraft?",
    link: `${HOME_URL}/travelmandate?section=${covidOthers}#SanitiseAircraft`,
    tags: ["covid", "safety", "precaution"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.others,
    text: "What are the quarantine rules for the destination state?",
    link: `${HOME_URL}/travelmandate?section=${covidOthers}#quarantineState`,
    tags: ["statewise", "quarantine", "guidelines", "regulation"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.others,
    text: "What if I am not allowed to fly due to high temperature recorded during thermal screening?",
    link: `${HOME_URL}/travelmandate?section=${covidOthers}#highTemperature`,
    tags: ["thermal", "screening", "temperature", "covid", "detect"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.others,
    text: "How can I declare I am fit for air travel?",
    link: `${HOME_URL}/travelmandate?section=${covidOthers}#travelFitness`,
    tags: ["declaration", "health", "aarogya", "fit"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.others,
    text: "Am I allowed to fly if I am from a containment zone?",
    link: `${HOME_URL}/travelmandate?section=${covidOthers}#containmentZone`,
    tags: ["containment", "travel", "covid"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.others,
    text: "Will flybig sanitise the luggage while loading and off-loading?",
    link: `${HOME_URL}/travelmandate?section=${covidOthers}#sanitiseLuggage`,
    tags: ["sanitise", "luggage", "health", "preventive", "measure"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.others,
    text: "What is social distancing? Why is it important?",
    link: `${HOME_URL}/travelmandate?section=${covidOthers}#socialDistancing`,
    tags: ["social", "distancing", "WHO", "Covid"],
  },
  {
    page: "TRAVEL MANDATE",
    section: headerDict.covid.others,
    text: "Are temperature checks in place at Airports?",
    link: `${HOME_URL}/travelmandate?section=${covidOthers}#tempearatureChecks`,
    tags: ["thermal", "screening", "temperature", "covid"],
  },
  //------------------------------------------------------------------------------
];

export default ContentDictionary;
