/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Image,
}
  from 'react-bootstrap';
import { Link } from 'react-router-dom'
import './Career.scss';

import Pilot from './Items/Pilot';
import CabinCrew from './Items/CabinCrew';
import GroundStaff from './Items/GroundStaff';
import Engineering from './Items/Engineering';
import Corporate from './Items/Corporate';
import Others from './Items/Others';
import { useLocation } from 'react-router';
import { HOME_URL } from '../../config/config';

const PILOT = 'PILOT';
const CABINCREW = 'CABIN CREW';
const GROUNDSTAFF = 'GROUND STAFF';
const ENGINEERING = 'ENGINEERING';
const CORPORATE = 'CORPORATE & COMMERCIAL';
const OTHERS = 'OTHERS';

export default function Career() {
  const [activeBtn, setActiveBtn] = useState(null);

  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath == '/careers/latest') {
      setActiveBtn(OTHERS);
    } else if (currentPath == '/careers/pilot') {
      setActiveBtn(PILOT);
    } else if (currentPath == '/careers/cabin_crew') {
      setActiveBtn(CABINCREW);
    } else if (currentPath == '/careers/ground-staff') {
      setActiveBtn(GROUNDSTAFF);
    }
  }, [])

  return (

    <div className="career">
      <Row className="header" style={{ marginBottom: '15px' }}>
        <Col xs={12} md={10} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Image src="/career/career.png" roundedCircle />
          <div>
            <div className="title">CAREERS</div>
            <div>
              {/* To join us and become a part of the flybig family contact us at */}
              We are continuously in search of fresh talents; However please be cautioned that certain people claiming to represent Flybig, by misusing the brand name "Flybig" and the names of our employees are demanding money in exchange for interviews or jobs with Flybig. Report any such fraudulent incident to <a href="mailto:hr@flybig.in">hr@flybig.in</a>
            </div>
          </div>
        </Col>
        <Col xs={12} md={10} className='py-2'>
          <a className='fw-bold text-decoration-underline' href={`${HOME_URL}/attention-job-seekers`}>ATTENTION JOB SEEKERS</a>
        </Col>
      </Row>
      <Row className="header">
        <Col xs={10}>
          <span className="title" style={{}}>JOBS AT FLYBIG</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5}>
          <Button onClick={() => setActiveBtn(activeBtn === PILOT ? '' : PILOT)}>
            <Image src="/career/pilot.png" roundedCircle />
            {PILOT}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Button onClick={() => setActiveBtn(activeBtn === CABINCREW ? '' : CABINCREW)}>
            <Image src="/career/cabincrew.png" roundedCircle />
            {CABINCREW}
          </Button>
        </Col>
        <Col xs={12} md={10}>
          {activeBtn === PILOT && <Pilot />}
          {activeBtn === CABINCREW && <CabinCrew />}
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={5}>
          <Button onClick={() => setActiveBtn(activeBtn === GROUNDSTAFF ? '' : GROUNDSTAFF)}>
            <Image src="/career/groundstaff.png" roundedCircle />
            {GROUNDSTAFF}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Button onClick={() => setActiveBtn(activeBtn === ENGINEERING ? '' : ENGINEERING)}>
            <Image src="/career/engineering.png" roundedCircle />
            {ENGINEERING}
          </Button>
        </Col>
        <Col xs={12} md={10}>
          {activeBtn === GROUNDSTAFF && <GroundStaff />}
          {activeBtn === ENGINEERING && <Engineering />}
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={5}>
          <Button onClick={() => setActiveBtn(activeBtn === CORPORATE ? '' : CORPORATE)}>
            <Image src="/career/corporate.png" roundedCircle />
            {CORPORATE}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          <Button onClick={() => setActiveBtn(activeBtn === OTHERS ? '' : OTHERS)}>
            <Image src="/career/others.png" roundedCircle />
            {OTHERS}
          </Button>
        </Col>
        <Col xs={12} md={10}>
          {activeBtn === CORPORATE && <Corporate />}
          {activeBtn === OTHERS && <Others />}
        </Col>
      </Row>
    </div>
  );
}

/* eslint-disable */
