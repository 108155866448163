import React, { useContext } from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import { __RouterContext } from 'react-router';
import { useTransition, animated, config } from 'react-spring';
import Contact from '../routes/Contact/Contact';
import PartnerLogin from '../routes/PartnerLogin/PartnerLogin';
import AboutUs from '../routes/AboutUs/AboutUs';
import Book from '../routes/Book/Book';
import FAQ from '../routes/FAQ/FAQ';
import WeatherAdvisory from '../routes/WeatherAdvisory/WeatherAdvisory'
import COC from '../routes/COC/COC';
import Search from '../routes/Search/Search';
import SpecialAssistance from '../routes/SpecialAssistance/SpecialAssistance';
import Career from '../routes/Career/Career';
import TravelMandate from '../routes/TravelMandate/TravelMandate';
import GroupBooking from '../routes/GroupBooking/GroupBooking';
import DelhiShillong from '../routes/TravelRoutes/DelhiShillong';
import Leadership from '../routes/Leadership/Leadership';
import PrivacyPolicy from '../routes/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from '../routes/TermsConditions/TermsConditions';

import Header from './Header';
import Links from './Links';

import './Main.scss';
import FadeIn from './Animations/Animator';
import Covid from '../routes/Covid/Covid';
import AttentionJobSeekers from '../routes/AttentionJobSeekers/AttentionJobSeekers';

export default function Main() {
  const { location } = useContext(__RouterContext);
  const transitions = useTransition(location, (loc) => loc.pathname, {
    config: config.slow,
  });

  const iframeMode = location.pathname.toLowerCase().includes('headerframe') || location.pathname.toLowerCase().includes('footerframe');
  return (
    <FadeIn>
      <main className="main" style={iframeMode ? { backgroundColor: 'white' } : {}}>
        {
          transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={props}>
              <Switch location={item}>
                <Route path="/book" component={Book} />
                <Route path="/contact" component={Contact} />
                <Route path="/register" component={PartnerLogin} />
                <Route path="/about" component={AboutUs} />
                <Route path="/faqs" component={FAQ} />
                <Route path="/weather-advisory" component={WeatherAdvisory} />
                <Route path="/assistance" component={SpecialAssistance} />
                <Route path="/coc" component={COC} />
                <Route path="/information/conditions-of-carriage.html" component={COC} />
                <Route path="/attention-job-seekers" component={AttentionJobSeekers} />
                <Route path="/careers" component={Career} />
                <Route path="/headerframe" component={Header} />
                <Route path="/footerframe" component={Links} />
                <Route path="/search" component={Search} />
                <Route path="/travel-mandate" component={TravelMandate} />
                <Route path="/groupbookingreservation" component={GroupBooking} />
                <Route path="/delhi-shillong" component={DelhiShillong} />
                <Route path="/leadershipteam" component={Leadership} />
                <Route path="/covid" component={Covid} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-and-conditions" component={TermsConditions} />
                <Redirect exact from="/" to="book" />
              </Switch>
            </animated.div>
          ))
        }
      </main>
    </FadeIn>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
