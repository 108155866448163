/* eslint-disable max-len */
import React from 'react';
import './Item.scss';
// import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';

export default function Pilot() {
  return (
    <div className="item">
      <div className="cellheader cardItemHeader">
        PILOT
      </div>
      <hr />

      {/* <Accordion defaultActiveKey="0"> */}
      <CardHolder header="Pilots (Expat & Indians) for Twin Otter DHC-6 (400)" eventKey="0">

        <h2>Hiring for Pilots:</h2>
        <p> Inviting resume from suitable Pilots on Type rated and Open rated for Twin Otter (DHC 6) or Similar aircraft:</p>
        <h3 className='text-left mb-0'>Expat Pilots requirement:</h3>
        <div style={{ padding: '10px' }}>
          <ul>
            <li>Minimum flying experience - 1500 hrs.</li>
            <li>Minimum flying experience on type/variant as PIC - 300 hrs on multi engine.</li>
            <li>PIC Flying experience in last 90 days - 10 hours including 05 take off and landings only on DHC 6</li>
            <li>Total instrument flying experience - 250 hours</li>
            <li>Valid ATPL / CPL</li>
            <li>Valid Endorsement of DHC 6 (400) (land)</li>
            <li>Valid and endorsed IR/PPC DHC 6 (400) on License</li>
            <li>ELP - Level 4 and above endorsed on License</li>
            <li>FRTOL Valid</li>
            <li>Valid Class 1 Medical</li>
            <li>No accident and incident/BA in last 5/3 years.</li>
          </ul>
        </div>

        <h3 className='text-left mb-0'> Indian Pilots Requirement:</h3>
        <div style={{ padding: '10px' }}>
          <ul>
            <li>Minimum flying experience - 1500 hrs.</li>
            <li>Minimum PIC flying experience  - 500 hrs</li>
            <li>Total instrument flying experience - 250 hours</li>
            <li>Valid ATPL / CPL</li>
            <li>ELP - Level 4 and above endorsed on License.</li>
            <li>FRTOL Valid</li>
            <li>Valid Class 1 Medical</li>
            <li>No accident and incident/BA in last 5/3 years.</li>
          </ul>
        </div>

        <p>Note: Preference will be given to open rated, and type rated candidates. Please mention the post you are applying for in the mail subject.</p>
        <p>Suitable Pilots may kindly share their resume urgently at <a href="mailto:pilot@flybig.in">pilot@flybig.in</a> or <a href="mailto:hr@flybig.in">hr@flybig.in</a></p>

      </CardHolder>

      <CardHolder header="Indian Captains (Type Rated on ATR 72)" eventKey="1">
        <div style={{ padding: '10px' }}>
          <ul>
            <li>Hold current and valid Indian ATPL, FRTOL, RTR and medical.</li>
            <li>Hold English language proficiency certificate at a minimum ICAO standard Level 4.</li>
            <li>Minimum 2000 Hours total flying experience.</li>
            <li>Candidate possess accident/incident free record.</li>
          </ul>
        </div>
      </CardHolder>

      <CardHolder header="​Indian Captains (Non Type Rated)" eventKey="2">
        <div style={{ padding: '10px' }}>
          <ul>
            <li>Hold current and valid Indian ATPL, FRTO, RTR and medical.</li>
            <li>Hold English language proficiency certificate at a minimum ICAO standard Level 4.</li>
            <li>Minimum 2000 Hours total flying experience including 500 hours of PIC and 300 hours of commercial flying experience.</li>
            <li>Candidate possess accident/incident free record.</li>
          </ul>
        </div>
      </CardHolder>

      <CardHolder header="​Indian Captains (Transition)" eventKey="3">
        <div style={{ padding: '10px' }}>
          <ul>
            <li>Hold current and valid Indian ATPL, FRTO, RTR and medical.</li>
            <li>Hold English language proficiency certificate at a minimum ICAO standard Level 4.</li>
            <br />
            <li>
              Minimum 2500 hrs total flying experience including 500 hours of PIC.
            </li>
            <p>
              Or
            </p>
            <li>
              Total 2000 hrs flying experience including 200 hrs of PIC with minimum 1500 hrs turbojet experience.
            </li>
            <p>
              Or
            </p>
            <li>
              Total 2000 hrs flying experience including 500 hrs of PIC with minimum 1500 hrs turbojet experience.
            </li>
          </ul>
        </div>

      </CardHolder>

      <CardHolder header="Expat Captains" eventKey="4">
        <div style={{ padding: '10px' }}>
          <ul>
            <li>Minimum flying experience - 2000Hrs.</li>
            <li>Minimum PIC flying experience– 1000 Hrs.</li>
            <li>Minimum PIC on type/variant - 100 hrs with current IR/LR check/ PPC (Pilot’s Proficiency Checks).</li>
            <li>Recent experience - at least three takes-off and landings within the preceding 90 days on the type of aircraft applied for, or in a flight simulator approved for the purpose.</li>
          </ul>
        </div>
      </CardHolder>

      <CardHolder header="​First Officer" eventKey="5">
        <div style={{ padding: '10px' }}>
          <ul>
            <li>The minimum age 21 yearsShould be Indian national.</li>
            <li>Hold current and valid Indian CPL / ATPL, FRTO, RTR and medical.</li>
            <li>Hold English language proficiency certificate at a minimum ICAO standard Level 4.</li>
            <li>Candidate possess accident/incident free record.</li>
          </ul>
        </div>
      </CardHolder>
    </div>
  );
}
