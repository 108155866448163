/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function TravellingWithInfant() {
  return (
    <div className="saitem">
      <div className="header">
        <p>
          Travelling with Infant
        </p>
      </div>

      <p>
        At flybig we are committed to making our youngest guests travel experience enjoyable and comfortable.
        Please see details below.
      </p>
      <p>
        <strong>Age:</strong>
        {' '}
        Children above the age of seven (7) days, upto two (2) years are considered infants and need to accompanied by an adult at all times throughout thier journey. Before you come to the airport, please carry a valid proof of age for the infant. Some of the documents that we accept as proof of age include passport, birth certificate etc.
      </p>
      <p>
        <strong>Seating:</strong>
        {' '}
        No extra seats may be booked for infants as they travel in a parents lap.
      </p>
      <p>
        <strong>Number:</strong>
        {' '}
        Only one (1) infant per adult is permitted.
      </p>
      <p>
        <strong>Fee:</strong>
        For infants not occupying a seat, a fee, as mentioned in fees and charges, per sector is applicable, such fees is inclusive of all applicable taxes including, but not limited to GST, fees and surcharges.
      </p>
      <p>
        <strong>Baggage allowance:</strong>
        We permit a hand baggage allowance of up to seven (7) kgs for every infant travelling with us.
      </p>
    </div>
  );
}
