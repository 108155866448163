/* eslint-disable max-len */
import React from 'react';
import { PHONE_NUMBER } from '../../../constants';
import './SAItem.scss';

export default function MobiltyAssistance() {
  return (
    <div className="faqitem">
      {/* <div className="header">
        <p>
          Mobility Assistance
        </p>
      </div> */}
      <p>flybig would be more than happy to provide a wheelchair as per the guests requirement. We request you to book a wheelchair at least forty eight (48) hours prior to departure. In order to ensure that you have a hassle free experience, we recommend that you reach the airport at least two (2) hours prior to departure and inform our reservation team. Our airport (check/reservation) staff may ask you some questions to determine the level of assistance required.</p>
      <p>We have wheelchairs available for use at all airport locations to assist with transportation from check-in to the boarding gate, while making connections (if applicable) and from the aircraft to the arrivals hall at the destination.</p>
      <p>flybig wheelchair service is provided with an attendant (for pushing the wheelchair) to assist in transporting you from the reservation counter till  check-in/special assistance counter and from  check-in counter till the gate or the aircraft door.</p>
      <p>
        Guests travelling in a group of ten (10) or more and requiring wheelchairs, may contact us by calling us at our call centre @
        {` ${PHONE_NUMBER} `}
        at least forty eight (48) hours before scheduled departure.
      </p>
      <strong>If you are carrying your own wheelchair, please note the following:</strong>
      <ul>
        <li>If guests wish to check-in their own wheel chair, our ground team member will provide assistance to dismantle and/or reassemble the wheelchair under your supervision. However, flybig shall not be liable for any damage arising while handling the wheelchair. We will return your wheelchair at the destination airport</li>
        <li>If you so wish to use your own wheelchair at the airport, do let us know. We will take your wheelchair at the aircraft. This is also basis the security procedure of the airport.</li>
        <li>In the event of loss or damage of wheelchairs, mobility equipment or assistive devices while being handled at the airport or transported on board aircraft will be compensated by the airline, airport operator, the relevant ground handling agency or organization which is responsible for such loss.</li>
        <li>Electronic wheelchairs will be dismantled at our check-in counters. The guest will need to carry a battery terminal cap or an insulated box to insulate and seal the battery terminals to avoid damage.</li>
        <li>Acceptance of power driven wheelchairs/assistive devices using batteries shall be subject to applicable regulations governing carriage of dangerous goods. You may call our call centre for additional clarifications.</li>
        <li>If your wheelchair has been stowed in the hold, we will make every effort to return it to you as close to the aircraft door as possible. If this is not possible, we will make sure that there is a wheelchair available to assist you to the baggage reclaim area.</li>
      </ul>

      <strong>Our cabin crew members are trained in providing first aid assistance and the following assistance to you</strong>
      <ul>
        <li>An individual safety briefing to any guest who may need the assistance of another person to move expeditiously to an exit in the event of an emergency</li>
        <li>An Individual safety briefing to any guest whose disability prevents them from comprehending the safety information presented during our safety briefing.</li>
        <li>Assistance with stowing and retrieving carry-on and assistive devices.</li>
        <li>Assistance with opening food and drink items.</li>
      </ul>
      <strong>
        Our crew would not be able to assist you with the following:
      </strong>
      <ul>
        <li>Administration of medication</li>
        <li>Assistance in actual eating or drinking</li>
        <li>Assistance within the restroom</li>
      </ul>
    </div>
  );
}
