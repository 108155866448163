import React from 'react'

const AttentionJobSeekers = () => {
    return (
        <div className='container'>
            <div className="card">
                <div className="card-header fw-bold">ATTENTION JOB SEEKERS</div>
                <div className="card-body">
                    <p>We would like to bring to your attention a matter of utmost importance regarding job placements at flybig and its parent company, Big Charter Pvt Ltd, along with its sister concerns. It has come to our notice that certain individuals or groups may be misleading job seekers by soliciting financial considerations or requesting money for job placements within flybig or its sister concerns.</p>
                    <p>We categorically state that flybig, Big Charter Pvt Ltd, and its sister concerns do not engage in any form of financial transactions or ask for money from candidates in exchange for job placement. We have a strict policy against such practices and are committed to maintaining transparency and fairness in our recruitment processes.</p>
                    <p>We urge all prospective candidates to be cautious and vigilant while seeking employment opportunities. If you come across any advertisements, personal communications, or assurances that ask for financial or other favors in return for job placement at flybig or its affiliated companies, we strongly advise you to report such instances immediately to <a href="mailto:hr@flybig.in">hr@flybig.in</a>. The flybig intends to take appropriate legal actions against any individual or agency found to be involved in soliciting money from candidates and or offering job opportunities on our behalf whether verbal or in writing.</p>
                    <p>flybig and its parent company, Big Charter Pvt Ltd, are committed to providing equal employment opportunities based on merit and qualifications. We value the skills and talents of individuals and ensure a fair and unbiased selection process for all applicants. We do not endorse or support any unauthorized individuals or organizations claiming to offer job placements on our behalf.</p>
                </div>
            </div>
        </div>
    )
}

export default AttentionJobSeekers