/* eslint-disable react/prop-types */
import React from 'react';
import './Search.scss';
import {
  Row, Col,
} from 'react-bootstrap';
import { FaRegFrownOpen as FaFrownOpen } from 'react-icons/fa';
import queryString from 'query-string';
import ContentDictionary from './ContentDictionary';

export default function Search({ location }) {
  const parsed = queryString.parse(location.search);
  let searchParam = '';

  if (parsed && parsed.searchText) {
    searchParam = parsed.searchText.toLowerCase();
  }

  const items = ContentDictionary.filter(
    (item) => {
      const ret = item.tags.filter((tag) => searchParam.includes(tag.toLowerCase())).length > 0;
      return ret;
    },
  );

  const display = {};

  items.forEach((item) => {
    if (!display[item.page]) {
      display[item.page] = {};
    }
    if (!display[item.page][item.section]) {
      display[item.page][item.section] = [];
    }
    display[item.page][item.section].push({ text: item.text, link: item.link });
  });


  return (
    <Row className="justify-content-md-center search">
      <Col xs={10} style={{ fontSize: '22px', marginBottom: '20px' }}>
        Results for
        {' '}
        {`"${searchParam}"`}
      </Col>
      <Col xs={10}>
        {
          Object.keys(display).map((page) => Object.keys(display[page]).map((section) => (
            <div className="section">
              <div className="header">
                {page}
                {' '}
                {'>'}
                {' '}
                {section}
              </div>
              {
            display[page][section].map((item) => (
              <div className="link">
                <a href={item.link}>{item.text}</a>
              </div>
            ))
          }
            </div>
          )))
}
      </Col>
      <Col xs={10}>
        {
        Object.keys(display).length <= 0 && (

          <div className="message">
            <div style={{ fontSize: '40px' }}><FaFrownOpen /></div>
            <div>No matches found</div>
          </div>
        )
      }
      </Col>
    </Row>
  );
}
