/* eslint-disable max-len */
import React from 'react';
import { PHONE_NUMBER } from '../../../constants';
import './SAItem.scss';

export default function CognitiveChallenges() {
  return (
    <div className="saitem">
      <p>
        If you have cognitive challenges, flybig’s awareness of the assistance needed is very important. These disabilities are not always obvious and each guest's situation is unique. Please let us know about the assistance that you would require, at the time of making your reservation, at the airport and during the flight for ensuring that the correct assistance is provided.
      </p>
      <ul>
        <li>
          Guests with Autism/Intellectual disability/Dementia/Alzheimer's and Down syndrome need to book their flights by calling us at our call centre @
          {` ${PHONE_NUMBER}`}
          .
        </li>
        <li>While making your reservation, please advise us about your specific requirements and the best way we can assist you.</li>
      </ul>
    </div>
  );
}
