import React, { useState } from 'react';
import {
  Button, Col, Image, Row,
} from 'react-bootstrap';
import CardHolder from '../../components/CardHolder';
import AdverseMedicalConditions from './SAItems/AdverseMedicalConditions';
import AssistiveDevice from './SAItems/AssistiveDevice';
import BloodDisorder from './SAItems/BloodDisorder';
import CardiovascularDisorders from './SAItems/CardiovascularDisorders';
import CentralNervousSystem from './SAItems/CentralNervousSystem';
import CognitiveChallenges from './SAItems/CognitiveChallenges';
import CommunicableDisease from './SAItems/CommunicableDisease';
import DisabilityAssistance from './SAItems/DisabilityAssistance';
import EmotionalDisorders from './SAItems/EmotionalDisorders';
import ENTDisorder from './SAItems/ENTDisorder';
import ExpectingMother from './SAItems/ExpectingMother';
import EyeDisorders from './SAItems/EyeDisorders';
import GastroIntestinal from './SAItems/GastroIntestinal';
import InternalMedicalDevices from './SAItems/InternalMedicalDevices';
import MobiltyAssistance from './SAItems/MobiltyAssistance';
import POC from './SAItems/POC';
import RespiratoryDisorders from './SAItems/RespiratoryDisorders';
import SafetyAssistant from './SAItems/SafetyAssistant';
import SensoryImpairments from './SAItems/SensoryImpairments';
import ServiceDog from './SAItems/ServiceDog';
import SpinalSupportEquipment from './SAItems/SpinalSupportEquipment';
import Stretchers from './SAItems/Stretchers';
import Trauma from './SAItems/Trauma';
import TravellingWithInfant from './SAItems/TravellingWithInfant';
import UnaccompaniedMinor from './SAItems/UnaccompaniedMinor';
import './SpecialAssistance.scss';

const travellingWithInfant = 'Travelling with Infant';
const unaccompaniedMinor = 'Unaccompanied Minor - "Flying Solo Service"';
const expectingMother = 'Expecting Mother';

// const sse = 'Spinal Support Equipment (SSE)';
// const emotionalDisorders = 'Guest with Psychiatric or Emotional Disorders';
// const cognitiveChallenges = 'Guest with Cognitive challenges/Dementia/Alzheimer/Autism and Down Syndrome';
// const internalMedicalDevices = 'Internal Medical Devices';
// const portableOxygen = 'Portable Oxygen Concentrator';
// const adverseMedicalConditions = 'Guests with certain adverse medical conditions';
// const stretchers = 'Guests requiring Stretchers';
// const cardiovascular = 'Cardiovascular and other circulatory disorders';
// const bloodDisorder = 'Blood Disorder';
// const communicable = 'Communicable/ infectious disease';
// const ent = 'ENT Disorder (Ear, Nose and throat)';
// const eyeDisorders = 'Eye Disorders';
// const respiratoryDisorders = 'Respiratory Disorders';
// const centralNervousSystem = 'Central Nervous System';
// const gastro = 'Gastro – Intestinal';
// const trauma = 'Trauma';

const disabilityItems = [
  {
    header: 'Disability Assistance',
    id: 'disabilityAssistance',
    component: (<DisabilityAssistance />),
  },
  {
    header: 'Requirement of travelling with a Safety Assistant',
    id: 'safetyAssistant',
    component: (<SafetyAssistant />),
  },
  {
    header: 'For our guests with Sensory Impairments',
    id: 'sensoryImpairments',
    component: (<SensoryImpairments />),
  },
  {
    header: 'Mobility Assistance',
    id: 'mobilityAssistance',
    component: (<MobiltyAssistance />),
  },
  {
    header: 'Assistive Device',
    id: 'assistiveDevice',
    component: (<AssistiveDevice />),
  },
  {
    header: 'Service Dog',
    id: 'serviceDog',
    component: (<ServiceDog />),
  },
  {
    header: 'Spinal Support Equipment (SSE)',
    id: 'spinalSupportEquipment',
    component: (<SpinalSupportEquipment />),
  },
  {
    header: 'Guest with Psychiatric or Emotional Disorders',
    id: 'emotionalDisorders',
    component: (<EmotionalDisorders />),
  },
  {
    header: 'Guest with Cognitive challenges/Dementia/Alzheimer/Autism and Down Syndrome',
    id: 'cognitiveChallenges',
    component: (<CognitiveChallenges />),
  },
  {
    header: 'Internal Medical Devices',
    id: 'internalMedicalDevices',
    component: (<InternalMedicalDevices />),
  },
  {
    header: 'Portable Oxygen Concentrator',
    id: 'POC',
    component: (<POC />),
  },
  {
    header: 'Guests with certain adverse medical conditions',
    id: 'AdverseMedicalConditions',
    component: (<AdverseMedicalConditions />),
  },
  {
    header: 'Guests requiring Stretchers',
    id: 'Stretchers',
    component: (<Stretchers />),
  },
  {
    header: 'Cardiovascular and other circulatory disorders',
    id: 'CardiovascularDisorders',
    component: (<CardiovascularDisorders />),
  },
  {
    header: 'Blood Disorder',
    id: 'BloodDisorder',
    component: (<BloodDisorder />),
  },
  {
    header: 'Communicable/ infectious disease',
    id: 'CommunicableDisease',
    component: (<CommunicableDisease />),
  },
  {
    header: 'ENT Disorder (Ear, Nose and throat)',
    id: 'ENTDisorder',
    component: (<ENTDisorder />),
  },
  {
    header: 'Eye Disorders',
    id: 'EyeDisorders',
    component: (<EyeDisorders />),
  },
  {
    header: 'Respiratory Disorders',
    id: 'RespiratoryDisorders',
    component: (<RespiratoryDisorders />),
  },
  {
    header: 'Central Nervous System',
    id: 'CentralNervousSystem',
    component: (<CentralNervousSystem />),
  },
  {
    header: 'Gastro – Intestinal',
    id: 'GastroIntestinal',
    component: (<GastroIntestinal />),
  },
  {
    header: 'Trauma',
    id: 'Trauma',
    component: (<Trauma />),
  },
];

export default function SpecialAssistance() {
  const [activeView, setActiveView] = useState('main');
  const [specialView, setSpecialView] = useState(travellingWithInfant);

  return (
    <div className="specialAssistance">
      {activeView === 'main' && (
        <Row>
          <Col xs={12} md={10}>
            <div className="headerButton">
              <Image roundedCircle src="/assistance/assistance.ico" />
              <div>SPECIAL / DISABILITY ASSISTANCE</div>
            </div>
          </Col>

          <Col xs={12} md={10}>
            <Row className="baner">
              <Col md={6} className="bannerRight bannerLeft">
                {/* <Image src="/assistance/special_assistance_top.png" /> */}
                <div className="bannerHeader">
                  <Image src="/assistance/pregnantWoman.ico" roundedCircle />
                  <div>
                    SPECIAL ASSISTANCE
                  </div>
                </div>
              </Col>
              <Col md={6} className="bannerLeft">
                <p className="bannerText">
                  At flybig our endeavor is to ensure our guests
                  requiring special assistance (Unaccompained Minor,
                  Infant and/or Expecting Mother) have a hassle-free
                  journey.
                </p>
                <Button className="bannerButton" onClick={() => setActiveView('specialAssitance')}>
                  KNOW MORE
                </Button>
              </Col>
            </Row>

            <Row className="baner">
              <Col md={6} className="bannerRight bannerLeft">
                {/* <Image src="/assistance/special_assistance_bottom.png" /> */}
                <div className="bannerHeader">
                  <Image src="/assistance/disabledPerson.ico" roundedCircle />
                  <div>
                    DISABILITY ASSISTANCE
                  </div>
                </div>
              </Col>
              <Col md={6} className="bannerLeft">
                <p className="bannerText">
                  At flybig, we care for your needs. Book a wheelchair at
                  least 48 hours prior to departure for a hassle-free
                  travel.
                </p>
                <Button className="bannerButton" onClick={() => setActiveView('disabilityAssitance')}>
                  KNOW MORE
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {activeView === 'specialAssitance' && (
        <Row>
          <Col xs={12} md={10}>
            <Row className="baner">
              <Col md={12} className="bannerLeft">
                <div className="bannerHeader">
                  <Image src="/assistance/pregnantWoman.ico" roundedCircle />
                  <div>
                    SPECIAL ASSISTANCE
                  </div>
                </div>
                <p>The following are considered as our guests requiring special assistance:</p>

                <Row className="buttonArray">
                  <Col md={6}>
                    <Button onClick={() => setSpecialView(travellingWithInfant)}>
                      {travellingWithInfant}
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button onClick={() => setSpecialView(unaccompaniedMinor)}>
                      {unaccompaniedMinor}
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button onClick={() => setSpecialView(expectingMother)}>
                      {expectingMother}
                    </Button>
                  </Col>
                </Row>

                {specialView === travellingWithInfant && <TravellingWithInfant />}
                {specialView === unaccompaniedMinor && <UnaccompaniedMinor />}
                {specialView === expectingMother && <ExpectingMother />}

                <div className="okButton">
                  <Button className="bannerButton " onClick={() => setActiveView('main')}>
                    OK
                  </Button>
                </div>

              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {activeView === 'disabilityAssitance' && (
        <Row>
          <Col xs={12} md={10}>
            <Row className="baner">
              <Col md={12} className="bannerLeft">
                <div className="bannerHeader">
                  <Image src="/assistance/disabledPerson.ico" roundedCircle />
                  <div>
                    DISABILITY ASSISTANCE
                  </div>
                </div>

                {/* <Accordion defaultActiveKey="0"> */}
                {
                  disabilityItems.map((item, index) => (
                    <CardHolder header={item.header} eventKey={index + 1}>
                      {item.component}
                    </CardHolder>
                  ))
                }
                {/* </Accordion> */}

                <div className="okButton">
                  <Button className="bannerButton" onClick={() => setActiveView('main')}>
                    OK
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

      )}
    </div>
  );
}
