/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function CommunicableDisease() {
  return (
    <div className="saitem">
      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>
        <tr>
          <td>CHICKEN POX</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT ALLOWED TO FLY FOR 14 DAYS AFTER RASHES APPEAR</td>
          <td>IF NO FRESH RASHES AND ALL RASHES HAVE DRIED UP</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>MEASLES</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT ALLOWED TO FLY FOR 7 DAYS AFTER RASHES APPEAR</td>
          <td>AFTER 7 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>MUMPS</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT ALLOWED TO FLY FOR 7 DAYS AFTER SWELLING APPEARS</td>
          <td>AFTER 7 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>RUBELLA</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT ALLOWED TO FLY FOR 7 DAYS AFTER RASHES APPEAR</td>
          <td>AFTER 7 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>H1N1/SWINE FLU</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 10 DAYS</td>
          <td>AFTER 10 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

      </table>
    </div>
  );
}
