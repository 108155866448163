import React from 'react';
import { Card, Image } from 'react-bootstrap';
import './Contact.scss';

export default function Address({ icon, header, body }) {
  return (
    <Card className="contactus">
      <Card.Header>
        <Card.Title>
          <Image
            src={icon}
            roundedCircle
            style={{
              width: '50px', height: '50px', margin: '5px', color: 'pink',
            }}
          />
          <strong>
            {header}
            :
          </strong>
        </Card.Title>
        <Card.Body style={{ marginLeft: '15px' }}>

          {body}
        </Card.Body>
      </Card.Header>

    </Card>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
