/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function InternalMedicalDevices() {
  return (
    <div className="saitem">
      <ul>
        <li>If you are travelling with an internal medical device, such as a pacemaker or a defibrillator, kindly inform the security personnel conducting your screening, before the process begins.</li>
        <li>In case you have an internally implanted brace, please carry a certificate specifying the same from your doctor.</li>
      </ul>
    </div>
  );
}
