import React from 'react';
import './FAQItem.scss';
import CardHolder from '../../../components/CardHolder';

const TwinOtter = ({ eventKey }) => {

    const data = [
        {
            id: 'TwinOtterDHC6',
            header: 'Twin-Otter (DHC 6-400) Aircraft at a glance',
            body: (
                <>

                    <div className="">
                        <h4 className='text-dark'>Operational Sectors:</h4>
                    </div>
                    <div className="">
                        <table className="flybigTable m-0" style={{ width: '90%' }}>
                            {/* <thead>
                                <tr><th colSpan={2} className='text-center ' style={{ backgroundColor: "#b0b0b0ba" }}>Operational Sectors</th></tr>
                            </thead> */}
                            <tbody>
                                <tr>
                                    <th className='text-center'>City Name</th>
                                    <th className='text-center'>IATA Code</th>
                                </tr>
                                <tr>
                                    <td className='text-center'>Hindon</td>
                                    <td className='text-center'>VDX</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>Ludhiana</td>
                                    <td className='text-center'>LUH</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>Bhatinda</td>
                                    <td className='text-center'>BUP</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>Dehradun</td>
                                    <td className='text-center'>DED</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className='mb-4'>
                        <h4 className="text-dark">1. Check-in Baggage:</h4>
                        <table className="flybigTable m-0" style={{ width: '90%' }}>
                            <tbody>
                                <tr>
                                    <th className='text-center'>Aircraft</th>
                                    <th className='text-center'>No. of piece</th>
                                    <th className='text-center'>Permissible weight</th>
                                    <th className='text-center'>Dimension</th>
                                </tr>
                                <tr>
                                    <td className='text-center'>DHC 6-400 Twin Otter</td>
                                    <td className='text-center'>1 piece only </td>
                                    <td className='text-center'>No more than 7 KG</td>
                                    <td className='text-center'>Maximum dimensions 45 inch (115cm)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='my-4'>
                        <h4 className="text-dark">2. Cabin Baggage:</h4>
                        <table className="flybigTable m-0" style={{ width: '90%' }}>
                            <tbody>
                                <tr>
                                    <th className='text-center'>Aircraft</th>
                                    <th className='text-center'>No. of piece</th>
                                    <th className='text-center'>Permissible weight</th>
                                    <th className='text-center'>Dimension</th>
                                </tr>
                                <tr>
                                    <td className='text-center'>DHC 6-400 Twin Otter</td>
                                    <td className='text-center'>1 piece only</td>
                                    <td className='text-center'>No more than 3 KG</td>
                                    <td className='text-center'>23cm (H) x 33cm (L) x 33cm (W) or 9" x 13" x 13"</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className='w-100 text-danger mt-2 mx-0'><i><b>*Note</b>- No baggage allowance for <b>“Infant”</b></i></p>
                    </div>

                    <div className='my-4'>
                        <h4 className="text-dark mb-1">3. Fare Rule:</h4>
                        <p className='p-0 mx-0'>
                            Cancellation/Date change can be done any time before 2 hours of scheduled departure.
                        </p>
                        <table className="flybigTable m-0" style={{ width: '90%' }}>
                            <tr>
                                <th className='text-center'>Applicability</th>
                                <th className='text-center'>Fare Rule</th>
                            </tr>
                            <tr>
                                <td className='text-center'>Date Change Fee (Per Pax/Per sector)</td>
                                <td className='text-center'>Anytime change – Rs. 2000/- per pax/per sector</td>
                            </tr>
                            <tr>
                                <td rowSpan={2} className='text-center'>Cancellation</td>
                                <td className='text-center'>{'Non-refundable if base fare < Rs. 3000/-'}</td>
                            </tr>
                            <tr>
                                <td className='text-center'>{'Rs. 3000 if base fare >= Rs. 3000/-'}</td>
                            </tr>
                            <tr>
                                <td className='text-center'>No-Show/Offload/Late cancellation fee</td>
                                <td className='text-center'>Full base fare forfeited </td>
                            </tr>
                            <tr>
                                <td className='text-center'>Seating </td>
                                <td className='text-center'>Free</td>
                            </tr>
                        </table>
                    </div>

                    <div className='my-4'>
                        <h4 className="text-dark">4. Ancillaries:</h4>
                        <table className="flybigTable m-0" style={{ width: '90%' }}>
                            <tbody>
                                <tr>
                                    <th className='text-center'>Applicability</th>
                                    <th className='text-center'>Price</th>
                                </tr>
                                <tr>
                                    <td className='text-center'>Excess Baggage charges per kg at airport</td>
                                    <td className='text-center'>Rs. 700/-</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>UNMR (Unaccompanied Minor) charges</td>
                                    <td className='text-center'>Rs. 3500/-</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>Special Baggage Handling fee per piece</td>
                                    <td className='text-center'>Rs. 1500/-</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className='w-100 mt-2 mx-0'><i>* all inclusive (including 18% GST)</i></p>
                    </div>

                    <div className='my-4'>
                        <h4 className="text-dark">5. Prepaid Baggage Fee:</h4>
                        <p className='p-0 mx-0'>Can be booked any-time before 2 hrs. to the departure.</p>
                        <table className="flybigTable m-0" style={{ width: '90%' }}>
                            <tbody>
                                <tr>
                                    <th className='text-center'>Slab</th>
                                    <th className='text-center'>Price</th>
                                </tr>
                                <tr>
                                    <td className='text-center'>For any additional excess baggage upto 1Kg</td>
                                    <td className='text-center'>Rs. 700</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>From 1.1kgs to 3.0kgs</td>
                                    <td className='text-center'>Rs. 2000</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>From 3.1kgs to 5.0kgs</td>
                                    <td className='text-center'>Rs. 3250</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="my-4">
                        <br />
                        <h4 className='text-dark'>IMPORTANT INFORMATION: </h4>
                        <strong>Lavatories:</strong>
                        <p>flybig DHC-6 Twin Otter aircraft DOES NOT have a lavatory on board. We recommend the Guests to use the restroom facilities before boarding the aircraft.</p>

                        <strong>Air conditioning:</strong>
                        <p>flybig DHC-6 Twin Otter is a non-pressurized aircraft (No air conditioning facility). flybig shall not be held responsible for any discomfort caused by the absence of air conditioning facilities on board.</p>
                    </div>
                </>
            ),
        }
    ];

    return (
        <div className="faqitem">
            {
                data.map((item) => (
                    <CardHolder
                        eventKey={item.id}
                        selectedEventKey={eventKey}
                        id={item.id}
                        header={item.header}
                    >
                        {item.body}
                    </CardHolder>
                ))
            }
        </div>
    )
}

export default TwinOtter;