/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function ExpectingMother() {
  return (
    <div className="faqitem">
      <table className="flybigTable">
        <tr>
          <th>
            MEDA CASES
          </th>
          {' '}
          <th>
            ABBREVIATIONS
          </th>
          {' '}
          <th>
            NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)
          </th>
          {' '}
          <th>
            PERMITTED
          </th>
          <th>
            FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )
          </th>
          <th>
            MEDIF FORM
          </th>
        </tr>
        <tr>
          <td>PREGNANCY (SINGLE AND UNCOMPLICATED)</td>
          <td>EXPCT</td>
          <td>NOT PERMITTED TO FLY BEYOND 36 WEEKS</td>
          <td>
            <p>
              0 WEEKS TO 28 WEEKS: PERMITTED TO TRAVEL.
            </p>
            <p>
              FROM BEGINNING OF 28 WEEK TILL THE END OF 36 WEEKS, A FIT TO FLY CERTIFICATE IS REQUIRED.
            </p>
          </td>
          <td>
            <p>
              YES (IF PREGNANCY IS BETWEEN 33-36 WEEKS) FROM TREATING GYNAECOLOGIST, NOT MORE
            </p>
            <p>
              THAN 7 DAYS OLD
            </p>
          </td>
          <td>
            NO
          </td>
        </tr>

        <tr>
          <td>MISCARRIAGE (THREATENED OR COMPLETE)</td>
          <td>EXPCT</td>
          <td>NOT PERMITTED TO FLY IF ACTIVE BLEEDING AND PAIN</td>
          <td>PERMITTED TO FLY ONCE STABLE, NO BLEEDING AND NO PAIN FOR AT LEAST 24 HOURS</td>
          <td>NO</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>MULTIPLE PRAGNANCY( TWIN/TRIPLET ) AND</td>
          <td>EXPCT</td>
          <td>ON INDIVIDUAL CASE BASIS, NOT PERMITTED BEYOND 32 WEEKS</td>
          <td>PERMITTED UPTO 32 WEEKS WITH FIT TO FLY FROM 28 WEEKS ONWARDS.</td>
          <td>YES, FROM TREATING GYNAECOLOGIST, NOT MORE THAN 3 DAYS OLD</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>COMPLICATED PRAGNANCIES</td>
          <td />
          <td>AND FIT TO FLY REQUIRED FROM 28 WEEKS ONWARDS</td>
          <td>COMPANY DOCTORS OPINION/APPROVAL IN CASE OF ANY DOUBT</td>
          <td>YES</td>
          <td>NO</td>
        </tr>
      </table>

    </div>
  );
}
