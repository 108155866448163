/* eslint-disable max-len */
import React from "react";
import "./Item.scss";

export default function Others() {
    return (
        <div className="item">
            <div className="cellheader cardItemHeader">OTHERS</div>
            <hr />

            <div>
                <h2 className="mb-3">Flight Dispatcher</h2>
                <h4 className="fw-bold mb-2 p-0">Qualifications</h4>
                <ul>
                    <li>DGCA Approved Flight Dispatch license, to be recurrent, preferably on ATR 72-500.</li>
                    <li>Thorough knowledge of aviation meteorology and practical application of weather synopsis and forecast products.</li>
                    <li>Satisfactory completion of training, testing and checking requirements as defined in flybig's Flight Operations Training
                        Manual (FOTM). </li>
                    <li>Strong attention to detail.</li>
                    <li>Proficient in both written and spoken English.</li>
                    <li>Familiar with the crew, schedule, and engineering limitations and impacts.</li>
                    <li>Candidate must possess at least a Bachelor’s Degree.</li>
                </ul>
                <br />
                <h4 className="fw-bold mb-2 p-0">Roles and Responsibilities</h4>
                <ul>
                    <li>
                        Responsible for dispatch release and operational control for flybig
                        flight operations in accordance with regulatory requirements and
                        company policy in order to provide safe, comfortable, and on-time
                        service to guests.
                    </li>
                    <li> Making tactical economic decisions to drive efficiency and optimization for the airline.</li>
                    <li>
                        Analyzing meteorological data from multiple sources to identify
                        hazards such as convective activity, turbulence, icing, and other
                        factors. Work in conjunction with flight crew to mitigate
                        meteorological threats both, in pre-flight and enroute phases.
                    </li>
                    <li>
                        Proactively monitoring enroute aircraft, providing flight crew with
                        timely updates to changes in dispatch parameters and taking action
                        to deal with unplanned events/situations.
                    </li>
                    <li>
                        Keeping all stakeholders informed of significant changes in flight
                        status, aircraft substitutions, delays, unscheduled landings,
                        diversions, or cancellations.
                    </li>
                    <li>Willingness to perform other responsibilities as assigned from timeto time.</li>
                </ul>
                <br />
                <h4 className="fw-bold mb-2 p-0">Preferred Requirement</h4>
                <ul>
                    <li>Preferably with at least 1 year of dispatch experience with any other airline. </li>
                    <li>Willingness to work in shifts on a rotational basis in a 24*7 environment.</li>
                    <li>Willing to relocate to any location across the flybig network.</li>
                </ul>
                <br />
                <h4 className="fw-bold mb-2 p-0">Location</h4>
                <ul><li>Gurugram</li></ul>
            </div>
            <hr />

            <div>
                <h2 className="mb-3">Manager - Flight Ops.</h2>
                <h4 className="fw-bold mb-2 p-0">Department </h4>
                <ul><li> Flight Operations</li></ul>
                <h4 className="fw-bold mb-2 p-0">Sub Department</h4>
                <ul><li> Training</li></ul>
                <h4 className="fw-bold mb-2 p-0">Location</h4>
                <ul><li>Gurugram</li></ul>
                <h4 className="fw-bold mb-2 p-0">Reports to</h4>
                <ul><li>Director Ops</li></ul>

                <h4 className="fw-bold mb-2 p-0">Roles and Responsibilities</h4>
                <ul>
                    <li>Systems upkeep towards Flight Crew training, qualification, and renewal.</li>
                    <li>Audit of internal processes which includes documentation, record upkeep and system database.</li>
                    <li>Design, issuance, revisions, distribution, implementation & compliance of Operations Manual.</li>
                    <li>Work in coordination with FSD & DTL, DGCA towards Trainer Upgrades & renewals. Operations Training Manual edition, revision approval.</li>
                    <li>Monitor medical examination dates for aircrew and obtain medical appointments well in time.</li>
                    <li>All EGCA Compliance.</li>
                    <li>Scrutiny of all Documents of inducted flight crew.</li>
                    <li>Security Clearance processing.</li>
                    <li>FATA Issuance and Renewal.</li>
                    <li>License, FRTOL and IR renewal processing.</li>
                    <li>Liaise with other sub-departments for smooth and timely operations.</li>
                    <li>Any other assignments from Reporting Manager/Head of Department/EVP.</li>
                    <li>DGCA liaising. </li>
                </ul>
                <h4 className="fw-bold mb-2 p-0">Work Experience</h4>
                <ul>
                    <li>3 Years of Exp <br />
                        <small>MAINTAINING ALL OPS AND TRAINING DOCUMENTS INCLUDING OM, REGULATIONS, AIP</small>
                    </li>
                </ul>
                <h4 className="fw-bold mb-2 p-0">Desired industry Experience </h4>
                <ul><li>Aviation</li></ul>
            </div>
            <hr />
            <div>
                <div>
                    <h2 className="mb-3">Asst. Manager/Manager - HR</h2>
                    <h4 className="fw-bold mb-2 p-0">Department </h4>
                    <ul><li> Human Resources</li></ul>
                    <h4 className="fw-bold mb-2 p-0">Location</h4>
                    <ul><li>Gurugram</li></ul>
                    <h4 className="fw-bold mb-2 p-0">Reports to</h4>
                    <ul><li>Director - HR</li></ul>

                    <h4 className="fw-bold mb-2 p-0">Roles and Responsibilities</h4>
                    <ul>
                        <li>Actively involved in Recruitment, sourcing, on-boarding, joining process & other initiatives.</li>
                        <li>Coordinate communication with candidates and schedule interviews</li>
                        <li>Coordinate HR projects (meetings, training, surveys etc) and measure progress.</li>
                        <li>Assisting with day to day operations of the HR functions and duties</li>
                        <li>Compiling and updating employee records (hard and soft copies)</li>
                        <li>Process documentation and prepare reports relating to personnel activities.</li>
                        <li>Deal with employee requests regarding human resources issues and clarification.</li>
                        <li>Fluent in Payroll software and compiling data like attendance, absences, bonus, leaves etc</li>
                        <li>Handle & resolve grievance and employee's issues.</li>
                        <li>Involve in preparing employees' master data and update periodically.</li>
                    </ul>
                    <h4 className="fw-bold mb-2 p-0">Work Experience & Skill set </h4>
                    <ul>
                        <li>8 years and above, preferably from Aviation industry.</li>
                        <li>Effective communication skills and presentation aptitude.</li>
                        <li>Team player having analytical and problem-solving skills.</li>
                        <li>Matured and professional interpersonal skills.</li>
                        <li>Ability to work independently and deliver with minimum supervision.</li>
                        <li>Excellent and MS Office esp in advance Excel.</li>
                    </ul>
                </div>
            </div>
            <hr />
            <div>
                <p> Interested candidates can share their resume to <a href="mailto:hr@flybig.in">hr@flybig.in</a> </p>
                <h4 className="fw-bold py-0 mb-2">NOTE</h4>
                <ul> <li>flybig does not solicit any kind of payment for interviews & recruitment. </li> </ul>
            </div>
        </div>
    );
}
