/* eslint-disable */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './DelhiShillong.scss';

export default function DelhiShillong() {
  return (

    <Row style={{
      background: 'white',
      justifyContent: 'center'
    }} className="travelRoutes">
      <Col lg={9} md={10} xs={11} style={{
      padding: '15px'
    }}>
        <p style={{
      fontSize: '46px',
      color: '#B61F79'
    }}>
          Delhi-Shillong-Delhi Charter flight details
        </p>

        <table className="flybigTable">
          <tr>
            <th>
              Travel Date
            </th>
            <th>From</th>
            <th>To</th>
            <th>Departure</th>
            <th>Arrival</th>
            <th>Frequency</th>
          </tr>
          <tr>
            <td>19th & 26th Mar</td>
            <td>DEL</td>
            <td>SHL</td>
            <td>10:15</td>
            <td>13:25</td>
            <td>Fri</td>
          </tr>
          <tr>
            <td>19th & 26th Mar</td>
            <td>SHL</td>
            <td>DEL</td>
            <td>13:50</td>
            <td>17:00</td>
            <td>Fri</td>
          </tr>
        </table>
        <div style={{
      textAlign: 'right'
    }}><strong>*Subject to Govt. approval</strong></div>
        <br />
        <p style={{
      fontSize: '18px',
      color: '#B61F79'
    }}>
          For further enquiry, please click the link below and fill up the form. We will get back to you immediately. You can also write to us at
          {' '}
          <a style={{
      fontSize: '23px',
      color: '#B61F79'
    }} href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a>
        </p>

        <div style={{
      textAlign: 'center'
    }}>
          <a style={{
      fontSize: '23px',
      color: '#B61F79'
    }} href="https://docs.google.com/forms/d/e/1FAIpQLSfxfnrR2j5pam5WtNMLNjSeZZw15Hzxa0HaW7A-Rs-o4TZX6w/viewform" target="_blank" rel="noreferrer">
            Click HERE for further enquiry
          </a>
        </div>

        <br />
        <div style={{
      fontSize: '23px',
      color: '#B61F79',
      textAlign: 'center'
    }}>
          <p><strong>DO's and DONT's to remember while visiting Shillong.</strong></p>
          <p>
            <strong>
              Click
              {' '}
              <a target="_blank" rel="noreferrer" href="https://eb9adaa5-7d63-4942-8347-81357c3cd3d6.filesusr.com/ugd/9f3d8d_19eecd965e5a42b1a2956372d865150b.pdf">HERE</a>
              {' '}
              to access the reopening of State for Tourism
            </strong>
          </p>
        </div>
        <p>
          Click
          {' '}
          <a target="_blank" rel="noreferrer" href="https://www.meghalayatourism.in">Here</a>
          {' '}
          to access the Meghalaya Government Tourism Department website
        </p>
        <p>
          Given the prevailing Covid-19 situation, all tourists visiting Meghalaya from outside the state to please check the applicable Standard Operating Procedure and abide, Click
          {' '}
          <a target="_blank" rel="noreferrer" href="https://www.meghalaya.gov.in/sites/default//files/announcement/Helpline_Numbers_Updated.pdf">HERE</a>
          {' '}
          for more information.
        </p>
      </Col>
    </Row>
  );
}
