/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Row, Col, Button, Image,
} from 'react-bootstrap';
import queryString from 'query-string';
import '../COC/COC.scss';
import './FAQ.scss';
import ManageBooking from './FAQItems/ManageBooking';
import Checkin from './FAQItems/Checkin';
import Baggage from './FAQItems/Baggage';
import PaymentFees from './FAQItems/PaymentFees';
import SpecialHandling from './FAQItems/SpecialHandling';
import GeneralQueries from './FAQItems/GeneralQueries';
import TravelCertificate from './FAQItems/TravelCertificate';
import Refund from './FAQItems/Refund';
import SpecialMedicalCondition from './FAQItems/SpecialMedicalCondition';
import FlightDelayCancellations from './FAQItems/FlightDelayCancellations';
import TwinOtter from './FAQItems/TwinOtter';

const manageBooking = 'manageBooking';
const checkin = 'checkin';
const baggage = 'baggage';
const paymentfees = 'paymentfees';
const generalqueries = 'generalqueries';
const specialhandling = 'specialhandling';
const refund = 'refund';
const travelCertificate = 'travelCertificate';
const specialMedicalCondition = 'specialMedicalCondition';
const flightDelay = 'flightDelay';
const twinOtter = 'twinOtter';

const headerDict = {
    manageBooking: 'MANAGE BOOKING',
    checkin: 'CHECK-IN',
    baggage: 'BAGGAGE',
    paymentfees: 'PAYMENT & FEES',
    generalqueries: 'GENERAL QUERIES',
    specialhandling: 'SPECIAL HANDLING',
    refund: 'REFUND',
    travelCertificate: 'TRAVEL CERTIFICATE',
    specialMedicalCondition: 'MEDICAL ASSISTANCE',
    flightDelay: 'FLIGHT DELAY AND CANCELLATIONS',
    twinOtter: 'Twin-Otter (DHC 6-400) Aircraft',
};

export default function FAQ({ location }) {
    const parsed = queryString.parse(location.search);
    let activeLink = '';
    const hash = location.hash.slice(1);
    if (parsed && parsed.section) {
        activeLink = parsed.section;
    }
    const [activeBtn, setActiveBtn] = useState(activeLink);

    return (
        <div className="faq COC">
            <Row>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === manageBooking ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === manageBooking ? '' : manageBooking)}>
                        <Image style={{ float: 'left' }} src="/faq/plane.ico" roundedCircle />
                        <span>{headerDict[manageBooking]}</span>
                    </Button>
                </Col>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === baggage ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === baggage ? '' : baggage)}>
                        <Image style={{ float: 'left' }} src="/faq/checkin.ico" roundedCircle />
                        <span>{headerDict[baggage]}</span>
                    </Button>
                </Col>
                <Col xs={12} md={10}>
                    {activeBtn === manageBooking && <ManageBooking eventKey={hash} />}
                    {activeBtn === baggage && <Baggage eventKey={hash} />}
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === paymentfees ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === paymentfees ? '' : paymentfees)}>
                        <Image style={{ float: 'left' }} src="/faq/wallet.ico" roundedCircle />
                        <span>
                            {headerDict[paymentfees]}
                        </span>
                    </Button>
                </Col>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === generalqueries ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === generalqueries ? '' : generalqueries)}>
                        <Image style={{ float: 'left' }} src="/faq/hand.ico" roundedCircle />
                        <span>
                            {headerDict[generalqueries]}
                        </span>
                    </Button>
                </Col>
                <Col xs={12} md={10}>
                    {activeBtn === paymentfees && <PaymentFees eventKey={hash} />}
                    {activeBtn === generalqueries && <GeneralQueries eventKey={hash} />}
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === specialhandling ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === specialhandling ? '' : specialhandling)}>
                        <Image style={{ float: 'left' }} src="/faq/hands.ico" roundedCircle />
                        <span>
                            {headerDict[specialhandling]}
                        </span>
                    </Button>
                </Col>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === refund ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === refund ? '' : refund)}>
                        <Image style={{ float: 'left' }} src="/faq/percentage.ico" roundedCircle />
                        <span>
                            {headerDict[refund]}
                        </span>
                    </Button>
                </Col>
                <Col xs={12} md={10}>
                    {activeBtn === specialhandling && <SpecialHandling eventKey={hash} />}
                    {activeBtn === refund && <Refund eventKey={hash} />}
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === travelCertificate ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === travelCertificate ? '' : travelCertificate)}>
                        <Image style={{ float: 'left' }} src="/faq/travelCertificate.png" roundedCircle />
                        <span>
                            {headerDict[travelCertificate]}
                        </span>
                    </Button>
                </Col>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === specialMedicalCondition ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === specialMedicalCondition ? '' : specialMedicalCondition)}>
                        <Image style={{ float: 'left' }} src="/faq/medicalAssistance.png" roundedCircle />
                        <span>
                            {headerDict[specialMedicalCondition]}
                        </span>
                    </Button>
                </Col>
                <Col xs={12} md={10}>
                    {activeBtn === travelCertificate && <TravelCertificate eventKey={hash} />}
                    {activeBtn === specialMedicalCondition && <SpecialMedicalCondition eventKey={hash} />}
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === flightDelay ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === flightDelay ? '' : flightDelay)}>
                        <Image style={{ float: 'left' }} src="/faq/flightDelay.png" roundedCircle />
                        {headerDict[flightDelay]}
                    </Button>
                </Col>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === checkin ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === checkin ? '' : checkin)}>
                        <Image style={{ float: 'left' }} src="/faq/checkin.ico" roundedCircle />
                        <span>
                            {headerDict[checkin]}
                        </span>
                    </Button>
                </Col>
                <Col xs={12} md={10}>
                    {activeBtn === checkin && <Checkin eventKey={hash} />}
                    {activeBtn === flightDelay && <FlightDelayCancellations eventKey={hash} />}
                </Col>
            </Row>


            <Row>
                <Col xs={12} md={5}>
                    <Button className={activeBtn === twinOtter ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === twinOtter ? '' : twinOtter)}>
                        <Image style={{ float: 'left' }} src="/faq/twinOtter.png" roundedCircle />
                        {headerDict[twinOtter]}
                    </Button>
                </Col>

                <Col xs={12} md={10}>
                    {activeBtn === twinOtter && <TwinOtter eventKey={hash} />}
                </Col>
            </Row>
        </div>
    );
}
