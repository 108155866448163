/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function POC() {
  return (
    <div className="saitem">
      <p>
        For our guests requiring a Portable Oxygen Concentrator (POC), only the ones approved by Federal Aviation Administration (FAA) and Flybig’s Engineering team is permitted on board flybig flights either as checked-in or hand baggage,  subject to following conditions being met:
      </p>

      <ul>
        <li>A request for carriage or use of POC on board should be intimated to flybig a minimum of forty eight (48) hours prior to the scheduled departure time. Only lithium battery powered POCs are allowed on board our aircraft.</li>
        <li>The POC should not cause any interference with the electrical, navigation or communication equipment on board</li>
        <li>You must comply with all security requirements and instructions communicated by the crew, from time to time</li>
        <li>The guest will be solely responsible for packing, assembling, dismantling or facilitating administration of POC both on ground and on board a flight</li>
        <li>flybig, in its sole discretion, reserves the right to refuse carriage of POC, if it is likely to endanger the safety of the aircraft or of any person.</li>
        <li>For safety purposes, please ensure that the POC prior to check-in is empty and free of pressure. Thus, if POC was used before coming to the airport, then the guest must reset the POC to avoid any residual oxygen in the equipment;</li>
        <li>The lithium battery attached to the POC  should be removed and carried by the guest as hand baggage.</li>
        <li>In case you need to use the POC during flight, please ensure to fill up the medical form and call our call centre atleast 48 hours prior to the departure of your flight. Click here for MEDIF Form</li>
        <li>We will be seating our guests carrying/using POC on a window seat, except on exit rows.</li>
        <li>During taxi, take-off and landing, the POC, if being used will need to be stowed under the seat in front.</li>
        <li>During an unlikely event of decompression (in case of decrease in cabin pressure), you must wear the oxygen mask from the overhead panel as a primary source of oxygen and not the POC, since the oxygen mask would ensure a continuous flow of oxygen and would also minimize the chance/ effects of  post decompression sickness.</li>
        <li>
          The non-exhaustive list of current FAA approved POCs which are allowed on board our aircraft are mentioned below. Please note that this list is subject to change. Please call our call centre if your POC is not included in the list mentioned below:

          <ol>
            <li>Air Sep Focus, Air Sep Free Style, Air Sep Free Style 5 and Air Sep Life Style</li>
            <li>De Vilbiss Healthcare iGo, Delphi RS-00400</li>
            <li>Inogen One, Inogen One G2 and Inogen One G3</li>
            <li>Inova Labs LifeChoice Activox, Inova Labs LifeChoice or International Biophysics LifeChoice</li>
            <li>Invacare XPO2 and Invacare Solo2</li>
            <li>Oxlife Independence</li>
            <li>Oxus Inc. RS-00400 or Delphi RS-00400</li>
            <li>Precision Medical EasyPulse</li>
            <li>Respironics EverGo & Respironics SimplyGo</li>
            <li>SeQual Eclipse, SeQual Technology Eclipse, SeQual SAROS, SeQual eQuinox (model 4000) and SeQual Oxywell (model 4000)</li>
            <li>VBOX Trooper</li>
          </ol>
        </li>
      </ul>
    </div>
  );
}
