import React from 'react';
import './FAQItem.scss';
// import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
// import { HOME_URL, SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'refundIternaryCancellation',
    header: 'Can a refund be obtained on an Itinerary cancellation? (Passenger)',
    body: (
      <>
        {' '}
        <p>
          Yes, a refund on an itinerary cancellation can be obtained. You can cancel the ticket online and get refunded after the applicable cancellation charges are incurred. Changes or cancellations to booking must be made at least. If the fare is refundable then only refund can be obtained after deduction of charges, else only the taxes will be refunded.
        </p>
        <ul>
          <li>
            2 hrs prior for domestic flights
          </li>
        </ul>
        <p>
          There will be an imposition of No Show charges if the ticket is not cancelled/ rescheduled within the stipulated time which means the ticket amount will be forfeited and only applicable taxes will be refunded.
        </p>
      </>
    ),
  },
  {
    id: 'refundProcess',
    header: 'How can a refund for a cancelled ticket be obtained?',
    body: (
      <p>
        Refund for cancelled tickets will be processed after the deduction of appropriate cancellation fees, which may take up to 7 working days.
      </p>
    ),
  },
  {
    id: 'refundCashReservation',
    header: 'How can a refund for a reservation paid in cash be obtained?',
    body: (
      <>
        <p>
          For Bookings made by cash at the airport, refunds will be processed through an electronic transfer of funds (NEFT), pursuant to filling the requisite refund form at the airport.
        </p>
        <strong>Persons to Whom Refund Will Be Made</strong>
        <p>
          flybig shall be entitled to make refund either to the person named in the Booking, or to the person who has paid for the Booking, upon presentation of satisfactory proof and holding himself out as a person to whom refund may be made and shall be deemed a proper refund, and shall discharge flybig from liability and any further claim for refund from any person. Passengers will have to write to us at groups.relations@flybig.in for assistance.
        </p>
      </>
    ),
  },
  {
    id: 'refundTravelAgency',
    header: 'How can I obtain a refund for a ticket booked through a travel agency?',
    body: (
      <p>
        The refund for the amount will be made to the travel agency's account on cancellation of the booking. Passengers will have to contact their travel agent to claim refund.
      </p>
    ),
  },
  {
    id: 'refundNoTravel',
    header: 'Can a refund be obtained in case I am unable to travel?',
    body: (
      <p>
        Unutilised tickets and No Shows will only have their applicable airport taxes refunded based on the proper documents produced – Medical certificate or Death Certificate of the immediate blood relation, and upon verification the refund can be obtained.
      </p>
    ),
  },
  {
    id: 'refundMissedFlight',
    header: 'What is the amount refunded for a flight I missed?',
    body: (
      <p>
        In case of a missed flights/ no show, all statutory taxes and User Development Fee (UDF)/Airport Development Fee (ADF)/ Passenger Service Fee (PSF)/Airport Security Fee (ASF) will be refunded wherever applicable.
      </p>
    ),
  },
  {
    id: 'refundNoShowTaxes',
    header: 'What is the procedure for claiming No Show taxes?',
    body: (
      <p>
        Please contact the flybig guest service team (click here
        {' '}
        <a href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a>
        ) and they will be happy to assist you!
      </p>
    ),
  },
];

export default function Refund({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              id={item.id}
              header={item.header}
              selectedEventKey={eventKey}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
