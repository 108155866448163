import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ADDRESS } from '../../constants';
import Address from './Address';
import './Contact.scss';

function ContactBar({ address }) {
  return (
    <Col
      md={{ span: 11, offset: 0 }}
      style={{
        borderRadius: '5px', margin: '10px',
      }}
    >
      <Address
        icon={address.icon}
        header={address.name}
        body={(
          <div>
            {address.post}
          </div>
            )}
      />
    </Col>
  /* <Col
        md={{ span: 5 }}
        xs={{ span: 12, offset: 0 }}
        style={{
          padding: '0px', background: '#e2e5e8', borderRadius: '5px', textAlign: 'center',
        }}
      >

        <Image src={`${address.image}`} style={{ width: '100%', height: 'auto', borderRadius: '5px' }} />
        <Row style={{
          textAlign: 'center', fontSize: 'medium', justifyContent: 'center', paddingTop: '10px',
        }}
        >
          {address.cordinates}
        </Row>
      </Col> */
  );
}

export default function Contact() {
  return (
    <div>
      <h4>
        Contact Us
      </h4>
      <Row className="contactbar mx-0">
        {ADDRESS.map((address) => <ContactBar address={address} />)}
      </Row>

    </div>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
