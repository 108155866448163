/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function RespiratoryDisorders() {
  return (
    <div className="saitem">
      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>

        <tr>
          <td>PNEUMOTHORAX(AIR IN THE CAVITY AROUND A LUNG DUE TO PUNCTURE WOUND OR SPONTANEOUS)</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY upto 6 days and UNTIL PNEUMOTHORAX IS RESOLVED AND LUNG IS FULLY INFLATED</td>
          <td>PERMITTED AFTER 7 DAYS AFTER FULL INFLATION OF LUNGS IN SPONTANEOUS PNEUMOTHORAX AND 14 DAYS AFTER TRAUMATIC PNEUMOTHRAX</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>CHEST SURGERY( LUNG )</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 10 DAYS</td>
          <td>PASSENGER MAY BE ALLOWED TO FLY 10 DAYS AFTER SURGERY PROVIDED with uncomplicated recovery</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>PNEUMONIA</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY UNTIL FULLY RECOVERED/MUST BE SYMPTOM FREE</td>
          <td>PERMITTED IF FULLY RECOVERED AND SYMPTOM FREE</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>TUBERCULOSIS</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>Not permitted to fly for minimum of 02 weeks from the day of diagnosis & treatment started</td>
          <td>AFTER AT LEAST 02 WEEKS OF START OF TREATMENT</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>COPD, Emphysema, Pulmonary fibrosis, Pleural effusion, Hemothorax etc OR CHRONIC LUNG DISEASE</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>RECENT ACUTE EXACERBATION AND UNRESOLVED CASES REQUIRES SUPPLEMENTARY OXYGEN</td>
          <td>
            Exercise tolerance (Walk)
            {'>'}
            {' '}
            50 meters without breathlessness and general condition is adequate. FULLY RECOVERED AND NO CURRENT INFECTION.
          </td>
          <td>Yes</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>Pulmonary Hypertension</td>
          <td>MEDA (Medical Condition)</td>
          <td>Not permitted if breathlessness present (NYHA II & III)</td>
          <td>Permitted if not breathless (NYHA – I)</td>
          <td>Yes</td>
          <td>No</td>
        </tr>

        <tr>
          <td>Cystic Fibrosis</td>
          <td>MEDA (Medical Condition)</td>
          <td>Not permitted in presence of additional infection</td>
          <td>Permitted if no current infection</td>
          <td>Yes</td>
          <td>No</td>
        </tr>

        <tr>
          <td>Asthma</td>
          <td>MEDA (Medical Condition)</td>
          <td>If symptomatic on inhaler also</td>
          <td>Currently asymptomatic and no infection. MUST CARRY INHALER AND MEDICINES IN HAND BAGGAGE</td>
          <td>No</td>
          <td>No</td>
        </tr>

      </table>
    </div>
  );
}
