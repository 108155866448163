/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';
import { PHONE_NUMBER } from '../../../constants';

export default function SafetyAssistant() {
  return (
    <div className="saitem">
      {/* <div className="header">
        <p>
          Requirement of travelling with a Safety Assistant
        </p>
      </div> */}

      <p>
        A safety assistant is any able-bodied person who asssists the guest with disability to exit the aircraft in case of an emergency evacuation and can establish communication with cabin crew for the purpose of the required safety briefing.
      </p>

      <p>
        <strong>
          For any of our guests travelling alone, you should be able to perform the following activites independently:
        </strong>
      </p>

      <ul>
        <li>
          Fasten and unfasten your seat belt
        </li>
        <li>Put on and take of the lifejacket</li>
        <li>Leave the seat and get to an emergeny exit (by walking or otherwise)</li>
        <li>Put on an oxygen mask</li>
      </ul>

      <p>
        <strong>
          For certain guests we express our special concern and insist that they travel with a safety assistant. Guests who are likely to need a safety assistant are those:
        </strong>
      </p>

      <ul>
        <li>Our guests who have a severe learning or cognitive challenge which prevents them from understanding or reacting to safety instructions.</li>
        <li>Our guests who have a challenge that prevents them from moving without assistance to reach an emergency exit.</li>
        <li>Our guests traveling on a stretcher require a safety assistant who is a medical/parmedical escort. In selected cases flybig's internal physician may permit a non-medical safety assistant.</li>
        <li>Our guests with mental disability who are unable to comprehend or respond appropriately to safety instructions, including the regulatory safety briefing and any other safety related instructions provided in the aircraft.</li>
        <li>Our guests with a mobility impairment so severe that the person is unable to physically assist in his or her own evacuation from the aircraft in case of an emergency.</li>
        <li>Our guests with severe hearing, vision and speech impairment - who cannot establish any means of communication with the cabin crew that is adequate both to allow transmission of the safety briefing and to enable the guest to assist in his or her own evacuation from the aircraft in the event of an emergency.</li>
        <li>Guests who are unable to fasten and unfasten their seat belt.</li>
        <li>Guests who are unable to put on and take off thier lifejacket.</li>
        <li>Guests who are unable to leave thier seat and get yourself to an emergency exit(by walking or otherwise).</li>
        <li>Guests who are unable to don an oxygen mask.</li>
      </ul>

      <p>
        If you require to travel with a companion, flybig will sell them a ticket at the same fare. It may also be possible to find another passenger on the flight willing to take the role of an accompanying person for emergency purposes however this cannot be guaranteed.
      </p>

      <p>
        <strong>
          If you are in doubt about the requirement of travelling with a safety assistant, please call our call centre @
          {` ${PHONE_NUMBER}`}
        </strong>
      </p>

      <p><strong>FAQs on the Safety Assistant</strong></p>
      <p><strong>Who can be a Safety Assistant?</strong></p>
      <p>
        The safety assistant shall be at least eighteen (18) years old, physically and mentally able and willing to evacuate a guest with disability in case of an emergency.
      </p>
      <p>
        <strong>Seating of the Safety Assistant</strong>
      </p>
      <p>
        The accompanying safety assistant will be seated next to our guest, in order to assist in case of an emergency. If a safety assistant is accompanying several guests with disabilities, the safety assistant will be seated in the same row as our guests with disability. In case seats are not available in the same row then the safety assistant will be seated in the row immediately in front or behind the guest/s with disability.
      </p>
    </div>
  );
}
