/* eslint-disable */
import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Image,
}
from 'react-bootstrap';
import { ABOUTUS } from '../../constants';
import './AboutUs.scss';

export function AboutUsBar({
  image,
  content,
}) {
  return (
    <Row className="justify-content-center aboutContainer mx-0">
      <Col xs={10}>
        <Row className="aboutLeftContainer">
          <Col md={{ span: 6 }} style={{ padding: '0px' }}>
            <Image src={image} className="leftContainerImage" />
          </Col>
          <Col md={{ span: 6 }} style={{ padding: '0px' }}>
            <div className="aboutContent">
              {content}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

/* eslint-disable */

/* eslint-disable */
export default function AboutUs() {
  return (
    <div>
      <h4>
        About Us
      </h4>
      <AboutUsBar
        {...(ABOUTUS[0])}
        content={
      (
        < >
          <Row noGutters>
            <span>
              <Image src={ABOUTUS[0].headerIcon} className="aboutHeaderIcon" roundedCircle />
              <h5 style={{ display: 'inline' }} className="aboutHeader">
                {ABOUTUS[0].header}
              </h5>
            </span>
          </Row>
          <Row noGutters>
            As a young country, India still has towns and cities which are not connected by direct flights. People who have no option but to use inconvenient and time-consuming modes of transportation. We at flybig intend to change this.
          </Row>
          <Row noGutters>
            In conjunction with the UDAN initiative, flybig, India's newest friendly regional airline, provides connectivity to destinations that were hitherto not accessible by air. Our reason for existence will be based on providing a continuum of a warm family atmosphere on all of our flights whilst providing connectivity and convenient timings for travel.
          </Row>
          <Row noGutters>
            The management team of Big Charter brings to the table the entire gamut of aviation experience and open up a new world of possibilities.
          </Row>
        </>
      )
    }
      />
      <Row className="justify-content-center aboutContainer mx-0">
        <Col xs={10}>
          <Row className="aboutLeftContainer" style={{ flexDirection: 'row-reverse' }}>
            <Col md={{ span: 6 }} style={{ padding: '0px' }}>
              <Image src={ABOUTUS[1].image} className="leftContainerImage" style={{ height: '500px' }} />
            </Col>
            <Col md={{ span: 6 }} style={{ padding: '0px' }}>
              <div className="aboutContent">
                <Row noGutters>
                  <span>
                    <Image src={ABOUTUS[1].headerIcon} className="aboutHeaderIcon" roundedCircle />
                    <h5 style={{ display: 'inline' }} className="aboutHeader">
                      {ABOUTUS[1].header}
                    </h5>
                  </span>
                </Row>
                <Row noGutters>
                  <h5 style={{ fontWeight: 'bold', color: '#B62079' }}>
                    The Big Vision
                  </h5>
                </Row>
                <Row noGutters>
                  To be recognized as an airline with integrity that truly cares for all its patrons as a family!
                </Row>
                <Row noGutters>
                  <h5 style={{ fontWeight: 'bold', color: '#B62079' }}>
                    The Big Mission
                  </h5>
                </Row>
                <Row noGutters>
                  To be an Airline that provides the following values to our guest.
                </Row>

                <Row style={{ textAlign: 'center', justifyContent: 'left', alignItems: 'center' }}>
                  <Col xs={5}>
                    <h4 style={{ fontWeight: 'bold', margin: '0', padding: '0' }}>
                      SAFE
                    </h4>
                    <Image src="/values/safe.png" style={{ height: 'auto', width: '90px' }} />
                  </Col>
                  <Col xs={5}>
                    <h4 style={{ fontWeight: 'bold', margin: '0', padding: '0' }}>
                      RELIABLE
                    </h4>
                    <Image src="/values/reliable.png" style={{ height: 'auto', width: '90px' }} />
                  </Col>
                </Row>
                <Row style={{ textAlign: 'center', justifyContent: 'left', alignItems: 'center' }}>
                  <Col xs={5}>
                    <h4 style={{ fontWeight: 'bold', margin: '0', padding: '0' }}>
                      CONVENIENT
                    </h4>
                    <Image src="/values/convenient.png" style={{ height: 'auto', width: '90px' }} />
                  </Col>
                  <Col xs={5}>
                    <h4 style={{ fontWeight: 'bold', margin: '0', padding: '0' }}>
                      CARING
                    </h4>
                    <Image src="/values/caring.png" style={{ height: 'auto', width: '90px' }} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <AboutUsBar
        image={ABOUTUS[2].image}
        content={
      (
        <>
          <Row noGutters>
            <span>
              <Image src={ABOUTUS[2].headerIcon} className="aboutHeaderIcon" roundedCircle />
              <h5 style={{ display: 'inline' }} className="aboutHeader">
                {ABOUTUS[2].header}
              </h5>
            </span>
          </Row>
          <Row noGutters>
            Safety is our top priority as an airline. We treat our guests as we would like to be treated ourselves.
          </Row>
          <Row noGutters>
            Our team is committed to giving guests the attention they deserve by being mindful of their varied needs.
          </Row>
          <Row noGutters>
            To be the best we must be ahead of the rest. By constantly using innovative and cutting edge technologies we ensure our guests have a unique experience.
          </Row>
        </>
      )
    }
      />
    </div>
  );


}

/* eslint-disable */
