import React from 'react';
import './FAQItem.scss';
// import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
// import { HOME_URL, SQIVA_URL } from '../../../config/config';
import { SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'travelCertificate',
    header: 'What is a Travel Certificate?',
    body: (
      <p>
        Travel Certificate is a proof of travel provided at a charge of INR 200 per person per sector inclusive of GST.
      </p>
    ),
  },{
    id: 'reimbursement',
    header: 'Can a Travel Certificate help us with reimbursement, GST and LTC claims?',
    body: (
      <p>
        Yes it can. Please click here(
        <a href={`${SQIVA_URL}/manage-booking`}>www.flybig.in\Travel certificate</a>
        ) to request for a Travel Certificate.
      </p>
    ),
  },{
    id: 'travelCertificatePayment',
    header: 'How can I retrieve and make the payment for a travel certificate?',
    body: (
      <p>
        Please click here(
        <a href={`${SQIVA_URL}/manage-booking`}>www.flybig.in\Travel certificate</a>
        ) to request and pay for a Travel Certificate.
      </p>
    ),
  },
];

export default function TravelCertificate({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              selectedEventKey={eventKey}
              id={item.id}
              header={item.header}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
