/* eslint-disable */
import { Accordion } from 'react-bootstrap';
import React from 'react';
import CardHolder from '../../../components/CardHolder';
import './FAQItem.scss';
import { HOME_URL, SQIVA_URL } from '../../../config/config';

export default function ManageBooking({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      <CardHolder
        header="How can I change/ cancel my reservation?"
        id="changeReservation"
        selectedEventKey={eventKey}
        eventKey="changeReservation"
      >
        <p>
          Visit the
          {' '}
          <a href={`${SQIVA_URL}/manage-booking`}>www.flybig.in/managebooking</a>
          {' '}
          option on the homepage of our website which has a list of features that allow you to retrieve and re-print your itineraries. You can also process changes/ cancellations to the itinerary and make special service request additions (meals, seat, pre-paid excess baggage etc.) in your booking. You can also update your contact information on this page.
        </p>
      </CardHolder>

      <CardHolder
        header="How is View/ Change bookings beneficial to me?"
        id="editBookingBenefit"
        eventKey="editBookingBenefit"
        selectedEventKey={eventKey}
      >
        <p>
          The View/ Change bookings sections is a self-service online tool on the website specially designed to eliminate the need to check booking details and request booking services via our call centre.
        </p>
      </CardHolder>

      <CardHolder
        header="What will I be able to do through View/Change booking? What are the features of the View/ Change Bookings option on the website?"
        id="features"
        eventKey="features"
        selectedEventKey={eventKey}
      >
        <p>The View/ Change bookings sections is a self-service online tool on the website specially designed to eliminate the need to check booking details and request booking services via our call centre.</p>
        <p>The View/Change booking section offers the following features to our guests:</p>

        <ul>
          <li>Retrieve itinerary</li>
          <li>Email itinerary</li>
          <li>Re-Print itinerary</li>
          <li>Add special services</li>
          <li>Reschedule reservations</li>
          <li>
            Cancel and request refund for your itinerary (subject to ticket fare type and available for tickets booked on
            <a href={HOME_URL}>www.flybig.in</a>
            {' '}
            only). Partial and full refunds will be allowed only to the same credit / debit card / bank account.
          </li>
        </ul>

      </CardHolder>
      <CardHolder
        header="How is View/ Change bookings accessible?"
        id="accessManageBooking"
        eventKey="accessManageBooking"
        selectedEventKey={eventKey}
      >
        <p>
          Go to the home page of our website (
          <a href={HOME_URL}>www.flybig.in</a>
          ) and click on the
          {' '}
          <a href={`${SQIVA_URL}/manage-booking`}>www.flybig.in\managebooking</a>
          {' '}
          option. The reservation record can be accessed through the six- letter flybig Booking Reference Number/ PNR, Last Name (as mentioned in the booking)
        </p>
      </CardHolder>

      <CardHolder
        header="Would I be able to change my origin and destination through the View/Change Booking facility?"
        id="changePlace"
        eventKey="changePlace"
        selectedEventKey={eventKey}
      >
        <p>
          You will not be able to change your origin and destination through the View/ Change Booking facility but passengers can opt to cancel their reservation and create a new reservation through the regular booking procedure.
        </p>
      </CardHolder>

      <CardHolder
        header="Would I be able to View/Change booking if I have booked on phone at the Call Centre but paid online?"
        id="phoneManageBooking"
        eventKey="phoneManageBooking"
        selectedEventKey={eventKey}
      >
        <p>
          You will be able to View/Change Booking if you have booked on phone at the Call Centre and paid online.
        </p>
      </CardHolder>

      <CardHolder
        header="Would I be able to cancel my ticket and use the same ticket in the future?"
        id="reuseTicket"
        eventKey="reuseTicket"
        selectedEventKey={eventKey}
      >
        <p>
          You will not be able to use your cancelled ticket in the future but you can choose to retain a Credit Note or Credit Shell with flybig and use the remaining amount after deduction of applicable charges for your next reservation. The Credit Note or Credit Shell can be used if the reservation is made from the website or the call centre(Kindly note that reservations made from third parties, and international card payments would not be liable for the Credit Note or Credit Shell). Credit Shell can only be used for the same Passenger for whom the reservation was cancelled.
        </p>
      </CardHolder>
      <CardHolder
        header="Till when can I cancel/ refund my booking online?"
        id="periodManageBooking"
        eventKey="periodManageBooking"
        selectedEventKey={eventKey}
      >
        <p>
          On domestic flights you can cancel/ refund till 2 hours prior to flight departure
        </p>
      </CardHolder>

      <CardHolder
        header="What charges are incurred in cased of flight change/cancellation?"
        id="flightCancellationCharges"
        eventKey="flightCancellationCharges"
        selectedEventKey={eventKey}
      >
        <p>
          The following charges are incurred in case of flight cancellations:
        </p>
        <table className="flybigTable" style={{ width: 'auto' }}>
          <tr>
            <td colSpan={2} style={{ alignItems: 'center' }}>
              <strong>Cancellation Fees</strong>
            </td>
          </tr>
          <tr>
            <th>Time duration</th>
            <th>Applicable Fee (Inclusive)</th>
          </tr>
          <tr>
            <td>Cancellation upto 2 hours before scheduled departure</td>
            <td>Up to INR 3000 or Airfare charges (Base fare + fuel surcharge), whichever is lower</td>
          </tr>
          <tr>
            <td>Change upto 2 hours before scheduled departure</td>
            <td>Up to INR 2000 or base fare + fuel surcharge whichever is lower + fare difference(if any)</td>
          </tr>
          <tr>
            <td>0-2 hours before scheduled departure</td>
            <td>No show charges</td>
          </tr>

          <tr>
            <td colSpan={2}>
              <strong>Time duration</strong>For domestic bookings, guests can make any changes or cancellations free of charge within 24 hours of booking, if booked at least 7 days before the travel dates.
            </td>
          </tr>
        </table>
      </CardHolder>

      <CardHolder
        header="Can I alter my name in case I am not able to travel and transfer my ticket to another person?"
        id="alterName"
        eventKey="alterName"
        selectedEventKey={eventKey}
      >
        <p>Currently, the tickets are non-transferable, hence, name changes on a confirmed reservation are not permissible.</p>
        <p>You will need to cancel your ticket and book a new ticket with the new passenger's name. Cancellation charges, if any, will be applicable accordingly.</p>
      </CardHolder>

      <CardHolder
        header="What is the procedure to cancel my flight?"
        id="cancellationProcedure"
        eventKey="cancellationProcedure"
        selectedEventKey={eventKey}
      >
        <p>
          The guests booking can be cancelled/ changed before the scheduled time of departure by contacting flybig's Call Centre, at flybig's Airport Counters, on flybig's website and through participating travel agent responsible for original booking 2 hours prior to flight departure for domestic flights
        </p>
      </CardHolder>
      {/* </Accordion> */}
    </div>

  );
}

/* eslint-disable */
