/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Row, Col, Button, Image,
} from 'react-bootstrap';
import queryString from 'query-string';
import '../COC/COC.scss';
import './FAQ.scss';
import Booking from './Covid/Booking';
import Checkin from './Covid/Checkin';
import Inflight from './Covid/Inflight';
import Others from './Covid/Others';

const booking = 'booking';
const checkin = 'checkin';
const inflight = 'inflight';
const others = 'others';

const headerDict = {
  booking: 'BOOKING',
  checkin: 'CHECK-IN',
  inflight: 'INFLIGHT',
  others: 'OTHERS',
};

export default function CovidFAQ({ location }) {
  const parsed = queryString.parse(location.search);
  let activeLink = '';
  const hash = location.hash && location.hash.slice(1);
  if (parsed && parsed.section) {
    activeLink = parsed.section;
  }
  const [activeBtn, setActiveBtn] = useState(activeLink);

  return (
    <div className="faq COC">
      <Row>
        <Col xs={10} style={{ fontSize: 'xx-large', fontWeight: 'bold', letterSpacing: '2px' }}>
          FREQUENTLY ASKED QUESTIONS (FAQs)
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={5}>
          <Button className={activeBtn === booking ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === booking ? '' : booking)}>
            <Image src="/faqCovid/booking.png" roundedCircle />
            <span>{headerDict[booking]}</span>
          </Button>
        </Col>
        <Col xs={6} md={5}>
          <Button className={activeBtn === checkin ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === checkin ? '' : checkin)}>
            <Image src="/faqCovid/checkin.png" roundedCircle />
            <span>{headerDict[checkin]}</span>
          </Button>
        </Col>
        <Col xs={12} md={10}>
          {activeBtn === booking && <Booking eventKey={hash} />}
          {activeBtn === checkin && <Checkin eventKey={hash} />}
        </Col>
      </Row>

      <Row>
        <Col xs={6} md={5}>
          <Button className={activeBtn === inflight ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === inflight ? '' : inflight)}>
            <Image src="/faqCovid/inflight.png" roundedCircle />
            <span>{headerDict[inflight]}</span>
          </Button>
        </Col>
        <Col xs={6} md={5}>
          <Button className={activeBtn === others ? 'faqActive' : 'faqInactive'} onClick={() => setActiveBtn(activeBtn === others ? '' : others)}>
            <Image src="/faqCovid/others.png" roundedCircle />
            <span>{headerDict[others]}</span>
          </Button>
        </Col>
        <Col xs={12} md={10}>
          {activeBtn === inflight && <Inflight eventKey={hash} />}
          {activeBtn === others && <Others eventKey={hash} />}
        </Col>
      </Row>
    </div>
  );
}
