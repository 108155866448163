/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function CentralNervousSystem() {
  return (
    <div className="saitem">
      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>
        <tr>
          <td>CVA (STROKE)</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 14 DAYS</td>
          <td>PERMITTED AFTER 14 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>SPINAL SURGERY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED WITHIN 7 DAYS OF SURGERY</td>
          <td>PERMITTED AFTER 7 DAYS OF SURGERY</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>CRANIAL SURGERY(NEURO SURGERY)</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY BEFORE 10 DAYS</td>
          <td>PERMITTED FROM 10TH DAY ONWARDS. Cranium should be free from AIr</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>TIA</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY BEFORE 02 DAYS</td>
          <td>PERMITTED AFTER 2 DAYS and proper investigation</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>Cognitive Impairment/ Autism/ Cerebral Palsy</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED WITHOUT ESCORT</td>
          <td>PERMITTED WITH 1:1 ESCORT, Stable clinically</td>
          <td>NO</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>EPILEPSY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED WITHIN 24 HRS OF EPILEPTIC EPISODE</td>
          <td>PERMITTED AFTER 24 HRS OF EPILEPTIC EPISODE IF CONTROLLED, MUST TAKE A DOSE OF MEDICINE BEFORE FLIGHT</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

      </table>
    </div>
  );
}
