import React from 'react'
import './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
    return (
        <div>
            <div className="container privacy pt-5 card my-3">
                <h1 className='text-flybig mb-3'>Privacy Policy </h1>
                <div className="mb-5">
                    <p>Big Charter Private  Limited (“flybig”) has created the following privacy statement in order to demonstrate its policy on privacy of passenger data, and also to comply with applicable Indian data privacy laws, including without limitation, the Information Technology Act, 2000 and the Information Technology (Reasonable Security and Procedures and Sensitive Personal Data or Information) Rules, 2011 which govern its collection and/or disclosure of the sensitive personal data or information submitted by its users/customers, if any.</p>
                    <ul>
                        <li>flybig encourages you to read this privacy statement before using this website, flybig.in (“Website”). By using this Website, you agree to the terms and conditions given in this privacy statement and the Terms & Conditions, available on the Website. If you do not agree to the terms and conditions of this statement and/or the Terms & Conditions, please do not use this Website.</li>
                        <li>flybig practices for the Website. This Website may contain links to other third-party websites. flybig is not responsible and/or liable for the privacy practices or the content of such other third-party websites.</li>
                        <li>We use your IP address to help diagnose problems with our server and/or to administer the Website. This gives us an idea of which parts of the Website users are visiting. We do not link IP addresses to anything personally identifiable. This means that a user’s session will be tracked, but the user will be anonymous.</li>
                        <li>You can visit most pages on the Website without giving us any information about yourself. However, the Website may require you to give us contact information like your name and email address and other personal information, when you make a booking or payment for our services on the Website or in-flight, or for any third-party services offered on the Website.</li>
                        <li>As a general practice, no sensitive personal information or data is collected about or from you without your consent, apart from any information or data voluntarily submitted by you including while making purchases at the Website, in contacting flybig through the Website, answering surveys and polls, entering contests and similar promotions and signing up for updates. Voluntary provision of information by you, including by the use of the Website, will be deemed as consent.</li>
                        <li>Your personal information or data may be collected if you wish to create your account on our Website or participate in any other membership program(s) or contests or promotions, through which you may purchase any products or services offered by us, receive discounts, promotions, participate in any contests, etc. Your payment information, such as credit card number and expiration date ("Payment Information"), will be collected by the respective banks and /or third-party payment gateway(s), if you wish to make any purchases at the Website. Any sharing of Personal Information including Payment Information will be subject to the privacy statement of such banks and/or third-party payment gateway(s).</li>
                        <li>The nature of personal information or data usually received by us, includes:
                            <ul>
                                <li>information about your personal details, viz., name, address, telephone/mobile number, e-mail, etc.,</li>
                                <li>information which you voluntarily disclose to us by e-mail or other means, whether pursuant to a contract or otherwise, including through the Contact Us link on our website,</li>
                                <li>all such information that is made available to us by your browser when you visit our website, viz., date and time of the enquiries put forth by you, your browser language, etc.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="mb-5">
                    <p>This privacy statement does not apply to any non-personal information disclosed by you or collected by us or any personal information or data which is in public domain or which is disclosed pursuant to law.</p>
                    <ul>
                        <li>We use your contact information to (i) fulfil our contract of carriage with you, (ii) process and assist you with any transactions related to your booking, and for fulfilling such booking, (iii) respond to your queries, (iv) notify you about changes to our services, including flight alert messages, (v) send you information about our company and/or any offers or promotions flybig and/or its partners may be offering, (iv) to provide customised services and marketing including sending you feedback surveys, (v) to make this website and our services more relevant for your needs and (vi) to create and publish content that may be useful to you. In respect of the above, we may contact you by means of post, email, SMS, phone, fax and private messages through digital platforms including Whatsapp. Users may opt-out of receiving promotional communications from us by contacting <a href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a>. We may retain personal information for the purpose of satisfying any legal, business, accounting or reporting requirements, as required by applicable law.</li>
                        <li>We occasionally engage other parties to provide limited services to us or on our behalf. To the extent permitted by law, we will only provide those other parties the information they need to deliver the service, and they are prohibited from using that information for any other purpose.</li>
                        <li>flybig may disclose your personal information if required to do so by law, or if required to be disclosed to any Government agencies who are mandated under law to obtain such information for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution and punishment of offences, or in the good-faith belief that such action is necessary to:
                            <ul>
                                <li>conform to the edicts of the law or comply with legal process served on flybig or the Website,</li>
                                <li>protect and defend the rights or property of flybig and its family of websites, or</li>
                                <li>act in urgent circumstances to protect the personal safety of flybig employees, users of flybig ’s products or services, or members of the public.</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className="mb-5">
                    <p>To the extent required by applicable laws, your consent will be obtained prior to disclosure of any sensitive personal data or information collected from you, which consent may be given by you by implication or specification, including in terms of your use of our website and implied awareness of the terms of this privacy statement governing such use.</p>
                    <ul>
                        <li>flybig may use your information for internal business purposes, such as analyzing and managing its businesses. Your information collected from the Website may be combined with other information collected by flybig via other online or offline sources, with demographic information and other information that is available in the public domain.</li>
                        <li>Your information may be stored and processed in India or any other country in which flybig or its affiliates, subsidiaries or agents maintain facilities, and by using the Website, you consent to any such transfer of information outside of your country.</li>
                        <li>When you give us personal information, flybig will not share that information with third parties without your permission, other than for the limited exceptions already listed or if necessary for the performance of a contract with you. It will only be used for the purposes stated above.</li>
                        <li>In the event that another company acquires all or substantially all of the assets of flybig’s business through a consolidation, merger, asset purchase or other transaction, we, upon taking consent from you, will have the right to transfer all data (including any sensitive personal information or data) that is in our possession or under our control to such acquiring party and thereafter, the privacy policy of such acquiring party shall govern all such data and any personal information or data that is transferred to the acquiring party.</li>
                        <li>In the event that another company acquires all or substantially all of the assets of flybig’s business through a consolidation, merger, asset purchase or other transaction, we, upon taking consent from you, will have the right to transfer all data (including any sensitive personal information or data) that is in our possession or under our control to such acquiring party and thereafter, the privacy policy of such acquiring party shall govern all such data and any personal information or data that is transferred to the acquiring party</li>
                        <li>Further, if you intend to withdraw your consent at any time for usage of any personal information or data provided by you, we will respect your decision and not use such information. However, your intention of withdrawing consent for use of your personal information or data by us should be in writing, and in such a case, we will be under no obligation to provide or continue providing the services for which such information was provided.</li>
                        <li>This Website has security measures in place to protect the loss, misuse, and/or alteration of information under our control. To protect sensitive personal information or data against accidental or unlawful destruction, loss or modification and against unauthorized disclosure or access, we have implemented various security practices that are updated and audited on a regular basis in accordance with applicable law. The data resides behind a firewall, with access restricted to authorized flybig personnel. Also, our servers and workstations are protected by antivirus software which are updated regularly to minimize the threats of any virus, malware or Trojan attacks and are located in secure data facilities (with limited access) to further increase security.</li>
                        <li>Please be informed that despite these reasonable efforts to protect personal data on our servers, no method of transmission over the Internet is guaranteed to be secure. Therefore, while we strive to protect your personal information or data at all times, we cannot guarantee its absolute security and shall not be liable for any breach of security by an outside party, including where an unauthorized access, modification, destruction or disclosure of your personal information or data is attributable to any acts of an outside party, or any breach of security, virus and data protection protocols at your end or on the computer/mobile system that you use to access the Website. It is also important for you to protect against unauthorized access to your password and to your computer. Be sure to sign off when you are done using a shared computer.</li>
                        <li>flybig will occasionally update this privacy statement.</li>
                        <li>For any queries related to this privacy statement, correction of any personal information or data provided by you or for making a complaint about breach of privacy, you may please contact the following official of flybig designated as the Appellate authority, Mr. Rajiv Sharma at appellateauthority@flybig.in and/or Nodal Officer, Ms. Shivani Jain at <a href="mailto:nodalofficer@flybig.in">nodalofficer@flybig.in</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy