export const StateToCityDict = {
  Chandigarh: [
    'CHANDIGARH',
  ],
  'Andhra Pradesh': [
    'EAST GODAVARI',
    'CHITTOOR',
    'GUNTUR',
    'ANANTAPUR',
    'KURNOOL',
    'SRIKAKULAM',
    'NELLORE',
    'PRAKASAM',
    'WEST GODAVARI',
    'CUDDAPAH',
    'VISAKHAPATNAM',
    'VIZIANAGARAM',
    'KRISHNA',
  ],
  Bihar: [
    'BHOJPUR',
    'ARARIA',
    'BANKA',
    'BEGUSARAI',
    'GOPALGANJ',
    'MUNGER',
    'NAWADA',
    'SAHARSA',
    'SHEOHAR',
    'SUPAUL',
    'BUXAR',
    'KATIHAR',
    'JEHANABAD',
    'KISHANGANJ',
    'SAMASTIPUR',
    'ROHTAS',
    'MADHUBANI',
    'SITAMARHI',
    'VAISHALI',
    'WEST CHAMPARAN',
    'JAMUI',
    'SARAN',
    'DARBHANGA',
    'SIWAN',
    'MADHEPURA',
    'LAKHISARAI',
    'SHEIKHPURA',
    'EAST CHAMPARAN',
    'BHAGALPUR',
    'GAYA',
    'KAIMUR (BHABUA)',
    'AURANGABAD',
    'PATNA',
    'KHAGARIA',
    'MUZAFFARPUR',
    'PURNIA',
    'NALANDA',
  ],
  'Andaman and Nicobar Islands': [
    'NORTH AND MIDDLE ANDAMAN',
    'SOUTH ANDAMAN',
    'NICOBAR',
  ],
  'Daman and Diu': [
    'DAMAN',
    'DIU',
  ],
  'Arunachal Pradesh': [
    'LOHIT',
    'PAPUM PARE',
    'TAWANG',
    'UPPER SUBANSIRI',
    'KURUNG KUMEY',
    'EAST SIANG',
    'CHANGLANG',
    'DIBANG VALLEY',
    'EAST KAMENG',
    'LOWER DIBANG VALLEY',
    'LOWER SUBANSIRI',
    'TIRAP',
    'WEST KAMENG',
    'UPPER SIANG',
    'WEST SIANG',
  ],
  Assam: [
    'JORHAT',
    'HAILAKANDI',
    'MARIGAON',
    'NAGAON',
    'NALBARI',
    'SIVASAGAR SIBSAGAR',
    'KARIMGANJ',
    'BARPETA',
    'KARBI ANGLONG',
    'NORTH CACHAR HILLS',
    'LAKHIMPUR',
    'GOLAGHAT',
    'KOKRAJHAR',
    'GOALPARA',
    'KAMRUP',
    'DARRANG',
    'DHUBRI',
    'DHEMAJI',
    'TINSUKIA',
    'SONITPUR',
    'CACHAR',
    'DIBRUGARH',
    'BONGAIGAON',
  ],
  'Dadra and Nagar Haveli': [
    'DADRA AND NAGAR HAVELI',
  ],
  Delhi: [
    'NORTH WEST DELHI',
    'NEW DELHI',
    'NORTH DELHI',
    'CENTRAL DELHI',
    'WEST DELHI',
    'SOUTH DELHI',
    'SOUTH WEST DELHI',
    'EAST DELHI',
  ],
  Haryana: [
    'YAMUNA NAGAR',
    'ROHTAK',
    'JHAJJAR',
    'PANCHKULA',
    'PANIPAT',
    'REWARI',
    'AMBALA',
    'FATEHABAD',
    'FARIDABAD',
    'KURUKSHETRA',
    'KARNAL',
    'SONIPAT',
    'JIND',
    'KAITHAL',
    'MAHENDRAGARH',
    'SIRSA',
    'GURGAON',
    'BHIWANI',
    'HISAR',
  ],
  Chhattisgarh: [
    'DANTEWADA',
    'JASHPUR',
    'NARAYANPUR',
    'RAIGARH',
    'JANJGIR-CHAMPA',
    'SURGUJA',
    'KANKER',
    'BIJAPUR',
    'RAIPUR',
    'DHAMTARI',
    'KAWARDHA',
    'MAHASAMUND',
    'KORBA',
    'KORIYA',
    'DURG',
    'BILASPUR',
    'RAJNANDGAON',
    'BASTAR',
  ],
  Gujarat: [
    'AMRELI',
    'BANASKANTHA',
    'ANAND',
    'NAVSARI',
    'PORBANDAR',
    'BHARUCH',
    'MAHESANA',
    'SURAT',
    'VADODARA',
    'NARMADA',
    'SABARKANTHA',
    'RAJKOT',
    'SURENDRA NAGAR',
    'AHMEDABAD',
    'KACHCHH',
    'THE DANGS',
    'BHAVNAGAR',
    'DAHOD',
    'PATAN',
    'VALSAD',
    'PANCH MAHALS',
    'JUNAGADH',
    'KHEDA',
    'GANDHI NAGAR',
    'JAMNAGAR',
  ],
  Goa: [
    'NORTH GOA',
    'SOUTH GOA',
  ],
  'Himachal Pradesh': [
    'BILASPUR',
    'LAHUL AND SPITI',
    'UNA',
    'KULLU',
    'CHAMBA',
    'SIRMAUR',
    'SHIMLA',
    'SOLAN',
    'HAMIRPUR',
    'KINNAUR',
    'KANGRA',
    'MANDI',
  ],
  'Jammu and Kashmir': [
    'KARGIL',
    'BARAMULLA',
    'KATHUA',
    'SRINAGAR',
    'KUPWARA',
    'ANANTHNAG',
    'SAMBA',
    'DODA',
    'POONCH',
    'BUDGAM',
    'LEH',
    'RAJAURI',
    'PULWAMA',
    'UDHAMPUR',
  ],
  Karnataka: [
    'BAGALKOT',
    'GADAG',
    'HASSAN',
    'HAVERI',
    'MANDYA',
    'UTTARA KANNADA',
    'BANGALORE',
    'DHARWARD',
    'GULBARGA',
    'MYSORE',
    'BANGALORE RURAL',
    'BIDAR',
    'DAVANGARE',
    'KOPPAL',
    'CHAMRAJNAGAR',
    'CHITRADURGA',
    'BELGAUM',
    'DAKSHINA KANNADA',
    'TUMKUR',
    'KOLAR',
    'BIJAPUR',
    'KODAGU',
    'BELLARY',
    'RAICHUR',
    'UDUPI',
    'SHIMOGA',
    'CHICKMAGALUR',
  ],
  Jharkhand: [
    'DUMKA',
    'EAST SINGHBHUM',
    'LOHARDAGA',
    'SERAIKELA-KHARSAWAN',
    'SIMDEGA',
    'SAHIBGANJ',
    'PAKUR',
    'PALAMAU',
    'RAMGARH',
    'RANCHI',
    'HAZARIBAG',
    'WEST SINGHBHUM',
    'KODERMA',
    'LATEHAR',
    'DHANBAD',
    'BOKARO',
    'GIRIDH',
    'JAMTARA',
    'CHATRA',
    'GUMLA',
    'GARHWA',
    'DEOGHAR',
    'GODDA',
  ],
  Maharashtra: [
    'AMRAVATI',
    'AHMEDNAGAR',
    'GONDIA',
    'NANDED',
    'NANDURBAR',
    'MUMBAI',
    'WASHIM',
    'AKOLA',
    'CHANDRAPUR',
    'BHANDARA',
    'BULDHANA',
    'DHULE',
    'BEED',
    'GADCHIROLI',
    'HINGOLI',
    'LATUR',
    'JALGAON',
    'JALNA',
    'NAGPUR',
    'PARBHANI',
    'RAIGAD',
    'SANGLI',
    'SINDHUDURG',
    'THANE',
    'AURANGABAD',
    'KOLHAPUR',
    'SATARA',
    'PUNE',
    'YAVATMAL',
    'OSMANABAD',
    'WARDHA',
    'SOLAPUR',
    'RATNAGIRI',
    'NASHIK',
  ],
  Kerala: [
    'ALAPPUZHA',
    'ERNAKULAM',
    'KASARGOD',
    'IDUKKI',
    'KANNUR',
    'KOZHIKODE',
    'WAYANAD',
    'PALAKKAD',
    'THRISSUR',
    'MALAPPURAM',
    'KOTTAYAM',
    'KOLLAM',
    'THIRUVANANTHAPURAM',
    'PATHANAMTHITTA',
  ],
  Manipur: [
    'SENAPATI',
    'TAMENGLONG',
    'CHURACHANDPUR',
    'IMPHAL EAST',
    'THOUBAL',
    'IMPHAL WEST',
    'CHANDEL',
    'BISHNUPUR',
    'UKHRUL',
  ],
  'Madhya Pradesh': [
    'BALAGHAT',
    'ANUPPUR',
    'DEWAS',
    'DINDORI',
    'DATIA',
    'DHAR',
    'HARDA',
    'GWALIOR',
    'MANDLA',
    'SAGAR',
    'SATNA',
    'SHIVPURI',
    'TIKAMGARH',
    'UMARIA',
    'VIDISHA',
    'HOSHANGABAD',
    'JABALPUR',
    'KATNI',
    'KHARGONE West Nimar',
    'REWA',
    'SEHORE',
    'SHEOPUR',
    'SEONI',
    'SHAJAPUR',
    'SHAHDOL',
    'UJJAIN',
    'SIDHI',
    'ASHOKNAGAR',
    'MORENA',
    'NARSINGHPUR',
    'GUNA',
    'BHOPAL',
    'CHHINDWARA',
    'BHIND',
    'CHHATARPUR',
    'PANNA',
    'RAISEN',
    'DAMOH',
    'INDORE',
    'BARWANI',
    'BURHANPUR',
    'BETUL',
    'RATLAM',
    'MANDSAUR',
    'RAJGARH',
    'JHABUA',
    'KHANDWA East Nimar',
    'NEEMUCH',
  ],
  Nagaland: [
    'MON',
    'MOKOKCHUNG',
    'PHEK',
    'KOHIMA',
    'DIMAPUR',
    'KIPHIRE',
    'PEREN',
    'LONGLENG',
    'TUENSANG',
    'WOKHA',
    'ZUNHEBOTTO',
  ],
  Mizoram: [
    'LUNGLEI',
    'SAIHA',
    'MAMMIT',
    'AIZAWL',
    'CHAMPHAI',
    'LAWNGTLAI',
    'SERCHHIP',
    'KOLASIB',
  ],
  Lakshadweep: [
    'LAKSHADWEEP',
  ],
  Meghalaya: [
    'RI BHOI',
    'JAINTIA HILLS',
    'EAST GARO HILLS',
    'SOUTH GARO HILLS',
    'WEST KHASI HILLS',
    'WEST GARO HILLS',
    'EAST KHASI HILLS',
  ],
  Orissa: [
    'NABARANGAPUR',
    'NAYAGARH',
    'MALKANGIRI',
    'MAYURBHANJ',
    'KALAHANDI',
    'KENDRAPARA',
    'KORAPUT',
    'KANDHAMAL',
    'SONAPUR Subarnapur',
    'PURI',
    'DEBAGARH Deogarh',
    'NUAPADA',
    'BARGARH',
    'KHURDA Khordha',
    'KENDUJHAR',
    'SAMBALPUR',
    'GAJAPATI',
    'GANJAM',
    'ANGUL',
    'BOUDH',
    'JAGATSINGHAPUR',
    'JAJAPUR',
    'BHADRAK',
    'BALANGIR',
    'JHARSUGUDA',
    'DHENKANAL',
    'CUTTACK',
    'BALESWAR',
    'RAYAGADA',
    'SUNDERGARH',
  ],
  Puducherry: [
    'KARAIKAL',
    'MAHE',
    'PONDICHERRY',
  ],
  'Tamil Nadu': [
    'PERAMBALUR',
    'TIRUVALLUR',
    'VIRUDHUNAGAR',
    'THANJAVUR',
    'TUTICORIN Thoothukudi',
    'CHENNAI',
    'DINDIGUL',
    'MADURAI',
    'KRISHNAGIRI',
    'SALEM',
    'ERODE',
    'COIMBATORE',
    'VELLORE',
    'TIRUNELVELI',
    'CUDDALORE',
    'KANYAKUMARI',
    'NAGAPATTINAM',
    'ARIYALUR',
    'DHARMAPURI',
    'KARUR',
    'NAMAKKAL',
    'TIRUVANNAMALAI',
    'NILGIRIS',
    'THENI',
    'TIRUVARUR',
    'PUDUKKOTTAI',
    'SIVAGANGA',
    'VILLUPURAM',
    'KANCHIPURAM',
    'RAMANATHAPURAM',
    'TIRUCHIRAPPALLI',
  ],
  Punjab: [
    'BATHINDA',
    'FARIDKOT',
    'MOHALI',
    'JALANDHAR',
    'NAWANSHAHR',
    'AMRITSAR',
    'MUKTSAR',
    'GURDASPUR',
    'FATEHGARH SAHIB',
    'MOGA',
    'FIROZPUR',
    'LUDHIANA',
    'KAPURTHALA',
    'SANGRUR',
    'HOSHIARPUR',
    'ROPAR Rupnagar',
    'PATIALA',
    'MANSA',
  ],
  Rajasthan: [
    'BHILWARA',
    'DAUSA',
    'NAGAUR',
    'SAWAI MADHOPUR',
    'PALI',
    'TONK',
    'SRI GANGANAGAR',
    'CHURU',
    'JAIPUR',
    'SIROHI',
    'BARAN',
    'JHUNJHUNU',
    'SIKAR',
    'DUNGARPUR',
    'BARMER',
    'BHARATPUR',
    'DHOLPUR',
    'BANSWARA',
    'UDAIPUR',
    'HANUMANGARH',
    'KOTA',
    'JHALAWAR',
    'RAJSAMAND',
    'JODHPUR',
    'AJMER',
    'ALWAR',
    'CHITTORGARH',
    'BUNDI',
    'KARAULI',
    'BIKANER',
    'JALOR',
    'JAISALMER',
  ],
  Tripura: [
    'BALRAMPUR',
    'BASTI',
    'BAREILLY',
    'DEORIA',
    'HATHRAS',
    'KHERI',
    'SIDDHARTHNAGAR',
    'BANDA',
    'MEERUT',
    'SANT KABIR NAGAR',
    'AGRA',
    'CHANDAULI',
    'CHITRAKOOT',
    'BULANDSHAHR',
    'GAUTAM BUDDHA NAGAR',
    'HAMIRPUR',
    'JHANSI',
    'KANPUR DEHAT',
    'KANPUR NAGAR',
    'JAUNPUR',
    'MAINPURI',
    'SANT RAVIDAS NAGAR',
    'GORAKHPUR',
    'UNNAO',
    'VARANASI',
    'BALLIA',
    'SHRAWASTI',
    'SITAPUR',
    'ETAH',
    'SULTANPUR',
    'KANNAUJ',
    'HARDOI',
    'ETAWAH',
    'JYOTIBA PHULE NAGAR',
    'FARRUKHABAD',
    'MAHARAJGANJ',
    'PILIBHIT',
    'BUDAUN',
    'MUZAFFARNAGAR',
    'SHAHJAHANPUR',
    'GHAZIPUR',
    'SONBHADRA',
    'AURAIYA',
    'BAGPAT',
    'BIJNOR',
    'LUCKNOW',
    'ALIGARH',
    'KUSHINAGAR',
    'AMBEDKAR NAGAR',
    'ALLAHABAD',
    'BARABANKI',
    'LALITPUR',
    'FIROZABAD',
    'FATEHPUR',
    'JALAUN',
    'GHAZIABAD',
    'GONDA',
    'MORADABAD',
    'SAHARANPUR',
    'MAU',
    'RAEBARELI',
    'KAUSHAMBI',
    'BAHRAICH',
    'AZAMGARH',
    'MAHOBA',
    'FAIZABAD',
    'MATHURA',
    'RAMPUR',
    'MIRZAPUR',
    'PRATAPGARH',
  ],
  Uttarakhand: [
    'NORTH 24 PARGANAS',
    'NORTH DINAJPUR Uttar',
    'HOOGHLY',
    'KOLKATA',
    'MALDA',
    'COOCH BEHAR',
    'BIRBHUM',
    'PURULIA',
    'DARJEELING',
    'NADIA',
    'BANKURA',
    'BARDHAMAN',
    'East Medinipur',
    'SOUTH 24 PARGANAS',
    'JALPAIGURI',
    'HOWRAH',
    'SOUTH DINAJPUR Dakshin',
    'WEST MIDNAPORE',
    'WEST MEDINIPUR',
    'MURSHIDABAD',
  ],
  'Uttar Pradesh': [
    'PAURI GARHWAL',
    'ALMORA',
    'UTTARKASHI',
    'RUDRAPRAYAG',
    'UDHAM SINGH NAGAR',
    'HARIDWAR',
    'CHAMPAWAT',
    'BAGESHWAR',
    'NAINITAL',
    'DEHRADUN',
    'CHAMOLI',
    'PITHORAGARH',
    'TEHRI GARHWAL',
  ],
  'West Bengal': [
    'HYDERABAD',
    'WARANGAL',
    'Rangareddi',
    'NIZAMABAD',
    'ADILABAD',
    'KHAMMAM',
    'MEDAK',
    'MAHABUBNAGAR',
    'KARIM NAGAR',
    'NALGONDA',
  ],
  'Telangana': [
    'Adilabad',
    'Bhadragiri Kothagudem',
    'Hanumakonda',
    'Hyderabad',
    'Jagtial',
    'Jangaon',
    'Jayashankar Bhoopalpally',
    'Jogulamba Gadwal',
    'Kamareddy',
    'Karimnagar',
    'Khammam',
    'Komaram cheem Asfiabad',
    'Mahabubabad',
    'Mahabubnagar',
    'Mancherial',
    'Medak',
    'Medchal-Malkajgiri',
    'Mulugu',
    'Nagarkurnool',
    'Nalgonda',
    'Narayanpet',
    'Nirmal',
    'Nizamabad',
    'Peddapalli',
    'Rajanna Siricilla',
    'Rangareddy',
    'Sangareddy',
    'Sidipet',
    'Suryapet',
    'Vikarabad',
    'Wanaparthy',
    'Warangal',
    'Yadadri Bhuvanagiri'    
  ]
};
