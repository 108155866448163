/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function EyeDisorders() {
  return (
    <div className="saitem">
      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>

        <tr>
          <td>PENETRATING EYE INJURY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 7 DAYS</td>
          <td>PERMITTED AFTER 7 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>INTRA-OCULAR SURGERY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 7 DAYS</td>
          <td>AFTER 7 DAYS WITH FITNESS FROM TREATING EYE SURGEON</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>CATARACT SURGERY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED FOR 24 HOURS</td>
          <td>PERMITTED AFTER 24 HOURS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>SURGERY FOR RETINAL DETACHMENT</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 21 DAYS</td>
          <td>AFTER 21 DAYS WITH FIT TO FLY</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>CORNEAL LASER SURGERY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED BEFORE 24 HOURS</td>
          <td>PERMITTED AFTER 24 HOURS</td>
          <td>NO</td>
          <td>NO</td>
        </tr>

      </table>
    </div>
  );
}
