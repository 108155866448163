// export const HOME_URL = 'http://localhost:3000';
export const HOME_URL = "https://flybigstaging.virtualpages.in";
// export const HOME_URL = "https://flybig.in";

export const SQIVA_URL = "https://flybig.paxlinks.com";
export const PARTNER_LOGIN = "https://paxlinks.com/?&domain=flybig";
export const FACEBOOK_URL = "https://www.facebook.com/flybigairline";
export const INSTAGRAM_URL = "https://www.instagram.com/flybig_connect";
export const TWITTER_URL = "https://twitter.com/flybigairlines";
export const LINKEDIN_URL = "https://www.linkedin.com/company/37437494";
export const GOOGLE_ANALYTIC_TRACKING = "G-61LBR8FF1P";