import React from 'react'
import './TermsConditions.scss'

const TermsConditions = () => {
    return (
        <>
            <div className="container privacy pt-5 card my-3">
                <h1 className='text-flybig mb-3'>Terms and Conditions </h1>
                <div className="mb-5">
                    <ul>
                        <li>Big Charter Private Limited (“flybig”) website, <b>www.flybig.in</b> is offered to the Customers on the express condition of acceptance of these terms and conditions. The terms and conditions shall be deemed to constitute a legally binding contract between flybig and the Customer. If these terms and conditions are not acceptable to the Customer, the Customer is requested to exit flybig website (term as defined below).</li>
                        <li>flybig authorizes the Customer to view the content available on its website www.flybig.in. The Customer is not permitted to copy, replicate, modify, derivative, display, perform, create derivative works from, transfer or sell any information obtained from flybig website, whether in print, visual or electronic form, for any purpose whatsoever, without the prior written permission, at the sole discretion of flybig. Customers may not use flybig 's website, www.flybig.in or any micro site comprised in www.flybig.in, or any other flybig owned website accessible through the use of www.flybig.in (all of which are collectively referred as 'flybig website'), for any purpose that is unlawful or that is otherwise prohibited by these conditions of use.</li>
                        <li>Customers must not:
                            <ul>
                                <li>resell the services provided in the flybig website,</li>
                                <li>use the flybig website to make any speculative, false or fraudulent Bookingc</li>
                                <li>use the flybig website so as to interfere with others' use of the flybig website.</li>
                            </ul>
                        </li>
                        <li>flybig does not warrant or represent that use of the flybig website or its online booking facility or any third party payment systems used for making payments to flybig will be uninterrupted or error-free or that any information, data, content, software or other material accessible through the flybig website will be free of bugs, viruses, worms, Trojan horses or other harmful components.</li>
                        <li>Customers represent that, unless otherwise specifically permitted by flybig, the flybig website and all the products and services offered by flybig, including without limitation, are meant for personal and non-commercial use only, and cannot be redistributed by them to any person not authorised to receive the same.</li>
                        <li>Customers represent that they are of sufficient legal age to use this service, and they possess the legal right and ability to create binding obligations for any liability that they may incur as a result of the use of this service.</li>
                        <li>Customers understand that they are financially responsible for all uses of this service by them and those using their login information.</li>
                        <li>Customers will supervise all usage of the booking facility under their name or account.</li>
                        <li>Customers warrant that all information supplied by them and members of their household in using the booking facility are true and accurate.</li>
                        <li>flybig and its licensors retain all rights (including copyright and patent rights) with respect to all software and underlying information and material available through the flybig website.</li>
                        <li>Customers must not download or otherwise export or re-export any software or underlying information or material available through the flybig website except with the written permission of flybig and in full compliance with all Indian and other applicable laws and regulations, provided, however that any downloading that occurs in the normal course of using the flybig website in accordance with the published written instructions of flybig shall not be prohibited</li>
                        <li>flybig website may contain hyperlinks to websites operated by parties other than flybig. Such hyperlinks are provided for your reference only. flybig does not control such websites and is not responsible for their content(s). flybig 's inclusion of hyperlinks to such websites does not imply any endorsement of the material on such websites or any association with their operators.</li>
                        <li>flybig, its parent company and/or its affiliates may also present advertisements or promotional materials on or through flybig website. Customer's participation in any promotional event is subject to the terms and conditions associated with that event. Customer's dealings with, or participation in promotions of, any third-party advertisers on or through the flybig website are solely between the Customer and such third-party. Customer agrees that flybig, its parent company and/or its affiliates shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such third parties reference on the flybig website.</li>
                        <li>Without limiting any of the disclaimers of warranty set forth in these terms and conditions, flybig does not provide or make any representation as to the quality or nature of any of the third party information, products or services provided through the flybig website, or any other representation, warranty or guaranty. Furthermore, flybig in particular disclaims any responsibility, if such third party websites:
                            <ul>
                                <li>infringe any third party's intellectual property rights,</li>
                                <li>provide any information which is inaccurate, incomplete or misleading,</li>
                                <li>are not merchantable or fit for a particular purpose,</li>
                                <li>do not undertake adequate security measures,</li>
                                <li>contain viruses or other items of destructive nature, or</li>
                                <li>provide any libelous or defamatory contents or information</li>
                            </ul>
                        </li>
                    </ul>
                    <p>The information contained and/or provided in this website is meant solely for information purposes and should not be relied upon for any other use whatsoever.</p>
                    <ul>
                        <li>Customer's use of flybig website constitutes Customer's agreement to be bound, without any modifications or reservations whatsoever, by these terms and conditions, which may be updated or modified from time to time without notice to the Customer. Any change(s) shall become part of these terms and conditions and shall apply immediately. By continuing to use the flybig website after such modifications, Customer indicates acceptance of those modifications. Customer may also be required to re-accept the substantially revised terms and conditions to continue to use the flybig website.</li>
                        <li>Customer hereby consents to the exclusive jurisdiction and venue of courts in Gurugram, Haryana in all disputes arising out of or relating to the use of the flybig website. Use of the flybig website is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this paragraph.</li>
                        <li>Any rights or permissions not expressly granted herein are reserved.</li>
                        <li>For any requests pertaining to customer data accessibility including profile deletion, you can raise a request to. <a href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a></li>
                        <li>Upon exercising such an option, your personal information shall not, in the future, be available for bookings by you.  However, please note that flybig shall be required to retain your personal/booking data/travel records and history, for its legitimate use or for such period as per law. Also, requests such as opting out of, or unsubscribing from receiving promotional communication is to be raised through the same process i.e., by sending an email to <a href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a></li>
                    </ul>
                </div>
            </div>
            <div className="container privacy pt-5 card my-3">
                <h1 className='text-flybig mb-3'>Disclaimer </h1>
                <div className="mb-5">
                    <ul>
                        <li>flybig has made best efforts to provide accurate information, however, flybig and/or its affiliates do not guarantee the validity or veracity of any such information and as such flybig expressly disclaims any liability for any direct, indirect, punitive, incidental, special or consequential damages in respect of actions taken or not taken based on any or all the contents of this website or arising out of or in any way connected with use of the website or with any delay or inability to use the website, or for any information, software, products, and services obtained through the website, whether based on contract, tort, strict liability or otherwise, even if flybig has been advised of the possibility of damages.</li>
                        <li>flybig makes no representations about the suitability for any purpose of the information, software, products, and services contained on the website. All such information, software, products, and services are provided “as is” without warranty of any kind. flybig hereby disclaims all warranties and conditions—including all implied warranties of merchantability, fitness for particular purposed, title and non-infringement—with regard to the information, software, products and services contained on the website.</li>
                    </ul>
                    <p>
                        flybig's online reservation system resides on a secure server that encrypts purchase information.  Flybig uses all reasonable endeavors to protect personal information from loss, misuse and alteration. Only authorized flybig employees or agents, who are under appropriate confidentiality obligations, will have access to personal information. However, Customers will be responsible for any user ID or password that is used on our web site. Customers should take due care to protect them.  Further, the information, software, products, and services published on the website may include inaccuracies or typographical errors. Changes are periodically made to the information herein. flybig may make improvements and/or changes to the website from time to time as may require and when deems fit.
                    </p>
                </div>
            </div>
        </>
    )
}

export default TermsConditions