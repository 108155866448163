/* eslint-disable max-len */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Item.scss';

function renderTooltip(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Click to see more details...
    </Tooltip>
  );
}

export default function Engineering() {
  return (
    <div className="item">
      <div className="cellheader cardItemHeader">
        ENGINEERING
      </div>
      <hr />

      <div className="cardItemHeader cellheader">
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={
          renderTooltip
      }
        >
          <a target="_blank" href="/career/TechnicalInstructor.pdf">
            Technical Instructor
          </a>
        </OverlayTrigger>
      </div>

      <div>
        <p><strong>Qualifications</strong></p>
        <ul>
          <li>Aircraft Type Certification: Airframe and/or Powerplant Certification, or B1 / B2 or ICAO mechanical engineer license or equivalent certification, or equivalent experience on aircraft type (i.e. for prior-military service applicants)</li>
          <li>Minimum of 10000 hours (5 years) aviation maintenance experience.</li>
          <li>Minimum of 6000 hours (3 years) experience on aircraft of primary assigned program(s) or similar type aircraft</li>
          <li>Excellent presentation & interpersonal skills</li>
          <li>Technical Certification, Engineering License, or equivalent 4-year college degree</li>
        </ul>
        <p>(i.e. Federal Aviation Administration Airframe & Powerplant certification, EASA B1 / B2 certification, or documented equivalent training/experience, for example, in the event of prior-military-service applicants)</p>

        <br />

        <p><strong>Roles and Responsibilities</strong></p>
        <ul>
          <li>Act as Subject Matter Expert (SME) for technical courseware development / revision, as required</li>
          <li>Instruct clients in the safe, effective and efficient maintenance, operation, servicing and ground handling, functional operations test, adjustments/rigging, removal & installation procedures, inspection, and/or troubleshooting of airframe, powerplant and/or avionics systems.</li>
        </ul>
        <p>(Level of complexity: typically, up to ‘ATA 104 Specification Level 3’ but in special cases Level</p>

        <p>
          4 and/or Level 5, as required)
        </p>

        <ul>
          <li>
            Instructional duties, in accordance with established procedures, include:
            Classroom preparation
            <ul>
              <li>Classroom instructions as well as instructional sessions utilizing, as applicable, simulator or other part task training devices, labs, etc.</li>
              <li>If applicable in the assigned program(s) practical training sessions consisting of training modules utilizing OEM technical data and/or publications, computer-based-training programs, simulator or other part task training devices, aircraft and/or aircraft components, labs, etc.</li>
              <li>Conducts course examinations in accordance with PART 147 regulations in the course of client type certification</li>
            </ul>
          </li>

          <li>
            Only experienced candidates can apply.
          </li>
        </ul>
      </div>
    </div>
  );
}
