/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function CardiovascularDisorders() {
  return (
    <div className="saitem">
      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>

        <tr>
          <td>ANGINA</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>UNSTABLE ANGINA OR ANGINA WITH MINIMAL EXERTION</td>
          <td>PERMITTED IF CONTROLLED WITH MEDICATION AND NO CHEST PAIN AT REST.CARRY MEDICINES IN HAND BAGGAGE.</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>MYOCARDIAL INFRACTION/HEART ATTACK</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED BEFORE 10 DAYS OF THE LAST ATTACK</td>
          <td>PERMITTED TO TRAVEL AFTER 10 DAYS PROVIDED PASSENGER IS CARRYING A FIT TO FLY CERTIFICATE FROM TREATING DOCTOR.</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>CARDIAC FAILURE</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED IF ACUTE HEART FAILURE/UNCONTROLLED CRONIC HEART FAILURE</td>
          <td>PERMITTED IF CARDIAC FAILURE IS CONTROLLED AND CONDITION IS STABLE</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>CARDIAC SURGERY(HEART SURGERY LIKE CABG, ASD, VSD )</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED BEFORE 10 DAYS</td>
          <td>PERMITTED FROM 10TH DAY ONWARDS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>ANGIOGRAPHY (HEART – CORONARY ARTERY X RAYS)</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 24 HOURS</td>
          <td>PERMITTED TO FLY AFTER 24 HOURS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>ANGIOPLASTY WITH OR WITHOUT STENT (WIDENING OF ARTERIES)</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 2 DAYS</td>
          <td>PERMITTED FROM 3rd DAY ONWARDS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>HEART TRANSPLANT</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 21 DAYS</td>
          <td>PERMITTED AFTER 21 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>PACEMAKER OR DEFIBRILLATOR IMPLANTATION</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 2 DAYS</td>
          <td>PERMITTED AFTER 2 DAYS IF RYTHM IS STABLE AND NO PNEUMOTHORAX</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>Cyanotic Congenital Heart Disease</td>
          <td>MEDA (Medical Condition)</td>
          <td>---</td>
          <td>All cases to be considered by Airline doctors before being permitted to fly. Inflight oxygen requirement to be considered.</td>
          <td>Yes</td>
          <td>No</td>
        </tr>

        <tr>
          <td>Pulmonary Edema</td>
          <td>MEDA (Medical Condition)</td>
          <td>Unresolved cases.</td>
          <td>Resolved cases(including precipitating causes) after certificate to that effect required</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>Ablation Therapy</td>
          <td>MEDA (Medica Condition)</td>
          <td>Not permitted before 2 days of procedure. (May be permitted by airline doctor between 2 to 7 days). Risk of DVT to be considered.</td>
          <td>Permitted 7 days after procedure</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>Pulmonary Embolism</td>
          <td>MEDA (Medical Condition)</td>
          <td>Not permitted before 5 days</td>
          <td>Permitted ≥ 5 days if anticoagulation stable and PAO2 Normal</td>
          <td>YES</td>
          <td>NO</td>
        </tr>
      </table>
    </div>
  );
}
