/* eslint-disable */
import { useMediaQuery } from "react-responsive";
import React from "react";

export const HELPLINE_NUMBER = "flybig Helpline No.: 1800 XXXXX";
export const PHONE_NUMBER = "+91 9910 655 655";
export const EMAIL = "info@flybig.in";
export const COPYRIGHT = "Copyright 2020 flybig. All rights reserved.";

export const ADDRESS = [
  {
    icon: "/contactus/registeredOffice.ico",
    name: "Registered Office",
    post: (
      <div>
        <p>CIN: U62200MH2014PTC260145</p>
        <p>M S Sunder Corporation,</p>
        <p>313, Plot-3, D Wing, Sunder Dham, Borivali (W),</p>
        <p>Off S V Road, Poisar, Mumbai-400092,</p>
        <p>Maharashtra, India</p>
      </div>
    ),
    cordinates: "0000 0000",
    image: "/contactus/registerdOfficeAddress.png",
  },
  {
    icon: "/contactus/corporateOffice.ico",
    name: "Corporate Office",
    post: (
      <div>
        <p>Killa No. 13, 3rd Floor, Begumpur, Khatola,</p>
        <p>Gurugram - 122001, Haryana, India</p>
      </div>
    ),
    cordinates: "28.4181479 77.0070347",
    image: "/contactus/corporateOfficeAddress.png",
  },
  {
    icon: "/contactus/feedback.png",
    name: "Guest Feedback",
    post: (
      <div>
        For any feedback/suggestions/complaints or to reach out to our Guest
        Relations Team, please write to us at{" "}
        <a href="mailto:guest.relations@flybig.in">guest.relations@flybig.in</a>
      </div>
    ),
    cordinates: "28.4181479 77.0070347",
    image: "/contactus/corporateOfficeAddress.png",
  },
  {
    icon: "/contactus/helpline.png",
    name: "Guest Helpline",
    post: (
      <div>
        For any queries related to your travel, call our call center on:{" "}
        {PHONE_NUMBER}
      </div>
    ),
    cordinates: "28.4181479 77.0070347",
    image: "/contactus/corporateOfficeAddress.png",
  },
  {
    icon: "/contactus/grievance.png",
    name: "Grievance Redressal",
    post: (
      <div>
        <p>
          In compliance with The Civil Aviation Requirement, Section -3, Air
          Transport, Series M, Part IV. You may write to the following:
        </p>
        <p>
          Nodal Officer, Ms. Shivani Jain at{" "}
          <a href="mailto:nodalofficer@flybig.in">nodalofficer@flybig.in</a>
        </p>
        <p>
          Appellate authority, Mr. Rajiv Sharma at{" "}
          <a href="mailto:appellateauthority@flybig.in">
            appellateauthority@flybig.in
          </a>
        </p>
        <p>
          You may contact them from Monday – Friday between 0930hrs – 1830hrs.
        </p>
      </div>
    ),
    cordinates: "28.4181479 77.0070347",
    image: "/contactus/corporateOfficeAddress.png",
  },
  {
    icon: "/contactus/group-sales.png",
    name: "Group Sales",
    post: (
      <div>
        If you have more than 9 passengers travelling, please contact us for
        special group fares at{" "}
        <a href="mailto:groupdesk@flybig.in">groupdesk@flybig.in</a>
      </div>
    ),
    cordinates: "28.4181479 77.0070347",
    image: "/contactus/corporateOfficeAddress.png",
  },
  {
    icon: "/contactus/advertise.png",
    name: "Advertise with Us",
    post: (
      <div>
        For any enquiries related to Sales, Promotions, Media or Ancillary
        Tie-ups – please email us at{" "}
        <a href="mailto:info@flybig.in">info@flybig.in</a>
      </div>
    ),
    cordinates: "28.4181479 77.0070347",
    image: "/contactus/corporateOfficeAddress.png",
  },
];

export const SOCIAL = [
  {
    name: "facebook",
    icon: "/social/facebook.ico",
    url: "/",
  },
  {
    name: "twitter",
    icon: "/social/twitter.ico",
    url: "/",
  },
  {
    name: "instagram",
    icon: "/social/instagram.ico",
    url: "/",
  },
  {
    name: "linkedin",
    icon: "/social/linkedin.ico",
    url: "/",
  },
  {
    name: "youtube",
    icon: "/social/youtube.ico",
    url: "/",
  },
];

export const ABOUTUS = [
  {
    image: "/aboutus/corporate.jpg",
    headerIcon: "/aboutus/corporate.ico",
    header: "CORPORATE INFORMATION",
  },
  {
    image: "/aboutus/vision.jpg",
    headerIcon: "/aboutus/vision.ico",
    header: "VISION & MISSION",
  },
  {
    image: "/aboutus/values.png",
    headerIcon: "/aboutus/values.ico",
    header: "BIG VALUES",
  },
];

export const BOOKCITIES = [
  {
    name: "Bhopal",
    text: "",
    image: "/cities/Bhopal.png",
  },
  {
    name: "Indore",
    text: "",
    image: "/cities/Indore.png",
  },
  {
    name: "Jabalpur",
    text: "",
    image: "/cities/Jabalpur.png",
  },
];

export const BANNERS = [
  {
    image: "/banners/banner_up.jpeg",
  },
  {
    image: "/banners/NNS_web.png",
  },
  // {
  //   image: "/banners/Final_Banner.jpeg",
  //   url: "/coc/independence-week_offer-offer#sertion_6",
  // },
  // {
  //   image: "/banners/Website Banner Jorhat.jpeg"
  // },
  // {
  //   image: "/banners/Banner to upload.jpeg"
  // },
  // {
  //   image: "/banners/banner_pittorgarh.jpeg",
  // },
  // {
  //   image: "/banners/New Banner.jpeg",
  // },
  // {
  //   image: "/banners/KOL_PATNA.JPG",
  // },
  // {
  //   image: "/banners/SILCHAR_1.jpg",
  // },
  // {
  //   image: "/banners/BANNER_RUPSI.jpg",
  // },
  // {
  //   image: "/banners/NU_BANNER2.jpg",
  // },
  // {
  //   image: "/banners/PREPAID.JPG",
  // },
  // {
  //   image: "/banners/web-banner-01.jpg",
  // },
  {
    image: "/banners/Attention banner-01.jpg",
  },
  // {
  //   image: "/banners/banner-03.jpg",
  // },
  // {
  //   image: "/banners/23-jan-banner-01.jpg",
  // },
  // {
  //   image: "/banners/image001.jpg",
  // },
];

export function useGetMedia() {
  const res = {
    0: useMediaQuery({ minWidth: 0, maxWidth: 575 }),
    1: useMediaQuery({ minWidth: 576, maxWidth: 767 }),
    2: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    3: useMediaQuery({ minWidth: 992, maxWidth: 1199 }),
    4: useMediaQuery({ minWidth: 1200 }),
  };
  return Object.keys(res).filter((key) => res[key] === true)[0];
}

/* eslint-disable */
/** *************************************************************************
 * COPYRIGHT NOTICE:
 *  COPYRIGHT 2020 Rockwell International.
 *  All rights reserved under copyright laws.  Proprietary
 *  and confidential material.  Distribution, use and
 *  disclosure restricted by Rockwell International.
 ************************************************************************* */
