/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function ServiceDog() {
  return (
    <div className="saitem">
      <p>flybig does not accept Service Dogs on ATRs.</p>
    </div>
  );
}
