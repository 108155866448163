/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function GastroIntestinal() {
  return (
    <div className="saitem">
      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>

        <tr>
          <td>MAJOR ABDOMINAL SURGERY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY BEFORE 10 DAYS</td>
          <td>PERMITTED FROM 10 TH DAY ONWARDS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>APPENDECTOMY /LAPAROSCOPIC SURGERY (KEYHOLE)</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 5 DAYS</td>
          <td>PERMITTED FROM 5th DAY ONWARDS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>INVESTIGATIVE LAPAROSCOPY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 2 DAYS</td>
          <td>PERMITTED TO FLY AFTER 2 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>JAUNDICE( LIVER DISEASE)</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY IN CERTAIN CASES LIKE SEVERE JAUNDICE/HEPATIC FAILURE/HEPATIC ENCEPHALOPATHY</td>
          <td>IN CASE OF MILD/MODERATE JAUNDICE , NO ABDOMINAL PAIN AND NO VOMITING CAN FLY WITH COMPANY DOCTOR'S APPROVAL.</td>
          <td>YES</td>
          <td />
        </tr>

        <tr>
          <td>LIVER/KIDNEY TRANSPLANT</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 14 DAYS</td>
          <td>PERMITTED AFTER 14 DAYS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>GI Bleed</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY FOR 10 DAYS</td>
          <td>PERMITTED FROM 10th day onwards</td>
          <td />
          <td />
        </tr>
      </table>

    </div>
  );
}
