import { Form as FormikForm, Formik } from 'formik';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
	Carousel, Col, Image, Row,
} from 'react-bootstrap';
import { IconContext } from 'react-icons';
// import { FaExclamationCircle } from 'react-icons/fa';
import { GoChevronLeft as FaChevronLeft, GoChevronRight as FaChevronRight } from 'react-icons/go';
import Iframe from 'react-iframe';
import * as Yup from 'yup';
import FadeIn from '../../components/Animations/Animator';
import { SQIVA_URL } from '../../config/config';
import './Book.scss';
// import {
//   CitySelector, CountSelector,
// } from './Select';
import { BANNERS } from '../../constants';

// function CitySelectorFormik({ label, ...props }) {
//   const [field, meta, helpers] = useField(props);
//   const { value } = meta;
//   const { setValue } = helpers;
//   return (
//     <>
//       <Form.Group
//         as={Row}
//         noGutters
//         style={{
//           alignItems: 'center',
//           background: '#e2e5e8',
//           padding: '0 5px',
//           border: `${meta.touched && meta.error ? '1px solid #B62079' : 'none'
//           }`,
//           position: 'static',
//           borderRadius: '3px',
//         }}
//       >
//         <Form.Label column xs="auto">
//           {meta.touched && meta.error ? (
//             <FaExclamationCircle />
//           ) : null}
//           {label}
//         </Form.Label>
//         <Col style={{ position: 'static' }}>
//           <CitySelector
//             value={value}
//             onChange={setValue}
//             {...props}
//           />
//         </Col>
//       </Form.Group>
//     </>
//   );
// }

// function PersonSelectorFormik({ label, ...props }) {
//   const [field, meta, helpers] = useField(props);

//   const { value } = meta;
//   const { setValue } = helpers;

//   return (
//     <div style={{ position: 'relative' }}>
//       <Form.Group
//         as={Row}
//         noGutters
//         style={{
//           alignItems: 'center',
//           background: '#e2e5e8',
//           padding: '0 5px',
//           borderRadius: '3px',
//         }}
//       >
//         <Form.Label column xs="auto">
//           {label}
//         </Form.Label>
//         <Col style={{ position: 'static' }}>
//           <CountSelector
//             value={value}
//             onChange={setValue}
//             {...props}
//           />
//         </Col>
//       </Form.Group>
//     </div>
//   );
// }

export default function Book() {

	const [show, setShow] = useState(false);

	return (
		<FadeIn>
			<IconContext.Provider value={{ color: 'white' }}>
				<div>
					<div className="bookContainer">
						<div className="bookOverlayCarousel">
							<IconContext.Provider value={{ size: '4em', color: 'white', boxShadow: '5px 10px gray' }}>
								<Carousel
									style={{ margin: '0px' }}
									nextIcon={
										<FaChevronRight />
									}
									prevIcon={
										<FaChevronLeft />
									}
									indicators={false}
								>
									{
										BANNERS.map((banner, i) => (
											<Carousel.Item key={i} interval={banner.type === 'video' ? 60000 : 5000} style={{ height: 'auto', backgroundSize: 'cover' }}>
												{banner.type === 'video' ? <>
													<video class="img-fluid" autoPlay={true} muted>
														<source src={banner.image} type="video/mp4" />
													</video>

												</> : <>
													{banner.url
														&& (
															<a href={banner.url} target={banner.target}>
																<img
																	className=" w-100"
																	src={banner.image}
																	alt=""
																	style={{ height: '500px', backgroundSize: 'cover' }}
																/>

															</a>
														)}

													{
														!banner.url
														&& (
															<img onClick={() => {
																if (banner.openModal) {
																	setShow(true)
																}
															}}
																className=" w-100"
																src={banner.image}
																alt=""
																style={{ height: '500px', backgroundSize: 'cover' }}
															/>
														)
													}
												</>}

											</Carousel.Item>
										))
									}
								</Carousel>
							</IconContext.Provider>
						</div>
						{/* <div className="bookFlightOverlay">
          <Button size="lg">
            BOOK FLIGHT
          </Button>
        	</div> */}

						{/* <div className="bookFlyBigPhul">
            <Image src="/favicon.png" />
          </div> */}
						<div className="bookOverlayControlContainer">
							<Row className="bookOverlayControls mx-0">
								<Col className="override" xs={{ span: 11, offset: 1 }}>
									<Formik
										initialValues={{
											origin: null,
											destination: null,
											adult: 1,
											child: 0,
											infant: 0,
											startDate: null,
											returnDate: null,
											bookingType: 'Round trip',
											actionType: 'book',
										}}
										onSubmit={(values, actions) => {
											// POST action goes here
											setTimeout(() => {
												alert(JSON.stringify(values, null, 2));
												actions.setSubmitting(false);
											}, 1000);
										}}
										validationSchema={
											Yup.object().shape({
												origin: Yup.object()
													.required('Required'),
												destination: Yup.object()
													.required('Required'),
												startDate: Yup.string()
													.required('Required'),
												returnDate: Yup.mixed().when('bookingType', {
													is: 'Round trip',
													then: Yup.string().required('Required'),
												}),
											})
										}
									>
										{(props) => (
											<FormikForm className="bookForm">
												<Iframe
													url={`${SQIVA_URL}/search-schedule/iframe`}
													width="100%"
													height="510px"
													frameBorder="0"
												/>
												{/* <ActionType name="actionType" field="actionType" />
                        {props.values.actionType === 'book' && (
                          <>
                            <MyRadioField name="bookingType" field="bookingType" />

                            <Row style={{ position: 'relative' }}>
                              <Col xs={12} lg={6} style={{ position: 'static' }}>
                                <CitySelectorFormik
                                  name="origin"
                                  field="origin"
                                  label="FROM"
                                  placeholder="SELECT ORIGIN"
                                  options={[{ name: 'thiruvananthapuram', iata: 'JLR', airport: 'JABALPUR AIRPORT' }, { name: 'BHOPAL', iata: 'BHO', airport: 'RAJA BHOJ INTERNATIONAL AIRPORT' }]}
                                />
                              </Col>
                              <Col xs={12} lg={6} style={{ position: 'static' }}>
                                <CitySelectorFormik
                                  name="destination"
                                  field="destination"
                                  label="TO"
                                  placeholder="SELECT DESTINATION"
                                  options={[{ name: 'thiruvananthapuram', iata: 'JLR', airport: 'JABALPUR AIRPORT' }, { name: 'BHOPAL', iata: 'BHO', airport: 'RAJA BHOJ INTERNATIONAL AIRPORT' }]}
                                />
                              </Col>
                            </Row>
                            <Date
                              name="startDate"
                              field="startDate"
                              label="DEPARTURE DATE"
                            />
                            <Date
                              name="returnDate"
                              field="returnDate"
                              label="RETURN DATE"
                              disabled={props.values.bookingType === 'One-way'}
                            />
                            <PersonSelectorFormik
                              name="adult"
                              field="adult"
                              label="ADULT"
                              placeholder="0"
                            />
                            <PersonSelectorFormik
                              name="child"
                              field="child"
                              label="CHILD"
                              placeholder="0"
                            />
                            <PersonSelectorFormik
                              name="infant"
                              field="infant"
                              label="INFANT"
                              placeholder="0"
                            />
                            <Row className="justify-content-end" style={{ padding: '0 15px' }}>
                              <Button type="submit" size="lg">SEARCH FLIGHT</Button>
                            </Row>
                          </>
                        )} */}
											</FormikForm>
										)}
									</Formik>
								</Col>
							</Row>
						</div>
					</div>
					<div className="bookContainer mx-auto" style={{ maxWidth: '520px', marginTop: '90px' }}>
						{/* <div className="bookBottomRibbon">
              <Image
                src="/navbar.png"
              />
            </div> */}

						{/* <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Col xs={{ span: 10, offset: 1 }} className="bookNotes">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Col>
            </Row> */}

						<div style={{ marginTop: '40px' }} className="d-flex justify-content-center mx-4">
							<Col className="bookValues col-6 mx-2 mx-lg-3">
								<Row style={{ margin: '10px', fontSize: '1.4rem' }}>
									SAFE
								</Row>
								<Row>
									<Image src="/values/safe.ico" style={{ width: '8rem', height: '8rem' }} />
								</Row>
								<Row style={{ margin: '10px 5px' }}>
									STRESS-FREE FLYING.
								</Row>
							</Col>
							<Col className="bookValues col-6 mx-2 mx-lg-3" >
								<Row style={{ margin: '10px', fontSize: '1.4rem' }}>
									RELIABLE
								</Row>
								<Row>
									<Image src="/values/reliable.ico" style={{ width: '8rem', height: '8rem' }} />
								</Row>
								<Row style={{ margin: '10px 5px' }}>
									ON-TIME. EVERYTIME.
								</Row>
							</Col>
						</div>
						<div style={{ marginTop: '20px', marginBottom: '20px' }} className="d-flex justify-content-center mx-4">
							<Col className="bookValues col-6 mx-2 mx-lg-3">
								<Row style={{ margin: '10px', fontSize: '1.4rem' }}>
									CONVENIENT
								</Row>
								<Row>
									<Image src="/values/convenient.ico" style={{ width: '8rem', height: '8rem' }} />
								</Row>
								<Row style={{ margin: '10px 5px' }}>
									NO MIDDLE SEAT.
								</Row>
							</Col>
							<Col className="bookValues col-6 mx-2 mx-lg-3">
								<Row style={{ margin: '10px', fontSize: '1.4rem' }}>
									CARING
								</Row>
								<Row>
									<Image src="/values/caring.ico" style={{ width: '8rem', height: '8rem' }} />
								</Row>
								<Row style={{ margin: '10px 5px' }}>
									SERVICE WITH A SMILE.
								</Row>
							</Col>
						</div>
						{/* <Row style={{ marginTop: '80px', marginBottom: '20px' }}>
              <Col xs={{ span: 10, offset: 1 }} className="bookNotes">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Col>
            </Row> */}

						{/* <CityCarousel /> */}

					</div>
				</div>
			</IconContext.Provider>

			<Modal centered show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Terms and Conditions	</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul>
						<li>Above are base fare, Taxes, and fees extra</li>
						<li>Sale validity - 01st Feb 00:00 hrs to 10th Feb 23:59 hrs</li>
						<li>Travel Validity - 11th Feb 00:00 hrs to 31st Mar 23:59 hrs</li>
						<li>Cancellation / No show/offload - only statutory taxes will be refundable</li>
						<li>Rebooking / Name change charges - Rs. 1000 / pax/sector</li>
						<li>Seats will be available on an FCFS basis</li>
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</FadeIn >
	);
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
