/* eslint-disable */
import React from 'react';
import CardHolder from '../../../components/CardHolder';
import { HOME_URL } from '../../../config/config';
import './FAQItem.scss';

export default function PaymentFees({ eventKey }) {
  return (
    <div className="faqitem">
      { /* <Accordion defaultActiveKey={eventKey}> */}

      <CardHolder
        id="fareRules"
        eventKey="fareRules"
        selectedEventKey={eventKey}
        header="What are Fare Rules?"
      >
        <p>
          The rules and conditions of any reservation depend upon the fare type that the reservation has been made under. These rules and applicable conditions for a particular fare type are called Fare Rules.
        </p>
        <p>
          Given below are the fare rules applicable to the two different type of fares offered by flybig:
        </p>

        <table className="flybigTable">
          <tr>
            <th>Other Fare Rules</th>
            <th>Return Fare</th>
            <th>Group Fare</th>
          </tr>
          <tr>
            <td>
              Applicability
            </td>
            <td>
              Only  available on Domestic sectors, subject to seat availability
            </td>
            <td>
              More than 9 passengers may be booked as a group, for the same you may write to us at {' '}
              <a href="mailto:groupdesk@flybig.in">groupdesk@flybig.in</a>
            </td>
          </tr>

          <tr>
            <td>Change/Cancellations</td>
            <td>
              <p>Yes, you can cancel or change your Return Fare booking.</p>
              <p>Please refer Conditions of Carriage for change/cancellation fee and detailed T&Cs.</p>
            </td>
            <td>
              Yes, you can cancel or change your Group Fare booking.
            </td>
          </tr>

          <tr>
            <td>
              Switch to other fare type
            </td>
            <td>
              <p>
                You cannot switch to any other option of fares.
              </p>
              <p>
                Any such change will be considered a change of booking and the applicable change fee and difference of fare will accordingly apply.
              </p>
            </td>
            <td>NA</td>
          </tr>

          <tr>
            <td>Partial Cancellation</td>
            <td>You can partially cancel a booking on Return Fare</td>
            <td>NA</td>
          </tr>
        </table>

      </CardHolder>

      <CardHolder
        eventKey="BigliteFare"
        selectedEventKey={eventKey}
        id="BigliteFare"
        header="What is Biglite Fare?"
      >
        <p>Biglite are restricted fares</p>
      </CardHolder>

      <CardHolder
        selectedEventKey={eventKey}
        id="BigEasyFare"
        eventKey="BigEasyFare"
        header="What is BigEasy Fare?"
      >
        <p>Bigeasy fares is for guests who have dynamic travel needs.</p>
      </CardHolder>

      <CardHolder
        id="groupFare"
        selectedEventKey={eventKey}
        eventKey="groupFare"
        header="What is Group Fare?"
      >
        <p>
          More than 9 passengers may be booked as a group, for the same you may write to us
          at <a href="mailto:groupdesk@flybig.in">groupdesk@flybig.in</a>
        </p>

      </CardHolder>

      <CardHolder
        id="defence"
        selectedEventKey={eventKey}
        eventKey="defence"
        header="What is Defence category?"
      >
        <p>
          Upto 50% discount on base fare is provided for bookings made under Defence category and is
          open to serving and retired Defence and paramilitary personnel. This offer cannot be clubbed with
          any other promotional scheme or discount offer extended by flybig. The discount of upto 50%
          shall be reduced to the extent of any other promotional scheme available such that the overall
          discount does not exceed 50%.
        </p>

        <p>
          Defence reservations can be booked only through flybig official website <a href={`${HOME_URL}`}>www.flybig.in</a>
        </p>

        <strong>Number of seats under Defence Category</strong>
        <p>
          There is restricted inventory available, which is served on first come first serve basis. In case the inventory is exhausted under this category, you may choose to fly a different date or select a normal class fare to check the availability on the same date.
        </p>

        <strong>Family</strong>
        <p>
          The special offer is open to serving and retired Defence and paramilitary personnel and their families.
        </p>

        <strong>Required Documents</strong>
        <p>
          Travellers would be required to produce their Military ID card/ Dependent card at the time of check-in/airport entry for verification. Passengers will be denied boarding in case they fail the verification stage during the check-in process, also the ticket will stand cancel if the valid Military ID / Dependent card is not provided. A fresh ticket needs to be purchased.
        </p>

        <strong>Rescheduling</strong>
        <p>
          The reservation booked under Armed Forces Personnel Discount category can be modified with NIL charges, the ticket cancellation & No-Show would attract charges as per the normal cancellation policy. Also this fare cannot be clubbed with any other fare, hence if seat not available on the specified date, need to book a date where the seat is available.
        </p>

      </CardHolder>

      <CardHolder
        id="senior"
        eventKey="senior"
        selectedEventKey={eventKey}
        header="What is Senior Citizen Fare?"
      >

        <p>
          Upto 6% discount on base fare is provided for bookings made under Senior Citizen with the age
          limit of 60 and above. This offer cannot be clubbed with any other promotional scheme or
          discount offer extended by flybig. The discount of upto 6% shall be reduced to the extent of any
          other promotional scheme available such that the overall discount does not exceed 6%.
        </p>

        <p>
          Senior Citizen reservations can be booked only through flybig official website <a href={`${HOME_URL}`}>www.flybig.in</a>
        </p>

        <strong>Number of seats under Senior Citizen</strong>
        <p>
          There is limited inventory available, which is served on first come first serve basis. In case the inventory is exhausted under this category, you may choose to fly a different date or select a normal class fare to check the availability on the same date.
        </p>

        <strong>Family</strong>
        <p>
          The special offer is open to Senior citizen only and not their families.
        </p>

        <strong>Required Documents</strong>
        <p>
          Travellers would be required to produce their Senior Citizen Card or Any ID Proof with Age or Birth Date at the time of check-in/airport entry for verification. Passengers will be denied boarding in case they fail the verification stage during the check-in process, also the ticket will stand cancel if the valid ID Proof is not provided, A fresh ticket needs to be purchased
        </p>

        <strong>Rescheduling and Cancellation</strong>
        <p>
          The reservation booked under Senior Citizen Discount category can be modified with NIL charges, the ticket cancellation & No-Show would attract charges as per the normal cancellation policy. Also this fare cannot be clubbed with any other fare, hence if seat not available on the specified date, need to book a date where the seat is available.
        </p>
      </CardHolder>

      <CardHolder
        id="groupReservation"
        eventKey="groupReservation"
        selectedEventKey={eventKey}
        header="What is group Reservation?"
      >

        <p>A group is defined as 10 or more passengers travelling together in one reservation.</p>
        <p>
          For all your group queries we request you to send email to {' '}
          <a href="mailto:groupdesk@flybig.in">groupdesk@flybig.in</a>
          {' '}
          with your group request and the airline shall revert within 24hours.
        </p>
        <p>
          <strong>Note:</strong> if you are a travel partner with us, you are requested to login on our website (<a href={`${HOME_URL}`}>www.flybig.in</a>) and register, else you can contact our local Sales Agent in your city for becoming a Travel Partner.
        </p>
        <p>With your travel partner login id and raise your groups queries by sending email to group desk id.</p>
        <p>For any other assistance kindly contact us on below ids-:</p>
        <p>
          Direct passengers-: <a href="mailto:groupdesk@flybig.in">groupdesk@flybig.in</a>
        </p>
      </CardHolder>

      <CardHolder
        id="student"
        eventKey="student"
        selectedEventKey={eventKey}
        header="What is student fare?"
      >

        <p>Upto 10% discount on base fare is provided for bookings made under Student fare above the age of 12 years. This offer cannot be clubbed with any other promotional scheme or discount offer extended by flybig. The discount of upto 10% shall be reduced to the extent of any other promotional scheme available such that the overall discount does not exceed 10%.</p>
        <p>
          Student Fare reservations can be booked only through flybig official website
          <a href={`${HOME_URL}`}>www.flybig.in</a>
        </p>

        <strong>Number of seats under Student Fare</strong>
        <p>
          There is limited inventory available, which is served on first come first serve basis. In case the inventory is exhausted under this category, you may choose to fly a different date or select a normal class fare to check the availability on the same date.
        </p>

        <strong>Family</strong>
        <p>
          The special offer is open to Students only and not their families.
        </p>

        <strong>Required Documents</strong>
        <p>
          A valid student ID from a bonafide university/school is mandatory at the time of check-in for verification. The discount entitles you to a 10% off on base fare and additional baggage allowance of 10kgs. Failing to provide relevant documents will result in either charging prevailing fares or denial of boarding.
          {/* Travellers would be required to produce their Valid Student Identity Card of the Institution at the time of check-in/airport entry for verification. Passengers will be denied boarding in case they fail the verification stage during the check-in process, also the ticket will stand cancel if the valid ID Proof is not provided, A fresh ticket needs to be purchased */}
        </p>

        <strong>Rescheduling & Cancellation</strong>
        <p>
          The reservation booked under Student Fare category can be modified with NIL charges, the ticket cancellation & No-Show would attract charges as per the normal cancellation policy. Also this fare cannot be clubbed with any other fare, hence if seat not available on the specified date, need to book date with seat available
        </p>
      </CardHolder>

      <CardHolder
        id="paymentQuery"
        eventKey="paymentQuery"
        selectedEventKey={eventKey}
        header="How do I make a payment on flybig website?"
      >
        <ul>
          <li>
            Choose your desired flight plans and proceed to make a reservation where you will enter your credit card details such as name of cardholder, card number, expiry date, and CVV.
          </li>
          <li>
            flybig will connect to your credit card issuing bank’s website to verify if your card is secured by Verified by Visa or MasterCard SecureCode.
          </li>
          <li>
            A bank window will appear requesting a password.
          </li>
          <li>
            Once your password is verified you will be automatically directed back to flybig’s reservation page to complete your booking.
          </li>
        </ul>

        <strong>Additional Charges</strong>
        <p>
          A nominal non-refundable fee of INR 300 per person per flight for Net Banking / Debit Cards & Credit Cards will be charged as a Convenience fee.
        </p>
        <p>
          <strong>Cards Accepted:</strong>
        </p>
        <ul>
          <li>
            MasterCard
          </li>
          <li>
            Visa
          </li>
        </ul>

      </CardHolder>

      <CardHolder
        id="paymentVisa"
        eventKey="paymentVisa"
        selectedEventKey={eventKey}
        header="What if I want to make a payment on the website through my VISA Debit Card?"
      >
        <p>
          Many debit cards can also be used as credit cards on our website thereby increasing the convenience of making payments for your flybig bookings. Be sure to register them using Verified by Visa with your respective bank as well.
        </p>

      </CardHolder>

      <CardHolder
        id="masterSecure"
        eventKey="masterSecure"
        selectedEventKey={eventKey}
        header="What if my bank doesn’t offer Verified by Visa or MasterCard SecureCode?"
      >
        <p>
          It is mandatory for all credit cards issued in India to be verified by one or the other authentication services. If you are banking with an Indian bank and they do not offer this service we ask that you use an alternate credit card or another payment option. If your card is issued outside of India, your transaction may be processed without registering for one of the services. To continue to enjoy a hassle-free booking experience with added security, make sure to register for an authentication service (if you have a MasterCard or Visa Debit/Credit Card) with your respective bank.
        </p>
      </CardHolder>

      <CardHolder
        id="otherPayments"
        eventKey="otherPayments"
        selectedEventKey={eventKey}
        header="Which all are the other form of payments accepted at flybig?"
      >
        <p>
          Debit cards, Credit cards, Net banking, Travel Vouchers (only flybig), Wallet and UPI
        </p>
      </CardHolder>

      <CardHolder
        id="flybigCurrency"
        eventKey="flybigCurrency"
        selectedEventKey={eventKey}
        header="Which all currencies are accepted at flybig?"
      >
        <p>
          INR
        </p>
      </CardHolder>

      <CardHolder
        id="carryCard"
        eventKey="carryCard"
        selectedEventKey={eventKey}
        header="Do I need to carry a copy of my credit/ debit card or the original copy on the date of travel?"
      >
        <p>
          Yes, passengers should carry their card at the time of check-in for verification purpose. In case, they have used someone else’s card to book ticket than we recommend our passengers to carry the copy of the credit/ debit card. Alternatively, in the absence of original card or card copy passenger may carry the statement of the card reflecting the transaction done for said flybig reservations.
        </p>
        <p>
          Passengers may be denied boarding in case the card verification process is not complete.
        </p>
        <p>
          Guests who have used cards issued out of Indian Boundaries must carry the card or a photo copy of the card at the time of Check-in. Guests who have used such cards for making a Booking may receive a verification call. If the transaction remains unverified, the amount will be refunded and an email notification will be sent to make the payment towards such Booking through the prescribed alternate modes within the stipulated time, failing which the reservation may be cancelled. We strongly recommend that the Guests check the status of their Booking on the Website before travel.
        </p>
      </CardHolder>
      <CardHolder
        id="additionalCharges"
        eventKey="additionalCharges"
        selectedEventKey={eventKey}
        header="Are there any additional charges if I make my payment through the call centre?"
      >
        <p>
          A booking fee of INR 250 per passenger per sector (or equivalent) will be applicable for all bookings made from the call center and airport ticketing counter.
        </p>
      </CardHolder>

      <CardHolder
        id="callCenter"
        eventKey="callCenter"
        selectedEventKey={eventKey}
        header="Can I book through the call center using my credit card for another guest?"
      >
        <p>
          While it is recommended to pay for your own flight ticket, you can pay for another guest using your credit card. However, in such a case the guest should carry a photocopy of (front side only) of the credit card used for purchase of the tickets attested by the credit card holder for verification at the Airport check-in counter.
        </p>
      </CardHolder>
      <CardHolder
        eventKey="convenienceFeeReason"
        id="convenienceFeeReason"
        selectedEventKey={eventKey}
        header="Why am I charged a convenience fee?"
      >
        <p>
          Please note that, in compliance of the order of the Honourable Supreme Court dated 23.01.2013 in Civil Appeal No. 8771 of 2012, flybig does not charge any Transaction Fee from its guests.
        </p>
        <p>
          A convenience fee is collected by the airlines for establishing, maintaining and operating the online booking system, to enable the passengers to book the air tickets online while using a credit/debit card. This fees includes the charges paid by the airlines to the concerned bank (and it varies from one bank to another) for availing of such facility.
        </p>
      </CardHolder>

      <CardHolder
        id="convenienceFee"
        eventKey="convenienceFee"
        selectedEventKey={eventKey}
        header="What is Convenience Fee?"
      >
        <p>
          A convenience fee of INR 300 per person per flight for Net banking/Credit Cards/Debit Cards payments is collected by the airlines for establishing, maintaining and operating the online flight booking system. This fee includes the charges paid by the airlines to the concerned Bank (varies from one Bank to another) for availing of such facilities. A convenience fee of INR 250 is charged per person per sector on all bookings made through our call centre, mobile app and airport ticketing counter.
        </p>
        <p>
          Convenience Fee is non-refundable.
        </p>
      </CardHolder>
      <CardHolder
        id="visamaster"
        eventKey="visamaster"
        selectedEventKey={eventKey}
        header="What is Verified by Visa (VBV) or MasterCard Secure Code (MSC)?"
      >
        <p>
          Visa and MasterCard that allows you to complete online transaction securely. By authenticating and verifying your credit card details with an additional password it reduces the possibility of credit card fraud. This service is free of charge for you, the credit card holder.
        </p>
        <strong>
          How to Register
        </strong>
        <p>
          If you have a Visa or MasterCard go to your respective bank’s website where you will be asked to follow a simple step by step procedure to register for Verified by Visa, ProtectBuy or MasterCard Secure Code. After entering your credit card details you will be asked to create a password that will be used every time you make an online payment. Once complete you can continue making payments online.
        </p>

      </CardHolder>
      { /* </Accordion> */}
    </div>
  );
}
