import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './GroupBooking.scss';

export default function GroupBooking() {
  return (
    <div className="group-booking">
      <Row>
        <Col xs={12} md={10}>
          <p>Kindly direct all your group reservation queries to</p>
          <p><a href="mailto:Groupdesk@flybig.in">Groupdesk@flybig.in</a></p>
          <p>
            Our team will promptly assist you with all possible assistance in making your journey
            truly memorable with us.
          </p>
          <p>
            We thank you for your time today
          </p>
        </Col>
      </Row>
    </div>
  );
}
