import './Navbar.scss';
import React, { useState, useEffect } from 'react';

import { Row, Dropdown } from 'react-bootstrap';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { HOME_URL, SQIVA_URL } from '../config/config';
import { useGetMedia } from '../constants';

export default function FlybigNavbar({ props }) {
  const media = useGetMedia();
  const [active, setActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (media >= 2) {
      setActive(false);
    }
  }, [media]);

  const ServicesAccordion = (
    <Dropdown
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      onClick={() => setShowDropdown(true)}
      show={showDropdown}
      drop="auto"
    >
      <Dropdown.Toggle style={{ fontWeight: 'normal' }} type="Link">
        Services
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/manage-booking`}>Manage Booking</Dropdown.Item>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/web-checkin`}>Web Check-in</Dropdown.Item>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/manage-booking`}>Travel Certificate</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const servicesDropdown = (
    <Dropdown
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      show={showDropdown}
      drop="down"
    >
      <Dropdown.Toggle style={{ fontSize: 'large', fontWeight: 'normal' }} type="button">
        Services
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/manage-booking`}>Manage Booking</Dropdown.Item>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/web-checkin`}>Web Check-in</Dropdown.Item>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/manage-booking`}>Travel Certificate</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <div className="flybigNavbar">
      {media >= 2 && (
        <Row sm={0} md={12} className="horizontalNavbar">
          <ul>

            <li>
              <a target="_parent" href={`${HOME_URL}/book`}>
                Book
              </a>
            </li>
            {/* <li>
              <a target="_parent" href="/Offers">
                Offers
              </a>
            </li> */}
            <li>
              {servicesDropdown}
            </li>
            <li>
              <a target="_parent" href={`${HOME_URL}/about`}>
                About Us
              </a>
            </li>
            <li>
              <a target="_parent" href={`${HOME_URL}/contact`}>
                Contact Us
              </a>
            </li>
          </ul>
        </Row>
      )}
      {media < 2 && (
        <Row className="flybigVerticalNavbar">
          <IconContext.Provider value={{ color: 'white', size: '20px', backgroundColor: 'transparent' }}>
            <FaBars
              onClick={function () {
                setActive(true);
                window.parent.postMessage('openDropdown', '*');
              }}
              className={active ? 'm-fadeOut' : 'm-fadeIn'}
            />
            <FaTimes
              onClick={function () {
                setActive(false);
                window.parent.postMessage('closeDropdown', '*');
              }}
              className={!active ? 'm-fadeOut' : 'm-fadeIn'}
            />
            <div
              className={`flybigNavBarDropDown ${active ? 'flybigNavBarDropDownactive' : 'flybigNavBarDropDowninactive'}`}
            >
              <ul>
                <li>
                  <a target="_parent" href={`${HOME_URL}/twin-otter`} className='disabled' title='Under Construction'>
                    Twin Otter
                  </a>
                </li>
                <li>
                  <a target="_parent" href={`${HOME_URL}/atr`} className='disabled' title='Under Construction'>
                    ATR
                  </a>
                </li>
                <li>
                  <a target="_parent" href={`${HOME_URL}/Book`}>
                    Book
                  </a>
                </li>
                {/* <li>
                  <a target="_parent" href="/Offers">
                    Offers
                  </a>
                </li> */}
                <li>
                  {
                    ServicesAccordion
                  }
                </li>
                <li>
                  <a target="_parent" href={`${HOME_URL}/about`}>
                    About Us
                  </a>
                </li>
                <li>
                  <a target="_parent" href={`${HOME_URL}/contact`}>
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </IconContext.Provider>
        </Row>
      )}
    </div>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
