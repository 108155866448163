/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function AssistiveDevice() {
  return (
    <div className="saitem">
      <p>Assistive devices are not considered as part of the carry-on or checked baggage limit, however, for safety reasons they are subject to carry-on size and weight restrictions.</p>
      <p>The following is a non-exhaustive list of assistive devices:</p>
      <ul>
        <li>Crutches</li>
        <li>Cane</li>
        <li>Child carriers/backpack carriers</li>
        <li>Walker</li>
        <li>Braces/Prosthesis</li>
        <li>Manual powered wheelchair</li>
        <li>Strollers</li>
        <li>Breast pump</li>
        <li>POC and CPAP machines</li>
      </ul>

      <p>
        <strong>In case your device is not included above, please get in touch with our call center for further details.</strong>
      </p>
      <p>
        Please note that our crew will help you fit these in the overhead bin of our aircraft. If these do not fit, we would keep it in our aircraft hold, and it will be one of the first items brought to the aircraft door. You may also request to pick it up at arrival hall.
      </p>
    </div>
  );
}
