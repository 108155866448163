import React from 'react';
import {
  Button, Col, Image, Row,
} from 'react-bootstrap';
import { SQIVA_URL } from '../../config/config';
import CovidFAQ from '../FAQ/CovidFAQ';
import './TravelMandate.scss';

function TravelMandateCard({ imageURL, header, children }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Image
        src={imageURL}
        alt="imageURL"
        style={{
          borderRadius: '8px 8px 0 0',
          height: 'auto',
          backgroundSize: 'cover',
          width: '100%',
          borderColor: '#E1A6CA',
          borderWidth: 'thin',
        }}
      />

      <div
        className="oval cardContent pinkBorder"
        style={{
          borderRadius: '0 0 8px 8px', borderTop: 'none', marginTop: '0', height: '100%',
        }}
      >
        <div className="headerText" style={{ color: 'black' }}>
          {header}
        </div>
        <br />
        <div style={{ fontSize: 'medium', fontWeight: 'normal' }}>
          {children}
        </div>
      </div>
    </div>
  );
}

function TMGuidelines({
  src, alt, header, children, reverse,
}) {
  return (
    <Row style={{ flexDirection: reverse ? 'row-reverse' : 'row', alignItems: 'center' }}>
      <Col md={6}>
        <div className="header grayBackground grayBorder" style={{ textAlign: 'center' }}>
          <Image src={src} alt={alt} style={{ width: '40%', height: 'auto' }} />
        </div>
      </Col>

      <Col md={6}>
        <div className="headerText">
          {header}
        </div>

        <br />

        {children}

      </Col>
    </Row>
  );
}
export default function TravelMandate({ location }) {
  return (
    <div className="travelMandate">
      <Image
        src="/travelMandate/banner.jpg"
        alt="Covid banner"
        className=" w-100 pushDown"
        style={{
          height: 'auto', backgroundSize: 'cover', width: '100%',
        }}
      />

      <Row>
        <Col md={10}>
          <div className="header grayBackground pushDown">
            <Image src="/travelMandate/headerIcon.png" roundedCircle />
            TRAVEL MANDATES
          </div>

          <div className="oval pinkBorder headerText whiteBackground pushDown">
            <Row style={{ justifyContent: 'left' }}>
              <Col>
                OUR PRECAUTIONS FOR A SAFE TRAVEL.
              </Col>
            </Row>

            <Row className="cardHolder" style={{ alignItems: 'stretch' }}>
              <Col md="4">
                <TravelMandateCard imageURL="/travelMandate/cleaning.png" header="AIRCRAFT CLEANING">
                  We assure you that our aircraft will be cleaned and all the touch points will be thoroughly sanitized with the approved disinfectant before every flight.
                </TravelMandateCard>
              </Col>

              <Col md="4">
                <TravelMandateCard imageURL="/travelMandate/crew.png" header="CABIN CREW HYGIENE PRACTICES">
                  Our cabin crew will take care of you while maintaining hygiene and weargin the necessary protective equipment to ensure safety.
                </TravelMandateCard>
              </Col>

              <Col md="4">
                <TravelMandateCard imageURL="/travelMandate/seating.png" header="SEATING ARRANGEMENT">
                  We will ensure that there is a safe distance maintained while you are seated in the aircraft.
                </TravelMandateCard>
              </Col>
            </Row>
          </div>

          <div className="oval pinkBorder whiteBackground pushDown">
            <Row className="headerText" style={{ justifyContent: 'left' }}>
              <Col md={12}>
                GUIDELINES FOR YOUR SAFETY.
              </Col>
            </Row>

            <TMGuidelines src="/travelMandate/webCheckIn.png" alt="Web checkin" header="WEB CHECK-IN">
              <p>
                Web Check-in is a must. Make sure that you check-in online through our website or mobile app, 48 hours to 60 minutes before the scheduled departure timing of our flight. No physical check-in will be done at the airport counters.
              </p>
              <br />

              <a href={`${SQIVA_URL}/manage-booking`}>
                <Button className="mainBtn">
                  CLICK HERE
                </Button>
              </a>
            </TMGuidelines>

            <TMGuidelines src="/travelMandate/arogyaSetuApp.png" alt="Aarogya Setu App" header="INSTALL THE AAROGYA SETU APP" reverse>
              <p>
                Install the Aarogya Setu app before you reach the airport. The airport authorities will check your medical details through the app. If the app indicates a 'red' status, you will not be allowed to travel.
              </p>
            </TMGuidelines>

            <TMGuidelines src="/travelMandate/maskGloves.png" alt="Mask & Gloves" header="FACE MASK & GLOVES">
              <p>
                You must wear a face mask to enter the airport and continue wearing it all times. Also, you are advised to wear gloves and carry a bottle of hand sanitiser (350ml).
              </p>
            </TMGuidelines>

            <TMGuidelines src="/travelMandate/boardingPass.png" alt="BOARDING PASS & BAGGAGE TAG" header="BOARDING PASS & BAGGAGE TAG" reverse>
              <p>
                To enable a contactless travel journey, make sure to obtain your boarding pass and baggage tag. Please carry a printed or soft copy of the same.
              </p>
            </TMGuidelines>

            <TMGuidelines src="/travelMandate/alarm.png" alt="BE AHEAD OF SCHEDULE" header="BE AHEAD OF SCHEDULE">
              <p>
                Please react at least 2 hours prior to your scheduled departure. Our counters will now close 1 hour before your flight time. Due to the various safety measures being taken at the airport, the pre-flight journey may take longer than usual.
              </p>
            </TMGuidelines>

            <TMGuidelines src="/travelMandate/onboard.png" alt="WHEN YOU ARE ON-BOARD" header="WHEN YOU ARE ON-BOARD" reverse>
              <p>
                Once you are on-board we request you to be seated. Minimise the use of lavatories and avoid any non-essential movement. When the flight lands, wait for your turn to go off-board so as to avoid overcrowding.
              </p>
            </TMGuidelines>
          </div>
        </Col>
      </Row>

      <CovidFAQ location={location} />
    </div>
  );
}
