import React from 'react';
import {
  Row, Col, Container
} from 'react-bootstrap';

import './WeatherAdvisory.scss';

export default function WeatherAdvisory() {
  return (
    <> 
      <Container>
        <Row>
          <Col xs={12} md={12} className="WeatherAdvisory p-5">
            <h1 className="Header text-uppercase">Weather advisory</h1>
            <div className="info-text my-2 py-2">
              flybig’s aim is to always facilitate air-travel, ensure passenger comfort and maintain a high level of OTP. In order to ensure smooth free travel for passengers there is a need to update delays related to unforeseen circumstances.
            </div>
            <div className="info-text my-2 py-2">
              <b>Weather Delays :</b> In case of delays of flights,  Our team would send SMS or WhatsApp messages to update the passengers regarding the status of the flight.
            </div>
            <div className="info-text my-2 py-2">
              <b>Technical Delays :</b> In case of delays due to technical snags,  a need is felt to provide the information to the passengers through SMS or  WhatsApp
            </div>
            <p className="info-text my-2 py-2">
              <b>Cancellations :</b> Our endeavour of the airline is always OTP and make all efforts for flights to reach the destination. However, there are instances where the airline is constrained to cancel flights due to unforeseen circumstances, in this case also we would inform the passengers through SMS or WhatsApp.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}