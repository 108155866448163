/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';
import { PHONE_NUMBER } from '../../../constants';

export default function SensoryImpairments() {
  return (
    <div className="saitem">
      <div className="header">
        <p>
          For our guests with sensory impairments
        </p>
      </div>
      <p>
        We at flybig are highly committed to guest safety and convenience and put forward our best to ensure all our guests experience the finest services on board our flight. We have learnt to interact with people with disabilities, and continue to find better ways to serve our guests with special needs
      </p>
      <strong>(a)    Visual Impairment</strong>
      <p>
        Please let us know what we can do to best assist you. Please find below some valuable information which would help you travel hassle free:
      </p>
      <ul>
        <li>Request for special assistance while making your reservation.</li>
        <li>Request for special assistance while making your reservation.</li>
        <li>Please reach the airport two (2) hours prior to the scheduled time of departure and inform our staff at the reservation desk. They would be happy to assist you with your check-in and boarding formalities.</li>
        <li>During the flight you may request our cabin crew for any assistance you may need.</li>
        <li>We can assist with carry-on items, assistive devices, getting to restrooms, opening snacks, and getting to the next gate if you have a connecting flight.</li>
        <li>
          To better accommodate your seating requirements, please select seats either online or by calling us at our call centre @
          {` ${PHONE_NUMBER}`}
          .
        </li>
      </ul>
      <p>On board our aircraft, we have Safety Briefing Card printed in braille. Our cabin crew will read the meal menu options, identify food items, assist you in their placement on the tray and assist with opening the food package, upon request.</p>
      <p>Do please note that some of the airports are silent airports and there are no pre-departure announcements made.</p>
      <strong>(b)   Hearing Impairment</strong>
      <p>
        Please let us know what we can do to best assist you. Please find below some valuable information which would help you travel hassle-free:
      </p>
      <ul>
        <li>Request for special assistance while making your reservation.</li>
        <li>Please reach the airport two (2) hours prior to the scheduled time of departure and inform our staff at the reservation desk. They would be happy to assist you with your check-in and boarding formalities.</li>
        <li>During the flight you may request our cabin crew for any assistance you may need.</li>
        <li>We can assist with carry-on items, assistive devices, getting to restrooms, opening snacks, and getting to the next gate if you have a connecting flight.</li>
        <li>
          To better accommodate your seating requirements please select seats either online or by calling us at our call centre @
          {` ${PHONE_NUMBER}`}
          .
        </li>
      </ul>
      <strong>(c)    Visual and Hearing Impairment</strong>
      <p>
        Please let us know what we can do to best assist you. Please find below some valuable information which would help you travel hassle free:
      </p>
      <ul>
        <li>Request for special assistance while making your reservation</li>
        <li>Please reach the airport two (2) hours prior to the scheduled time of departure and inform our staff at the reservation desk. They would be happy to assist you with your check-in and boarding formalities</li>
        <li>During the flight you may request our cabin crew for any assistance you may need.</li>
      </ul>
    </div>
  );
}
