// import React, { useEffect } from 'react';
import React, {  } from 'react';
import '../FAQItems/FAQItem.scss';
// import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
import {  SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'mandatoryCheckin',
    header: 'Is web check-in mandatory?',
    body: (
      <p>
        Yes. Web check-in is now mandatory as per the new flight travel guidelines. You can do a
        {' '}
        <a href={`${SQIVA_URL}/web-checkin`}>www.flybig.in/web-checkin</a>
        {' '}
        for your flight from 72 hours until 60 minutes before the departure.
      </p>
    ),
  },
  {
    id: 'baggageReceipt',
    header: 'Do I have to check-in my baggage online? How will I receive the check-in baggage receipt?',
    body: (
      <p>
        Yes. To avoid crowding at the baggage counter, you are required to check-in your baggage online. You will be provided with an electronic receipt either via SMS or through email.
      </p>
    ),
  },
  {
    id: 'baggageTag',
    header: 'How do I put my baggage tag on my check-in baggage?',
    body: (
      <p>
        For tracking your baggage, you will be provided with the baggage identification number. You can print it and affix it on your bag at a prominent place. If you are unable to print it, then you can mention the PNR number and your name on a piece of paper and affix it with a strong string on your bag.
      </p>
    ),
  },
  {
    id: 'eBoardingPass',
    header: 'Will I get an e-boarding pass?',
    body: (
      <p>
        Yes. You will get an e-boarding pass after you have done the web check-in and submitted the health declaration form.
      </p>
    ),
  },
  {
    id: 'checkinProcess',
    header: 'What is the check-in process at the airport?',
    body: (
      <p>
        Due to the current COVID-19 crisis, there are specific changes in the way you will check-in for your flight at the airport. The process is as follows:
        <ul>
          <li>To avoid queues at the airport, you are required to do a mandatory www.flybig.in/web-checkin  which includes checking in your baggage online and filling a self-declaration form</li>
          <li>As you reach the airport, you will undergo thermal screening. You need to show your health status on the ‘Aarogya Setu’ app along with the e-boarding pass</li>
          <li>For baggage drop, you are required to self-drop the baggage on the belt</li>
          <li>After the baggage drop, you can move towards the boarding gate</li>
        </ul>
        All through the check-in process at the airport, there will be clear indicators for social distancing to avoid physical contact or crowding. Make sure you follow these indicators.
      </p>
    ),
  },
  {
    id: 'aarogyaRegister',
    header: 'Is it mandatory to register on the Aarogya Setu app?',
    body: (
      <p>
        As per the government guidelines for air travel, it is mandatory to show your health status on the Aarogya Setu app.
      </p>
    ),
  },
  {
    id: 'closureTime',
    header: 'What is the closure time for check-in and boarding the flight?',
    body: (
      <p>
        As contactless travel is the norm, web check-in is mandatory. However, check-in counters close 60 minutes before the departure of the flight. Boarding gates for the flights close 25 minutes before the departure.
      </p>
    ),
  },
];

export default function Checkin({ eventKey }) {
  // useEffect(() => {
  //   if (document.location.hash === '#some-anchor') {
  //     setTimeout(() => {
  //       document
  //         .querySelector('#some-anchor')
  //         .scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     }, 300);
  //   }
  // }, []);
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              id={item.id}
              header={item.header}
              selectedEventKey={eventKey}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
