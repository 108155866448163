/* eslint-disable */
import React from 'react';
import './Item.scss';

export default function CabinCrew() {

  return (
    <div className="item">
      <h4 className='fw-bold pl-0 text-decoration-underline'>FRESHERS (ATR 72 & DHC 06)</h4>

      <p>Freshers applying for Cabin Crew may share their resume along with passport and full size photograph
        at <a href="mailto:info@fwstc.in">info@fwstc.in</a></p>

      <h4 className='fw-bold pl-0 text-decoration-underline'>EXPERIENCED CABIN CREW (SENIOR CABIN CREW - ATR 72 & DHC 06)</h4>

      <strong>APPLICABILITY</strong>
      <ul>
        <li>Female Indian nationals, with valid Indian Passport, minimum 21 years of age.</li>
        <li>Valid Competency Card / SEP Card with 1 endorsed Recurrent or 1 year of type experience.</li>
      </ul>

      <strong>EDUCATIONAL QUALIFICATION</strong>
      <ul>
        <li>A minimum of 10+2, from a recognised Board or University.</li>
      </ul>

      <strong>COMMUNICATION</strong>
      <ul>
        <li>Fluency and clarity of speech in both, English and Hindi.</li>
      </ul>

      <strong>HEIGHT AND WEIGHT</strong>
      <ul>
        <li>A minimum of 155 cms, and weight proportionate to BMI.</li>
      </ul>

      <strong>APPEARANCE</strong>
      <ul>
        <li>Positive body language and well groomed. No visible Tattoos.</li>
      </ul>

      <strong>DOCUMENTS TO BE E-MAILED</strong>
      <ul>
        <li>CV</li>
        <li>Full Length & Passport Size Photo</li>
        <li>Competency Card/SEP Card</li>
        <li>Educational Certificates</li>
        <li>Passport (Issue date: 2019 onwards)</li>
        <li>Aadhaar</li>
        <li>PAN Card</li>
        <li>Email/Letter of Relieving/Resignation from the last Employer.</li>
        <li>AEP surrender copy (Receiving)</li>
      </ul>

      <strong>JOB TYPE - FULL-TIME</strong>
      <ul>
        <li>All applications from experienced Cabin Crew need to be emailed at hr@flybig.in and we shall
          get back to the shortlisted candidates.</li>
      </ul>

      <strong>LOCATION</strong>
      <ul>
        <li>Candidate should be willing to relocate anywhere across the flybig network as per company
          requirement.</li>
      </ul>

      <p>flybig does not solicit any kind of payment for interviews & recruitment. For flybig's official job postings,
        please visit our careers page at <a href="https://flybig.in/careers">https://flybig.in/careers</a></p>

    </div>
  );
}
