/* eslint-disable max-len */
import React from 'react';
import './SAItem.scss';

export default function ENTDisorder() {
  return (
    <div className="saitem">
      <table className="flybigTable">
        <tr>
          <th>MEDA CASES</th>
          <th>ABBREVIATIONS</th>
          <th>NOT PERMITTED (PERMITTED- UNDER CERTAIN CONDITIONS)</th>
          <th>PERMITTED</th>
          <th>FIT TO FLY CERTIFICATE  ( NOT OLDER THAN 72 HOURS )</th>
          <th>MEDIF FORM</th>
        </tr>

        <tr>
          <td>OTITIS MEDIA AND SINUSITIS</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>ACUTE ILLNESS WITH LOSS OF EUSTACHIAN TUBE FUNCTION (severe cold and ear infection)</td>
          <td>IF ABLE TO CLEAR EAR</td>
          <td>NO</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>MIDDLE EAR SURGERY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY BEFORE 10 DAYS</td>
          <td>PERMITTED FROM 10TH DAY ONWARDS WITH FIT TO FLY FROM TREATING ENT SPECIALIST</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

        <tr>
          <td>TONSILLECTOMY</td>
          <td>MEDA (MEDICAL CONDITION)</td>
          <td>NOT PERMITTED TO FLY BEFORE 10 DAYS</td>
          <td>PERMITTED FROM 10TH DAY ONWARDS</td>
          <td>YES</td>
          <td>NO</td>
        </tr>

      </table>
    </div>
  );
}
