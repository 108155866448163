import './Covid.scss';

const Covid = () => {

    return (
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <p className="card-text fs-1">In case of a passenger falling sick and is diagnosed as COVID-19 positive necessitating a change in the confirmed travel plan, the guest will be allowed to avail of one <b>free date change*</b></p>
                    <p>(*T&C Apply) for his/her confirmed ticket.</p>
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr class="">
                                    <th scope="row">Applicability</th>
                                    <td>For all	 S9 documents across the flybig network.</td>
                                </tr>
                                <tr class="">
                                    <th scope="row">Passenger type</th>
                                    <td>
                                        <p>Valid for all Confirmed Guests.</p>
                                        <p>Guests are required to produce a <b className='text-decoration-underline'>COVID-19-positive certificate not older than D-07 days from the date of scheduled flight departure.</b></p>
                                        <p>The waiver would be extended only to the guest (as per booked PNR), who needs to complete mandatory government formalities such as quarantine, in case of tested COVID-19 positive. </p>
                                    </td>
                                </tr>
                                <tr class="">
                                    <th scope="row">Travel date</th>
                                    <td>
                                        <p className='fw-bold'>Confirmed New Travel date should be in the next 30 days (from the date of travel)</p>
                                        <p>To avoid a no-show, the booking needs to be canceled on time as per fare rules.</p>
                                    </td>
                                </tr>

                                <tr class="">
                                    <th scope="row">Waiver benefit</th>
                                    <td>
                                        <p>One <b className='text-decoration-underline'>Free</b> Date change.</p>
                                        <p>No charges for the same RBD are available even if the fare is higher, however, a difference in fare will be charged in case of a higher RBD</p>
                                        <p>Free No-Show will be applicable only in case of a Covid Test conducted within 48hrs of departure and a Covid-19 positive report received within the ambit of the “No-Show definition” of respective sector fare rules. </p>
                                    </td>
                                </tr>
                                <tr class="">
                                    <th scope="row">Cancellation /Refund</th>
                                    <td>
                                        <p>In case of a refund, applicable cancellation charges will apply</p>
                                    </td>
                                </tr>
                                <tr class="">
                                    <th scope="row">Documentation</th>
                                    <td>
                                        <p>A covid-19 positive certificate/report from an authorized diagnostic center is a MUST.</p>
                                        <p className='fw-bold'>A valid RTPCR report which has been processed through an ICMR-approved lab and has a QR Code for verification is a must. </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Covid;