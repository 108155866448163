/* eslint-disable max-len */
import React from 'react';
import { PHONE_NUMBER } from '../../../constants';
import './SAItem.scss';

export default function SpinalSupportEquipment() {
  return (
    <div className="saitem">
      <ul>
        <li>
          Guests who bring their own spinal support equipment can call our call centre @
          {` ${PHONE_NUMBER}`}
          , at least seven (7) working days prior to departure to inform us about their SSE specifications.
        </li>
        <li>Our team will check and confirm if your equipment can be accommodated safely on our aircraft.</li>
        <li>Guest supplied spinal support equipment will be carried free of charge.</li>
      </ul>
    </div>
  );
}
