/* eslint-disable */
import React from 'react';
import { SQIVA_URL } from '../../../config/config';
import CardHolder from '../../../components/CardHolder';

import './FAQItem.scss';

export default function CheckIn({ eventKey }) {
  return (
    <div className="faqitem">
      { /* <Accordion defaultActiveKey={eventKey}> */}
      <CardHolder
        id="checkin"
        eventKey="checkin"
        selectedEventKey={eventKey}
        header="How do I check-in at the airport?"
      >
        <p>
          Passengers can check-in at flybig counters located at airports. Passengers must keep in mind the following rules related to airport check-ins:
        </p>

        <p>
          <strong>Domestic Travel</strong>
          <ul>
            <li>
              Check-in counters closes 45 minutes prior to scheduled departure time.
            </li>
            <li>
              Boarding gates close 25 minutes prior to departure at all airports. Passengers must be present at the boarding gate no later than the time specified by flybig when they check in or any subsequent announcements made at the airport.
            </li>
          </ul>
        </p>

        <p>
          flybig recommends that Guests report for Check-in at least 2 hours prior to the departure of the scheduled flight. Passenger journey will be smoother if they allow themselves ample time to comply with the Check-in timelines. flybig reserves the right to cancel a Guest’s reservation if he/she does not comply with the check-in timelines.
        </p>
      </CardHolder>
      <CardHolder
        id="webcheckin"
        eventKey="webcheckin"
        selectedEventKey={eventKey}
        header="What is Web Check-in?"
      >

        <p>
          Avoid long queues at the airport and travel hassle-free with web check-in. Web Check-in opens 72hrs prior to the flight departure and closes 02hr before the scheduled departure of the flight.
        </p>

        <p>
          <strong>Useful Tips:</strong>
          <ul>
            <li>Please don’t forget to carry a printout of your boarding card.</li>
            <li>If you're carrying check-in baggage, please go straight to our bag drop counter.</li>
            <li>Hand baggage weight should not be more than 7 kgs on ATR & 3kgs on DHC6</li>
            <li>Free check-in baggage allowance is 15 kgs on ATR & 7kgs on DHC6. In case you wish to pre purchase excess baggage, please click visit our website <a href="www.flybig.in">www.flybig.in</a> or alternatively call our guest helpline +91 9910655655</li>
            <li>Departure gate closes 25 minutes prior to the scheduled departure time.</li>
            <li>Please make sure you clear all your pending payments in order to Web Check-in for your upcoming flight.</li>
          </ul>
        </p>

        <p>
          Guests are not eligible for Web Check-in service –
          <ul>
            <li>
              If guest have a reservation for any Special Service besides meals, for example, Wheelchair, Medical Passenger
            </li>
            <li>
              If guest have made a booking under Special PTC (Armed Forces Personnel, Senior Citizen, Unaccompanied Minor, Student Fares), Group Booking (more than 9 guests on a single reservation number) or guest travelling with Infant.
            </li>
          </ul>
        </p>

      </CardHolder>
    </div>

  );
}
