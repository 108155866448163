import React from 'react';
import './FAQItem.scss';
// import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
import { HOME_URL } from '../../../config/config';

const data = [
	{
		id: 'GQNameMismatch',
		header: 'Can I be denied boarding if my name or title on the booking does not match the details on my photo identity card?',
		body: (
			<p>
				Yes, boarding can be denied if the name or title on the itinerary does not match the name on the identity card.
			</p>
		),
	},
	{
		id: 'GQDocuments',
		header: 'Which all documents are accepted for Domestic Flights?',
		body: (
			<p>
				<ul>
					<li>Valid Passport</li>
					<li>Valid Driving License</li>
					<li>Election Photo Identification Card</li>
					<li>PAN card issued by the Income Tax department</li>
					<li>Photo identity card issued by the employer, being government and reputed private sector organisations</li>
					<li>Children travelling on flybig should carry their school identification cards or any other photo identification proof</li>
					<li>Valid birth certificates of infants need to be produced at the time of check-in</li>
					<li>For Foreign Nationals who travel on domestic flights, the only valid photo ID for travel is Passport.</li>
				</ul>

				There shall not be any requirement of identity proof in respect of Infant/Minor accompanied by their parents/guardian who is a bonafide passenger having a valid identity proof as listed above. However, an unaccompanied minor shall be required to produce a valid identity proof as listed above.
			</p>
		),
	},
	{
		id: 'GQDocumentsForeignNational',
		header: 'Which all documents are accepted for Foreign Nationals ?',
		body: (
			<>
				<p>The only valid photo ID for travel is Passport.</p>
				<p>Guest’s passport must be valid for at least six (6) months from the date of travel.</p>
			</>
		),
	},
	{
		id: 'GQDocumentImphal',
		header: 'Is there any additional document required for entering Imphal?',
		body: (
			<>
				<p>
					All foreign nationals travelling to Imphal only are required to carry a Protected Area Permit, issued by the Home Department of Manipur after getting the approval from Ministry of Home, Govt of India. The Permit also specifies the passport number and duration of stay in the stated city. As per the Govt of India directive flybig is entitled to deny boarding rights to any Foreign National in the absence of a valid Protected Area Permit and the entire amount would be forfeited.
				</p>
				<strong>For Nationals of any other country</strong>
				<p>
					For nationals of any other country, passport is the only valid document for travel.
				</p>
			</>
		),
	},
	{
		id: 'GQNoTicket',
		header: 'The amount has been deducted from my account however, I have not received the ticket?',
		body: (
			<>
				<p>
					Amount may be deducted from your account but the ticket has not been generated:
				</p>
				<p>The amount received by us will automatically be refunded within 5 business days</p>
				<p>In case it is not refunded then you may share the following Information with our call center team or contact our guest relations team via email (groups.relations@flybig.in):</p>

				<ul>
					<li>Transaction ID sent by flybig via email at the time of initiating the payment along with amount, date and time of transaction OR</li>
					<li>PGI Reference number along with amount, date and time of transaction OR</li>
					<li>Scanned copy of bank statement</li>
				</ul>
			</>
		),
	},
	{
		id: 'GQPrintOut',
		header: 'How can I get a print out of my ticket/itinerary?',
		body: (
			<>
				<p>Click on View/ChangeBooking(click here link) on our home page and retrieve your reservation by entering your PNR (a six digit code generally sent as an SMS after you make a reservation) and email address or last name. Once it is reservation is retrieved you can take a print out of your ticket.</p>
				<p>Note: If you have made the reservation through a travel agency you may have to get your email address updated in order to retrieve the booking online.</p>
			</>
		),
	},
	{
		id: 'GQPrintBoardingPass',
		header: 'How can I print/re-print my Web Check-in boarding pass?',
		body: (
			<p>
				If you have already Web Check-in, you will need to click on the (www.flybig.in\web-check-in) link on our home page and retrieve your reservation using your PNR (a six digit code generally sent as an SMS after you make a reservation) or last name. Once your reservation is retrieved you can print or reprint your boarding pass.
			</p>
		),
	},
	{
		id: 'GQBookSeat',
		header: 'How can I book a specific seat for myself on the flight?',
		body: (
			<p>
				Passengers can book specific seats on the flight through our website or at the airport.
			</p>
		),
	},
	{
		id: 'GQPriorityCheckin',
		header: 'What is Priority Check-in Service?',
		body: (
			<>
				<p>flybig offers Priority Check-in Service for a hassle free experience at the airport. This Service helps passengers save time by getting their luggage on priority at arrivals.</p>
				<p>
					The priority check-in service can be purchased through flybig’s website (
					<a href={HOME_URL}>www.flybig.in</a>
					) and Call center until two (2) hours prior to domestic departures and at the airport up until the closure of the check-in process for the relevant flight for which the passenger intends to purchase the service with a charge of INR 250 all inclusive.
				</p>
			</>
		),
	},
	{
		id: 'GQOnBoardPurchase',
		header: 'On-board purchases:',
		body: (
			<p>
				We accept cash payments for on-board purchases.
			</p>
		),
	},
	{
		id: 'GQAlcohol',
		header: 'Is alcohol offered on-board flybig?',
		body: (
			<p>
				As per DGCA regulations serving and consumption of alcohol is strictly prohibited on Domestic flights.
			</p>
		),
	},
	{
		id: 'GQSmoking',
		header: 'Is smoking allowed on- board flybig flights?',
		body: (
			<p>
				Smoking is strictly prohibited on any flybig aircraft
			</p>
		),
	},
	{
		id: 'GQFoodWater',
		header: 'Can I carry water & food on board?',
		body: (
			<table className="flybigTable m-0" style={{ width: '90%' }}>
				<tbody>
					<tr>
						<th>ATR</th>
						<th>DHC6</th>
					</tr>
					<tr>
						<td>flybig allows passengers to bring food items on-board. Allowed food items include cold snacks, non-alcoholic drinks, snack bars and biscuits.</td>
						<td>Not allowed</td>
					</tr>
					<tr>
						<td>Messy, oily or strong smelling food items are not allowed on-board</td>
						<td>Messy, oily or strong smelling food items are not allowed on-board</td>
					</tr>
				</tbody>
			</table>
		),
	},
	{
		id: 'GQEarPlugs',
		header: 'Does flybig provide ear plugs and cotton?',
		body: (
			<>
				<p><strong className='ml-0'>ATR</strong> - flybig currently does not provide ear plugs and cotton on-board. Therefore, we recommend that passengers carry their own chewing gums, cotton and/or ear plugs for their comfort. It is also recommended that passengers carry blankets/ shawl on board. We would be serving candies on board (the ones you can keep in your mouth and suck, it helps in releasing pressure from the ears, although not recommended for our little guests)</p>
				<p><strong className='ml-0'>DHC6</strong> -  flybig provides ear plugs/cotton on the DHC-6 Twin otter aircraft along with candies on board</p>
			</>
		),
	},
	{
		id: 'GQMedicalAssistance',
		header: ' Will the cabin crew be able to provide me with any medical assistance?',
		body: (
			<>
				<p>flybig would like to mention that our cabin crews are not authorized to give special assistance (e.g. lifting etc.). Further they are trained only in first-aid and are not permitted to administer any injections or to give any medication (The crew are trained first aiders and do administer certain medications orally / application from the on-board First Aid Kit).</p>
				<p>
					A guest who has agreed to our condition of carriage (www.flybig.in/coc) agrees and understands that he/she has accepted to undertake the travel on flybig on his/her own will and volition, and further agrees that flybig will not be responsible for any liability other than the liability as specified in the condition of carriage (
					<a href={`${HOME_URL}/coc`}>www.flybig.in/coc</a>
					)
				</p>
			</>
		),
	},
	{
		id: 'GQAircraftType',
		header: 'What type of aircraft does flybig fly?',
		body: (
			<table className="flybigTable m-0" style={{ width: '90%' }}>
				<tbody>
					<tr>
						<th className='text-center'>Aircraft type</th>
						<th className='text-center'>Number</th>
						<th className='text-center'>Seating Capacity</th>
					</tr>
					<tr>
						<td className='text-center'>ATR-72/500</td>
						<td className='text-center'>1</td>
						<td className='text-center'>72</td>
					</tr>
					<tr>
						<td className='text-center'>ATR-72/600</td>
						<td className='text-center'>2</td>
						<td className='text-center'>70</td>
					</tr>
					<tr>
						<td className='text-center'>DHC-6/400 Twin Otter</td>
						<td className='text-center'>1</td>
						<td className='text-center'>19</td>
					</tr>
				</tbody>
			</table>
		),
	},
	{
		id: 'GQDestinations',
		header: 'What all cities does flybig fly to?',
		body: (
			<p>
				flybig has a total destination count of 17. This includes Guwahati, Rupsi, Agartala, Kolkata, Dibrugarh, Pasighat, Tezu, Hollongi (Itanagar), Patna, Imphal, Silchar, Dehradun, Pitorhgarh, Pantnagar, Ludhiana, Bhatinda & Hindon – this information will be updated as more destinations are added in future.
			</p>
		),
	},
	{
		id: 'GQNotSurname',
		header: "How should I book a ticket if I don't have a surname?",
		body: (
			<div>
				<p className="mb-1">In case, you don't have a surname mentioned in your government ID, you can use your first name as your surname during your booking.</p>
				<p className="mb-1"><b>For example:</b> A person with a name "ABC" can fill their surname as "ABC" during the booking. This way the booking would be made in the name of (Title) ABC ABC.</p>
			</div>
		),
	},
];

export default function GeneralQueries({ eventKey }) {
	return (
		<div className="faqitem">
			{/* <Accordion defaultActiveKey={eventKey}> */}
			{
				data.map((item) => (
					<CardHolder
						eventKey={item.id}
						selectedEventKey={eventKey}
						id={item.id}
						header={item.header}
					>
						{item.body}
					</CardHolder>
				))
			}
			{/* </Accordion> */}
		</div>
	);
}
