/* eslint-disable max-len */
import React from 'react';
import './Item.scss';

export default function GroundStaff() {
	return (
		<>
			<div className="item">
				<div className="cellheader cardItemHeader">
					GROUND STAFF
				</div>
				<p><strong>Basic AvSec</strong></p>
				<p>We are searching for talented personalities to join our airline. If you are looking for a bright future, launch your career with us!</p>
				<p>We offer you ‘state of the art training’, an exciting lifestyle and a promising career, not just a job!</p>
				<p>We expect that you look after our ‘security programme’ on ground by delivering the comprehensive security services.</p>
				<br />
				<p><strong>APPLICABILITY</strong></p>
				<p>
					Male / Female
				</p>
				<br />
				<p><strong>MINIMUM QUALIFICATION & REQUIREMENT</strong></p>
				<ul>
					<li>Indian National, PAN Card, Aadhar Card</li>
					<li>Minimum Age of 18 years</li>
					<li>Minimum Height of 155 cms</li>
					<li>Graduates</li>
					<li>Excellent Health & Fitness</li>
					<li>Fluency in Spoken & Written English & Hindi</li>
					<li>Outgoing personality with excellent interpersonal skills</li>
					<li>Ability to work within team</li>
					<li>Passionate about Customer (guest) Service</li>
				</ul>
				<br />
				<p><strong>Documents to be emailed</strong></p>
				<ul>
					<li>CV</li>
					<li>Full Length Photo</li>
					<li>Passport Size Photo</li>
					<li>Competency Card (if any)</li>
				</ul>
				<br />
				<p><strong>Preference</strong></p>
				<ul>
					<li>

						AVSEC Screeners with valid certification
					</li>
				</ul>
			</div>

			<div className="item">
				<div className="cellheader cardItemHeader">
					Security Openings
				</div>

				<p className='fw-bold'>Open Positions in Security</p>
				<ol>
					<li className='no-star'> Executive Security</li>
					<li className='no-star'> Senior Executive Security</li>
					<li className='no-star'> Supervisor Security experience minimum 5 years</li>
					<li className='no-star'> Security In-Charge experience – minimum 7 years</li>
				</ol>

				<p className='fw-bold'>Mandatory certification</p>
				<ul>
					<li>Valid Basic AVSEC</li>
					<li>AVSEC Screeners with valid certification</li>
				</ul>

				<p className='fw-bold'>MINIMUM QUALIFICATION & REQUIREMENT</p>
				<ul>
					<li>Indian National, PAN Card, Aadhar Card</li>
					<li>Minimum Age of 18 years</li>
					<li>Minimum Height of 155 cms</li>
					<li>Graduates</li>
					<li>Excellent Health & Fitness</li>
					<li>Fluency in Spoken & Written English & Hindi</li>
					<li>Outgoing personality with excellent interpersonal skills</li>
					<li>Ability to work within team.</li>
					<li>Passionate about guest Service</li>
				</ul>

				<p className='fw-bold'>Documents to be emailed.</p>
				<ul>
					<li>CV</li>
					<li>Full Length Photo</li>
					<li>Passport Size Photo</li>
					<li>Valid DG, BA and Screener Certificates</li>
				</ul>

				<p>Location hiring for – Guwahati, Agartala, Dibrugarh, Rupsi, Kolkata, Tezu, Pasighat, Patna, Imphal, Silchar, Hollongi, Hindon, Dehradun, Pantnagar, Pithoragarh, Ludhiana or any flybig Network</p>
				<p>Please send your CV to <a href="mailto:hr@flybig.in">hr@flybig.in</a></p>
			</div>
		</>
	);
}
